<div class="row no-margin">
  <div class="col-sm-3"></div>
  <div class="col-sm-3">
    <gib-datepicker [placeholder]="'from'" [control]="dateFrom"></gib-datepicker>
  </div>
  <div class="col-sm-3">
    <gib-datepicker [placeholder]="'to'" [control]="dateTo"></gib-datepicker>
  </div>
  <div class="col-sm-3"></div>
</div>
<div class="row no-margin">
  <div class="col-sm-12">
    <div class="grid-wrapper" *ngIf="headerColumns.length > 0">
      <div class="header-wrapper">
        <div class="name-col">
          <div></div>
          <div></div>
        </div>
        <div class="month-wrapper" [style.minWidth]="monthWrapperWidth">
          <div class="month-col" *ngFor="let column of headerColumns">
            <div class="month-name">
              {{column.month}}
            </div>
            <div class="{{'month-day ' + (entry.isWeekend ? 'weekend-marker': '')}}" *ngFor="let entry of column.days">
              {{entry.day}}
            </div>
          </div>
        </div>
      </div>
      <div class="row-wrapper">
        <div *ngFor="let meetingRoom of schedules; let i = index" [style.lineHeight]="'11px'">
          <div class="name-col" [style.top.px]="index*24">
            {{meetingRoom.alias}}
          </div>
          <div class="trainer-day-wrapper" [style.minWidth]="monthWrapperWidth">
            <div class="day-col" *ngFor="let day of meetingRoom.meetingSchedules" [style.background]="day.color" [style.marginRight]="day.marginRight"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
