<div class="row">
  <div class="col-sm-12">
    <h1>{{ title | translate }}</h1>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <mat-tab-group (selectedTabChange)="clicked($event)">
      <mat-tab label="{{ 'openTasks' | translate }}">
        <event-task-table #eventTable [tasks]="tasks" (taskSelected)="openFormForTask($event, taskIdFilter)"></event-task-table>
      </mat-tab>
      <mat-tab label="{{ 'completedTasks' | translate }}">
        <completed-task-table [completedTasks]="completedTasks"></completed-task-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
