import { Component, OnInit, Input } from '@angular/core';
import { Chat } from '../../services/event.service';

@Component({
  selector: 'gib-chat',
  templateUrl: './gib-chat.component.html',
  styleUrls: ['./gib-chat.component.scss']
})
export class GibChatComponent implements OnInit {

  @Input() chatElements: Chat[];

  constructor() { }

  ngOnInit() {
  }

}
