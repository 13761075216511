import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { EnvService } from "./env.service";

export class PrivacyStatementDto {
  de: string;
  en: string;
  de3rd: string;
  en3rd: string;
  deFilename: string;
  enFilename: string;
  deFiletitle: string;
  enFiletitle: string;

  constructor() {
    this.de = "";
    this.en = "";
    this.de3rd = "";
    this.en3rd = "";
    this.deFilename = "";
    this.enFilename = "";
    this.enFiletitle = "";
    this.deFiletitle = "";
  }
}

export class HealthInsurance {
  companynameAlias: string;
  privacyStatementDe: string;
  privacyStatementEn: string;
  privacyStatement3rdPartyDe: string;
  privacyStatement3rdPartyEn: string;

  constructor() {
    this.companynameAlias = "";
    this.privacyStatementDe = "";
    this.privacyStatementEn = "";
    this.privacyStatement3rdPartyDe = "";
    this.privacyStatement3rdPartyEn = "";
  }
}

@Injectable()
export class HealthInsuranceService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {}

  delete(hiId: string): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/healthInsurance/delete/" + hiId, this.config);
  }

  createupdateFaq(hi: HealthInsurance): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/healthInsurance/createupdate", hi, this.config);
  }

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthInsurance/findAll", this.config);
  }

  get(hiId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthInsurance/get/" + hiId, this.config);
  }

  get3rdPartyPrivacyStatement(hiId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/public/healthInsurance/get3rdPartyPrivacyStatement/" + hiId, this.config);
  }

  exists(hiId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthInsurance/exists/" + hiId, this.config);
  }

  mapHealthInsuranceToForm(hi: HealthInsurance): UntypedFormGroup {
    const customerForm = this.formBuilder.group(hi);
    return customerForm;
  }

  mapFormToHealthInsurance(form: UntypedFormGroup): HealthInsurance {
    const customer = form.getRawValue();
    return customer;
  }
}
