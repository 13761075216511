import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'smiley-icon',
  templateUrl: './smiley-icon.component.html',
  styleUrls: ['./smiley-icon.component.scss']
})
export class SmileyIconComponent implements OnInit {
  @Input() color: string = 'navy';

  constructor() { }

  ngOnInit(): void {
  }

}
