<div class="{{ 'row votes ' + (votingParticipant.get('scheduletype').value === 'CLOSED' ? 'unavailableVotes' : '') }}" style="padding-top: 16px" *ngIf="votingParticipant.get('scheduletype').value === 'OPEN' || votingParticipant.get('scheduletype').value === 'CLOSED'">
  <div class="col-md-6 col-lg-1">
    <gib-input [placeholder]="'from'" [control]="votingParticipant.get('timeFrom')"></gib-input>
  </div>
  <div class="col-md-6 col-lg-1">
    <gib-input [placeholder]="'to'" [control]="votingParticipant.get('timeTo')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-1">
    <gib-input [placeholder]="'firstname'" [control]="votingParticipant.get('firstname')" [type]="votingParticipant.get('firstname').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-1">
    <gib-input [placeholder]="'lastname'" [control]="votingParticipant.get('lastname')" [type]="votingParticipant.get('lastname').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2">
    <gib-input [placeholder]="'email'" [control]="votingParticipant.get('email')" [type]="votingParticipant.get('email').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2" *ngIf="votingParticipant.get('scheduleEntryCompanynameRequired').value">
    <gib-input [placeholder]="'companyname'" [control]="votingParticipant.get('companyname')" [type]="votingParticipant.get('companyname').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-1" *ngIf="votingParticipant.get('scheduleEntryCompanynameRequired').value">
    <gib-input [placeholder]="'zip'" [control]="votingParticipant.get('zip')" [type]="votingParticipant.get('zip').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-1">
    <gib-input [placeholder]="'phonenumber'" [control]="votingParticipant.get('phone')" [type]="votingParticipant.get('phone').value === 'Anonymous' ? 'password' : 'text'"></gib-input>
  </div>
  <div class="col-md-12 col-lg-1 submitButton mb-3 center">
    <button *ngIf="votingParticipant.get('scheduletype').value === 'OPEN'" class="btn btn-primary" type="submit" (click)="updateScheduleDataClicked()">{{ "submit" | translate }}</button>
    <button *ngIf="votingParticipant.get('scheduletype').value === 'CLOSED' && votingParticipant.get('firstname').value !== 'Anonymous'" class="btn btn-secondary" type="submit" (click)="deleteScheduleEntry()">{{ "delete" | translate }}</button>
  </div>
  <hr />
</div>

<div class="row votes unavailableVotes" style="padding-top: 16px" *ngIf="votingParticipant.get('scheduletype').value === 'UNAVAILABLE'">
  <div class="col-md-2 col-lg-1">
    <gib-input [placeholder]="'from'" [control]="votingParticipant.get('timeFrom')"></gib-input>
  </div>
  <div class="col-md-2 col-lg-1">
    <gib-input [placeholder]="'to'" [control]="votingParticipant.get('timeTo')"></gib-input>
  </div>
  <div class="col-md-6 col-lg-8 align-self-center">
    <mat-label> {{ votingParticipant.get("title").value | translate }}</mat-label>
  </div>
  <div class="col-md-2 col-lg-2"></div>
  <hr />
</div>

<div class="row votes blockedVotes" style="padding-top: 16px" *ngIf="votingParticipant.get('scheduletype').value === 'BLOCKED'">
  <div class="col-md-2 col-lg-1">
    <gib-input [placeholder]="'from'" [control]="votingParticipant.get('timeFrom')"></gib-input>
  </div>
  <div class="col-md-2 col-lg-1">
    <gib-input [placeholder]="'to'" [control]="votingParticipant.get('timeTo')"></gib-input>
  </div>
  <div class="col-md-6 col-lg-8 align-self-center">
    <mat-label> {{ votingParticipant.get("title").value | translate }}</mat-label>
  </div>
  <div class="col-md-2 col-lg-2"></div>
  <hr />
</div>
