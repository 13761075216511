import { FormHelper } from "src/app/helper/form.helper";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Customer, CustomerService } from "./../../../services/customer.service";
import { CustomerTableComponent } from "./../components/customer-table/customer-table.component";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { Observable } from "rxjs";
import { getAllCustomers } from "../../../store/selectors/customer.selectors";

@Component({
  selector: "customer-page",
  templateUrl: "./customer-page.component.html",
  styleUrls: ["./customer-page.component.scss"],
})
export class CustomerPageComponent implements OnInit {
  @ViewChild("custmrTable", { static: true }) customerTable: CustomerTableComponent;

  customers$: Observable<Customer[]> = this.store.select(getAllCustomers)
  selectedCustomer: Customer;

  constructor(
    private customerService: CustomerService,
    private formHelper: FormHelper,
    private store: Store<fromStore.GibState>
  ) {
  }

  ngOnInit() {
    this.store.dispatch(
      new fromStore.LoadCustomers()
    )
  }

  fetchCustomers() {
    this.customerTable.resetDisplayedColumns()
    this.selectedCustomer = null;
    this.store.dispatch(
      new fromStore.LoadCustomers()
    )
  }

  openCustomerCard(custmr?: Customer) {
    if (!custmr) {
      this.selectedCustomer = new Customer();
    } else {
      this.selectedCustomer = custmr;
    }
  }
}
