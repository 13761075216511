<div class="row headerRow">
  <div class="col-12">
    <h1>{{'feedbackReportsOverview' | translate}}</h1>
  </div>
</div>
<div class="row">
  <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2 center">
    <report-tile [url]="'customerFeedbackReport'" title="customerFeedbackForm" image="/assets/images/charts/chart.png"></report-tile>
  </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2 center">
    <report-tile [url]="'trainerFeedbackReport'" title="trainerFeedbackForm" image="/assets/images/charts/chart.png"></report-tile>
  </div>
</div>