import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'logistic-vehicle',
  templateUrl: './logistic-vehicle.component.html',
  styleUrls: ['./logistic-vehicle.component.sass']
})
export class LogisticVehicleComponent implements OnInit {

  @Input() eventHealthscreening;

  constructor() { }

  ngOnInit() {
  }

}
