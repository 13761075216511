<div *ngIf="!invalidUrl">
  <h1>{{ "yourBooking" | translate }}</h1>
  <div class="d-flex w-100 justify-content-center">
    <event-booking-form *ngIf="eventForm" [showPrintButtons]="showPrintButtons" [buid]="buid" [eventForm]="eventForm" [chatElements]="chatElements"></event-booking-form>
  </div>
  <div *ngIf="showSubmitButtons" class="d-flex w-100 mt-4 justify-content-center">
    <button *ngIf="showDefaultButton()" class="btn btn-primary" (click)="openSubmitConfirmationClicked()">
      <i class="material-icons">check</i>
      {{ "submitData" | translate }}
    </button>
    <button *ngIf="showBookingButtons()" class="btn btn-success" (click)="openBokkingConfirmationClicked()">
      <i class="material-icons">check</i>
      {{ "book" | translate }}
    </button>
    <button *ngIf="showBookingButtons()" class="btn btn-secondary" (click)="openQueryConfirmation()">
      <i class="material-icons">chat</i>
      {{ "query" | translate }}
    </button>
    <button *ngIf="showBookingButtons()" class="btn btn-danger" (click)="openRejectConfirmation()">
      <i class="material-icons">close</i>
      {{ "storn" | translate }}
    </button>
    <button *ngIf="showOrderConfirmationButton()" class="btn btn-success" (click)="openOrderConfirmationClicked()">
      <i class="material-icons">check</i>
      {{ "orderConfirmation" | translate }}
    </button>
  </div>
  <div class="d-flex w-100 justify-content-center mt-4" [@visibility]="!showSubmitButtons ? 'visible' : 'hidden'">
    <p [ngStyle]="{ color: resultColor }">
      <b>{{ result | translate }}</b>
    </p>
  </div>
</div>
