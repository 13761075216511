import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { HealthInsurance, HealthInsuranceService } from 'src/app/services/health-insurance.service';

@Component({
  selector: 'add-healthinsurance-bottom-sheet',
  templateUrl: './add-healthinsurance-bottom-sheet.component.html',
  styleUrls: ['./add-healthinsurance-bottom-sheet.component.sass']
})
export class AddHealthinsuranceBottomSheetComponent implements OnInit {

  selectedHealthInsurance: HealthInsurance;
  healthInsuranceForm: UntypedFormGroup;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheet: MatBottomSheet, private healthInsuranceService: HealthInsuranceService) { }

  ngOnInit() {
    this.selectedHealthInsurance = this.data.selectedHealthInsurance;
    this.healthInsuranceForm = this.healthInsuranceService.mapHealthInsuranceToForm(this.selectedHealthInsurance);
  }
  
  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

}
