import { Subscription } from "rxjs";
import { Component, OnInit, Input, ViewChild, OnChanges, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelper } from "../../helper/form.helper";

@Component({
  selector: "gib-datepicker",
  templateUrl: "./gib-datepicker.component.html",
  styleUrls: ["./gib-datepicker.component.scss"],
})
export class GibDatepickerComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  noValidDateMessage: string;

  @Input() placeholder: string;
  @Input() hint = "";

  isRequired: boolean;
  showError = false;
  errorMessage = "";
  date;

  langSubscription: Subscription;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.date = this.inputControl.value;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== "") {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
      if (this.hint !== "") {
        this.hint = this.translateService.instant(hintInputValue);
      }
      this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    });

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.noValidDateMessage = this.formHelper.createNoValidDateFormatTranslation(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  getErrorMessage(pickerInput: string): string {
    this.showError = true;
    if ((!pickerInput || pickerInput === "") && this.isRequired) {
      return this.requiredErrorMessage;
    } else if ((!pickerInput || pickerInput === "") && !this.isRequired) {
      this.showError = false;
      return "";
    }
    return this.isMyDateFormat(pickerInput);
  }

  isMyDateFormat(date: string): string {
    if (date.length !== 10) {
      return this.noValidDateMessage;
    } else {
      const da = date.split(".");
      if (da.length !== 3 || da[0].length !== 2 || da[1].length !== 2 || da[2].length !== 4) {
        return this.noValidDateMessage;
      }
    }
    return this.noValidDateMessage;
  }

  dateChanged(event: any) {
    const value = event.value;
    if (value) {
      this.showError = false;
      this.errorMessage = "";
      this.inputControl.setValue(value);
    } else {
      this.errorMessage = this.getErrorMessage(event.targetElement.value);
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
