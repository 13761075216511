<div class="row d-flex justify-content-end">
  <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
</div>
<div class="row d-flex justify-content-between">
  <h2>{{ "myTasks" | translate }}</h2>
</div>

<div class="w-100">
  <trainer-task-table #eventTable [tasks]="tasks" (taskSelected)="openFormForTask($event)"></trainer-task-table>
</div>
