<h1>{{'documentVerifications' | translate}}</h1>
<div class="row">
  <!-- <div class="col-sm-12 col-md-6"> -->
  <div class="{{selectedFile ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-12'}}">
    <document-table *ngIf="documents" [documents]="documents" (fileSelected)="selectFile($event)"></document-table>
  </div>
  <div class="col-md-12 col-lg-6">
    <document-verification *ngIf="selectedFile" [fileMeta]="selectedFile"></document-verification>
  </div>
</div>
