import { Component, Input, OnInit } from '@angular/core';
import { ValueLabel } from "../value-label";
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from '../../helper/form.helper';

@Component({
  selector: 'gib-select',
  templateUrl: './gib-select.component.html',
  styleUrls: ['./gib-select.component.scss']
})
export class GibSelectComponent implements OnInit {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() options: ValueLabel[];

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
    this.placeholder = this.translateService.instant(this.placeholder);
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) { }

  ngOnInit() { }

}
