import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'logistic-trainer',
  templateUrl: './logistic-trainer.component.html',
  styleUrls: ['./logistic-trainer.component.sass']
})
export class LogisticTrainerComponent implements OnInit {

  @Input() trainer: UntypedFormGroup;
  @Input() eventHealthscreening;

  constructor() { }

  ngOnInit() {
  }

}
