import { FormHelper } from "./../../../helper/form.helper";
import { Chat, EventService } from "./../../../services/event.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CustomValidators } from "src/app/utils/custom-validator";

@Component({
  selector: "customer-confirmation-page",
  templateUrl: "./customer-confirmation-page.component.html",
  styleUrls: ["./customer-confirmation-page.component.scss"],
})
export class CustomerConfirmationPageComponent implements OnInit {
  eventForm: UntypedFormGroup;
  chatElements: Chat[];

  buid: string;
  activityId: string;
  messageName: string;

  constructor(private route: ActivatedRoute, private eventService: EventService, private formHelper: FormHelper, private cdr: ChangeDetectorRef, private customValidators: CustomValidators) {}

  ngOnInit() {
    this.loadEventData();
  }

  loadEventData() {
    this.route.queryParams.subscribe((values) => {
      this.buid = values["buid"];
      this.activityId = values["activity-id"];
      this.setMessageName(this.activityId);
      this.eventService.findByBuid(this.buid).subscribe((res) => {
        const event = res.body;
        this.eventForm = this.eventService.mapEventToForm(event);
        this.createFormValidations(this.eventForm);
        this.eventForm.addControl("withoutCustomerSuggestSelect", new UntypedFormControl(true));
        this.eventForm.get("eventDate").disable();
        this.eventForm.get("eventHealthScreenings").disable();
        this.eventForm.get("eventlocation").get("city").disable();
        if (this.eventForm.get("customer").get("id").value) {
          this.eventForm.get("customer").get("id").disable();
          this.formHelper.disableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
          this.formHelper.disableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
        }
        this.eventForm.updateValueAndValidity();
        this.loadChat();
      });
    });
  }

  loadChat() {
    this.eventService.getChatHistory(this.buid, "Antwort_Buchungsformular").subscribe((res) => {
      this.chatElements = res.body;
      this.cdr.detectChanges();
    });
  }

  setMessageName(activityId: string) {
    if (activityId === "st_mail_confirmation_reservation_ag" || activityId === "st_email_reminder" || activityId === "st_mail_query_processed_ag") {
      this.messageName = "msg_response_ag";
    } else if (activityId === "st_mail_update_data_ag" || activityId === "st_mail_update_data_reminder_ag") {
      this.messageName = "msg_update_data_ag";
    }
  }

  createFormValidations(eventForm: UntypedFormGroup) {
    eventForm.get("eventDate").setValidators([Validators.required, this.formHelper.isDateValid()]);
    eventForm.get("startTime").setValidators([Validators.required]);
    eventForm.get("endTime").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").updateValueAndValidity();
    eventForm.get("customerSalutation").setValidators([Validators.required]);
    eventForm.get("customerFirstname").setValidators([Validators.required]);
    eventForm.get("customerLastname").setValidators([Validators.required]);
    eventForm.get("customerPhone").setValidators([Validators.required]);
    eventForm.get("customerMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("customerCompanyName").setValidators([Validators.required]);

    eventForm.get("corporateCustomerCompanyName").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactSalutation").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactLastname").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactPhone").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);

    eventForm.get("customerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("eventlocation").get("type").setValidators([Validators.required]);
    eventForm.get("eventlocation").get("city").setValidators([Validators.required]);

    if (this.activityId === "st_mail_update_data_ag") {
      eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
      eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
      eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
    }
  }
}
