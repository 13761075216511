import { AfterContentChecked, Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Chat } from "../../../../services/event.service";
import { SelectOptions } from "../../../../utils/select-options";
import { GibModuleSelectionComponent } from "src/app/components/gib-module-selection/gib-module-selection.component";

@Component({
  selector: "event-booking-form",
  templateUrl: "./event-booking-form.component.html",
  styleUrls: ["./event-booking-form.component.scss"],
})
export class EventBookingFormComponent implements OnInit, AfterContentChecked {
  @Input() eventForm: UntypedFormGroup;
  @Input() internalNoteForm: UntypedFormGroup;
  @Input() chatElements: Chat[];
  @Input() task: any;
  @Input() buid: string;
  @Input() showPrintButtons: boolean;

  @ViewChild("module") moduleSelection: GibModuleSelectionComponent;

  userRole: string;
  salutationOptions = SelectOptions.salutationOptions;
  private _valid: boolean = true;

  constructor() {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
  }

  ngAfterContentChecked(): void {
    if (this.moduleSelection) {
      this._valid = this.moduleSelection.itemsValid;
    }
  }

  get itemsValid() {
    return this._valid;
  }
}
