<div class="row">
  <div class="col-sm-12 col-md-4">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-12 col-md-2"></div>
  <div class="col-sm-12 col-md-2">
    <gib-datepicker [placeholder]="'from'" [control]="dateFrom"></gib-datepicker>
  </div>
  <div class="col-sm-12 col-md-2">
    <gib-datepicker [placeholder]="'to'" [control]="dateTo"></gib-datepicker>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1">
    <button class="btn btn-secondary delete-btn">
      <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteAllMailConfirmation()">delete</i>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)" matSortActive="created" matSortDirection="desc" multiTemplateDataRows>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'created' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.created | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.status}} </td>
        </ng-container>

        <ng-container matColumnDef="sendTo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'sendTo' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.sendTo}} </td>
        </ng-container>

        <ng-container matColumnDef="cc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'cc' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.cc && row.cc !== 'null' ? row.cc : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="bcc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'bcc' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.bcc && row.bcc !== 'null' ? row.bcc : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'subject' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.subject}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteMailConfirmation(row)">delete</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="mail-log-details" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mail-log-table-details [content]="element.content"></mail-log-table-details>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mail-log-detail-row"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
