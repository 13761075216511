<div class="row d-flex justify-content-end">
  <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
</div>
<div class="row">
  <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 calendarCol">
    <div class="row">
      <div class="col-sm-12">
        <h2>{{ "calendar" | translate }}</h2>
      </div>
    </div>
    <gib-calendar [entries]="entries" (editEntry)="setEntryToEdit($event)" (entryDeleted)="getMyEntries()" (emptyEntryClicked)="setStartDate($event)" (emptyEntryShiftClicked)="setEndDate($event)"></gib-calendar>
  </div>
  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 formCol">
    <trainer-availablity-form #trainerAvailabilityForm [entry]="entryToEdit" (entryEditCanceled)="entryEditCanceledClicked()" (entryAdded)="getMyEntries()"></trainer-availablity-form>
  </div>
</div>
