<div class="d-flex justify-content-end">
  <button *ngIf="userRole === 'gib_customer'" type="button" class="btn btn-outline" (click)="navigateBack()">
    {{ "back" | translate }}
  </button>
</div>
<div class="d-flex justify-content-between">
  <h2>{{ "createEventInquiry" | translate }}</h2>
</div>
<br />
<event-inquiry-form #eventInquiryForm [eventForm]="eventForm" [internalNoteForm]="internalNoteForm" [buid]="buid" [showPrintButtons]="showPrintButtons"></event-inquiry-form>
<br />
<div *ngIf="customerProfileFilled" class="row">
  <div class="col-12 center">
    <button type="button" class="btn btn-secondary" *ngIf="!formSubmitted && userRole !== 'gib_customer'" (click)="startProcessClicked()">
      {{ "submit" | translate }}
    </button>
    <button type="button" class="btn btn-secondary" *ngIf="!formSubmitted && userRole === 'gib_customer'" (click)="startProcessClicked()">
      {{ "submitInquiry" | translate }}
    </button>
    <button type="button" class="btn btn-primary actionButton" *ngIf="formSubmitted && userRole !== 'gib_customer'" (click)="bookEventAgain()">
      {{ "inquiryEventOneMoreTime" | translate }}
    </button>
    <button type="button" class="btn btn-primary actionButton" *ngIf="this.buid && showPrintButtons" (click)="openDownloadPdfConfirmation()">
      {{ "print" | translate }}
    </button>
  </div>
</div>
