<div class="row">
  <div class="col-xl-12">
    <h4>{{ "generalComment" | translate }}</h4>
    <div class="box">
      <div class="row d-flex align-items-end">
        <div class="col-xl-6">
          <gib-textarea [placeholder]="'remark'" [control]="eventForm.get('remark')"></gib-textarea>
        </div>
        <div class="col-xl-3" *ngIf="formType === 'inquiry'">
          <!-- <div class="col-xl-3" *ngIf="this.userRole === 'gib_customer'"> -->
          <gib-datepicker [placeholder]="'estimatedBookingConfirmationDate'" [control]="eventForm.get('estimatedBookingConfirmationDate')"></gib-datepicker>
        </div>
        <div class="col-xl-3">
          <!-- <button type="button" class="btn btn-primary" *ngIf="!formSubmitted && userRole === 'gib_customer'" (click)="startProcessClicked()">{{ "submitInquiry" | translate }}</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
