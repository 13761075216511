import { FormHelper } from './../../../../helper/form.helper';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Output, Input, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'completed-task-table',
  templateUrl: './completed-task-table.component.html',
  styleUrls: ['./completed-task-table.component.scss']
})
export class CompletedTaskTableComponent implements OnInit {

  tsks: any[];
  displayedColumns: string[] = ['processName', 'processStepName', 'approver', 'outcome', 'created', 'completed', 'orderNumber', 'customerCompanyName', 'corporateCustomerCompanyName', 'eventDate', 'contactFullname'];

  dataSource: MatTableDataSource < any > ;

  @Output() taskSelected = new EventEmitter();
  @Input() set completedTasks(completedTasks: any[]) {
    this.tsks = completedTasks;
    if (completedTasks && completedTasks.length > 0) {
      for (const task of completedTasks) {
        if (task.processID) {
          task.processName = this.translateService.instant(task.processID.substring(0, task.processID.indexOf(':')));
        } else {
          task.processName = 'Ad-hoc';
        }
        task.outcome = this.translateService.instant('outcome_' + task.outcome);
      }
    }

    this.dataSource = new MatTableDataSource(completedTasks);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  taskRowClicked(completedTask: any) {
    this.taskSelected.emit(completedTask);
  }

  customFilterPredicate(row, filter): boolean {
    let prozessFilter = false;
    let taskNameFilter = false;
    prozessFilter = row.processName.toLowerCase().includes(filter);
    taskNameFilter = row.name.toLowerCase().includes(filter);
    return prozessFilter || taskNameFilter;
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if ($event.active === 'created' || $event.active === 'completed') {
      sortedTasks.sort(this.formHelper.sortByDateProperty($event.active));
    } else {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    }

    if ($event.direction === 'desc') {
      sortedTasks.reverse();
    }
    this.dataSource.data = sortedTasks;
    this.cdr.detectChanges();
  }

}