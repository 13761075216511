import * as fromEmployees from "../actions/employee.actions";
import { Employee, TrainerForAssignmentDto } from "src/app/services/employee.service";
import { Role } from "src/app/services/keycloak-connector.service";
import { ValueLabel } from "src/app/components/value-label";

export interface EmployeeState {
  employees: Employee[];
  trainerForAssignment: TrainerForAssignmentDto[],
  roles: ValueLabel[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: EmployeeState = {
  employees: [],
  trainerForAssignment: [],
  roles: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromEmployees.EmployeeActions): EmployeeState {
  switch (action.type) {
    case fromEmployees.LOAD_EMPLOYEES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromEmployees.LOAD_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employees: action.payload.employees,
        loading: false,
        loaded: true,
      };
    }
    case fromEmployees.LOAD_EMPLOYEES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromEmployees.LOAD_EMPLOYEES_BY_ROLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromEmployees.LOAD_EMPLOYEES_BY_ROLE_SUCCESS: {
      return {
        ...state,
        employees: action.payload.employees,
        loading: false,
        loaded: true,
      };
    }
    case fromEmployees.LOAD_EMPLOYEES_BY_ROLE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromEmployees.LOAD_ROLES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromEmployees.LOAD_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload.roles,
        loading: false,
        loaded: true,
      };
    }
    case fromEmployees.LOAD_ROLES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromEmployees.LOAD_TRAINER_FOR_ASSIGNMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromEmployees.LOAD_TRAINER_FOR_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        trainerForAssignment: action.payload.trainer,
        loading: false,
        loaded: true,
      };
    }
    case fromEmployees.LOAD_TRAINER_FOR_ASSIGNMENT_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
