import * as fromInactiveEvents from "../actions/inactive-event.actions";
import { EventOverviewDTO } from "src/app/services/event-overview.service";

export interface InactiveEventsState {
  entities: { [period: string]: { [id: string]: EventOverviewDTO } };
  loading: boolean;
  loaded: boolean;
}

export const initialState: InactiveEventsState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromInactiveEvents.InactiveEventActions): InactiveEventsState {
  switch (action.type) {
    case fromInactiveEvents.LOAD_INACTIVE_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromInactiveEvents.LOAD_INACTIVE_EVENTS_SUCCESS: {
      const events = action.payload;
      const period = action.period;
      const entities = events.reduce(
        (entities: { [period: string]: { [id: string]: EventOverviewDTO } }, event: EventOverviewDTO) => {
          return {
            ...entities,
            [period]: {
              ...entities[period],
              [event.reduxId]: event,
            },
          };
        },
        {
          ...state.entities,
        }
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromInactiveEvents.LOAD_INACTIVE_EVENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromInactiveEvents.REMOVE_INACTIVE_EVENTS: {
      const period = action.period;
      const { [period]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
        loading: false,
        loaded: false,
      };
    }
    case fromInactiveEvents.CLEAR_INACTIVE_EVENTS: {
      return {
        ...state,
        entities: {},
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getInactiveEventsEntities = (state: InactiveEventsState) => state.entities;
export const getInactiveEventsLoading = (state: InactiveEventsState) => state.loading;
export const getInactiveEventsLoaded = (state: InactiveEventsState) => state.loaded;
