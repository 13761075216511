<div class="row">
  <div class="col-xl-6">
    <h4>{{ "healthModules" | translate }}</h4>
    <div class="box">
      <mat-form-field>
        <mat-label>{{ "selectModules" | translate }}</mat-label>
        <mat-select [formControl]="healthModuleOnsiteSelectControl" (selectionChange)="updateModuleValues($event, 'healthModule', 'onsite')" multiple>
          <mat-optgroup *ngFor="let group of healthModuleOnsiteModuleGroups" [label]="group.name">
            <mat-option (onSelectionChange)="onHealthModuleSelectionChange($event, 'onsite')" *ngFor="let module of group.modules" [value]="module.value + ':' + group.key"> {{ module.label }} </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div *ngIf="healthModuleOnsiteSelectControl.value.length > 0">
        <div class="row" *ngFor="let topic of getSelectedTopics('healthModule', 'onsite'); let i = index">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6 topic">{{ topic | translate }}</div>
              <div class="col-xl-6 topic">
                <span *ngIf="i === 0">{{ "intervalPerPerson" | translate }}</span>
              </div>
            </div>
            <div *ngFor="let hsId of getSelectedModulesForTopic(topic, 'healthModule', 'onsite')">
              <gib-module-selection-item
                #item
                [module]="{ id: hsId, topic, label: findModuleLabelByValue(hsId, 'healthModule', 'onsite'), hint: getHint(hsId, 'healthModuleOnsiteModuleHints'), defaultAmount: getDefaultAmount(hsId, topic, 'healthModule', 'onsite') }"
                [options]="getHealthModuleOnsiteOptions(hsId, topic)"
                [defaultValue]="getHealthModuleDefaultValue(hsId, topic, 'healthModule', 'onsite')"
                [disabled]="healthModuleOnsiteSelectControl.disabled"
                (update)="onHealthModuleUpdate($event, 'onsite')"
              ></gib-module-selection-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6">
    <h4>{{ "onlineModules" | translate }}</h4>
    <div class="box">
      <mat-form-field>
        <mat-label>{{ "selectModules" | translate }}</mat-label>
        <mat-select [formControl]="healthModuleOnlineSelectControl" (selectionChange)="updateModuleValues($event, 'healthModule', 'online')" multiple>
          <mat-optgroup *ngFor="let group of healthModuleOnlineModuleGroups" [label]="group.name">
            <mat-option (onSelectionChange)="onHealthModuleSelectionChange($event, 'online')" *ngFor="let module of group.modules" [value]="module.value + ':' + group.key">
              {{ module.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div *ngIf="healthModuleOnlineSelectControl.value.length > 0">
        <div class="row" *ngFor="let topic of getSelectedTopics('healthModule', 'online'); let i = index">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6 topic">{{ topic | translate }}</div>
              <div class="col-xl-6 topic">
                <span *ngIf="i === 0">{{ "intervalPerPerson" | translate }}</span>
              </div>
            </div>
            <div *ngFor="let hsId of getSelectedModulesForTopic(topic, 'healthModule', 'online')">
              <gib-module-selection-item
                #item
                [module]="{ id: hsId, topic, label: findModuleLabelByValue(hsId, 'healthModule', 'online'), hint: getHint(hsId, 'healthModuleOnlineModuleHints'), defaultAmount: getDefaultAmount(hsId, topic, 'healthModule', 'online') }"
                [options]="getHealthModuleOnlineOptions(hsId, topic)"
                [defaultValue]="getHealthModuleDefaultValue(hsId, topic, 'healthModule', 'online')"
                [disabled]="healthModuleOnlineSelectControl.disabled"
                (update)="onHealthModuleUpdate($event, 'online')"
              ></gib-module-selection-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-6">
    <h4>{{ "interactiveWebinars" | translate }}</h4>
    <div class="box">
      <mat-form-field>
        <mat-label>{{ "selectModules" | translate }}</mat-label>
        <mat-select [formControl]="webinarSelectControl" (selectionChange)="updateModuleValues($event, 'webinar', null)" multiple>
          <mat-optgroup *ngFor="let group of webinarModuleGroups" [label]="group.name">
            <mat-option (onSelectionChange)="onSelectionChange($event, 'webinar')" *ngFor="let module of group.modules" [value]="module.value + ':' + group.key">
              {{ module.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div *ngIf="webinarSelectControl.value.length > 0">
        <div class="row" *ngFor="let topic of getSelectedTopics('webinar', null); let i = index">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6 topic">{{ topic | translate }}</div>
              <div class="col-xl-6 topic">
                <span *ngIf="i === 0">{{ "implementationType" | translate }} <span class="text-color-orange">*</span></span>
              </div>
            </div>
            <div *ngFor="let hsId of getSelectedModulesForTopic(topic, 'webinar', null)">
              <gib-module-selection-item
                #item
                [module]="{ id: hsId, topic, label: findModuleLabelByValue(hsId, 'webinar', null), hint: getHint(hsId, 'webinarModuleHints'), defaultAmount: getDefaultAmount(hsId, topic, 'webinar', null) }"
                [options]="getOptions()"
                [disabled]="webinarSelectControl.disabled"
                [defaultValue]="getDefaultValue(hsId, 'webinar', null, topic)"
                (update)="onUpdate($event, 'webinar')"
              ></gib-module-selection-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6">
    <h4>{{ "activeBreaks" | translate }}</h4>
    <div class="box">
      <mat-form-field>
        <mat-label>{{ "selectModules" | translate }}</mat-label>
        <mat-select [formControl]="activeBreakSelectControl" (selectionChange)="updateModuleValues($event, 'activeBreak', null)" multiple>
          <mat-optgroup *ngFor="let group of activeBreakModuleGroups" [label]="group.name">
            <mat-option (onSelectionChange)="onSelectionChange($event, 'activeBreak')" *ngFor="let module of group.modules" [value]="module.value + ':' + group.key">
              {{ module.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div *ngIf="activeBreakSelectControl.value.length > 0">
        <div class="row" *ngFor="let topic of getSelectedTopics('activeBreak', null); let i = index">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6 topic">{{ topic | translate }}</div>
              <div class="col-xl-6 topic">
                <span *ngIf="i === 0">{{ "implementationType" | translate }} <span class="text-color-orange">*</span></span>
              </div>
            </div>
            <div *ngFor="let hsId of getSelectedModulesForTopic(topic, 'activeBreak', null)">
              <gib-module-selection-item
                #item
                [module]="{ id: hsId, topic, label: findModuleLabelByValue(hsId, 'activeBreak', null), hint: getHint(hsId, 'activeBreakModuleHints'), defaultAmount: getDefaultAmount(hsId, topic, 'activeBreak', null) }"
                [options]="getOptions()"
                [disabled]="activeBreakSelectControl.disabled"
                [defaultValue]="getDefaultValue(hsId, 'activeBreak', null, topic)"
                (update)="onUpdate($event, 'activeBreak')"
              ></gib-module-selection-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
