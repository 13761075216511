<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content [formGroup]="votingParticipant">
  <p [innerHTML]="data.text"></p>
  <div class="row">
    <div class="col-sm-4">
      <gib-input [placeholder]="'firstname'" [control]="votingParticipant.get('firstname')"></gib-input>
    </div>
    <div class="col-sm-4">
      <gib-input [placeholder]="'lastname'" [control]="votingParticipant.get('lastname')"></gib-input>
    </div>
    <div class="col-sm-4" *ngIf="showCompanyName()">
      <gib-input [placeholder]="'companyname'" [control]="votingParticipant.get('companyname')"></gib-input>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <gib-input [placeholder]="'phonenumber'" [control]="votingParticipant.get('phonenumber')"></gib-input>
    </div>
    <div class="col-sm-4">
      <gib-input [placeholder]="'email'" [control]="votingParticipant.get('email')"></gib-input>
    </div>
    <div class="col-sm-4"></div>
  </div>
  <mat-checkbox formControlName="acceptedEventPrivacy">
    <div style="text-wrap: wrap; white-space: pre-wrap" [innerHTML]="data.eventPrivacyText"></div>
  </mat-checkbox>
  <br />
  <div *ngIf="data.thirdPartyPrivacyText !== ''">
    <mat-checkbox formControlName="acceptedThirdPartyPrivacy">
      <div style="text-wrap: wrap; white-space: pre-wrap" [innerHTML]="data.thirdPartyPrivacyText"></div>
    </mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelAction()">{{ "CANCEL" | translate }}</button>
  <button [disabled]="submitButtonDisabled()" class="btn btn-secondary" mat-button (click)="confirmAction()" cdkFocusInitial>{{ "WaitListPopupTitle" | translate }}</button>
</div>
