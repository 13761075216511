import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TkUzOverviewPageComponent } from "./tk-uz-overview-page/tk-uz-overview-page.component";
import { ComponentsModule } from "src/app/components/components.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TkUzOverviewTableComponent } from "./tk-uz-overview-table/tk-uz-overview-table.component";
import { TkUzGlobalRatesPageComponent } from "./tk-uz-global-rates-page/tk-uz-global-rates-page.component";
import { TkUzGlobalRatesTableComponent } from "./components/tk-uz-global-rates-table/tk-uz-global-rates-table.component";
import { AddTkUzGlobalRateComponent } from "./add-tk-uz-global-rate/add-tk-uz-global-rate.component";
import { TkUzGlobalRateFormComponent } from "./components/tk-uz-global-rate-form/tk-uz-global-rate-form.component";
import { EditTkUzGlobalRateComponent } from "./edit-tk-uz-global-rate/edit-tk-uz-global-rate.component";
import { TkUzEihsDialogComponent } from "./components/tk-uz-eihs-dialog/tk-uz-eihs-dialog.component";

@NgModule({
  declarations: [TkUzOverviewPageComponent, TkUzOverviewTableComponent, TkUzGlobalRatesPageComponent, TkUzGlobalRatesTableComponent, AddTkUzGlobalRateComponent, TkUzGlobalRateFormComponent, EditTkUzGlobalRateComponent, TkUzEihsDialogComponent],
  imports: [ComponentsModule, UtilsModule, AppRoutingModule, CommonModule],
  exports: [],
})
export class FinanceModule {}
