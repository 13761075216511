import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { FormHelper } from "../../../../helper/form.helper";

@Component({
  selector: "event-task-table",
  templateUrl: "./event-task-table.component.html",
  styleUrls: ["./event-task-table.component.scss"],
})
export class EventTaskTableComponent implements OnInit {
  tsks: any[];
  displayedColumns: string[] = ["process", "name", "createdAt", "orderNumber", "customerCompanyName", "corporateCustomerCompanyName", "contactFullname", "eventDate", "description"];

  dataSource: MatTableDataSource<any>;
  sort;

  @Output() taskSelected = new EventEmitter();

  @Input() set tasks(tasks: any[]) {
    this.tsks = tasks;
    if (tasks && tasks.length > 0) {
      for (const task of tasks) {
        if (task.task.processDefinitionId) {
          task.processName = this.translateService.instant(task.task.processDefinitionId.substring(0, task.task.processDefinitionId.indexOf(":")));
        } else {
          task.processName = "Ad-hoc";
        }
      }
    }

    this.dataSource = new MatTableDataSource(tasks);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;

    // default sorting
    const sortState: Sort = { active: "createdAt", direction: "asc" };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    // if (this.sort) {
    //   this.dataSource.sort = this.sort;
    // }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  taskRowClicked(task: any) {
    this.taskSelected.emit(task);
  }

  customFilterPredicate(row, filter): boolean {
    let prozessFilter = false;
    let taskNameFilter = false;
    prozessFilter = row.processName.toLowerCase().includes(filter);
    taskNameFilter = row.task.name.toLowerCase().includes(filter);
    return prozessFilter || taskNameFilter;
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if ($event.active === "createdAt") {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    } else if (["corporateCustomerCompanyName", "customerCompanyName"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    } else if (["name"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByProperty($event.active));
    } else if ($event.active === "eventDate") {
      sortedTasks.sort(this.formHelper.sortTasksByEventDate);
    }

    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  getCustomerContactFullname(task) {
    return task.customerContactFirstname + " " + task.customerContactLastname;
  }
}
