<div class="row d-flex justify-content-end mr-0 ml-0">
  <div class="col-sm-12 text-right">
    <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
  </div>
</div>
<div class="row d-flex justify-content-between mr-0 ml-0">
  <div class="col-sm-12">
    <h2>{{ "myEvents" | translate }}</h2>
    <div class="d-flex actions">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'search' | translate }}" />
      </mat-form-field>
      <gib-icon-button [icon]="'description'" [type]="'secondary'" [text]="'download'" (click)="exportExcel()"></gib-icon-button>
    </div>
  </div>
</div>

<div class="row mr-0 ml-0">
  <div class="col-sm-12">
    <div class="mat-elevation-z8" style="border-radius: 10px; box-shadow: 0 0 15px #ccc" #TABLE>
      <table mat-table #table style="border-radius: 10px" [dataSource]="dataSource" matSort>
        <ng-container style="border-radius: 10px" matColumnDef="eventDate">
          <th style="border-radius: 10px 0 0 0" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "eventDate" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.eventDate | amDateFormat : "DD.MM.YYYY" }}</td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "time" | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.time }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "status" | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ "eventState_" + row.state | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="corporateCustomerCompanyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "corporateCustomerCompanyName" | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.corporateCustomerCompanyName }}</td>
        </ng-container>

        <ng-container matColumnDef="modules">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "module_s" | translate }}</th>
          <td class="reduce-width" mat-cell *matCellDef="let row">{{ row.moduleName }}</td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "street" | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.street }}</td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "city" | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.city }}</td>
        </ng-container>

        <ng-container matColumnDef="book">
          <th style="border-radius: 0 10px 0 0" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="confirm-col" (click)="$event.stopPropagation()">
            <i *ngIf="row.hasAnonymousEvaluationDocuments" class="material-icons black customTooltip" style="position:relative; top: 8px; margin-right:3px" [placement]="'top'" [ngbTooltip]="'hasAnonymousEvaluationDocuments' | translate" (click)="eventRowClicked(row)">description</i>
            <checkmark-icon  *ngIf="!row.signed && !row.canBeSignedAndRated && row.state != 'CANCELED_BEFORE_BOOKED' && row.state != 'CANCELED' && row.state != 'REJECTED_NO_AVAILABILITY'" [color]="'grey'" [placement]="'top'" [ngbTooltip]="'signoffEvent_not_yet' | translate"></checkmark-icon>
            <checkmark-icon [color]="'blue'" *ngIf="!row.signed && row.canBeSignedAndRated" [placement]="'top'" [ngbTooltip]="'signoffEvent' | translate" (click)="signOffClicked(row)" ></checkmark-icon>
            <checkmark-icon [color]="'green'" *ngIf="row.signed"  [placement]="'top'" [ngbTooltip]="'signoffEvent_signed' | translate"></checkmark-icon>
            <smiley-icon [color]="'grey'" *ngIf="!row.rated && !row.canBeSignedAndRated && row.state != 'CANCELED_BEFORE_BOOKED' && row.state != 'CANCELED' && row.state != 'REJECTED_NO_AVAILABILITY'" [placement]="'top'" [ngbTooltip]="'submitFeedback_not_yet' | translate"></smiley-icon>
            <a *ngIf="!row.rated && row.canBeSignedAndRated" href="{{ '/customer-feedback-page?buid=' + row.buid }}" target="_blank">
              <smiley-icon [color]="'navy'" [placement]="'top'" [ngbTooltip]="'submitFeedback' | translate"></smiley-icon>
            </a>
            <smiley-icon *ngIf="row.rated" [color]="'green'" [placement]="'top'" [ngbTooltip]="'submitFeedback_rated' | translate"></smiley-icon>
            <bookagain-icon [color]="'navy'" [placement]="'top'" [ngbTooltip]="'inquiryEventOneMoreTime' | translate" (click)="selectedEventToBookAgain(row)"></bookagain-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="eventRowClicked(row)"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
