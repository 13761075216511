import { GibDialogService } from "./../../../components/dialogs/gib-dialog.service";
import { EventGridComponent } from "./../components/event-grid/event-grid.component";
import { EventOverviewService, EventOverviewDTO, EventOverviewUpdateDTO } from "./../../../services/event-overview.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Event } from "./../../../services/event.service";
import { TranslateService } from "@ngx-translate/core";
import { Filter } from "../../../services/event-overview.service";

// Store
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { Observable, Subscription, of } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";

@Component({
  selector: "event-overview-page",
  templateUrl: "./event-overview-page.component.html",
  styleUrls: ["./event-overview-page.component.scss"],
  animations: [trigger("visibility", [state("visible", style({ opacity: 1, height: "*" })), state("hidden", style({ opacity: 0, height: "0px" })), transition("visible <=> hidden", animate(500))])],
})
export class EventOverviewPageComponent implements OnInit, OnDestroy {
  eventsOverview$: Observable<EventOverviewDTO[]>;
  eventsTwoWeeks$: Observable<EventOverviewDTO[]>;
  events$: Observable<EventOverviewDTO[]>;
  eventsCancelled$: Observable<EventOverviewDTO[]>;
  eventsInactive$: Observable<EventOverviewDTO[]>;
  filteredEvents: EventOverviewDTO[] = [];

  selectedEventOverviewDTO: EventOverviewDTO;
  selectedEvent: Event;
  selectedEventForm: UntypedFormGroup;
  eventHealthScreening: UntypedFormGroup;
  componentName = "EventOverviewPageComponent";
  paginationNumber: number;
  paginationNumberTwoWeeks: number;
  downloadUrl;
  filename;

  showPendingButtons: boolean;
  result: string;

  eventsOverviewDate = new Date();
  eventsDate = new Date();
  eventsCancellationsDate = new Date();
  eventsRejectedDate = new Date();

  focus: boolean;

  subscriptions: Subscription[] = [];

  @ViewChild("grid1", { static: true }) grid1: EventGridComponent;
  @ViewChild("grid2", { static: true }) grid2: EventGridComponent;
  @ViewChild("grid3", { static: true }) grid3: EventGridComponent;
  @ViewChild("grid4", { static: true }) grid4: EventGridComponent;
  @ViewChild("grid5", { static: true }) grid5: EventGridComponent;

  private views: { [key: number]: string } = {
    1: "overview",
    2: "eventsTwoWeeks",
    3: "events",
    4: "cancelled",
    5: "rejected",
  };

  eventsOverviewFilters: Filter[] = [];
  eventsOverviewTwoWeeksFilters: Filter[] = [];
  eventsFilters: Filter[] = [];
  cancelledFilters: Filter[] = [];
  rejectedFilters: Filter[] = [];

  constructor(private eventOverviewService: EventOverviewService, private translateService: TranslateService, private dialogService: GibDialogService, private store: Store<fromStore.GibState>) {}

  ngOnInit() {
    // clear store
    this.store.dispatch(new fromStore.ClearActiveEvents());
    this.store.dispatch(new fromStore.ClearCancelledEvents());

    // load current month's active events
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.eventsOverviewDate),
        to: this.getPagingTo(this.eventsOverviewDate),
      })
    );
    // load previous month's active event
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, -1)),
        to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, -1)),
      })
    );
    // load next month's active events
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, 1)),
        to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, 1)),
      })
    );
    // selectors
    this.eventsOverview$ = this.store.select(fromStore.getActiveEventsOverview(this.getPeriod(this.eventsOverviewDate)));
    this.eventsTwoWeeks$ = this.store.select(fromStore.getActiveEventsTwoWeeks(this.startOfWeek, this.endOfSecondWeek));
    this.events$ = this.store.select(fromStore.getActiveEvents(this.getPeriod(this.eventsDate)));
    this.eventsCancelled$ = this.store.select(fromStore.getCancelledEvents(this.getPeriod(this.eventsCancellationsDate)));
    this.eventsInactive$ = this.store.select(fromStore.getInactiveEvents(this.getPeriod(this.eventsRejectedDate)));

    this.paginationNumber = 50;
    this.paginationNumberTwoWeeks = null;
  }

  private getPeriod(date: Date): string {
    const month = this.getPaddedMonth(date);
    return month + "/" + date.getFullYear();
  }

  private getPagingFrom(date: Date): string {
    const month = this.getPaddedMonth(date);
    const from = date.getFullYear() + "-" + month + "-" + "01";
    return from;
  }

  private getPagingTo(date: Date): string {
    const month = this.getPaddedMonth(date);
    const daysInMonth = this.getDaysInMonth(date.getMonth() + 1, date.getFullYear());
    const to = date.getFullYear() + "-" + month + "-" + daysInMonth;
    return to;
  }

  private getPaddedMonth(date: Date): string {
    return ("0" + (date.getMonth() + 1)).slice(-2);
  }

  private getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  private addMonthsToDate(d: Date, offset: number): Date {
    const date: Date = new Date(d.getTime());
    date.setDate(1);
    date.setMonth(date.getMonth() + offset);
    return date;
  }

  get startOfWeek() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  get endOfSecondWeek() {
    const date = this.startOfWeek;
    date.setDate(date.getDate() + 13);
    return date;
  }

  onUpdate(event: { payload: EventOverviewUpdateDTO; date: Date }) {
    const { eventId, eventInquiryHealthscreeningId } = event.payload;
    this.store.dispatch(new fromStore.UpdateEvent(event.payload));
    this.subscriptions.push(
      this.store.select(fromStore.getActiveEventsOverview(this.getPeriod(event.date))).subscribe((eventsInStore) => {
        if (eventsInStore && eventsInStore.length > 0) {
          const updatedEventInStore = eventsInStore.filter((eventInStore) => eventInStore.eventId === eventId && eventInStore.eventInquiryHealthscreeningId === eventInquiryHealthscreeningId)[0];
          const index = this.filteredEvents.findIndex((fe) => fe.eventId === eventId && fe.eventInquiryHealthscreeningId === eventInquiryHealthscreeningId);
          this.filteredEvents[index] = updatedEventInStore;
          if (this.eventsOverviewFilters.length > 0) {
            this.eventsOverview$ = of([...this.filteredEvents]);
          } else if (this.eventsFilters.length > 0) {
            this.events$ = of([...this.filteredEvents]);
          }
        }
      })
    );
  }

  onFilter(event: Filter[], grid: number) {
    if (grid === 1) {
      if (event.length > 0) {
        this.eventsOverviewFilters = event;
        this.getEventsWithFilter(this.views[1], event);
      } else {
        this.eventsOverviewFilters = [];
        this.eventsOverview$ = this.store.select(fromStore.getActiveEventsOverview(this.getPeriod(this.eventsOverviewDate)));
      }
    } else if (grid === 2) {
      if (event.length > 0) {
        this.eventsOverviewTwoWeeksFilters = event;
      } else {
        this.eventsOverviewTwoWeeksFilters = [];
      }
    } else if (grid === 3) {
      if (event.length > 0) {
        this.eventsFilters = event;
        this.getEventsWithFilter(this.views[3], event);
      } else {
        this.eventsFilters = [];
        this.events$ = this.store.select(fromStore.getActiveEvents(this.getPeriod(this.eventsDate)));
      }
    } else if (grid === 4) {
      if (event.length > 0) {
        this.cancelledFilters = event;
        this.getEventsWithFilter(this.views[4], event);
      } else {
        this.cancelledFilters = [];
      }
    } else if (grid === 5) {
      if (event.length > 0) {
        this.rejectedFilters = event;
        this.getEventsWithFilter(this.views[5], event);
      } else {
        this.rejectedFilters = [];
      }
    }
  }

  private getEventsWithFilter(view: string, filters: Filter[]) {
    const key = Object.keys(this.views).find((key) => {
      return this.views[key] === view;
    });
    this.eventOverviewService
      .getEventsWithFilter(view, filters)
      .pipe(take(1))
      .subscribe((res) => {
        if (key === "1") {
          this.filteredEvents = res.body;
          this.eventsOverview$ = of(this.filteredEvents);
        } else if (key === "2") {
        } else if (key === "3") {
          this.filteredEvents = res.body;
          this.events$ = of(this.filteredEvents);
        } else if (key === "4") {
        } else if (key === "5") {
        }
      });
  }

  onChange(event: { offset: number; date: Date }, grid: number) {
    this.focus = false;
    if (grid === 1) {
      const date: Date = new Date(this.eventsOverviewDate.getTime());
      if (event.offset) {
        date.setDate(1);
        date.setMonth(this.eventsOverviewDate.getMonth() + event.offset);
      } else {
        date.setDate(1);
        date.setMonth(event.date.getMonth());
        date.setFullYear(event.date.getFullYear());
      }
      this.eventsOverviewDate = new Date(date.getTime());
      this.eventsOverview$ = this.store.select(fromStore.getActiveEventsOverview(this.getPeriod(this.eventsOverviewDate)));
      this.focus = true;
      this.getActiveEvents(grid);
    } else if (grid === 3) {
      const date: Date = new Date(this.eventsDate.getTime());
      if (event.offset) {
        date.setDate(1);
        date.setMonth(this.eventsDate.getMonth() + event.offset);
      } else {
        date.setDate(1);
        date.setMonth(event.date.getMonth());
        date.setFullYear(event.date.getFullYear());
      }
      this.eventsDate = new Date(date.getTime());
      this.focus = true;
      this.events$ = this.store.select(fromStore.getActiveEvents(this.getPeriod(this.eventsDate)));
      this.getActiveEvents(grid);
    } else if (grid === 4) {
      const date: Date = new Date(this.eventsCancellationsDate.getTime());
      if (event.offset) {
        date.setDate(1);
        date.setMonth(this.eventsCancellationsDate.getMonth() + event.offset);
      } else {
        date.setDate(1);
        date.setMonth(event.date.getMonth());
        date.setFullYear(event.date.getFullYear());
      }
      this.eventsCancellationsDate = new Date(date.getTime());
      this.eventsCancelled$ = this.store.select(fromStore.getCancelledEvents(this.getPeriod(this.eventsCancellationsDate)));
      this.getCancelledEvents(false);
    } else if (grid === 5) {
      const date: Date = new Date(this.eventsRejectedDate.getTime());
      if (event.offset) {
        date.setDate(1);
        date.setMonth(this.eventsRejectedDate.getMonth() + event.offset);
      } else {
        date.setDate(1);
        date.setMonth(event.date.getMonth());
        date.setFullYear(event.date.getFullYear());
      }
      this.eventsRejectedDate = new Date(date.getTime());
      this.eventsInactive$ = this.store.select(fromStore.getInactiveEvents(this.getPeriod(this.eventsRejectedDate)));
      this.getInactiveEvents(false);
    }
  }

  private getActiveEvents(grid: number) {
    const currentDate: Date = new Date();
    const currentPeriod: string = this.getPeriod(currentDate);

    let periods: string[] = [];
    this.store
      .select(fromStore.getActiveEventsPeriods)
      .pipe(take(1))
      .subscribe((res) => {
        periods = res;
      });

    // Clean up
    // removing everything except current, previous, next and current month for both tabs
    const overviewSelectedPeriod: string = this.getPeriod(this.eventsOverviewDate);
    const overviewSelectedNextPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsOverviewDate, 1));
    const overviewSelectedPreviousPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsOverviewDate, -1));

    const eventsSelectedPeriod: string = this.getPeriod(this.eventsDate);
    const eventsSelectedNextPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsDate, 1));
    const eventsSelectedPreviousPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsDate, -1));

    periods.forEach((period) => {
      if (
        period === currentPeriod ||
        period === overviewSelectedPeriod ||
        period === overviewSelectedNextPeriod ||
        period === overviewSelectedPreviousPeriod ||
        period === eventsSelectedPeriod ||
        period === eventsSelectedNextPeriod ||
        period === eventsSelectedPreviousPeriod
      ) {
      } else {
        this.store.dispatch(new fromStore.RemoveActiveEvents(period));
      }
    });

    // Navigation from the events overview tab
    if (grid === 1) {
      // check and load current
      if (!periods.includes(overviewSelectedPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.eventsOverviewDate),
            to: this.getPagingTo(this.eventsOverviewDate),
          })
        );
      }
      // check and load next
      if (!periods.includes(overviewSelectedNextPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, 1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, 1)),
          })
        );
      }
      // check and load previous
      if (!periods.includes(overviewSelectedPreviousPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, -1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, -1)),
          })
        );
      }
    }
    // Navigation from the events tab
    else if (grid === 3) {
      // check and load current
      if (!periods.includes(eventsSelectedPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.eventsDate),
            to: this.getPagingTo(this.eventsDate),
          })
        );
      }
      // check and load next
      if (!periods.includes(eventsSelectedNextPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsDate, 1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsDate, 1)),
          })
        );
      }
      // check and load previous
      if (!periods.includes(eventsSelectedPreviousPeriod)) {
        this.store.dispatch(
          new fromStore.LoadActiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsDate, -1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsDate, -1)),
          })
        );
      }
    }
  }

  private getCancelledEvents(init: boolean) {
    if (init) {
      return this.checkCancelledStore()
        .pipe(
          switchMap(() => of(true)),
          catchError(() => of(false)),
          take(1)
        )
        .subscribe();
    } else {
      let periods: string[] = [];
      this.store
        .select(fromStore.getCancelledEventsPeriods)
        .pipe(take(1))
        .subscribe((res) => {
          periods = res;
        });

      // Clean up
      // removing everything except current, previous and next
      const cancelledCurrentPeriod: string = this.getPeriod(this.eventsCancellationsDate);
      const cancelledNextPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsCancellationsDate, 1));
      const cancelledPreviousPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsCancellationsDate, -1));

      periods.forEach((period) => {
        if (period === cancelledCurrentPeriod || period === cancelledNextPeriod || period === cancelledPreviousPeriod) {
        } else {
          this.store.dispatch(new fromStore.RemoveCancelledEvents(period));
        }
      });

      // check and load current
      if (!periods.includes(cancelledCurrentPeriod)) {
        this.store.dispatch(
          new fromStore.LoadCancelledEvents({
            from: this.getPagingFrom(this.eventsCancellationsDate),
            to: this.getPagingTo(this.eventsCancellationsDate),
          })
        );
      }
      // check and load next
      if (!periods.includes(cancelledNextPeriod)) {
        this.store.dispatch(
          new fromStore.LoadCancelledEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsCancellationsDate, 1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsCancellationsDate, 1)),
          })
        );
      }
      // check and load previous
      if (!periods.includes(cancelledPreviousPeriod)) {
        this.store.dispatch(
          new fromStore.LoadCancelledEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsCancellationsDate, -1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsCancellationsDate, -1)),
          })
        );
      }
    }
  }

  private getInactiveEvents(init: boolean) {
    if (init) {
      return this.checkInactiveStore()
        .pipe(
          switchMap(() => of(true)),
          catchError(() => of(false)),
          take(1)
        )
        .subscribe();
    } else {
      let periods: string[] = [];
      this.store
        .select(fromStore.getInactiveEventsPeriods)
        .pipe(take(1))
        .subscribe((res) => {
          periods = res;
        });

      // Clean up
      // removing everything except current, previous and next
      const inactiveCurrentPeriod: string = this.getPeriod(this.eventsRejectedDate);
      const inactiveNextPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsRejectedDate, 1));
      const inactivePreviousPeriod: string = this.getPeriod(this.addMonthsToDate(this.eventsRejectedDate, -1));

      periods.forEach((period) => {
        if (period === inactiveCurrentPeriod || period === inactiveNextPeriod || period === inactivePreviousPeriod) {
        } else {
          this.store.dispatch(new fromStore.RemoveInactiveEvents(period));
        }
      });

      // check and load current
      if (!periods.includes(inactiveCurrentPeriod)) {
        this.store.dispatch(
          new fromStore.LoadInactiveEvents({
            from: this.getPagingFrom(this.eventsRejectedDate),
            to: this.getPagingTo(this.eventsRejectedDate),
          })
        );
      }
      // check and load next
      if (!periods.includes(inactiveNextPeriod)) {
        this.store.dispatch(
          new fromStore.LoadInactiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsRejectedDate, 1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsRejectedDate, 1)),
          })
        );
      }
      // check and load previous
      if (!periods.includes(inactivePreviousPeriod)) {
        this.store.dispatch(
          new fromStore.LoadInactiveEvents({
            from: this.getPagingFrom(this.addMonthsToDate(this.eventsRejectedDate, -1)),
            to: this.getPagingTo(this.addMonthsToDate(this.eventsRejectedDate, -1)),
          })
        );
      }
    }
  }

  private checkCancelledStore(): Observable<boolean> {
    return this.store.select(fromStore.getCancelledEventsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          // load current month's cancelled events
          this.store.dispatch(
            new fromStore.LoadCancelledEvents({
              from: this.getPagingFrom(this.eventsCancellationsDate),
              to: this.getPagingTo(this.eventsCancellationsDate),
            })
          );
          // load previous month's cancelled event
          this.store.dispatch(
            new fromStore.LoadCancelledEvents({
              from: this.getPagingFrom(this.addMonthsToDate(this.eventsCancellationsDate, -1)),
              to: this.getPagingTo(this.addMonthsToDate(this.eventsCancellationsDate, -1)),
            })
          );
          // load next month's cancelled events
          this.store.dispatch(
            new fromStore.LoadCancelledEvents({
              from: this.getPagingFrom(this.addMonthsToDate(this.eventsCancellationsDate, 1)),
              to: this.getPagingTo(this.addMonthsToDate(this.eventsCancellationsDate, 1)),
            })
          );
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  private checkInactiveStore(): Observable<boolean> {
    return this.store.select(fromStore.getInactiveEventsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          // load current month's inactive events
          this.store.dispatch(
            new fromStore.LoadInactiveEvents({
              from: this.getPagingFrom(this.eventsRejectedDate),
              to: this.getPagingTo(this.eventsRejectedDate),
            })
          );
          // load previous month's inactive event
          this.store.dispatch(
            new fromStore.LoadInactiveEvents({
              from: this.getPagingFrom(this.addMonthsToDate(this.eventsRejectedDate, -1)),
              to: this.getPagingTo(this.addMonthsToDate(this.eventsRejectedDate, -1)),
            })
          );
          // load next month's inactive events
          this.store.dispatch(
            new fromStore.LoadInactiveEvents({
              from: this.getPagingFrom(this.addMonthsToDate(this.eventsRejectedDate, 1)),
              to: this.getPagingTo(this.addMonthsToDate(this.eventsRejectedDate, 1)),
            })
          );
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  clicked(event: any) {
    this.focus = false;
    window.scroll(0, 0);
    if (event.index === 0) {
      this.grid1.autoSizeAllColumns();
      this.focus = true;
    } else if (event.index === 1) {
      this.grid2.autoSizeAllColumns();
    } else if (event.index === 2) {
      this.grid3.autoSizeAllColumns();
    } else if (event.index === 3) {
      this.grid4.autoSizeAllColumns();
      this.grid4.gridOptions.columnApi.setColumnVisible("cancelationDate", true);
      this.grid4.gridOptions.columnApi.setColumnVisible("cancelationReason", true);
      this.grid4.hideButtons();
      this.getCancelledEvents(true);
    } else if (event.index === 4) {
      this.grid5.autoSizeAllColumns();
      this.grid5.hideButtons();
      this.getInactiveEvents(true);
    }
  }

  openDownloadExcelConfirmation() {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadExcelConfirmationText");
    if (this.translateService.currentLang === "en") {
      this.filename = "events.xlsx";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "Veranstaltungen.xlsx";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.downloadExcel(this.filename));
  }

  downloadExcel(filename: string) {
    this.eventOverviewService.downloadExcel().subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  onRefreshData() {
    this.store.dispatch(new fromStore.ClearActiveEvents());

    // load current month's active events
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.eventsOverviewDate),
        to: this.getPagingTo(this.eventsOverviewDate),
      })
    );
    // load previous month's active event
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, -1)),
        to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, -1)),
      })
    );
    // load next month's active events
    this.store.dispatch(
      new fromStore.LoadActiveEvents({
        from: this.getPagingFrom(this.addMonthsToDate(this.eventsOverviewDate, 1)),
        to: this.getPagingTo(this.addMonthsToDate(this.eventsOverviewDate, 1)),
      })
    );

    this.store.dispatch(new fromStore.ClearCancelledEvents());
    this.store.dispatch(new fromStore.ClearInactiveEvents());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
