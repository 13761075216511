import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GibDialogService } from 'src/app/components/dialogs/gib-dialog.service';
import { HealthInsurance, HealthInsuranceService } from 'src/app/services/health-insurance.service';

@Component({
  selector: 'healthinsurance-table',
  templateUrl: './healthinsurance-table.component.html',
  styleUrls: ['./healthinsurance-table.component.scss']
})
export class HealthinsuranceTableComponent implements OnInit {

  sort: any;
  hiArray: HealthInsurance[];
  expandedElement: HealthInsurance | null;
  isExpandable = true;
  displayedColumns: string[] = ['companynameAlias', 'edit', 'delete'];
  dataSource: MatTableDataSource < HealthInsurance > ;
  canEdit = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  @Output() healthInsuranceSelected = new EventEmitter<HealthInsurance>();
  @Output() addHealthInsuranceClicked = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @Input() set healthInsurances(healthInsurances: HealthInsurance[]) {
    this.hiArray = healthInsurances;
    this.dataSource = new MatTableDataSource(healthInsurances);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  get healthInsurances(): HealthInsurance[] {
    return this.hiArray;
  }


  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private dialogService: GibDialogService,
     private healthInsuranceService: HealthInsuranceService) {}

  ngOnInit() {
    const role = localStorage.role;
    if (role === 'gib_director' || role === 'gib_admin' || role === 'gib_employee') {
      this.canEdit = true;
    }

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  addHealthInsurance() {
    this.addHealthInsuranceClicked.emit(null);
    this.expandedElement = null;
  }

  selectHealthInsurance(hi: HealthInsurance) {
    this.healthInsuranceSelected.emit(hi);
    this.expandedElement = null;
  }

  openDeleteHealthInsuranceConfirmation(hi: HealthInsurance) {
    const title = this.translateService.instant('HEALTHINSURANCE_DELETE_CONFIRMATION_TITLE');
    const text = this.translateService.instant('HEALTHINSURANCE_DELETE_CONFIRMATION_TEXT', { companynameAlias: hi.companynameAlias });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteHealthInsurance(hi.companynameAlias));
  }

  deleteHealthInsurance(id: string) {
    this.healthInsuranceService.delete(id).subscribe(() => {
        this.refresh.emit(null);
    });
  }




}
