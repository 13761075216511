<div class="wrapper">
  <gib-fieldset [title]="'chatTillNow'">
    <div class="row">
      <div class="col-sm-12" *ngFor="let chat of chatElements">
        <p class="author"><b>{{(chat.timestamp | amDateFormat:'DD.MM.YYYY') + ' ' + (chat.timestamp | amDateFormat:'LT') + " - " + chat.author}} </b></p>
        <p>{{chat.content}}</p>
      </div>
    </div>
  </gib-fieldset>
</div>
