import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/helper/form.helper';
import { EventEmitterService } from 'src/app/services/eventEmitterService';
import { HealthInsurance, HealthInsuranceService } from 'src/app/services/health-insurance.service';
import { AddHealthinsuranceBottomSheetComponent } from '../components/add-healthinsurance-bottom-sheet/add-healthinsurance-bottom-sheet.component';
import { HealthinsuranceTableComponent } from '../components/healthinsurance-table/healthinsurance-table.component';

@Component({
  selector: 'healthinsurance-page',
  templateUrl: './healthinsurance-page.component.html',
  styleUrls: ['./healthinsurance-page.component.sass']
})
export class HealthinsurancePageComponent implements OnInit {
  @ViewChild('hitable') healthInsuranceTable: HealthinsuranceTableComponent;

  healthInsurances: HealthInsurance[];
  showAddHealthInsuranceCard = false;
  selectedHealthInsurance: HealthInsurance;

  constructor(private healthInsuranceService: HealthInsuranceService, private translateService: TranslateService, private formHelper: FormHelper,
    private bottomSheet: MatBottomSheet, private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
    this.eventEmitterService.listen().subscribe(() => {
      this.getHealthInsurances();
    });
    this.getHealthInsurances();
  }

  getHealthInsurances() {
    this.healthInsuranceService.findAll().subscribe(res => {
      this.selectedHealthInsurance = null;
      this.healthInsurances = res.body.sort(this.formHelper.sortByCompanyName);
    });
  }

  setSelectedHealthInsurance(hi: HealthInsurance) {
    this.selectedHealthInsurance = hi;
    this.openBottomSheet();
  }

  openAddEmployeeBottomSheet() {
    this.selectedHealthInsurance = new HealthInsurance();
    this.openBottomSheet();
  }


  openBottomSheet() {
    const sheetData = {selectedHealthInsurance: this.selectedHealthInsurance};
    this.bottomSheet.open(AddHealthinsuranceBottomSheetComponent, { data: sheetData});
  }



}
