<div class="row">  

  <div class="col-sm-12 col-md-4">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
      </mat-form-field>
  </div>
  
  <div class="col-sm-12 col-md-6"></div>
  
  <div class="col-sm-12 col-md-2 mb-2">
    <a class="btn btn-secondary add-customer-btn" (click)="addCustomer()">+</a>
  </div>

  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="firstname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'firstname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
        </ng-container>
        
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'lastname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.lastname}} </td>
        </ng-container>
        
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phonenumber' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.phonenumber}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.email}}</td>
        </ng-container>
        
        <ng-container matColumnDef="companyname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'companyname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.companyname}}</td>
        </ng-container>
        
        <ng-container matColumnDef="gpsUser">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col">
            <img src="assets/icon/bt_disabled.png" class="bt-icon" *ngIf="!row.gpsUser" [ngbTooltip]="'disableGpsUser' | translate" />
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectCustomer(row)">edit</i>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteCustomerConfirmation(row)">delete</i>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>

      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>





</div>