<div class="row">
  <div class="col-xl-12">
    <h4>{{ "eventInformation" | translate }}</h4>
  </div>
  <div class="col-xl-6 col-md-12">
    <div class="box">
      <div class="row">
        <div *ngIf="false" class="col-xl-12">
          <gib-checkbox [label]="'moduleIntroduction'" [control]="eventForm.get('moduleIntroduction')"></gib-checkbox>
          <br />
          <p>
            <i>{{ "moduleNote" | translate }}</i>
          </p>
        </div>
        <div *ngIf="false" class="col-xl-12">
          <gib-checkbox [label]="'modulePresentation'" [control]="eventForm.get('modulePresentation')"></gib-checkbox>
          <br />
          <p>
            <i>{{ "moduleNote" | translate }}</i>
          </p>
        </div>
        <div class="col-xl-12">
          <gib-checkbox [label]="'moduleEnglish'" [control]="eventForm.get('moduleEnglish')"></gib-checkbox>
        </div>
        <div class="col-xl-12">
          <gib-checkbox [label]="'anonymEvaluation'" [control]="eventForm.get('anonymEvaluation')"></gib-checkbox>
        </div>
        <div class="col-xl-12">
          <gib-checkbox [label]="'sendAppointmentlistToCorporateCustomer'" [control]="eventForm.get('sendAppointmentlistToCorporateCustomer')"></gib-checkbox>
        </div>
        <div class="col-xl-12">
          <gib-checkbox [label]="'skipSchedules'" [control]="eventForm.get('skipSchedules')"></gib-checkbox>
        </div>
        <div class="col-xl-12">
          <gib-checkbox [label]="'saveCo2Paper'" [control]="eventForm.get('saveCo2Paper')"></gib-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-md-12">
    <div class="box">
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="section-header">{{ "guestWifiOnSite" | translate }}</div>
              <div>
                <gib-radio-button-group [control]="eventForm.get('guestWifiOnSite')" [valueLabels]="wifiOptions"></gib-radio-button-group>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="section-header">{{ "plannedLocation" | translate }}</div>
              <div>
                <gib-radio-button-group [control]="eventForm.get('plannedLocation')" [valueLabels]="plannedLocationOptions"></gib-radio-button-group>
              </div>
            </div>
            <div class="mt-1 col-xl-12">
              <div class="section-header">{{ "parkingAccess" | translate }}</div>
              <div>
                <gib-radio-button-group [control]="eventForm.get('parkingPossible')" [valueLabels]="parkingPossibleOptions"></gib-radio-button-group>
              </div>
              <div *ngIf="eventForm.get('parkingPossible').value === 'false'">
                <gib-input [placeholder]="'alternative'" [control]="eventForm.get('parkingAlternative')"></gib-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-12" style="height: 220px">
          <div class="row">
            <div class="col-xl-12">
              <div class="section-header">{{ "targetGroup" | translate }}</div>
              <div><gib-checkbox [disabled]="eventForm.get('targetGroup').disabled" [label]="'officeStaff'" [control]="targetGroups.get('officeStaff')"></gib-checkbox></div>
              <div><gib-checkbox [disabled]="eventForm.get('targetGroup').disabled" [label]="'physicalWorkers'" [control]="targetGroups.get('physicalWorkers')"></gib-checkbox></div>
              <div><gib-checkbox [disabled]="eventForm.get('targetGroup').disabled" [label]="'apprentices'" [control]="targetGroups.get('apprentices')"></gib-checkbox></div>
              <div><gib-checkbox [disabled]="eventForm.get('targetGroup').disabled" [label]="'students'" [control]="targetGroups.get('students')"></gib-checkbox></div>
              <div><gib-checkbox [disabled]="eventForm.get('targetGroup').disabled" [label]="'other'" [control]="targetGroups.get('other')"></gib-checkbox></div>
              <div *ngIf="targetGroups.get('other').value">
                <gib-input [disabled]="eventForm.get('targetGroup').disabled" [placeholder]="'other'" [control]="targetGroups.get('otherText')"></gib-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
