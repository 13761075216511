import { FaqService } from './../../../../services/faq.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Faq } from 'src/app/services/faq.service';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'add-faq-bottom-sheet',
  templateUrl: './add-faq-bottom-sheet.component.html',
  styleUrls: ['./add-faq-bottom-sheet.component.scss']
})
export class AddFaqBottomSheetComponent implements OnInit {

  selectedFaq: Faq;
  faqForm: UntypedFormGroup;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheet: MatBottomSheet, private faqService: FaqService) { }

  ngOnInit() {
    this.selectedFaq = this.data.selectedFaq;
    this.faqForm = this.faqService.mapFaqToForm(this.selectedFaq);
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

}
