import { GibGdprCheckboxComponent } from "./gib-gdpr-checkbox/gib-gdpr-checkbox.component";
import { GibRadioButtonGroupVerticalComponent } from "./gib-radio-button-group-vertical/gib-radio-button-group-vertical.component";
import { NgModule } from "@angular/core";

import { UtilsModule } from "../utils/utils.module";
import { NavigationModule } from "./navigation/navigation.module";
import { GibInputComponent } from "./gib-input/gib-input.component";
import { GibExpansionPanelComponent } from "./gib-expansion-panel/gib-expansion-panel.component";
import { GibSelectComponent } from "./gib-select/gib-select.component";
import { GibSuggestSelectComponent } from "./gib-suggest-select/gib-suggest-select.component";
import { GibTextareaComponent } from "./gib-textarea/gib-textarea.component";
import { GibCheckboxComponent } from "./gib-checkbox/gib-checkbox.component";
import { GibFieldsetComponent } from "./gib-fieldset/gib-fieldset.component";
import { GibConfirmationDialogComponent } from "./dialogs/gib-confirmation-dialog/gib-confirmation-dialog.component";
import { GibDialogComponent } from "./dialogs/gib-dialog/gib-dialog.component";
import { GibErrorDialogComponent } from "./dialogs/gib-error-dialog/gib-error-dialog.component";
import { GibRadioButtonGroupComponent } from "./gib-radio-button-group/gib-radio-button-group.component";
import { GibFileuploadComponent } from "./gib-fileupload/gib-fileupload.component";
import { WarningIconComponent } from "./warning-icon/warning-icon.component";
import { SafeHtml } from "./pipes/safe-html/safe-html.component";
import { GibTextConfirmationDialogComponent } from "./dialogs/gib-text-confirmation-dialog/gib-text-confirmation-dialog.component";
import { GibCheckCloseComponent } from "./gib-check-close/gib-check-close.component";
import { GibConfirmationErrorDialogComponent } from "./dialogs/gib-confirmation-error-dialog/gib-confirmation-error-dialog.component";
import { GibServerErrorDialogComponent } from "./dialogs/gib-server-error-dialog/gib-server-error-dialog.component";
import { LoaderComponent } from "./loader/loader.component";
import { SecurePipe } from "./pipes/secure.pipe";
import { GibImageHandlerComponent } from "./gib-image-handler/gib-image-handler.component";
import { GibVideoHandlerComponent } from "./gib-video-handler/gib-video-handler.component";
import { TopicPipe, TopicStringPipe } from "./pipes/topic.pipe";
import { GibMultiselectComponent } from "./gib-multiselect/gib-multiselect.component";
import { GibDragAndDropBoxComponent } from "./gib-drag-and-drop-box/gib-drag-and-drop-box.component";
import { GibDatepickerComponent } from "./gib-datepicker/gib-datepicker.component";
import { GibTimepickerComponent } from "./gib-timepicker/gib-timepicker.component";

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { GibChatComponent } from "./gib-chat/gib-chat.component";
import { CustomerFormComponent } from "../pages/customer/components/customer-form/customer-form.component";
import { GibH5Component } from "./gib-h5/gib-h5.component";
import { GibModuleSelectionComponent } from "./gib-module-selection/gib-module-selection.component";
import { GibGridInputComponent } from "./gib-grid-input/gib-grid-input.component";
import { GibGridTrainerSelectComponent } from "./gib-grid-trainer-select/gib-grid-trainer-select.component";
import { GibCalendarComponent } from "./gib-calendar/gib-calendar.component";
import { CalendarModule } from "angular-calendar";
import { AvailabilityPipe } from "./pipes/availability.pipe";
import { GibIconButtonComponent } from "./gib-icon-button/gib-icon-button.component";
import { GibGridHospitantSelectComponent } from "./gib-grid-hospitant-select/gib-grid-hospitant-select.component";
import { GibRadioButtonGroupOrangeComponent } from "./gib-radio-button-group-orange/gib-radio-button-group-orange.component";
import { GibMultiInputComponent } from "./gib-multi-input/gib-multi-input.component";
import { GibModuleReplaceComponent } from "./gib-module-replace/gib-module-replace.component";
import { GibDocumentsComponent } from "./gib-documents/gib-documents.component";
import { GibPrivacyDialogComponent } from "./dialogs/gib-privacy-dialog/gib-privacy-dialog.component";
import { GibCancelationDialogComponent } from "./dialogs/gib-cancelation-dialog/gib-cancelation-dialog.component";
import { GibWaitinglistDialogComponent } from "./dialogs/gib-waitinglist-dialog/gib-waitinglist-dialog.component";
import { GibSlideshowComponent } from "./gib-slideshow/gib-slideshow.component";
import { GibModuleSelectionItemComponent } from "./gib-module-selection-item/gib-module-selection-item.component";
import { GibRadioButtonGroupVerticalV2Component } from "./gib-radio-button-group-vertical-v2/gib-radio-button-group-vertical-v2.component";
import { SmileyIconComponent } from "./smiley-icon/smiley-icon.component";
import { CheckmarkIconComponent } from "./checkmark-icon/checkmark-icon.component";
import { BookagainIconComponent } from "./bookagain-icon/bookagain-icon.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { GibInputCurrencyComponent } from "./gib-input-currency/gib-input-currency.component";
import { GibInputPercentageComponent } from "./gib-input-percentage/gib-input-percentage.component";
import { PercentageDirective } from "../directives/percentage.directive";

@NgModule({
  declarations: [
    GibInputComponent,
    GibExpansionPanelComponent,
    GibSelectComponent,
    GibSuggestSelectComponent,
    GibTextareaComponent,
    GibCheckboxComponent,
    GibFieldsetComponent,
    GibDialogComponent,
    GibConfirmationDialogComponent,
    GibErrorDialogComponent,
    GibRadioButtonGroupComponent,
    GibFileuploadComponent,
    WarningIconComponent,
    SafeHtml,
    GibTextConfirmationDialogComponent,
    GibCheckCloseComponent,
    GibConfirmationErrorDialogComponent,
    GibServerErrorDialogComponent,
    GibPrivacyDialogComponent,
    GibWaitinglistDialogComponent,
    LoaderComponent,
    SecurePipe,
    GibImageHandlerComponent,
    GibVideoHandlerComponent,
    TopicPipe,
    TopicStringPipe,
    GibMultiselectComponent,
    GibDragAndDropBoxComponent,
    GibDatepickerComponent,
    GibTimepickerComponent,
    GibChatComponent,
    CustomerFormComponent,
    GibH5Component,
    GibModuleSelectionComponent,
    GibGridInputComponent,
    GibGridTrainerSelectComponent,
    GibCalendarComponent,
    GibRadioButtonGroupVerticalComponent,
    AvailabilityPipe,
    GibIconButtonComponent,
    GibGridHospitantSelectComponent,
    GibGdprCheckboxComponent,
    GibRadioButtonGroupOrangeComponent,
    GibMultiInputComponent,
    GibModuleReplaceComponent,
    GibDocumentsComponent,
    GibCancelationDialogComponent,
    GibSlideshowComponent,
    GibModuleSelectionItemComponent,
    GibRadioButtonGroupVerticalV2Component,
    SmileyIconComponent,
    CheckmarkIconComponent,
    BookagainIconComponent,
    GibInputCurrencyComponent,
    GibInputPercentageComponent,
    PercentageDirective,
  ],
  imports: [UtilsModule, NavigationModule, NgxMaterialTimepickerModule, CalendarModule, CurrencyMaskModule],
  exports: [
    NavigationModule,
    GibInputComponent,
    GibExpansionPanelComponent,
    GibSelectComponent,
    GibSuggestSelectComponent,
    GibTextareaComponent,
    GibCheckboxComponent,
    GibFieldsetComponent,
    GibRadioButtonGroupComponent,
    GibFileuploadComponent,
    WarningIconComponent,
    SafeHtml,
    GibCheckCloseComponent,
    LoaderComponent,
    SecurePipe,
    GibImageHandlerComponent,
    GibVideoHandlerComponent,
    TopicPipe,
    TopicStringPipe,
    GibMultiselectComponent,
    GibDragAndDropBoxComponent,
    GibDatepickerComponent,
    GibTimepickerComponent,
    GibChatComponent,
    CustomerFormComponent,
    GibH5Component,
    GibModuleSelectionComponent,
    GibGridInputComponent,
    GibGridTrainerSelectComponent,
    GibCalendarComponent,
    GibRadioButtonGroupVerticalComponent,
    GibIconButtonComponent,
    GibGridHospitantSelectComponent,
    GibGdprCheckboxComponent,
    GibRadioButtonGroupOrangeComponent,
    GibMultiInputComponent,
    GibModuleReplaceComponent,
    GibDocumentsComponent,
    GibSlideshowComponent,
    GibModuleSelectionItemComponent,
    GibRadioButtonGroupVerticalV2Component,
    SmileyIconComponent,
    CheckmarkIconComponent,
    BookagainIconComponent,
    GibInputCurrencyComponent,
    GibInputPercentageComponent,
  ],
})
export class ComponentsModule {}
