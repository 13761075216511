<div [class.tk-uz-overview-page]="userRole === 'gib_tkcontrolling'">
  <h1>
    {{ (userRole === "gib_tkcontrolling" ? "tkUzOverview" : "tk-uz-overview") | translate }}
  </h1>
  <br />
  <mat-tab-group (selectedTabChange)="onTabChange($event.index)">
    <mat-tab label="{{ 'allBookings' | translate }}">
      <tk-uz-overview-table
        *ngIf="shouldShowTable(0)"
        [events]="events$ | async"
        [date]="tkUzOverviewDate"
        [type]="'allBookings'"
        (move)="onChange($event, 1)"
        (release)="onRelease($event)"
        (refresh)="onRefresh($event)"
      ></tk-uz-overview-table>
    </mat-tab>

    <mat-tab label="{{ 'pendingInvoices' | translate }}">
      <tk-uz-overview-table
        *ngIf="shouldShowTable(1)"
        [events]="paymentOpenTkEvents$ | async"
        [date]="tkUzOverviewDate"
        [type]="'pendingInvoices'"
        (move)="onChange($event, 1)"
        (release)="onRelease($event)"
        (refresh)="onRefresh($event)"
      ></tk-uz-overview-table>
    </mat-tab>

    <mat-tab label="{{ (userRole === 'gib_tkcontrolling' ? 'approvedInvoices' : 'paidInvoices') | translate }}">
      <tk-uz-overview-table
        *ngIf="shouldShowTable(2)"
        [events]="paymentApprovedTkEvents$ | async"
        [date]="tkUzOverviewDate"
        [type]="'approvedInvoices'"
        (move)="onChange($event, 1)"
        (release)="onRelease($event)"
        (refresh)="onRefresh($event)"
      ></tk-uz-overview-table>
    </mat-tab>

    <mat-tab disabled style="opacity: 1">
      <ng-template mat-tab-label style="opacity: 1">
        <button class="btn-primary btn" (click)="openDownloadExcelConfirmation()">{{ "downloadExcel" | translate }}</button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
