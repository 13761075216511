import { GibDialogService } from "./../../../../components/dialogs/gib-dialog.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CalendarService } from "../../../../services/calendar.service";
import * as moment from "moment";
import { FormHelper } from "../../../../helper/form.helper";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "trainer-availability-overview-grid",
  templateUrl: "./trainer-availability-overview-grid.component.html",
  styleUrls: ["./trainer-availability-overview-grid.component.scss"],
})
export class TrainerAvailabilityOverviewGridComponent implements OnInit {
  dateFrom: UntypedFormControl = new UntypedFormControl(moment());
  dateTo: UntypedFormControl = new UntypedFormControl(moment().add(13, "day"));
  headerColumns: any[] = [];
  monthWrapperWidth = "0px";
  trainerDayWrapperWidth = "0px";
  entries: any[] = [];
  downloadUrl;
  filename;
  lastSort = "";

  constructor(private calendarService: CalendarService, private formHelper: FormHelper, private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit() {
    this.headerColumns = this.createHeaderColumns();
    this.getAvailabilites();
    this.dateFrom.valueChanges.subscribe((res) => {
      if (res && this.dateTo.value) {
        this.headerColumns = this.createHeaderColumns();
        this.getAvailabilites();
      }
    });
    this.dateTo.valueChanges.subscribe((res) => {
      if (res && this.dateFrom.value) {
        this.headerColumns = this.createHeaderColumns();
        this.getAvailabilites();
      }
    });
  }

  getAvailabilites() {
    this.calendarService.getAllCalendarEntries(this.dateFrom.value, this.dateTo.value).subscribe((res) => {
      this.entries = res.body;
      for (const entry of this.entries) {
        entry.availabilities = this.sortAndExtendEntries(entry.availabilities);
        this.extendEntriesByColorAndMargin(entry.availabilities);
      }
      this.entries.sort(this.formHelper.sortByProperty("fullname"));
    });
  }

  createHeaderColumns() {
    const headerColumns = [];
    const start = moment(moment(this.dateFrom.value).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate());
    const end = moment(moment(this.dateTo.value).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate());
    let days = 0;

    while (end > start || start.format("M") === end.format("M")) {
      const headerColumn = { month: start.format("MMMM"), days: [] };
      const startTemp = moment(start.toDate());
      const endOfMonth = moment(startTemp.toDate()).endOf("month");

      while (end >= startTemp && endOfMonth >= startTemp) {
        const isWeekend = startTemp.toDate().getDay() % 6 === 0;
        headerColumn.days.push({ day: startTemp.format("DD"), isWeekend, date: startTemp.format("YYYY-MM-DD") });

        startTemp.add(1, "day");
        days++;
      }

      headerColumns.push(headerColumn);
      start.add(1, "month");
      start.set({ date: 1 });
    }

    this.monthWrapperWidth = days * 25 + 500 + "px";
    this.trainerDayWrapperWidth = days * 25 + 450 + "px";
    return headerColumns;
  }

  sortAndExtendEntries(entries: any[]) {
    if (entries.length > 0) {
      entries = this.fillMissingEntries(entries);
    } else {
      entries = this.fillAllEntries(entries);
    }
    // cause the first month is right aligned, the elements are missaligned for days < 10, so they has to fill up
    if (this.headerColumns && this.headerColumns[0] && this.headerColumns[0].days && this.headerColumns[0].days.length < 10) {
      const diffToTen = 10 - this.headerColumns[0].days.length;

      for (let i = 0; i < diffToTen; i++) {
        const monthNumber = parseInt(moment().month(this.headerColumns[0].month).format("M"), 0) - 1;
        const day = parseInt(this.headerColumns[0].days[0].day, 0);
        const dateToAdd = moment({ month: monthNumber, date: day, hour: 12 })
          .subtract(i + 1, "day")
          .format("YYYY-MM-DD");
        const entryToAdd = { date: dateToAdd, type: "UNDETERMINED" };
        entries.splice(0, 0, entryToAdd);
      }
    }
    return entries;
  }

  fillAllEntries(entries: any[]) {
    const enrtiesToInsert = [];
    const startDateString = moment(this.dateFrom.value).format("YYYY-MM-DD");
    const endDateString = moment(this.dateTo.value).format("YYYY-MM-DD");
    const diffBetweenStartAndEnd = moment(endDateString).diff(startDateString, "days");
    if (diffBetweenStartAndEnd > 0) {
      enrtiesToInsert.push({ entry: { date: moment(this.dateFrom.value).format("YYYY-MM-DD"), type: "UNDETERMINED" }, index: 0 });
      for (let i = 1; i < diffBetweenStartAndEnd + 1; i++) {
        const newDate = moment(moment(this.dateFrom.value).toDate()).add(i, "day");
        enrtiesToInsert.push({ entry: { date: moment(newDate).format("YYYY-MM-DD"), type: "UNDETERMINED" }, index: i });
      }
    }
    for (let i = enrtiesToInsert.length - 1; i >= 0; i--) {
      entries.splice(enrtiesToInsert[i].index, 0, enrtiesToInsert[i].entry);
    }
    entries = this.sortEntriesByDate(entries);
    return entries;
  }

  fillMissingEntries(entries: any[]) {
    const enrtiesToInsert = [];
    entries = this.sortEntriesByDate(entries);
    const startDateString = moment(this.dateFrom.value).format("YYYY-MM-DD");
    const endDateString = moment(this.dateTo.value).format("YYYY-MM-DD");
    const diffStartToFirstEntry = moment(entries[0].date).diff(moment(startDateString), "days");

    if (diffStartToFirstEntry > 0) {
      for (let i = 0; i < diffStartToFirstEntry; i++) {
        const dateToAdd = moment(startDateString).add(i, "day");
        enrtiesToInsert.push({ entry: { date: moment(dateToAdd).format("YYYY-MM-DD"), type: "UNDETERMINED" }, index: i });
      }
    }

    for (let i = 0; i < entries.length - 1; i++) {
      const diff = moment(entries[i + 1].date).diff(moment(entries[i].date), "days");

      if (diff > 1) {
        for (let f = 0; f < diff - 1; f++) {
          const newDate = moment(moment(entries[i].date).toDate()).add(1 + f, "day");
          enrtiesToInsert.push({ entry: { date: moment(newDate).format("YYYY-MM-DD"), type: "UNDETERMINED" }, index: i + 1 });
        }
      }
    }

    const diffEndToLastEntry = moment(endDateString).diff(entries[entries.length - 1].date, "days");
    if (diffEndToLastEntry > 0) {
      for (let i = 0; i < diffEndToLastEntry; i++) {
        const newDate = moment(moment(entries[entries.length - 1].date).toDate()).add(1 + i, "day");
        enrtiesToInsert.push({ entry: { date: moment(newDate).format("YYYY-MM-DD"), type: "UNDETERMINED" }, index: 0 });
      }
    }

    for (let i = enrtiesToInsert.length - 1; i >= 0; i--) {
      entries.splice(enrtiesToInsert[i].index, 0, enrtiesToInsert[i].entry);
    }
    entries = this.sortEntriesByDate(entries);
    return entries;
  }

  sortEntriesByDate(entries: any[]) {
    entries.sort((a, b) => {
      const dateA = moment(a.date).toDate().getTime();
      const dateB = moment(b.date).toDate().getTime();
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
    return entries;
  }

  extendEntriesByColorAndMargin(entries: any[]) {
    for (const entry of entries) {
      if (moment(entry.date).endOf("month").format("YYYY-MM-DD") === entry.date) {
        entry.marginRight = "2px";
      } else {
        entry.marginRight = "0px";
      }
      switch (entry.type) {
        case "AVAILABLE":
          entry.color = "#92cb91";
          break;
        case "UNAVAILABLE":
          entry.color = "#ad2121";
          break;
        case "APPOINTED":
          entry.color = "#394160";
          break;
        case "UNDETERMINED":
          entry.color = "";
          break;
      }
    }
  }

  openDownloadExcelConfirmation() {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadExcelConfirmationText");
    if (this.translateService.currentLang === "en") {
      this.filename = "TrainerVerfügbarkeiten.xlsx";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "trainerAvailabilities.xlsx";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.downloadExcel(this.filename));
  }

  downloadExcel(filename: string) {
    this.calendarService.postExcel(this.entries, this.headerColumns).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  sortByName(direction: string) {
    this.entries.sort(this.formHelper.sortByProperty("fullname"));
    if (this.lastSort === "name") {
      this.entries.reverse();
      this.lastSort = "";
      return;
    }
    this.lastSort = "name";
  }

  sortByDate(direction: string) {
    this.entries.sort(this.formHelper.sortByDateProperty("lastUpdated"));
    if (this.lastSort === "date") {
      this.entries.reverse();
      this.lastSort = "";
      return;
    }
    this.lastSort = "date";
  }
}
