import { FileService } from "src/app/services/file.service";
import { FormHelper } from "src/app/helper/form.helper";
import { Faq } from "src/app/services/faq.service";
import { FaqService } from "../../../services/faq.service";
import { UntypedFormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "trainer-faq-page",
  templateUrl: "./trainer-faq-page.component.html",
  styleUrls: ["./trainer-faq-page.component.scss"],
})
export class TrainerFaqPageComponent implements OnInit {
  userRole: string;
  faqForms: UntypedFormGroup[] = [];
  selectedFaq: Faq;
  faqs: any[] = [];
  imageUrls: string[] = [];

  constructor(private faqService: FaqService, private formHelper: FormHelper, private fileService: FileService) {}

  ngOnInit() {
    this.loadFaqs();
    this.userRole = localStorage.getItem("role");
  }

  loadFaqs() {
    this.faqService.findMy().subscribe((res) => {
      this.faqs = [];
      this.faqForms = [];
      res.body.sort(this.formHelper.sortByProperty("sortOrder"));
      for (const faq of res.body) {
        this.faqs.push(faq);
        const faqForm = this.faqService.mapFaqToForm(faq);
        this.formHelper.disableControls(faqForm);
        this.faqForms.push(faqForm);
      }
    });
  }
}
