import { FeedbackService, EventFeedbackTrainer } from './../../../services/feedback.service';
import { FormHelper } from '../../../helper/form.helper';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TaskBottomSheetComponent } from '../../events/components/task-bottom-sheet/task-bottom-sheet.component';
import { EventService } from '../../../services/event.service';
import { ProcessService, TaskOutcome } from '../../../services/process-service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GibDialogService } from 'src/app/components/dialogs/gib-dialog.service';

@Component({
  selector: 'my-tasks-page',
  templateUrl: './my-tasks-page.component.html',
  styleUrls: ['./my-tasks-page.component.scss']
})
export class MyTasksPageComponent implements OnInit {

  tasks: any[];
  selectedTask: any;
  eventForm: UntypedFormGroup;
  event: Event;

  constructor(private processService: ProcessService, private eventService: EventService, private bottomSheet: MatBottomSheet,
    private formHelper: FormHelper, private feedbackService: FeedbackService, private cdr: ChangeDetectorRef,
    private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit() {
    this.getActiveTasks();
  }

  getActiveTasks() {
    this.processService.getAllMyTasks().subscribe(res => {
      this.tasks = res.body;
    });
  }

  openFormForTask(task: any) {
    this.selectedTask = task;
    if (task.task.processInstanceId) {
      this.eventService.findByProcessInstanceId(task.task.processInstanceId).subscribe(res => {
        this.event = res.body;
        if (task.task.taskDefinitionKey === 'ut_trainer_call_customer') {
          this.eventForm = this.eventService.mapEventToForm(res.body);
          this.formHelper.disableControls(this.eventForm);
          const sheetData = { eventForm: this.eventForm, task, taskCompleteFn: () => this.getActiveTasks() };
          this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
        } else if (task.task.taskDefinitionKey === 'ut_trainer_feedback') {
          this.eventForm = this.eventService.mapEventToForm(res.body);
          const userId = localStorage.getItem('userId');
          this.eventService.findEihsByBuidAndTrainerId(task.buid, userId).subscribe(response => {
            const eihs = response.body;
            const feedbackForm = this.feedbackService.mapTrainerFeedbackToForm(new EventFeedbackTrainer());
            feedbackForm.get('overallRating').setValidators(Validators.required);
            feedbackForm.get('orgaGib').setValidators(Validators.required);
            feedbackForm.get('orgaLocation').setValidators(Validators.required);
            feedbackForm.get('orgaCustomer').setValidators(Validators.required);
            feedbackForm.get('measuringDevice').setValidators(Validators.required);
            feedbackForm.setValidators(this.feedbackService.isMeasuringDeviceBooleanNoAndNoComment);
            const sheetData = { eventForm: this.eventForm, feedbackForm, task, eihs, taskCompleteFn: () => this.getActiveTasks() };
            this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
          });
        }
      });
    } else {
      const title = task.task.name;
      const text = task.task.description;
      this.dialogService.openConfirmationDialog(title, text, () => (this.completeTask('APPROVED', task.task.id, null)));

    }
  }

  completeTask(outcome: TaskOutcome, taskId: string, query: string,
    params ? : Map < string, string > ) {
    this.processService.completeTask(taskId, outcome, query, params).subscribe(res => {
      this.getActiveTasks();
      this.cdr.detectChanges();
    });
  }


}