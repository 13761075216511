<div class="row">
  <div class="center">
    <h2>{{ "privacyPolicyHeader" | translate }}</h2>
  </div>

  <div class="col-sm-12">
    <gib-h5 [text]="'privacy1'"></gib-h5>
    <p>
      Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
      sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation
      per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
  </div>

  <div class="col-sm-12">
    <gib-h5 [text]="'privacy2'"></gib-h5>
    <p>
      <b>Externes Hosting</b>
      <br />
      Diese Website wird von der GiB GmbH auf einem dedizierten Server betrieben, der sich in einem Rechenzentrum der Firma Hetzner in Deutschland befindet und nach DIN IEC 27001 zertifiziert ist.
      <br />
      Die auf dieser Website erfassten personenbezogenen Daten, wie IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige generierte Daten, werden auf den Servern des Hosters gespeichert. Der Einsatz des Hosters erfolgt zur Vertragserfüllung
      gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie es zur
      Erfüllung seiner Leistungspflichten erforderlich ist und wird dabei unsere Weisungen in Bezug auf diese Daten befolgen.
    </p>
  </div>

  <div class="col-sm-12">
    <gib-h5 [text]="'privacy3'"></gib-h5>
    <p>
      <b>Benennung der verantwortlichen Stelle</b>
      <br />
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
      <br />

      <br />
      GiB – Gesundheit in Bewegung GmbH
      <br />
      Pascal Weber | Sarah Akherati
      <br />
      Hinschenfelder Strasse 34a
      <br />
      22041 Hamburg
      <br />

      <br />
      Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
      <br />

      <br />
      <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
      <br />
      Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
      Widerruf unberührt.
      <br />

      <br />
      <b>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</b>
      <br />
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet.
      Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
      <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"> https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html </a>
      <br />

      <br />
      <b>Recht auf Datenübertragbarkeit</b>
      <br />
      Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen
      Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
      <br />

      <br />
      <b>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</b>
      <br />
      Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Emp-fänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
      Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
      <br />

      <br />
      <b>SSL- bzw. TLS-Verschlüsselung</b>
      <br />
      Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbin-dung an der
      https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
    </p>
  </div>

  <div class="col-sm-12">
    <gib-h5 [text]="'privacy4'"></gib-h5>
    <p>
      <b>Server-Log-Dateien</b>
      <br />
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:
      <br />

      <br />
      • Besuchte Seite auf unserer Domain
      <br />
      • Datum und Uhrzeit der Serveranfrage
      <br />
      • Browsertyp und Browserversion
      <br />
      • Verwendetes Betriebssystem
      <br />
      • Referrer URL
      <br />
      • Hostname des zugreifenden Rechners
      <br />
      • IP-Adresse
      <br />

      <br />
      Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet
      <br />

      <br />
      <b>Registrierung auf dieser Website</b>
      <br />
      Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben sind vollständig anzugeben. Andernfalls werden wir die Registrierung
      ablehnen. Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse versendet, die bei der Registrierung angegeben wurde.
      <br />
      <br />
      Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
      <br />
      <br />
      Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      <br />

      <br />
      <b>Anfrage per E-Mail oder Telefon</b>
      <br />
      Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser
      Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an
      uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
      <br />
      <br />
      Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspei-cherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
      insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
      <br />
    </p>
  </div>

  <div class="col-sm-12">
    <gib-h5 [text]="'privacy5'"></gib-h5>
    <p>
      Bei unserer Webseite/Applikation legen wir großen Wert auf den Schutz Ihrer persönlichen Daten. Im Folgenden möchten wir Sie darüber informieren, wie wir Ihre Daten verarbeiten und welche Maßnahmen wir ergreifen, um Ihre Privatsphäre zu schützen.
      <br />
      <br />
      <b>Keine Nutzung von Tracking-Technologien</b>
      <br />
      Wir möchten klarstellen, dass unsere Anwendung keinerlei Tracking-Technologien verwendet. Es werden keine Benutzeraktivitäten überwacht oder verfolgt. Dies bedeutet, dass wir keine Tracking-Cookies oder ähnliche Technologien einsetzen, um Ihr Verhalten auf unserer Website zu analysieren.
      <br />
      <br />
      <b>Verwendung von JSON Web Tokens (JWT)</b>
      <br />
      Zur Authentifizierung und Verwaltung von Benutzersitzungen verwenden wir JSON Web Tokens (JWT). Ein JWT ist ein kompaktes, URL-sicheres Token, das Informationen über den Benutzer in einem verschlüsselten Format enthält. Diese Tokens werden verwendet, um den Benutzer während seiner Sitzung zu identifizieren und zu
      authentifizieren.
      <br />
      <br />
      <b>Nutzung von Keycloak und technischen Cookies</b>
      <br />
      Unsere Anwendung basiert auf Keycloak für die Authentifizierung und Verwaltung von Benutzersitzungen. Keycloak setzt bestimmte Cookies, wie z.B. AUTH_SESSION_ID und KEYCLOAK_IDENTITY, die technisch notwendig sind, um ein JWT zu generieren und zu verwalten. Diese Cookies enthalten keine Tracking-Informationen und
      dienen ausschließlich der sicheren Authentifizierung und Verwaltung Ihrer Sitzung.
      <br />
      <br />
      <b>Technische Notwendigkeit der Cookies</b>
      <br />
      Diese Cookies sind essenziell für die Funktionalität unserer Anwendung und ermöglichen es uns, Ihnen eine sichere und nahtlose Benutzererfahrung zu bieten.
      <br />
      <br />
      <b>Ihre Sicherheit und Privatsphäre</b>
      <br />
      Wir setzen moderne Sicherheitsmaßnahmen ein, um die Integrität und Vertraulichkeit Ihrer Daten zu gewährleisten. Ihre Daten werden sicher übertragen und gespeichert, und wir ergreifen alle notwendigen Schritte, um unbefugten Zugriff zu verhindern.
      <br />
      <br />
      Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränk-te Funktionalität unserer Website
      zur Folge haben.
      <br />
      <br />
      Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Be-reitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der
      Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
      <br />
      <br />
      Falls Sie Fragen oder Bedenken zu unserer Datenschutzpraxis haben, können Sie sich jederzeit an uns wenden. Wir stehen Ihnen gerne zur Verfügung, um Ihre Anliegen zu klären.
      <br />
    </p>
  </div>

  <div class="col-sm-12 footer center">
    <img src="assets/images/logo.svg" style="width: 200px" class="custom-logo" alt="Gesundheit in Bewegung" />
    <br />
    <br />
    <p style="text-align: center">&copy; 2020 GiB – Gesundheit in Bewegung GmbH, Hinschenfelder Strasse 34a, 22041 Hamburg</p>
  </div>
</div>
