import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ScheduleService } from "./../../../services/schedule-vote.service";
import { FormHelper } from "./../../../helper/form.helper";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { HealthInsuranceService, PrivacyStatementDto } from "src/app/services/health-insurance.service";
import { EnvService } from "src/app/services/env.service";
import { WaitingListService } from "src/app/services/waiting-list.service";
import { CustomValidators } from "src/app/utils/custom-validator";
import { Subscription } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "schedule-voting-page",
  templateUrl: "./schedule-voting-page.component.html",
  styleUrls: ["./schedule-voting-page.component.scss"],
})
export class ScheduleVotingPageComponent implements OnInit {
  votingParticipants: UntypedFormGroup[] = [];

  uuid: string;
  userId: string;

  moduleName: string;
  moduleNameEn: string;
  eventDate: string;

  filename: string;
  downloadUrl: string;

  onlineModule: boolean;
  showWaitingList: boolean;

  companynameAlias: string;
  participantRegistrationTime: string = "0";
  lang: string;
  langSubscription: Subscription;

  scheduleEntryCompanynameRequired: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formHelper: FormHelper,
    private scheduleService: ScheduleService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private configService: ConfigurationService,
    private dialogService: GibDialogService,
    private healthInsuranceService: HealthInsuranceService,
    private envService: EnvService,
    private waitingListService: WaitingListService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.loadScheduleData();
    this.configService.getParticipantRegistrationTime().subscribe((res) => {
      this.participantRegistrationTime = res.body.value;
    });

    this.translateService
      .get(`languages.${this.translateService.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();
    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.lang = event.lang;
      this.translateService
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });
  }

  onLangClick() {
    if (this.translateService.currentLang === "de") {
      this.translateService.use("en");
      localStorage.setItem("gib-locale", "en");
    } else {
      this.translateService.use("de");
      localStorage.setItem("gib-locale", "de");
    }
  }

  loadScheduleData() {
    this.route.queryParams.subscribe((values) => {
      this.uuid = values["uuid"];
      this.userId = values["userId"];
      if (this.uuid) {
        this.scheduleService.fetchAllScheduleEntriesByUuid(this.uuid, this.userId).subscribe((res) => {
          this.setModuleName(res.body);
          this.setModuleDate(res.body);
          this.setOnlineModule(res.body);
          this.setCompanynameAlias(res.body);
          this.setShowWaitingList(res.body);
          for (const participant of res.body) {
            this.votingParticipants.push(this.formBuilder.group(participant));
            this.scheduleEntryCompanynameRequired = participant.scheduleEntryCompanynameRequired;
          }
          this.setRequired();
          for (const participant of this.votingParticipants) {
            if (participant.get("scheduletype").value === "CLOSED") {
              this.formHelper.disableControls(participant);
            } else {
              this.formHelper.disableControlsByName(participant, ["timeFrom", "timeTo"]);
            }
          }
        });
      }
    });
  }

  setRequired() {
    for (const votingparticipant of this.votingParticipants) {
      votingparticipant.get("firstname").setValidators([Validators.required]);
      votingparticipant.get("lastname").setValidators([Validators.required]);
      votingparticipant.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
      if (votingparticipant.get("scheduleEntryCompanynameRequired").value) {
        votingparticipant.get("companyname").setValidators([Validators.required]);
        votingparticipant.get("zip").setValidators([Validators.required]);
      } else {
        votingparticipant.get("companyname").clearValidators();
        votingparticipant.get("zip").clearValidators();
      }
    }
  }

  setModuleName(scheduleEntries) {
    if (scheduleEntries.length > 0) {
      this.moduleName = scheduleEntries[0].modulename;
      this.moduleNameEn = scheduleEntries[0].modulenameEn;
    }
  }

  setOnlineModule(scheduleEntries) {
    if (scheduleEntries.length > 0 && scheduleEntries[0].onlineModule && scheduleEntries[0].moduleType === "HEALTH_MODULE") {
      this.onlineModule = scheduleEntries[0].onlineModule;
    } else {
      this.onlineModule = false;
    }
  }

  setShowWaitingList(scheduleEntries) {
    if (scheduleEntries.length > 0 && scheduleEntries[0].showWaitingList) {
      this.showWaitingList = scheduleEntries[0].showWaitingList;
    } else {
      this.showWaitingList = false;
    }
  }

  setModuleDate(scheduleEntries) {
    if (scheduleEntries.length > 0) {
      this.eventDate = scheduleEntries[0].eventDate;
    }
  }

  setCompanynameAlias(scheduleEntries) {
    if (scheduleEntries.length > 0) {
      this.companynameAlias = scheduleEntries[0].companynameAlias;
    }
  }

  getModuleName() {
    if (this.translateService.currentLang === "en" && this.moduleNameEn) {
      return this.moduleNameEn;
    }
    return this.moduleName;
  }

  downloadPdf() {
    if (this.translateService.currentLang === "en") {
      this.filename = "participantsList.pdf";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "TeilnehmerListe.pdf";
    }
    this.scheduleService.downloadPdf(this.uuid).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = this.filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  waitingListClicked() {
    const votingParticipant = new UntypedFormGroup({
      firstname: new UntypedFormControl("", [Validators.required]),
      lastname: new UntypedFormControl("", [Validators.required]),
      companyname: new UntypedFormControl("", this.scheduleEntryCompanynameRequired ? Validators.required : []),
      phonenumber: new UntypedFormControl(),
      email: new UntypedFormControl("", [Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]),
      acceptedEventPrivacy: new UntypedFormControl(),
      acceptedThirdPartyPrivacy: new UntypedFormControl(),
      uuid: new UntypedFormControl(this.uuid),
      scheduleEntryCompanynameRequired: new UntypedFormControl(this.scheduleEntryCompanynameRequired),
    });
    const lang = localStorage.getItem("gib-locale");
    const title = this.translateService.instant("WaitListPopupTitle");
    const text = this.translateService.instant("WaitListPopupText");
    this.healthInsuranceService.get3rdPartyPrivacyStatement(this.companynameAlias).subscribe((res) => {
      let privacyStatement: PrivacyStatementDto = res.body;
      let thirdPartyPrivacyText = "";
      let eventPrivacyText = "";
      let link = "";
      if (privacyStatement) {
        if (lang === "en") {
          link = this.envService.backendUrl + "/public/files/privacyPolicy/" + lang + "/" + privacyStatement.enFilename;
          thirdPartyPrivacyText = privacyStatement.en3rd;
          eventPrivacyText = privacyStatement.en + this.translateService.instant("ScheduleVoteDataPrivacyEventPrivacyText", { privacyPolicyUrl: link, title: privacyStatement.enFiletitle });
        } else {
          thirdPartyPrivacyText = privacyStatement.de3rd;
          link = this.envService.backendUrl + "/public/files/privacyPolicy/" + lang + "/" + privacyStatement.deFilename;
          eventPrivacyText = privacyStatement.de + this.translateService.instant("ScheduleVoteDataPrivacyEventPrivacyText", { privacyPolicyUrl: link, title: privacyStatement.deFiletitle });
        }
      }

      this.dialogService.openWaitingListDialog(title, text, eventPrivacyText, thirdPartyPrivacyText, votingParticipant, (updValue: UntypedFormGroup) => {
        if (updValue) {
          const waitingListEntry = updValue.getRawValue();
          this.waitingListService.createWaitingListEntries(waitingListEntry).subscribe((res) => {
            this.formHelper.disableControls(updValue);
            const title = this.translateService.instant("WaitListPopupSuccessTitle");
            const text = this.translateService.instant("WaitListPopupSuccessText");
            this.dialogService.openDialog(title, text, "success");
          });
        }
      });
    });
  }

  eventInThePast() {
    if (this.eventDate == null) {
      return false;
    }
    const eventDate = moment(this.eventDate);
    const today = moment(new Date());
    const dif = eventDate.diff(today, "days");
    const eventInThePast = dif < 0;
    return eventInThePast;
  }
}
