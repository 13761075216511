<form tabindex="0" >
  <mat-label *ngIf="options && options.length === 0">{{'noHospitantAvailable' | translate}}</mat-label>
  <mat-form-field *ngIf="options && options.length > 0">
    <mat-select #select [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
      <mat-option [value]="null">
        {{ '(' + ('deselectTrainer' | translate) + ')' }}
      </mat-option>
      <mat-option *ngFor="let option of options | availability:'AVAILABLE'" [value]="option.value" style="color: var(--success)">
        {{ option.label }}
      </mat-option>
      <mat-option *ngFor="let option of options | availability:'AVAILABLE_NOT_FITTING'" [value]="option.value" style="color: var(--warn)">
        {{ option.label }}
      </mat-option>
      <mat-option *ngFor="let option of options | availability:'UNDETERMINED'" [value]="option.value">
        {{ option.label }}
      </mat-option>
      <mat-option *ngFor="let option of options | availability:'UNAVAILABLE'" [value]="option.value" style="color: var(--error)">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
