<div class="form-group">
  <mat-tab-group *ngIf="eventForm && meetingForm" [selectedIndex]="selectedIndex">
    <mat-tab label="{{ 'details' | translate }}"><br>
      <event-logistics-form [userRole]="userRole" *ngIf="eventForm && meetingForm" [meetingForm]="meetingForm" [logisticsForm]="eventForm" [eventInquiryHealthscreeningIndex]="eihsIndex"></event-logistics-form>
    </mat-tab>
    <div *ngFor="let eventInquiryHealthscreening of eventForm.get('eventHealthScreenings')['controls']">
      <mat-tab *ngIf="showIfAssignedTrainerIsCurrentUser(eventInquiryHealthscreening)" label="{{eventInquiryHealthscreening.get('name').value ? eventInquiryHealthscreening.get('name').value : 'name not set...'}}" ><br>
        <div class="w-100 justify-content-end d-flex">
          <button *ngIf="userRole === 'gib_afg'" type="button" class="btn btn-primary d-flex align-items-center" (click)="createLinkFor3rdParty(eventInquiryHealthscreening)">
            <i class="copyButton" class="material-icons" placement="left">content_copy</i>
            {{ "copyLink" | translate }}</button>
        </div>
        <gib-fieldset *ngIf="eventInquiryHealthscreening.get('scheduleEntries').value && eventInquiryHealthscreening.get('scheduleEntries').value.length > 0" [title]="'participantList'" [disabled]="eventInquiryHealthscreening.get('breakTime1From').disabled">
          <event-participant *ngFor="let participant of eventInquiryHealthscreening.get('scheduleEntries').controls" [eventParticipant]="participant"></event-participant>
        </gib-fieldset>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>
