import {
  Component, Inject
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/components/dialogs/gib-dialog/gib-dialog.component';

@Component({
  selector: 'locking-dialog',
  templateUrl: 'locking-dialog.html',
})
export class LockingDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LockingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onOverride() {
    this.dialogRef.close();
  }

}
