<svg xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  id="svg-icon"
  class="svg-icon"
  viewBox="0 0 45 45"
>
  <path
  [class.green]="color === 'green'"
  [class.navy]="color === 'navy'"
  [class.blue]="color === 'blue'"
  [class.grey]="color === 'grey'"
  d="M10.59,26.86c0-10.06,8.16-18.22,18.22-18.22.59,0,1.18.03,1.75.08C27.86,3.54,22.44,0,16.19,0,7.25,0,0,7.25,0,16.19c0,7.2,4.7,13.31,11.21,15.41-.41-1.51-.62-3.1-.62-4.74h0Z" />
  <path
  [class.green]="color === 'green'"
  [class.navy]="color === 'navy'"
  [class.blue]="color === 'blue'"
  [class.grey]="color === 'grey'"
  d="M28.81,10.67c-8.94,0-16.19,7.25-16.19,16.19s7.25,16.19,16.19,16.19,16.19-7.25,16.19-16.19-7.25-16.19-16.19-16.19h0ZM36.21,28.95h-5.31v5.31c0,1.15-.94,2.09-2.09,2.09s-2.09-.94-2.09-2.09v-5.31h-5.31c-1.15,0-2.09-.94-2.09-2.09s.94-2.09,2.09-2.09h5.31v-5.31c0-1.15.94-2.09,2.09-2.09s2.09.94,2.09,2.09v5.31h5.31c1.15,0,2.09.94,2.09,2.09s-.94,2.09-2.09,2.09Z"
/>
</svg>
