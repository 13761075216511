import { Component, HostBinding, Input } from '@angular/core';
import { Employee } from '../../../../../../services/employee.service';

@Component({
  selector: 'trainer-table-details',
  templateUrl: './trainer-table-details.component.html',
  styleUrls: ['./trainer-table-details.component.scss']
})
export class TrainerTableDetailsComponent {

  @HostBinding('style.padding') padding = "10px";
  @HostBinding('style.padding-left') paddingLeft = "20px";
  @HostBinding('style.width') width = "100%";

  @Input() employee: Employee;

  constructor() {}

}
