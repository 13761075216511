<ul>
  <li>
    <a (click)="toggleTreeElement()">
      <i [@rotatedState]='state' class="material-icons">chevron_right</i>
      <span>{{treeContent.rootLabel | translate | uppercase}}</span>
    </a>
    <ul [@openClose]="treeElementOpened ? true : false" class="open-close-container">
      <li *ngFor="let link of treeContent.links">
        <a routerLink="{{link.route}}">
          <div  [@openClose]="treeElementOpened ? true : false" class="message-marker" *ngIf="link.message && link.message != 0">{{link.message}}</div>
          <span>{{link.label | translate}}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
