import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { FileService } from "../../../services/file.service";
import { HealthScreeningService, HealthScreening, HealthScreeningOverview } from "../../../services/health-screening.service";
import { FormHelper } from "../../../helper/form.helper";
import { HttpConfig } from "src/app/utils/authentication/auth-interceptor";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "health-screening-overview-page",
  templateUrl: "./health-screening-overview-page.component.html",
  styleUrls: ["./health-screening-overview-page.component.scss"],
})
export class HealthScreeningOverviewPageComponent implements OnInit {
  topics: any[] = [];
  sort;
  healthscreenings: any[] = [];
  online: boolean = false;
  companynameAlias: string;

  filterData: UntypedFormGroup = this.initFormGroup();

  constructor(
    private healthscreeningService: HealthScreeningService,
    private fileService: FileService,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private router: Router,
    private formHelper: FormHelper,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.healthscreeningService.getTopics().subscribe((res) => {
      this.topics = res.body;
      setTimeout(() => {
        window.scrollTo({ top: this.formHelper.getScrollPosition("HealthScreeningOverviewPageComponent"), behavior: "smooth" });
      }, 200);
    });

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("companynameAlias")) {
        this.companynameAlias = paramMap.get("companynameAlias");
      }
    });
    this.route.params.subscribe((routeParams) => {
      this.loadHealthScreenings();
    });

    this.filterData.valueChanges.subscribe(() => {
      this.applyAdvancedFilter();
    });
  }

  initFormGroup(): UntypedFormGroup {
    const fg = this.formBuilder.group({
      online: [true, []],
      onsite: [true, []],
      webinars: [true, []],
      activeBreaks: [true, []],
    });
    return fg;
  }

  applyAdvancedFilter() {
    const online = this.filterData.get("online").value;
    const onsite = this.filterData.get("onsite").value;
    const webinars = this.filterData.get("webinars").value;
    const activeBreaks = this.filterData.get("activeBreaks").value;
    if (!online && !onsite && !webinars && !activeBreaks) {
      this.healthscreenings = [];
    } else {
      if (online && onsite && webinars && activeBreaks) {
        this.loadHealthScreenings();
      } else {
        this.setFilter(online, onsite, webinars, activeBreaks);
      }
    }
    this.cdr.detectChanges();
  }

  loadHealthScreenings() {
    const online = this.filterData.get("online").value;
    const onsite = this.filterData.get("onsite").value;
    const webinars = this.filterData.get("webinars").value;
    const activeBreaks = this.filterData.get("activeBreaks").value;
    this.setFilter(online, onsite, webinars, activeBreaks);
  }

  setFilter(online: boolean, onsite: boolean, webinar: boolean, activeBreaks: boolean) {
    if (this.companynameAlias === "all") {
      this.healthscreeningService.findAllOverview().subscribe((res) => {
        this.applyFilter(res, online, onsite, webinar, activeBreaks);
      });
    } else {
      this.healthscreeningService.getHealthScreeningOverviewForCompanynameAlias(this.companynameAlias).subscribe((res) => {
        this.applyFilter(res, online, onsite, webinar, activeBreaks);
      });
    }
  }

  private applyFilter(res: HttpConfig, online: boolean, onsite: boolean, webinar: boolean, activeBreaks: boolean) {
    this.healthscreenings = [];
    const healthScreenings: HealthScreeningOverview[] = res.body.sort(this.formHelper.sortByModuleName);
    for (const healthscreening of healthScreenings) {
      if (online && onsite) {
        if (healthscreening.moduleType === "HEALTH_MODULE") {
          this.processHealthScreeningsByTopic(healthscreening);
        }
      } else {
        if (online) {
          if (healthscreening.moduleType === "HEALTH_MODULE" && healthscreening.online) {
            this.processHealthScreeningsByTopic(healthscreening);
          }
        } else if (onsite) {
          if (healthscreening.moduleType === "HEALTH_MODULE" && healthscreening.onsite) {
            this.processHealthScreeningsByTopic(healthscreening);
          }
        }
      }

      if (webinar) {
        if (healthscreening.moduleType === "WEBINAR") {
          this.processHealthScreeningsByTopic(healthscreening);
        }
      }
      if (activeBreaks) {
        if (healthscreening.moduleType === "ACTIVE_BREAK") {
          this.processHealthScreeningsByTopic(healthscreening);
        }
      }
    }

    const uniqueIds = [...new Set(this.healthscreenings.map((hs) => hs.id))];
    for (const id of uniqueIds) {
      this.fileService.getFileMetaByHealthscreening(id, "HEALTHSCREENING_PICTURE").subscribe((filemetas) => {
        const imageUrls = this.fileService.createRessourceUrls(filemetas);
        const indexes = this.findHealthScreeningIndex(this.healthscreenings, id);
        indexes.forEach((index) => (this.healthscreenings[index].imageUrls = imageUrls));
      });
    }
  }

  private processHealthScreeningsByTopic(healthScreening: HealthScreeningOverview) {
    const topics: string[] = healthScreening.topic.split(",");
    topics.forEach((topic) => {
      const hs: HealthScreeningOverview = {
        ...healthScreening,
        topic: topic.trim(),
      };
      this.healthscreenings.push(hs);
    });
  }

  findHealthScreeningIndex(hsArray: any[], id: any): number[] {
    const indexes: number[] = [];
    for (let i = 0; i < hsArray.length; i++) {
      if (hsArray[i].id === id) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  openDeleteConfirmation(hs: any) {
    const title = this.translateService.instant("HEALTHSCREENING_DELETE_CONFIRMATION_TITLE");
    const text = this.translateService.instant("HEALTHSCREENING_DELETE_CONFIRMATION_TEXT", { name: hs.moduleName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteHealthScreening(hs));
  }

  deleteHealthScreening(hs: any) {
    this.healthscreeningService.hasActiveEvents(hs.id).subscribe((res) => {
      if (res && res.body === false) {
        this.healthscreeningService.delete(hs.id).subscribe(() => {
          this.loadHealthScreenings();
        });
      } else {
        this.openhasActiveEventsError(hs);
      }
    });
  }

  openhasActiveEventsError(hs: any) {
    const title = this.translateService.instant("HEALTHSCREENING_DELETE_CONFIRMATION_TITLE");
    const error = this.translateService.instant("ERROR_HAS_ACTIVE_EVENTS");
    this.dialogService.openErrorDialog(title, [error]);
  }

  copyHealthScreening(id: number) {
    this.healthscreeningService.findOne(id).subscribe((res) => {
      let hs: HealthScreening = res.body;
      hs.id = null;
      hs.moduleName = "Kopie der " + hs.moduleName;
      hs.moduleNameEn = "Copy of " + hs.moduleNameEn;

      this.healthscreeningService.create(hs).subscribe((res) => {
        const newId = res.body.id;
        this.healthscreeningService.copyFiles(id, newId).subscribe((res) => {});
        this.loadHealthScreenings();
      });
    });
  }

  goToEditPage(id: number) {
    this.formHelper.saveScrollPosition("HealthScreeningOverviewPageComponent", window.pageYOffset);
    this.router.navigate(["/edit-health-screening-page", id]);
  }
}
