import { NgModule } from "@angular/core";

import { SidenavComponent } from "./sidenav/sidenav.component";
import { UtilsModule } from "../../utils/utils.module";
import { SidenavExtensionComponent } from "./sidenav-extension/sidenav-extension.component";
import { SidenavTreeComponent } from "./sidenav-tree/sidenav-tree.component";
import { RouterModule } from "@angular/router";
import { ToolbarComponent } from "./toolbar/toolbar.component";

@NgModule({
  declarations: [SidenavComponent, SidenavExtensionComponent, SidenavTreeComponent, ToolbarComponent],
  imports: [RouterModule, UtilsModule],
  exports: [SidenavComponent, SidenavExtensionComponent, SidenavTreeComponent, ToolbarComponent],
})
export class NavigationModule {}
