<div class="editRow">
  <button *ngIf="editMode" mat-button (click)="cancel()">{{ "CANCEL" | translate }}</button>
  <button *ngIf="editMode" class="btn btn-primary" (click)="save()">{{ "save" | translate }}</button>
  <button *ngIf="showSendScheduleButton && !editMode && (this.userRole === 'gib_director' || this.userRole === 'gib_admin' || this.userRole === 'gib_employee')" type="button" class="btn btn-primary" (click)="sendSchedulesClicked()">{{ "sendSchedule" | translate }}</button>
  <i id="edit_icon" *ngIf="isEditable && !editMode" placement="left" [ngbTooltip]="'EDIT' | translate" class="material-icons edit-icon" (click)="startEditMode()">edit</i>
  <i id="copy_icon" *ngIf="!editMode && (this.userRole === 'gib_director' || this.userRole === 'gib_admin' || this.userRole === 'gib_employee')" placement="left" [ngbTooltip]="'bookEventOneMoreTime' | translate" class="material-icons edit-icon" (click)="bookAgain()">library_books</i>
  <!-- <i id="close_icon" *ngIf="!editMode && (eventForm || trainerForEventForm)" placement="left" [ngbTooltip]="'close' | translate" class="material-icons close-icon" (click)="closeBottomSheet()">close</i> -->
  <i id="close_icon" *ngIf="!editMode && eventForm" placement="left" [ngbTooltip]="'close' | translate" class="material-icons close-icon" (click)="closeBottomSheet()">close</i>
</div>

<!-- <div class="editRow" *ngIf="this.userRole === 'gib_customer'">
  <i id="close_icon" placement="left" [ngbTooltip]="'close' | translate" class="material-icons close-icon" (click)="closeBottomSheet()">close</i>
</div> -->

<event-complete-form *ngIf="eventForm" [allowModuleReplace]="allowModuleReplace" [isEditable]="isEditable" [editMode]="editMode" [selectedIndex]="selectedIndex" [buid]="buid" [eventForm]="eventForm" [internalNoteForm]="internalNoteForm" [chatElements]="chatElements" [showPrintButtons]="showPrintButtons">
</event-complete-form>
