import { FileMeta } from "./../../services/file.service";
import { FileService } from "src/app/services/file.service";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "gib-video-handler",
  templateUrl: "./gib-video-handler.component.html",
  styleUrls: ["./gib-video-handler.component.scss"],
})
export class GibVideoHandlerComponent implements OnInit {
  _videos: FileMeta[];
  videoUrls: string[];
  userRole: string;

  @Output() fileDeleted = new EventEmitter<null>();
  @Output() videoUrlsGenerated = new EventEmitter<string[]>();

  @Input() set videos(videos: FileMeta[]) {
    this._videos = videos;
    this.createVideoUrls();
  }

  @Input() enableVideoDownload: boolean = false;

  get videos(): FileMeta[] {
    return this._videos;
  }
  constructor(private dialogService: GibDialogService, private fileService: FileService, private translateService: TranslateService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
  }

  createVideoUrls() {
    this.videoUrls = this.fileService.createRessourceUrls(this._videos);
    this.videoUrlsGenerated.emit(this.videoUrls);
  }

  openDeleteConfirmation(filemeta: FileMeta) {
    const fileName = filemeta.fileName;
    const title = this.translateService.instant("deleteFileConfirmationTitle");
    const text = this.translateService.instant("deleteFileConfirmationText", { fileName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteFile(filemeta.id));
  }

  deleteFile(id: number) {
    this.fileService.delete(id).subscribe(() => {
      this.fileDeleted.emit(null);
    });
  }

  trimFileName(fileName: string, lenght: number) {
    return fileName.substring(0, lenght) + "..." + fileName.substring(fileName.length - 7, fileName.length);
  }

  onDownload(file: FileMeta) {
    this.fileService.getFileData(file.id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = downloadUrl;
      a.download = file.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }
}
