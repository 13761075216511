import { FormHelper } from 'src/app/helper/form.helper';
import { FaqService } from './../../../../services/faq.service';
import { Faq } from 'src/app/services/faq.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HealthInsurance, HealthInsuranceService } from 'src/app/services/health-insurance.service';

@Component({
  selector: 'faq-form',
  templateUrl: './faq-form.component.html',
  styleUrls: ['./faq-form.component.scss']
})
export class FaqFormComponent implements OnInit {

  _faq: Faq;
  faqForm: UntypedFormGroup;
  companynameAliases = [];

  @Input() set faq(FAQ: Faq) {
    this._faq = FAQ;
    this.faqForm = this.initFormGroup();
  }

  get faq(): Faq {
    return this._faq;
  }

  @Output() faqAdded = new EventEmitter();
  constructor(private faqService: FaqService, private formHelper: FormHelper, private healthInsuranceService: HealthInsuranceService) { }

  ngOnInit() {
    this.loadCompanyNameAliases();
  }

  initFormGroup(): UntypedFormGroup {
    const faqForm = this.faqService.mapFaqToForm(this._faq);
    faqForm.get('title').setValidators(Validators.required);
    faqForm.get('description').setValidators(Validators.required);
    faqForm.get('sortOrder').setValidators(Validators.required);
    faqForm.get('companynameAlias').setValidators(Validators.required);
    return faqForm;
  }

  addFaqClicked() {
    this.formHelper.isFormValidElseShowErrors(this.faqForm, 'FAQ_ADDED_ERROR_TITLE', () => this.addFaq());
  }

  addFaq() {
    const faq = this.faqService.mapFormToFaq(this.faqForm);
    this.faqService.createupdateFaq(faq).subscribe(res => {
      this.faqAdded.emit(null);
    });
  }

  updateFaqClicked() {
    this.formHelper.isFormValidElseShowErrors(this.faqForm, 'FAQ_EDIT_ERROR_TITLE', () => this.updateFaq());
  }

  updateFaq() {
    const faq = this.faqService.mapFormToFaq(this.faqForm);
    this.faqService.createupdateFaq(faq).subscribe(res => {
      this.faqAdded.emit(null);
    });
  }

  cancel() {
    // do nothing, just emit with null to close the panel
    this.faqAdded.emit(null);
  }

  loadCompanyNameAliases() {
    this.healthInsuranceService.findAll().subscribe(list => {
      let tmp = [];
      list.body.forEach((element: HealthInsurance) => {
        tmp.push(element.companynameAlias)
      });
      tmp.sort();
      tmp.forEach((element: string) => {
        this.companynameAliases.push({ value: element, label: element });
      });
    });
  }
}
