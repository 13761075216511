<mat-card>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-6">{{ "fileName" | translate }}:</div>
      <div class="col-sm-6">
        {{ file.fileName }}
      </div>
      <div class="col-sm-6">{{ "fileType" | translate }}:</div>
      <div class="col-sm-6">
        {{ "FILE_TYPE_" + file.fileType | translate }}
      </div>
      <div class="col-sm-6">{{ "created" | translate }}:</div>
      <div class="col-sm-6">
        {{ file.created | amDateFormat: "DD.MM.YYYY HH:mm" }}
      </div>
      <div class="col-sm-6">{{ "user" | translate }}:</div>
      <div class="col-sm-6">
        {{ file.keycloak_fullname }}
      </div>
      <div class="col-sm-12 donwload-div">
        <button class="btn btn-primary" (click)="downloadFile()">
          <i class="material-icons">get_app</i>
          {{ "downloadFile" | translate }}
        </button>
      </div>
      <div *ngIf="showImage" class="col-sm-12 image-div">
        <img *ngIf="fileData" [src]="fileData | safeHtml" />
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <div [@visibility]="showButtons ? 'visible' : 'hidden'" class="button-div">
      <button class="btn btn-success" (click)="openApproveConfirmation()">
        <i class="material-icons">check</i>
        {{ "approve" | translate }}
      </button>
      <button class="btn btn-danger" (click)="openRejectConfirmation()">
        <i class="material-icons">close</i>
        {{ "decline" | translate }}
      </button>
    </div>
    <div [@visibility]="!showButtons ? 'visible' : 'hidden'" class="button-div">
      <p>
        {{ ("documentWas" | translate) + " " }} <b>{{ result | translate }}</b
        >!
      </p>
    </div>
  </mat-card-footer>
</mat-card>
