<ng-template #customTemplate let-day="day" let-openDay="openDay" let-locale="locale" let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody">
  <div [style.backgroundColor]="day.events.length == 1 && (day.events[0].meta.type === 'AVAILABLE' || day.events[0].meta.type === 'APPOINTED') ? day.events[0].color?.primary : ''" [style.height]="'100%'" class="custom-month-cell">
    <div class="cal-cell-top" [style.height]="day.events.length <= 1 ? '100%' : ''">
      <span class="cal-day-number" style="position: sticky; float: right" [style.color]="day.events.length == 1 && day.events[0].meta.type === 'APPOINTED' ? day.events[0].color?.secondary : ''">{{ day.date | calendarDate : "monthViewDayNumber" : locale }}</span>
      <div *ngIf="day.events.length == 1 && day.events[0].meta.type === 'UNAVAILABLE'" class="nv-event">
        {{ "NV" }}
      </div>
      <div *ngIf="day.events.length == 1 && day.events[0].meta.type === 'AVAILABLE'" class="av-event">
        <div>{{ day.events[0].meta.timeFrom + " - " + day.events[0].meta.timeTo }}</div>
        <div>{{ day.events[0].meta.distance + "km" }}</div>
      </div>
      <div *ngIf="day.events.length == 1 && day.events[0].meta.type === 'APPOINTED'" class="ev-event">
        <div>{{ day.events[0].meta.location }}</div>
        <div>{{ day.events[0].meta.distance + "km" }}</div>
        <div>{{ day.events[0].meta.timeFrom + " - " + day.events[0].meta.timeTo }}</div>
        <div>{{ day.events[0].meta.moduleName }}</div>
      </div>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 1">
      <div
        class="cal-event"
        *ngFor="let event of day.events; trackBy: trackByEventId"
        [style.backgroundColor]="event.color?.primary"
        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({ event: event })"
        (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="event.title | calendarEventTitle : 'monthTooltip' : event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        mwlDraggable
        [class.cal-draggable]="event.draggable"
        dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }"
        [dragAxis]="{ x: event.draggable, y: event.draggable }"
        (mwlClick)="eventClicked.emit({ event: event })"
      ></div>
    </div>
  </div>
</ng-template>

<div class="row">
  <div class="col-sm-3">
    <div class="btn-group">
      <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
        {{ "<" }}
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        {{ "today" | translate }}
      </div>
      <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
        {{ ">" }}
      </div>
    </div>
  </div>
  <div class="col-sm-6 calendar-title">
    <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "de" }}</h3>
  </div>
  <div class="col-sm-3"></div>
  <div class="col-sm-12">
    <div [ngSwitch]="view" class="trainer-calendar">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [locale]="locale" [viewDate]="viewDate" [activeDayIsOpen]="activeDayIsOpen" [events]="events" (dayClicked)="dayClicked($event)" [cellTemplate]="customTemplate"> </mwl-calendar-month-view>
    </div>
  </div>
</div>
