import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MeetingService, Meeting, MeetingSchedule } from "../../../services/meeting.service";

@Component({
  selector: "meeting-administration-page",
  templateUrl: "./meeting-administration-page.component.html",
  styleUrls: ["./meeting-administration-page.component.scss"],
})
export class MeetingAdministrationPageComponent implements OnInit {
  allMeetings: Meeting[];
  meetingToAdd = new Meeting();
  selectedMeeting: Meeting;
  allSchedules: MeetingSchedule[];
  filterData: UntypedFormGroup = this.initFormGroup();

  constructor(private meetingService: MeetingService, private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.getAllMeetings();
    this.filterData.valueChanges.subscribe(() => {
      this.applyAdvancedFilter();
    });
  }

  createEditMeeting(meetingForm: UntypedFormGroup) {
    if (meetingForm) {
      const meeting = this.meetingService.mapFormToMeeting(meetingForm);
      this.meetingService.createUpdate(meeting).subscribe((res) => {
        this.getAllMeetings();
      });
    } else {
      this.selectedMeeting = null;
    }
  }

  getAllMeetings() {
    this.meetingService.findAll().subscribe((res) => {
      this.allMeetings = res.body;
      this.selectedMeeting = null;
    });
  }

  deleteMeeting(meeting: Meeting) {
    this.meetingService.delete(meeting).subscribe((res) => {
      this.getAllMeetings();
    });
  }

  selectMeeting(meeting: Meeting) {
    this.selectedMeeting = meeting;
  }

  addMeetingClicked() {
    this.selectedMeeting = new Meeting();
  }

  initFormGroup(): UntypedFormGroup {
    const fg = this.formBuilder.group({
      healthModules: [true, []],
      webinars: [true, []],
      activeBreaks: [true, []],
    });
    return fg;
  }

  applyAdvancedFilter() {
    const healthModules = this.filterData.get("healthModules").value;
    const webinars = this.filterData.get("webinars").value;
    const activeBreaks = this.filterData.get("activeBreaks").value;
    if (!healthModules && !webinars && !activeBreaks) {
      this.allMeetings = [];
    } else {
      if (healthModules && webinars && activeBreaks) {
        this.getAllMeetings();
      } else {
        this.setFilter(healthModules, webinars, activeBreaks);
      }
    }
    this.cdr.detectChanges();
  }

  setFilter(healthModules: boolean, webinar: boolean, activeBreaks: boolean) {
    this.meetingService.findAll().subscribe((res) => {
      this.allMeetings = [];
      const meetings: Meeting[] = res.body;
      for (const meeting of meetings) {
        if (healthModules && meeting.moduleType === "HEALTH_MODULE") {
          this.allMeetings.push(meeting);
        }
        if (webinar && meeting.moduleType === "WEBINAR") {
          this.allMeetings.push(meeting);
        }
        if (activeBreaks && meeting.moduleType === "ACTIVE_BREAK") {
          this.allMeetings.push(meeting);
        }
      }
    });
  }
}
