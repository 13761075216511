import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { EventInquiryHealthscreening } from "./event.service";
import * as moment from "moment";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { EnvService } from "./env.service";

export class Meeting {
  id: number;
  alias: string;
  username: string;
  password: string;
  emergencyPhone: string;
  info: string;
  active: boolean;
  moduleType: string;

  constructor() {
    this.id = null;
    this.alias = "";
    this.username = "";
    this.password = "";
    this.emergencyPhone = "";
    this.info = "";
    this.active = true;
    this.moduleType = "";
  }
}

export class MeetingSchedule {
  id: number;
  meeting: Meeting;
  eihs: EventInquiryHealthscreening;
  eventDate: Date;
  timeFrom: string;
  timeTo: string;

  constructor() {
    this.id = null;
    this.meeting = null;
    this.eihs = null;
    this.eventDate = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
    this.timeFrom = "";
    this.timeTo = "";
  }
}

@Injectable({
  providedIn: "root",
})
export class MeetingService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {}

  createUpdate(meeting: Meeting): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/meeting/createupdate", meeting, this.config);
  }

  delete(meeting: Meeting): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/meeting/delete/" + meeting.id, this.config);
  }

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/meeting/getAllMeetings", this.config);
  }

  findAllSchedules(from: string, to: string): Observable<HttpConfig> {
    const dateFrom = moment(from).format("YYYY-MM-DD");
    const dateTo = moment(to).format("YYYY-MM-DD");
    return this.http.get(this.envService.backendUrl + "/meeting/getAllMeetingSchedules?from=" + dateFrom + "&to=" + dateTo, this.config);
  }

  getMeetingForEihsId(eihsId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/meeting/getMeetingForEihsId?eihsId=" + eihsId, this.config);
  }

  mapMeetingToForm(meeting: Meeting) {
    if (meeting !== null) {
      return this.formBuilder.group(meeting);
    } else {
      return this.formBuilder.group(new Meeting());
    }
  }

  mapFormToMeeting(form: UntypedFormGroup) {
    return form.getRawValue();
  }
}
