<div class="row">
  <div class="col-sm-6">
    <div class="static-container-left">
      <div>
        {{ availableLabel | translate}}
        <img src="assets/icon/add_all.png" class="icon-all" (click)="addAll()" placement="left" [ngbTooltip]="'dragAndDropAddAll' | translate" />
      </div>
      <div class="box-container-left">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="filteredAvailableItems" [cdkDropListConnectedTo]="[doneList]" class="box-list" (cdkDropListDropped)="drop($event)">
          <div class="box" *ngFor="let availableItem of filteredAvailableItems; let i = index" cdkDrag (dblclick)="addToAssignedItems(availableItem, i)">{{availableItem[shownKey]}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="static-container-right">
      <div>
        {{ assignedLabel | translate }}
        <img src="assets/icon/remove_all.png" class="icon-all" (click)="removeAll()" placement="left" [ngbTooltip]="'dragAndDropRemoveAll' | translate" />
      </div>
      <div class="box-container-right">
        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="assignedItemsIntern" [cdkDropListConnectedTo]="[todoList]" class="box-list" (cdkDropListDropped)="drop($event)">
          <div class="box" *ngFor="let assignedItem of assignedItemsIntern; let i = index" cdkDrag (dblclick)="removeFromAssignedItems(assignedItem, i)">{{assignedItem[shownKey]}}</div>
        </div>
      </div>
    </div>
  </div>
</div>