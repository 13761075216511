<div *ngIf="!urlHasError">
  <h1 style="text-align: center">{{ "customerRegistration" | translate }}</h1>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <p style="text-align: center">{{ "customerRegistrationSummitText" | translate }}:</p>
    </div>
    <div class="col-3"></div>
  </div>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4">
      <mat-card *ngIf="form">
        <mat-card-content>
          <form [formGroup]="form">
            <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
            <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
            <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
            <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
            <gib-input [placeholder]="'username'" [control]="form.get('username')"></gib-input>
            <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
            <gib-input [placeholder]="'phonenumber'" [control]="form.get('phonenumber')"></gib-input>
            <gib-input [placeholder]="'companyname'" [control]="form.get('companyname')"></gib-input>
            <gib-input [placeholder]="'password'" [control]="form.get('password')" [type]="'password'"></gib-input>
            <gib-input [placeholder]="'passwordConfirmation'" [control]="form.get('passwordConfirmation')" [type]="'password'"></gib-input>
            <hr />
            <gib-gdpr-checkbox [control]="form.get('gdprChecked')"></gib-gdpr-checkbox>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-4"></div>
  </div>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4" style="text-align: center">
      <button type="button" class="btn btn-primary" *ngIf="!registrationCompleted" (click)="submitRegistration()">{{ "finishRegstration" | translate }}</button>
    </div>
    <div class="col-4"></div>
  </div>
</div>
