import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "home-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
})
export class TileComponent implements OnInit {
  @Input() headername: string;
  @Input() linkTo: string;
  @Input() image: string;

  constructor(private router: Router) {}

  ngOnInit() {}

  route() {
    this.router.navigate([this.linkTo]);
  }
}
