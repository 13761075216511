import { FormHelper } from "./../../helper/form.helper";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "gib-radio-button-group",
  templateUrl: "./gib-radio-button-group.component.html",
  styleUrls: ["./gib-radio-button-group.component.scss"],
})
export class GibRadioButtonGroupComponent implements OnInit {
  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;
  @Input() showRequired: boolean = true;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    if (this.inputControl && this.inputControl.value === true) {
      this.inputControl.setValue("true");
    }
    if (this.inputControl && this.inputControl.value === false) {
      this.inputControl.setValue("false");
    }
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}
}
