import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ValueLabel } from "src/app/components/value-label";
import { ValueLabelBoolean } from "src/app/components/value-label-boolean";
import { HealthScreeningService } from "../../../../services/health-screening.service";
import { SelectOptions } from "../../../../utils/select-options";
import { HealthInsuranceService } from "../../../../services/health-insurance.service";
import { CustomerService } from "../../../../services/customer.service";

@Component({
  selector: "edit-health-screening-form",
  templateUrl: "./edit-health-screening-form.component.html",
  styleUrls: ["./edit-health-screening-form.component.scss"],
})
export class EditHealthScreeningFormComponent implements OnInit {
  @Input() healthScreeningForm: UntypedFormGroup;
  @Input() role: string;
  @Input() showSwayAsLink: boolean = false;
  @Input() step: number;

  locations: ValueLabel[] = SelectOptions.healthScreeningLocations;
  onlinePlatformOptions: ValueLabel[] = SelectOptions.onlinePlatformOptions;
  powerOptions: ValueLabelBoolean[] = SelectOptions.powerOptions;

  moduleOptions: ValueLabel[] = [
    { value: "HEALTH_MODULE", label: "module_option_health" },
    { value: "WEBINAR", label: "module_option_webinar" },
    { value: "ACTIVE_BREAK", label: "module_option_active_break" },
  ];
  topicOptions: ValueLabel[] = [];
  customerOptions: ValueLabel[] = [];
  a;
  constructor(private healthscreeningService: HealthScreeningService, private healthInsuranceService: HealthInsuranceService, private customerService: CustomerService) {}

  ngOnInit() {
    this.healthscreeningService.getTopics().subscribe((res) => {
      for (const topic of res.body) {
        this.topicOptions.push({ value: topic, label: topic });
      }
    });
    this.customerService.getAllCompanynameAliases().subscribe((res) => {
      if (res && res.body) {
        for (const alias of res.body) {
          this.customerOptions.push({ value: alias.id, label: alias.id });
        }
      }
    });
  }

  hasLocationPanelErrors() {
    return (
      (this.healthScreeningForm.get("requiredArea").errors !== null && this.healthScreeningForm.get("requiredArea").touched) ||
      (this.healthScreeningForm.get("power").errors !== null && this.healthScreeningForm.get("power").touched) ||
      (this.healthScreeningForm.get("accessories").errors !== null && this.healthScreeningForm.get("accessories").touched) ||
      (this.healthScreeningForm.get("location").errors !== null && this.healthScreeningForm.get("location").touched)
    );
  }

  get isHealthModule(): boolean {
    return this.healthScreeningForm.get("moduleType").value === "HEALTH_MODULE";
  }

  get isOnline(): boolean {
    return this.healthScreeningForm.get("online").value;
  }

  get isOnsite(): boolean {
    return this.healthScreeningForm.get("onsite").value;
  }
}
