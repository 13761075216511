import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { EnvService } from "./env.service";

class GibExceptionRequest {
  userId: string;
  userOfficialName: string;
  date: Date;
  exception: string;
  browser: string;
  currentUrl: string;
}

@Injectable({
  providedIn: "root",
})
export class ExceptionService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  userName: string;

  constructor(private http: HttpClient, private router: Router, private envService: EnvService) {}

  sendException(error: any): Observable<HttpConfig> {
    const errorToSend = new GibExceptionRequest();
    errorToSend.date = new Date();
    errorToSend.browser = window.navigator.userAgent;
    errorToSend.userId = localStorage.getItem("userId");
    errorToSend.userOfficialName = localStorage.getItem("officialName");
    errorToSend.exception = error.errorCode;
    errorToSend.currentUrl = this.router.url;
    return this.http.post(this.envService.backendUrl + "/exception", errorToSend, this.config);
  }
}
