import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { UntypedFormBuilder } from "@angular/forms";
import { Address } from "./keycloak-connector.service";
import { EnvService } from "./env.service";

export class ConfigEntry {
  key: string;
  value: string;

  constructor() {
    this.key = null;
    this.value = null;
  }
}

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {}

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/admin/config/getCustomConfigs", this.config);
  }

  getParticipantRegistrationTime(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/public/config/getParticipantRegistrationTime", this.config);
  }

  updateEntry(entry: ConfigEntry): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/admin/config/updateCustomConfig", entry, this.config);
  }

  findAddresses(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/admin/config/getOfficeAddresses", this.config);
  }

  updateAddress(address: Address): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/admin/config/updateOfficeAddress", address, this.config);
  }

  mapEntriesToFormArray(entries: ConfigEntry[]) {
    const fgArray = [];
    for (const entry of entries) {
      const fg = this.formBuilder.group(entry);
      fg.disable();
      fgArray.push(fg);
    }
    return this.formBuilder.array(fgArray);
  }
}
