import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { TranslateService } from "@ngx-translate/core";
import { EnvService } from "./env.service";

export declare type Salutation = "male" | "female";
export declare type Role = "gib_admin" | "gib_director" | "gib_employee" | "gib_trainer" | "gib_customer" | "gib_purchaser" | "gib_participant" | "gib_afg";
export declare type AddressType = "HOME" | "BUSINESS" | "DELIVERY" | "BILLING" | "EVENT" | "CUSTOMER_EVENT_ADDRESS" | "OFFICE_HH" | "OFFICE_B";

export class Address {
  id: number;
  type: AddressType;
  street: string;
  number: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;

  public constructor() {
    this.id = null;
    this.type = null;
    this.street = "";
    this.number = "";
    this.zip = "";
    this.city = "";
    this.country = "";
    this.countryCode = "";
  }
}

export class KeycloakUser {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  role: Role;
  salutation: Salutation;
  enabled: boolean;
  addresses: Address[];
  fullName: string;

  public constructor() {
    this.id = null;
    this.username = "";
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.role = null;
    this.salutation = null;
    this.enabled = true;
    this.addresses = [];
    this.fullName = "";
  }
}

@Injectable()
export class KeycloakConnectorService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  url: string;

  constructor(private http: HttpClient, private translateService: TranslateService, private envService: EnvService) {
    this.url = envService.ssoUrl + "/admin/realms/" + envService.keycloakRealm;
  }

  createKeycloakCustomer(user: KeycloakUser): Observable<string> {
    return this.http.post<string>(this.envService.backendUrl + "/public/customer/createKeycloakCustomer", user, this.config);
  }

  createUser(user: KeycloakUser): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const userForPost = this.createUserForPost(user);
      this.http
        .post(this.url + "/users", userForPost, this.config)
        .toPromise<HttpConfig>()
        .then((createdUser) => {
          const locationUrl = createdUser.headers.get("location") ? createdUser.headers.get("location") : "";
          const userId = locationUrl.substring(locationUrl.lastIndexOf("/") + 1, locationUrl.length);

          this.getAvailableRolesForUser(userId).subscribe((res) => {
            for (const role of res.body) {
              if (role.name === user.role) {
                this.addRoleToUser(userId, role).subscribe(() => {
                  resolve(userId);
                });
              }
            }
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  updateUser(user: KeycloakUser): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const userForPut = this.createUserForPost(user);
      this.http
        .put(this.url + "/users/" + user.id, userForPut, this.config)
        .toPromise<HttpConfig>()
        .then((res) => {
          this.getUserRoleObjects(user.id).then((result) => {
            const rolesToDelete = [];
            for (const role of result) {
              if (role.name.startsWith("gib_")) {
                rolesToDelete.push(role);
              }
            }
            this.deleteRolesFromUser(user.id, rolesToDelete).subscribe(() => {
              this.getAvailableRolesForUser(user.id).subscribe((availableRoles) => {
                for (const role of availableRoles.body) {
                  if (role.name === user.role) {
                    this.addRoleToUser(user.id, role).subscribe(() => {
                      resolve(user.id);
                    });
                  }
                }
              });
            });
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  deleteUser(userId: string): Observable<HttpConfig> {
    return this.http.delete(this.url + "/users/" + userId, this.config);
  }

  resetPassword(userId: string, newPassword: string, isTemporary: boolean) {
    const pwResetBody = { type: "password", temporary: isTemporary, value: newPassword };
    this.http.put(this.url + "/users/" + userId + "/reset-password", pwResetBody, this.config).subscribe((res) => {
      // subscribe(also when empty) is necessary to really trigger the pw reset...
    });
  }

  addRoleToUser(userId: string, role: string): Observable<HttpConfig> {
    return this.http.post(this.url + "/users/" + userId + "/role-mappings/realm", [role], this.config);
  }

  deleteRolesFromUser(userId: string, roles: any[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: roles,
    };
    return this.http.delete(this.url + "/users/" + userId + "/role-mappings/realm", httpOptions);
  }

  getAvailableRolesForUser(userId: string): Observable<HttpConfig> {
    return this.http.get(this.url + "/users/" + userId + "/role-mappings/realm/available", this.config);
  }

  getUserRoles(userId: string) {
    const promise = new Promise<string>((resolve, reject) => {
      const url = this.url + "/users/" + userId + "/role-mappings/realm";
      this.http
        .get(url, this.config)
        .toPromise()
        .then((roles) => {
          let userRoles = "";
          if (Array.isArray(roles["body"])) {
            for (const role of roles["body"]) {
              if (role.name.startsWith("gib_")) {
                userRoles += role.name + ", ";
              }
            }
            userRoles = userRoles.substring(0, userRoles.length - 2);
          }
          resolve(userRoles);
        });
    });
    return promise;
  }

  getUserRoleObjects(userId: string) {
    const promise = new Promise<any[]>((resolve, reject) => {
      const url = this.url + "/users/" + userId + "/role-mappings/realm";
      this.http
        .get(url, this.config)
        .toPromise()
        .then((roles) => {
          const userRoles = [];
          if (Array.isArray(roles["body"])) {
            for (const role of roles["body"]) {
              if (role.name.startsWith("gib_")) {
                userRoles.push(role);
              }
            }
          }
          resolve(userRoles);
        });
    });
    return promise;
  }

  getRoles(): Observable<HttpConfig> {
    return this.http.get(this.url + "/roles", this.config);
  }

  private createUserForPost(user: KeycloakUser) {
    return {
      id: user.id,
      username: user.username,
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      enabled: user.enabled,
    };
  }

  handleErrorMessage(message: string) {
    if (message.includes("User exists with same email")) {
      return this.translateService.instant("userExistsWithSameEmail");
    } else if (message.includes("User exists with same username or email")) {
      return this.translateService.instant("userExistsWithSameUsernameOrEmail");
    } else if (message.includes("User exists with same username")) {
      return this.translateService.instant("userExistsWithSameUsername");
    } else {
      return message;
    }
  }
}
