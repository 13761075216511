import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

import { ThemeModule } from './theme/theme.module';
import { MaterialModule } from './material/material.module';
import { AuthInterceptor } from './authentication/auth-interceptor';
import { KeycloakConnectorService } from '../services/keycloak-connector.service';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import 'moment/locale/de';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  providers: [
    TranslateService,
    KeycloakConnectorService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  imports: [
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MomentModule
  ],
  exports: [
    TranslateModule,
    ThemeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MomentModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ]
})

export class UtilsModule { }
