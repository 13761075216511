<mat-form-field placement="left" [ngbTooltip]="'replaceModule' | translate" appearance="none" *ngIf="!eventInquiryHealthscreening.get('onlineModule').value && editMode">
  <mat-label><i class="material-icons" *ngIf="!hideLabel" id="switchIcon">repeat</i></mat-label>
  <mat-select [formControl]="selectControl" class="replaceSelect">
    <mat-select-trigger><i class="material-icons" id="switchIcon">repeat</i></mat-select-trigger>
    <mat-optgroup *ngFor="let group of moduleGroups" [label]="group.name">
      <mat-option *ngFor="let module of group.modules" [value]="module.value" (click)="replaceModuleClicked(module)">
        {{ module.label }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>

<mat-form-field placement="left" [ngbTooltip]="'replaceModule' | translate" appearance="none" *ngIf="eventInquiryHealthscreening.get('onlineModule').value && editMode">
  <mat-label><i class="material-icons" *ngIf="!hideLabel" id="switchIcon">repeat</i></mat-label>
  <mat-select [formControl]="onlineSelectControl" class="replaceSelect">
    <mat-select-trigger><i class="material-icons" id="switchIcon">repeat</i></mat-select-trigger>
    <mat-optgroup *ngFor="let group of onlineModuleGroups" [label]="group.name">
      <mat-option *ngFor="let module of group.modules" [value]="module.value" (click)="replaceModuleClicked(module)">
        {{ module.label }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
