import { InternalNoteService, InternalNote } from "./../../../services/internal-note.service";
import { ActivatedRoute, ParamMap, Router, NavigationEnd } from "@angular/router";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { FormHelper } from "../../../helper/form.helper";
import { CustomerService } from "../../../services/customer.service";
import { Chat, EventService } from "../../../services/event.service";
import { ProcessService, TaskOutcome } from "../../../services/process-service";
import { TaskBottomSheetComponent } from "../components/task-bottom-sheet/task-bottom-sheet.component";
import { EventTaskTableComponent } from "./../components/event-task-table/event-task-table.component";
import { CustomValidators } from "src/app/utils/custom-validator";

@Component({
  selector: "event-tasks-page",
  templateUrl: "./event-tasks-page.component.html",
  styleUrls: ["./event-tasks-page.component.scss"],
})
export class EventTasksPageComponent implements OnInit {
  @ViewChild("eventTable", { static: true }) eventTable: EventTaskTableComponent;

  tasks: any[];
  completedTasks: any[];
  selectedTask: any;
  eventForm: UntypedFormGroup;
  internalNoteForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;
  chatElements: Chat[];
  event: Event;
  componentName = "EventTasksPageComponent";
  taskIdFilter: string;
  title: string;

  showButtons = true;
  task: any;
  userRole: string;

  constructor(
    private processService: ProcessService,
    private eventService: EventService,
    private formHelper: FormHelper,
    private customerService: CustomerService,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private internalNoteService: InternalNoteService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("taskIdFilter")) {
        this.taskIdFilter = paramMap.get("taskIdFilter");
      }
    });
    this.route.params.subscribe((routeParams) => {
      if (this.taskIdFilter === "all") {
        this.getAllFilteredActiveTasks("all");
        this.getAllFilteredCompletedTasks("all");
      } else {
        this.getAllFilteredActiveTasks(routeParams.taskIdFilter);
        this.getAllFilteredCompletedTasks(routeParams.taskIdFilter);
      }
      this.title = "event-tasks-" + this.taskIdFilter;
    });
    this.userRole = localStorage.getItem("role");
  }

  getTaskIdFilter() {
    return this.taskIdFilter;
  }

  getAllFilteredActiveTasks(filter: string) {
    this.processService.getAllActiveTasks(filter).subscribe((response) => {
      this.tasks = response.body;
      this.tasks.sort(this.formHelper.sortTasksByCreatedDate);
    });
  }

  getAllFilteredCompletedTasks(filter: string) {
    this.processService.getAllFilteredCompletedTasks(filter).subscribe((response) => {
      this.completedTasks = response.body;
      this.completedTasks.sort(this.formHelper.sortByProperty("created"));
    });
  }

  getActiveTasks() {
    this.processService.getAllProcessTasks().subscribe((tasks) => {
      this.tasks = tasks;
      this.tasks.sort(this.formHelper.sortTasksByCreatedDate);
    });
  }

  getCompletedTasks() {
    this.processService.getAllCompletedTasks().subscribe((response) => {
      this.completedTasks = response.body;
      this.completedTasks.sort(this.formHelper.sortByProperty("created"));
    });
  }

  openFormForTask(task: any, taskIdFilter: string) {
    this.selectedTask = task;
    if (task.task.taskDefinitionKey === "ut_create_companyname_alias") {
      this.customerService.getCustomer(task.buid).subscribe(
        (res) => {
          this.customerForm = this.customerService.mapCustomerToForm(res);
          this.addCustomerFormValidations(this.customerForm);
          const sheetData = { customerForm: this.customerForm, task, taskCompleteFn: () => this.getAllFilteredActiveTasks(taskIdFilter) };
          this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
        },
        (error) => {
          this.completeTask("APPROVED", task.task.id, null, null);
        }
      );
    } else if (task.task.processInstanceId) {
      this.eventService.findByProcessInstanceId(task.task.processInstanceId).subscribe((res) => {
        this.event = res.body;
        if (
          task.task.taskDefinitionKey === "ut_process_query" ||
          task.task.taskDefinitionKey === "ut_check_availability" ||
          task.task.taskDefinitionKey === "ut_edit_course_template" ||
          task.task.taskDefinitionKey === "ut_edit_course_tempalte" ||
          task.task.taskDefinitionKey === "ut_inform_about_failure_on_call" ||
          task.task.taskDefinitionKey === "ut_trainer_call_customer" ||
          task.task.taskDefinitionKey === "ut_gib_call_customer" ||
          task.task.taskDefinitionKey === "ut_call_customer"
        ) {
          this.eventForm = this.eventService.mapEventToForm(res.body);
          this.formHelper.disableControls(this.eventForm);
          this.internalNoteService.getById(this.eventForm.get("id").value, this.userRole).subscribe((res) => {
            let internalNote = res.body;
            if (internalNote === null) {
              internalNote = new InternalNote();
            }
            this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
            this.formHelper.disableControls(this.internalNoteForm);
            const sheetData = { eventForm: this.eventForm, internalNoteForm: this.internalNoteForm, task, taskCompleteFn: () => this.getAllFilteredActiveTasks(taskIdFilter) };
            this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
          });
        } else if (task.task.taskDefinitionKey === "ut_create_customer") {
          this.customerForm = this.customerService.mapEventToCustomerForm(res.body);
          this.addCustomerFormValidations(this.customerForm);
          const sheetData = { customerForm: this.customerForm, task, taskCompleteFn: () => this.getAllFilteredActiveTasks(taskIdFilter) };
          this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
        } else if (task.task.taskDefinitionKey === "ut_select_trainer") {
          this.eventForm = this.eventService.mapEventToForm(res.body);
          this.formHelper.disableControls(this.eventForm);
          const sheetData = { trainerForEventForm: this.eventForm, task, taskCompleteFn: () => this.getAllFilteredActiveTasks(taskIdFilter) };
          this.bottomSheet.open(TaskBottomSheetComponent, { data: sheetData });
        } else if (task.task.taskDefinitionKey === "ut_assign_trainers") {
          const title = this.translateService.instant("assignTrainerTitle");
          const text = this.translateService.instant("assignTrainerText");
          this.dialogService.openConfirmationDialog(title, text, () => (this.completeTask("APPROVED", task.task.id, null), this.getAllFilteredActiveTasks(taskIdFilter)));
        } else if (task.task.taskDefinitionKey === "ut_ag_confirmed_online_event") {
          const title = this.translateService.instant("ut_ag_confirmed_online_event_Title");
          const text = this.translateService.instant("ut_ag_confirmed_online_event_Text");
          this.dialogService.openConfirmationDialog(title, text, () => (this.completeTask("APPROVED", task.task.id, null), this.getAllFilteredActiveTasks(taskIdFilter)));
        } else if (task.task.taskDefinitionKey === "ut_create_meeting_accounts") {
          this.eventForm = this.eventService.mapEventToForm(res.body);
          const title = this.translateService.instant("createMeetingAccountsTitle");
          const demandList = task.variables.meetingDemandList;
          const demandCounts = {};
          demandList.forEach((element) => {
            const type = element.moduleType;
            demandCounts[type] = demandCounts[type] ? demandCounts[type] + 1 : 1;
          });
          let text = this.translateService.instant("createMeetingAccountsText");
          Object.keys(demandCounts).forEach((element) => {
            text = text.concat(this.translateService.instant(element) + ": " + demandCounts[element] + "<br>");
          });
          this.dialogService.openConfirmationDialog(title, text, () => (this.completeTask("APPROVED", task.task.id, null), this.getAllFilteredActiveTasks(taskIdFilter)));
        }
      });
    } else {
      const title = task.task.name;
      const text = task.task.description;
      this.dialogService.openConfirmationDialog(title, text, () => (this.completeTask("APPROVED", task.task.id, null), this.getAllFilteredActiveTasks(taskIdFilter)));
    }
  }

  completeTask(outcome: TaskOutcome, taskId: string, query: string, params?: Map<string, string>) {
    this.processService.completeTask(taskId, outcome, query, params).subscribe((res) => {
      this.showButtons = false;
      this.cdr.detectChanges();
    });
  }

  addCustomerFormValidations(customerForm: UntypedFormGroup) {
    customerForm.get("salutation").setValidators(Validators.required);
    customerForm.get("firstname").setValidators(Validators.required);
    customerForm.get("lastname").setValidators(Validators.required);
    customerForm.get("username").setValidators(Validators.required);
    customerForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    customerForm.get("phonenumber").setValidators(Validators.required);
    customerForm.get("companyname").setValidators(Validators.required);
    customerForm.get("companynameAlias").setValidators(Validators.required);
  }

  clicked(event: any) {
    window.scroll(0, 0);
    if (event.index === 0) {
      this.getAllFilteredActiveTasks(this.taskIdFilter);
    } else if (event.index === 1) {
      if (this.taskIdFilter === "all") {
        this.getAllFilteredCompletedTasks("");
      } else {
        this.getAllFilteredCompletedTasks(this.taskIdFilter);
      }
    }
  }
}
