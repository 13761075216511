import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Employee, EmployeeService } from "src/app/services/employee.service";
import { CustomValidators } from "src/app/utils/custom-validator";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { ValueLabel } from "../../../../components/value-label";
import { FormHelper } from "../../../../helper/form.helper";
import { KeycloakConnectorService } from "../../../../services/keycloak-connector.service";
import { SelectOptions } from "../../../../utils/select-options";

@Component({
  selector: "add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.scss"],
})
export class AddEmployeeComponent implements OnInit {
  _employee: Employee;
  salutationOptions = SelectOptions.salutationOptions;

  @Input() set employee(emp: Employee) {
    this._employee = emp;
    this.form = this.initFormGroup();
    this.initFormListener();
  }

  get employee(): Employee {
    return this._employee;
  }

  @Input() roles: ValueLabel[];
  @Output() employeeAdded = new EventEmitter<string>();

  form: UntypedFormGroup;

  constructor(
    private configService: ConfigurationService,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private formHelper: FormHelper,
    private keycloakConnector: KeycloakConnectorService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {}

  initFormGroup(): UntypedFormGroup {
    const employeeForm = this.employeeService.mapEmployeeToForm(this._employee);
    employeeForm.get("salutation").setValidators(Validators.required);
    employeeForm.get("username").setValidators(Validators.required);
    employeeForm.get("firstname").setValidators(Validators.required);
    employeeForm.get("lastname").setValidators(Validators.required);
    employeeForm.get("role").setValidators(Validators.required);
    employeeForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    employeeForm.get("phonenumber").setValidators(Validators.required);
    return employeeForm;
  }

  initFormListener() {
    // GPD-106: automatically set username, if first- and lastname are set
    this.form.get("firstname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("lastname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
    this.form.get("lastname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("firstname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
  }

  presetUsername() {
    const newUsername = this.form.get("firstname").value.charAt(0).toLowerCase() + this.form.get("lastname").value.charAt(0).toLowerCase() + "-gib";
    this.form.get("username").setValue(newUsername);
  }

  addEmployeeClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "EMPLOYEE_ADDED_ERROR_TITLE", () => this.addEmployee());
  }

  addEmployee() {
    this._employee = this.employeeService.mapFormToEmployee(this.form);
    this.keycloakConnector
      .createUser(this._employee)
      .then((userId) => {
        this._employee.id = userId;
        this.configService.findAll().subscribe(res => {
          let defaultPassword = res.body.find(item => item.key === 'DEFAULT_PASSWORD').value;
          this.keycloakConnector.resetPassword(this._employee.id, defaultPassword, true);
          this.employeeService.create(this._employee).subscribe(
            (res) => {
              this.openSuccessFullAddedDialog();
              this.employeeAdded.emit(null);
            },
            (error) => {
              this.keycloakConnector.deleteUser(userId).subscribe(() => {});
            }
          );
        });
      })
      .catch((err) => {
        this.openErrorAddedDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      });
  }

  updateEmployeeClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "EMPLOYEE_EDIT_ERROR_TITLE", () => this.updateEmployee());
  }

  updateEmployee() {
    this._employee = this.employeeService.mapFormToEmployee(this.form);
    this.keycloakConnector.updateUser(this._employee).then(
      () => {
        this.employeeService.update(this._employee).subscribe((res) => {
          this.openSuccessFullEditDialog();
          this.employeeAdded.emit(null);
        });
      },
      (err) => {
        this.openErrorEditDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      }
    );
  }

  cancel() {
    // do nothing, just emit with null to close the panel
    this.employeeAdded.emit(null);
  }

  private openSuccessFullAddedDialog(): void {
    const empFullName = this.employeeService.getFullName(this.employee);
    const title = this.translateService.instant("EMPLOYEE_ADDED_SUCCESS_TITLE");
    const text = this.translateService.instant("EMPLOYEE_ADDED_SUCCESS_TEXT", { employeeName: empFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openSuccessFullEditDialog(): void {
    const empFullName = this.employeeService.getFullName(this.employee);
    const title = this.translateService.instant("EMPLOYEE_EDIT_SUCCESS_TITLE");
    const text = this.translateService.instant("EMPLOYEE_EDIT_SUCCESS_TEXT", { employeeName: empFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openErrorAddedDialog(errorMessage: string): void {
    const title = this.translateService.instant("EMPLOYEE_ADDED_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  private openErrorEditDialog(errorMessage: string): void {
    const title = this.translateService.instant("EMPLOYEE_EDIT_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }
}
