import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GibDialogComponent } from "./gib-dialog/gib-dialog.component";
import { GibConfirmationDialogComponent } from "./gib-confirmation-dialog/gib-confirmation-dialog.component";
import { GibErrorDialogComponent } from "./gib-error-dialog/gib-error-dialog.component";
import { GibTextConfirmationDialogComponent } from "./gib-text-confirmation-dialog/gib-text-confirmation-dialog.component";
import { GibConfirmationErrorDialogComponent } from "./gib-confirmation-error-dialog/gib-confirmation-error-dialog.component";
import { GibServerErrorDialogComponent, GibServerError } from "./gib-server-error-dialog/gib-server-error-dialog.component";
import { GibPrivacyDialogComponent } from "./gib-privacy-dialog/gib-privacy-dialog.component";
import { UntypedFormGroup } from "@angular/forms";
import { GibCancelationDialogComponent } from "./gib-cancelation-dialog/gib-cancelation-dialog.component";
import { GibWaitinglistDialogComponent } from "./gib-waitinglist-dialog/gib-waitinglist-dialog.component";

@Injectable({
  providedIn: "root",
})
export class GibDialogService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  openDialog(title: string, text: string, type: string, closeAction?: any): void {
    const dialogRef = this.dialog.open(GibDialogComponent, {
      data: {
        title,
        text,
        type,
      },
    });
    if (closeAction) {
      dialogRef.afterClosed().subscribe((result) => {
        closeAction();
      });
    }
  }

  openLinkDialog(title: string, text: string, link: string, type: string) {
    this.dialog.open(GibDialogComponent, {
      data: {
        title,
        text,
        link,
        type,
      },
    });
  }

  openConfirmationDialog(title: string, text: string, confirmAction: any): void {
    this.dialog.open(GibConfirmationDialogComponent, {
      data: {
        title,
        text,
        confirmAction,
      },
    });
  }

  openErrorDialog(title: string, errors: Array<string>) {
    this.dialog.open(GibErrorDialogComponent, {
      data: {
        title,
        errors,
      },
    });
  }

  openConfirmationErrorDialog(title: string, errors: Array<string>, confirmAction: any) {
    this.dialog.open(GibConfirmationErrorDialogComponent, {
      data: {
        title,
        errors,
        confirmAction,
      },
    });
  }

  openConfirmationTextDialog(title: string, text: string, confirmationPlaceholderText: string) {
    // returns observable with confirmationText(string) or null if cancelled
    return this.dialog
      .open(GibTextConfirmationDialogComponent, {
        data: {
          title,
          text,
          confirmationPlaceholderText,
        },
      })
      .afterClosed();
  }

  openServerErrorDialog(error: GibServerError) {
    this.snackBar.openFromComponent(GibServerErrorDialogComponent, {
      // 30sec
      duration: 30 * 1000,
      data: error,
      verticalPosition: "bottom",
    });
  }

  openPrivacyDialog(title: string, text: string, eventPrivacyText: string, thirdPartyPrivacyText: string, votingParticipant: UntypedFormGroup, confirmAction: any) {
    this.dialog.open(GibPrivacyDialogComponent, {
      data: {
        title,
        text,
        eventPrivacyText,
        thirdPartyPrivacyText,
        votingParticipant,
        confirmAction,
      },
    });
  }

  openWaitingListDialog(title: string, text: string, eventPrivacyText: string, thirdPartyPrivacyText: string, votingParticipant: UntypedFormGroup, confirmAction: any) {
    this.dialog.open(GibWaitinglistDialogComponent, {
      data: {
        title,
        text,
        eventPrivacyText,
        thirdPartyPrivacyText,
        votingParticipant,
        confirmAction,
      },
    });
  }

  openCancelationDialog(title: string, text: string) {
    // returns observable with confirmationText(string) or null if cancelled
    return this.dialog
      .open(GibCancelationDialogComponent, {
        data: {
          title,
          text,
        },
      })
      .afterClosed();
  }

  openWrongFileTypeSnackbar(text: string, action: string) {
    this.snackBar.open(text, action, {
      duration: 3000,
    });
  }
}
