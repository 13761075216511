import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { EnvService } from "./env.service";
import moment from "moment";

declare type EventState = "INITIAL" | "PENDING" | "ACCEPTED" | "REJECTED" | "UNANSWERED" | "BOOKED" | "RUNNING" | "COMPLETED" | "CANCELED";

export class EventOverviewDTO {
  reduxId: string;
  eventInquiryHealthscreeningId: number;
  healthscreeningId: number;
  eventDate: string;
  eventId: number;
  companynameAlias: string;
  oderNumber: string;
  moduleName: string;
  healthscreeningReference: string;
  vehicle: string;
  trainerId: string;
  trainerFirstname: string;
  trainerLastname: string;
  hospitantId: string;
  hospitantFirstname: string;
  hospitantLastname: string;
  internetboxReference: string;
  presentation: boolean;
  presentationBillOnly: boolean;
  anonymousEvaluation: boolean;
  anonymousEvaluationBillOnly: boolean;
  presentationEnglish: boolean;
  street: string;
  number: string;
  zip: string;
  city: string;
  distanceFromHamburg: number;
  distanceFromBerlin: number;
  timeFrom: string;
  timeTo: string;
  customerContactSalutation: string;
  customerContactFirstname: string;
  customerContactLastname: string;
  customerContactPhone: string;
  corporateCustomerContactSalutation: string;
  corporateCustomerContactFirstname: string;
  corporateCustomerContactLastname: string;
  corporateCustomerContactPhone: string;
  corporateCustomerCompanyName: string;
  trainerAppointed: boolean;
  trainerFirsttime: boolean;
  state: EventState;
  areFeedbacksAvailable: boolean;
  region: string;
  teamnumber: string;
  cancelationDate: string;
  cancelationReason: string;
  lastUpdated: string;
  trainerEmailTimestamp: string;
  meetingAlias: string;
  deliveryType: string;
  onlineModule: boolean;
}

export class EventOverviewUpdateDTO {
  eventId: number;
  eventInquiryHealthscreeningId: number;
  key: string;
  value: string;
}

export class Filter {
  columnId: string;
  filter: string;
  filterType: string;
  type: string;
}

@Injectable()
export class EventOverviewService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;
  trainerChanged: Map<number, boolean> = new Map();
  initialTrainerId: Map<number, string> = new Map();
  initialColor: Map<number, string> = new Map();

  constructor(private http: HttpClient, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  getActiveEvents(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventOverview/getActiveEvents", this.config);
  }

  getActiveEventsWithDate(from: string, to: string): Observable<HttpConfig> {
    return this.http.get(`${this.envService.backendUrl}/eventOverview/getActiveEvents?from=${from}&to=${to}`, this.config);
  }

  getInactiveEvents(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventOverview/getInactiveEvents", this.config);
  }

  getInactiveEventsWithDate(from: string, to: string): Observable<HttpConfig> {
    return this.http.get(`${this.envService.backendUrl}/eventOverview/getInactiveEvents?from=${from}&to=${to}`, this.config);
  }

  getCanceledEvents(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventOverview/getCanceledEvents", this.config);
  }

  getCanceledEventsWithDate(from: string, to: string): Observable<HttpConfig> {
    return this.http.get(`${this.envService.backendUrl}/eventOverview/getCanceledEvents?from=${from}&to=${to}`, this.config);
  }

  getActiveEventsWithEmptyDates(from: string, to: string): Observable<HttpConfig> {
    return this.http.get(`${this.envService.backendUrl}/eventOverview/getActiveEventsWithEmptyDates?from=${from}&to=${to}`, this.config);
  }

  getEventsWithFilter(view: string, filters: Filter[]): Observable<HttpConfig> {
    const payload = {
      view,
      filters,
    };

    return this.http.post(`${this.envService.backendUrl}/eventOverview/getEventsWithFilter`, payload, this.config);
  }

  getActiveEventsNextTwoWeeksWithEmptyDates(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventOverview/getActiveEventsNextTwoWeeksWithEmptyDates", this.config);
  }

  // updateEvent(evnt: EventOverviewUpdateDTO): Observable<string> {
  //   return this.http.post(this.envService.backendUrl + "/eventOverview/update", evnt, { responseType: "text" });
  // }

  updateEvent(evnt: EventOverviewUpdateDTO): Observable<EventOverviewDTO> {
    return this.http.post<EventOverviewDTO>(this.envService.backendUrl + "/eventOverview/update", evnt);
  }

  public downloadExcel() {
    const from = moment().subtract(1, "years").format("YYYY") + "-01-01";
    const to = moment().add(1, "years").format("YYYY") + "-12-31";
    return this.http.get(this.envService.backendUrl + `/eventOverview/getExcelExport?from=${from}&to=${to}`, { responseType: "blob" });
  }
}
