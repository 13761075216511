<div class="row">
  <div class="col-sm-4">
    <br>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-8"></div>

  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)">

        <ng-container matColumnDef="processName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'process' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.processName}} </td>
        </ng-container>

        <ng-container matColumnDef="processStepName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'userTask' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.processStepName}} </td>
        </ng-container>

        <ng-container matColumnDef="approver">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'approver' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.approver}} </td>
        </ng-container>

        <ng-container matColumnDef="outcome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'outcome' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.outcome}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'createdDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.created | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'completedDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.completed | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>
        
        <ng-container matColumnDef="orderNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'orderNumber' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.orderNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="customerCompanyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customerCompanyName' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.customerCompanyName}} </td>
        </ng-container>

        <ng-container matColumnDef="corporateCustomerCompanyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'corporateCustomerCompanyName' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.corporateCustomerCompanyName}} </td>
        </ng-container>

        <ng-container matColumnDef="eventDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'eventDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.eventDate | amDateFormat:'DD.MM.YYYY'}} </td>
        </ng-container>

        <ng-container matColumnDef="contactFullname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contactFullname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.contactFullname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="taskRowClicked(row)"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
