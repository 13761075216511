import { NgModule } from '@angular/core';
import { ComponentsModule } from "../../components/components.module";
import { UtilsModule } from "../../utils/utils.module";
import { TileComponent } from './components/tile/tile.component';
import { HomePageComponent } from './home-page.component';


@NgModule({
  declarations: [
    HomePageComponent,
    TileComponent
  ],
  imports: [
    UtilsModule,
    ComponentsModule
  ],
  exports: [
    HomePageComponent
  ]
})
export class HomePageModule { }
