import { Injectable } from "@angular/core";
import { HealthScreening } from "./health-screening.service";
import { Subject } from "rxjs";

@Injectable()
export class CartService {
  cartModules: ModuleSelection[] = [];

  constructor() {}

  getCart() {
    return this.cartModules;
  }

  clearCart() {
    localStorage.removeItem('cartItems')
    this.cartModules = [];
  }

  addToCart(hs: HealthScreening, online: boolean, topic?: string) {
    this.cartModules.push(new ModuleSelection(hs, online, topic));
    let cartItems = {
      items: this.cartModules
    }
    localStorage.setItem('cartItems', JSON.stringify(cartItems))
  }

  hasItems() {
    return this.cartModules.length !== 0;
  }

  restoreCart() {
    if (localStorage.getItem('cartItems')) {
      this.cartModules = []
      let cartItems = JSON.parse(localStorage.getItem('cartItems'))
      for (let item of cartItems.items) {
        this.cartModules.push(item)
      }
    }
  }
}

export class ModuleSelection {
  hs: HealthScreening;
  online: boolean;
  topic: string;

  constructor(hs: HealthScreening, online: boolean, topic: string) {
    this.hs = hs;
    this.hs.visibileForCompanynameAliases = [];
    this.online = online;
    this.topic = topic;
    if (!hs.moduleType) {
      hs.moduleType = "HEALTH_MODULE";
    }
  }
}
