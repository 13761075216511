import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as moment from "moment";
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

export declare type TaskOutcome = "APPROVED" | "REJECTED" | "OK" | "QUERY";
export declare type ProcessName = "fileUploadVerification" | "registration" | "eventInquiry" | "event" | "trainerSelection";

@Injectable()
export class ProcessService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  customerResponseSteps = ["st_mail_confirmation_reservation_ag", "st_email_reminder", "st_mail_query_processed_ag", "st_mail_update_data_ag", "st_mail_update_data_reminder_ag", "st_send_order_confirmation_ag", "st_send_order_confirmation_reminder_ag"];
  lastMonthDateString = moment().subtract(31, "days").format("YYYY-MM-DD");

  constructor(private http: HttpClient, private envService: EnvService) {}

  // REST

  completeTask(taskId: string, outcome: TaskOutcome, reason: string, variables?: Map<string, string>): Observable<HttpConfig> {
    const url = this.envService.backendUrl + "/process/completeTask";
    const body = {
      id: taskId,
      outcome,
      reason,
    };
    if (variables && variables.has("query")) {
      body["query"] = variables.get("query");
    }
    if (variables && variables.has("AUTHOR")) {
      body["author"] = variables.get("AUTHOR");
    }
    if (variables && variables.has("customerId")) {
      body["customerId"] = variables.get("customerId");
    }
    return this.http.post(url, body, this.config);
  }

  public getDocumentVerificationCounter(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/files/meta?status=PENDING", this.config);
  }

  public getAllActiveTasksByProcess(processName: ProcessName): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllActiveTasksByProcess?processName=" + processName, this.config);
  }

  public getAllMyTasks(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllMyTasks", this.config);
  }

  public getAllProcessMeta(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllProcessMeta", this.config);
  }

  public getAllProcessTasks(): Observable<any[]> {
    let tasks = [];
    return new Observable((observer) => {
      this.getAllActiveTasksByProcess("eventInquiry").subscribe((res) => {
        tasks = res.body;
        this.getAllActiveTasksByProcess("event").subscribe((eventTasks) => {
          tasks = tasks.concat(eventTasks.body);
          this.getAllActiveTasksByProcess("trainerSelection").subscribe((trainerTasks) => {
            tasks = tasks.concat(trainerTasks.body);
            observer.next(tasks);
            observer.complete();
          });
        });
      });
    });
  }

  public getAllActiveTasks(taskIdFilter?: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllActiveTasks?taskIdFilter=" + taskIdFilter, this.config);
  }

  public getAllFilteredCompletedTasks(taskIdFilter?: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllCompletedTasks?taskIdFilter=" + taskIdFilter + "&from=" + this.lastMonthDateString, this.config);
  }

  public getAllCompletedTasks(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllCompletedTasks?from=" + this.lastMonthDateString, this.config);
  }

  public getAllMyCompletedTasks(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllMyCompletedTasks?from=" + this.lastMonthDateString, this.config);
  }

  public getAllMyCompletedTasksForUser(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/process/getAllMyCompletedTasksForUser?from=" + this.lastMonthDateString, this.config);
  }

  // Helper functions

  public isCustomerResponseStep(processStep: string) {
    return this.customerResponseSteps.includes(processStep);
  }
}
