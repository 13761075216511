
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError, concatMap } from "rxjs/operators";
import * as healthscreeningActions from "../actions/healthscreening.actions";
import * as fromServices from "../../services";
import { FormHelper } from "src/app/helper/form.helper";

@Injectable()
export class HealthScreeningEffects {
  constructor(
    private actions$: Actions,
    private healthscreeningService: fromServices.HealthScreeningService,
    private formHelper: FormHelper
  ) {}

  loadHealthScreening$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(healthscreeningActions.LOAD_HEALTHSCREENING),
      map((action: healthscreeningActions.LoadHealthscreening) => action.payload),
      concatMap((payload) => {
        return this.healthscreeningService.findOne(payload.id).pipe(
          map((response) => {
            return new healthscreeningActions.LoadHealthscreeningSuccess({healthscreening: response.body})
          },
            catchError((error) => of(new healthscreeningActions.LoadHealthscreeningFail(error)))
          )
        );
      })
    );
  });
}
