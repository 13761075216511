import { MetadataTableComponent } from './../components/metadata-table/metadata-table.component';
import { ProcessService } from './../../../services/process-service';
import { EventService, Chat } from './../../../services/event.service';
import { GibDialogService } from './../../../components/dialogs/gib-dialog.service';
import { CustomerService } from './../../../services/customer.service';
import { FormHelper } from './../../../helper/form.helper';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'metadata-page',
  templateUrl: './metadata-page.component.html',
  styleUrls: ['./metadata-page.component.scss']
})
export class MetadataPageComponent implements OnInit {

  @ViewChild('metaTable') eventTable: MetadataTableComponent;

  tasks: any[];
  completedTasks: any[];
  selectedTask: any;
  eventForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;
  chatElements: Chat[];
  event: Event;
  componentName = 'MetadataPageComponent';

  showButtons = true;
  task: any;

  constructor(private processService: ProcessService, private eventService: EventService,
              private formHelper: FormHelper, private customerService: CustomerService, private bottomSheet: MatBottomSheet,
              private translateService: TranslateService, private dialogService: GibDialogService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.getAllProcessMeta();
  }

  getAllProcessMeta() {
    this.processService.getAllProcessMeta().subscribe(response => {
      this.tasks = response.body;
      this.tasks.sort(this.formHelper.sortByProperty('created'));
    });
  }

}
