<div class="row votes unavailableVotes">
  <div class="col-md-6 col-lg-1">
    <gib-input [placeholder]="'Nr.'" [control]="waitingListEntry.get('id')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2">
    <gib-input [placeholder]="'firstname'" [control]="waitingListEntry.get('firstname')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2">
    <gib-input [placeholder]="'lastname'" [control]="waitingListEntry.get('lastname')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2">
    <gib-input [placeholder]="'email'" [control]="waitingListEntry.get('email')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-2" *ngIf="scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'companyname'" [control]="waitingListEntry.get('companyname')"></gib-input>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-1">
    <gib-input [placeholder]="'phonenumber'" [control]="waitingListEntry.get('phone')"></gib-input>
  </div>
  <div class="col-md-12 col-lg-2 submitButton mb-3 center">
    <!-- <button class="btn btn-secondary" type="submit" (click)="deleteWaitingListEntry()">{{ "delete" | translate }}</button> -->
  </div>
  <hr />
</div>
