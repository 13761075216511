import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOptions } from '../../../../utils/select-options';

@Component({
  selector: 'customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  salutationOptions = SelectOptions.salutationOptions;
  yesNoOptions = SelectOptions.yesNoOptions;

  @Input() form: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
  }

}
