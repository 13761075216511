import { KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment";
import { EnvService } from "../services/env.service";
import { ProfileService } from "../services/profile.service";

export function initializer(keycloak: KeycloakService, profileService: ProfileService, envService: EnvService): () => Promise<any> {
  const keycloakConfig = {
    url: envService.ssoUrl,
    realm: envService.keycloakRealm,
    clientId: "gib-pd-frontend",
  };
  return (): Promise<any> =>
    keycloak
      .init({
        config: keycloakConfig,
        initOptions: {
          flow: "implicit",
          onLoad: "check-sso",
        },
        loadUserProfileAtStartUp: false,
        enableBearerInterceptor: false,
      })
      .then((loggedIn) => {
        if (loggedIn) {
          localStorage.role = keycloak.getUserRoles().filter((role) => role.startsWith("gib_"));
          keycloak.getToken().then((token) => {
            localStorage.token = token;
          });
          profileService.getMyProfile().subscribe((res) => {
            if (res.body) {
              if (res.body.state === "INACTIVE" || res.body.state === "DELETED") {
                localStorage.role = null;
                localStorage.token = null;
                localStorage.officialName = null;
                localStorage.userId = null;
                keycloak.logout();
              } else {
                localStorage.userId = res.body.id;
                localStorage.officialName = res.body.officialName;
                localStorage.email = res.body.email;
              }
            }
          });
        } else {
          localStorage.role = null;
          localStorage.token = null;
          localStorage.officialName = null;
          localStorage.userId = null;
        }
      });
}
