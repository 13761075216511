import { BookAgainService } from "./../../../services/bookAgainService";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormHelper } from "../../../helper/form.helper";
import { EventService, CustomerEventOverviewDTO } from "../../../services/event.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { EventDetailsBottomSheetComponent } from "../components/event-details-bottom-sheet/event-details-bottom-sheet.component";
import { FileService } from "src/app/services/file.service";
import { TokenHelperService } from "src/app/services/token-helper.service";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "my-events",
  templateUrl: "./my-events.component.html",
  styleUrls: ["./my-events.component.scss"],
})
export class MyEventsComponent implements OnInit {
  events: CustomerEventOverviewDTO[];
  eventId: number;

  constructor(private dialogService: GibDialogService, private translateService: TranslateService, private eventService: EventService, private formHelper: FormHelper, private bottomSheet: MatBottomSheet, private router: Router, private bookAgainService: BookAgainService, private route: ActivatedRoute, private fileService: FileService, private tokenHelperService: TokenHelperService) {}

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    this.eventService.findMyEventOverview().subscribe((res) => {
      this.events = res.body;
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("buid")) {
          this.openEventDetailsDeepLink(paramMap.get("buid"));
        }
      });
    });
  }

  openEventDetailsDeepLink(buid: string) {
    for (const eventDTO of this.events) {
      if (eventDTO.buid === buid) {
        this.openEventDetails(eventDTO);
        return;
      }
    }
  }

  openEventDetails(eventDTO: CustomerEventOverviewDTO) {
    if (eventDTO.state == "PENDING") {
      this.confirmEvent(eventDTO);
    } else {
      this.eventService.findByBuid("" + eventDTO.buid).subscribe((res) => {
        const eventForm = this.eventService.mapEventToForm(res.body);
        this.formHelper.disableControls(eventForm);
        this.bottomSheet.open(EventDetailsBottomSheetComponent, { data: { eventForm } });
      })
    }
  }

  openEventDetailsForSignoff(eventDTO: CustomerEventOverviewDTO) {
    let userId = this.tokenHelperService.getUserId();
    this.fileService.getFileMetasByUserAndType(userId, "CUSTOMER_SIGNATURE_DOCUMENT").subscribe((res) => {
      if (res && res[0]) {
        this.eventService.findByBuid("" + eventDTO.buid).subscribe((res) => {
          const eventForm = this.eventService.mapEventToForm(res.body);
          this.formHelper.disableControls(eventForm);
          this.bottomSheet.open(EventDetailsBottomSheetComponent, { data: { eventForm } });
        })
      } else {
        let title = this.translateService.instant('CUSTOMER_SIGNATURE_DOCUMENT_MISSING_TITLE');
        let text = this.translateService.instant('CUSTOMER_SIGNATURE_DOCUMENT_MISSING_TEXT');
        this.dialogService.openDialog(title, text, 'warning')
      }
    });
  }

  confirmEvent(eventDTO: CustomerEventOverviewDTO) {
    const buid = eventDTO.buid;
    this.router.navigate(["/event-inquiry/customer-response/submit"], {
      queryParams: {
        buid,
        "activity-id": "st_mail_confirmation_reservation_ag",
      },
    });
  }

  bookEventAgain(eventDto: CustomerEventOverviewDTO) {
    this.eventService.findByBuid(eventDto.buid).subscribe((res) => {
      const eventForm = this.eventService.copyEventForm(this.eventService.mapEventToForm(res.body));
      this.bookAgainService.setEventForm(eventForm);
      this.router.navigate(["/event-inquiry-page/FORM"]);
    });
  }
}
