import { GibDialogService } from 'src/app/components/dialogs/gib-dialog.service';
import { FileMeta, FileService } from '../../services/file.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gib-image-handler',
  templateUrl: './gib-image-handler.component.html',
  styleUrls: ['./gib-image-handler.component.scss']
})
export class GibImageHandlerComponent {
  _images: FileMeta[];
  imageUrls: string[];

  @Output() fileDeleted = new EventEmitter < null > ();
  @Output() imageUrlsGenerated = new EventEmitter<string[]> ();

  @Input() set images(images: FileMeta[]) {
    this._images = images;
    this.createImageUrls();
  }

  get images(): FileMeta[] {
    return this._images;
  }

  constructor(private dialogService: GibDialogService, private fileService: FileService,
    private translateService: TranslateService) { }

  createImageUrls() {
    this.imageUrls = this.fileService.createRessourceUrls(this._images);
    this.imageUrlsGenerated.emit(this.imageUrls);
  }

  openDeleteConfirmation(filemeta: FileMeta) {
    const fileName = filemeta.fileName;
    const title = this.translateService.instant('deleteFileConfirmationTitle');
    const text = this.translateService.instant('deleteFileConfirmationText', { fileName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteFile(filemeta.id));
  }

  deleteFile(id: number) {
    this.fileService.delete(id).subscribe(() => {
      this.fileDeleted.emit(null);
    });
  }

}
