import * as fromActiveEvents from "../actions/active-event.actions";
import { EventOverviewDTO } from "src/app/services/event-overview.service";

export interface ActiveEventsState {
  entities: { [period: string]: { [id: string]: EventOverviewDTO } };
  loading: boolean;
  loaded: boolean;
}

export const initialState: ActiveEventsState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromActiveEvents.ActiveEventActions): ActiveEventsState {
  switch (action.type) {
    case fromActiveEvents.LOAD_ACTIVE_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActiveEvents.LOAD_ACTIVE_EVENTS_SUCCESS: {
      const events = action.payload;
      const period = action.period;
      const entities = events.reduce(
        (entities: { [period: string]: { [id: string]: EventOverviewDTO } }, event: EventOverviewDTO) => {
          return {
            ...entities,
            [period]: {
              ...entities[period],
              [event.reduxId]: event,
            },
          };
        },
        {
          ...state.entities,
        }
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromActiveEvents.UPDATE_EVENT_FAIL:
    case fromActiveEvents.LOAD_ACTIVE_EVENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromActiveEvents.REMOVE_ACTIVE_EVENTS: {
      const period = action.period;
      const { [period]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }
    case fromActiveEvents.CLEAR_ACTIVE_EVENTS: {
      return {
        ...state,
        entities: {},
      };
    }
    case fromActiveEvents.UPDATE_EVENT_SUCCESS: {
      const event = action.payload;
      const period: string = event.eventDate.substring(5, 7) + "/" + event.eventDate.substring(0, 4);
      const entities = {
        ...state.entities,
        [period]: {
          ...state.entities[period],
          [event.reduxId]: event,
        },
      };
      return {
        ...state,
        entities,
      };
    }
  }
  return state;
}

export const getActiveEventsEntities = (state: ActiveEventsState) => state.entities;
export const getActiveEventsLoading = (state: ActiveEventsState) => state.loading;
export const getActiveEventsLoaded = (state: ActiveEventsState) => state.loaded;
