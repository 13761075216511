<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="alias">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "alias" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.alias }}</td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "username" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.username }}</td>
    </ng-container>

    <ng-container matColumnDef="emergencyPhone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "emergencyPhone" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.emergencyPhone | translate }}</td>
    </ng-container>

    <ng-container matColumnDef="moduleType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "moduleType" | translate }}</th>

      <td mat-cell *matCellDef="let row">{{ row.moduleType | translate }}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "active" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <gib-check-close [value]="row.active"></gib-check-close>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectMeeting(row)">edit</i>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteMeetingConfirmation(row)">delete</i>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="meeting-details" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <meeting-table-details [meeting]="element"></meeting-table-details>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="meeting-detail-row"></tr>
  </table>
  <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
