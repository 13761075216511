import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { EnvService } from "./env.service";
import moment from "moment";

export interface AccountingOverviewDto {
  eihsId: number;
  eventId: number;
  buid: string;
  eventDate: Date;
  orderNumber: string;
  deliveryType: string;
  moduleName: string;
  moduleType: string;
  topic: string;
  durationInMilliseconds: number;
  targetGroup: string;
  corporateCustomerCompanyName: string;
  location: string;
  region: string;
  teamnumber: string;
  presentationEnglish: Boolean;
  anonymousEvaluation: Boolean;
  consumableMaterials: Boolean;
  costPercentage: number;
  costTotal: number;
  accountingStatus: string;
  accountingStatusChange: string;
  paymentStatus: string;
  paymentDate: string;
  invoiceSentDate: string;
}

export class PaymentBuidDto {
  buidList: string[];
}

@Injectable({
  providedIn: "root",
})
export class AccountingService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getTkEvents(from: string, to: string): Observable<AccountingOverviewDto[]> {
    return this.http.get<AccountingOverviewDto[]>(`${this.envService.backendUrl}/accounting/getTkEvents?from=${from}&to=${to}`);
  }

  getGibEvents(from: string, to: string): Observable<AccountingOverviewDto[]> {
    return this.http.get<AccountingOverviewDto[]>(`${this.envService.backendUrl}/accounting/getGibEvents?from=${from}&to=${to}`);
  }

  getPaymentApproved(from: string, to: string): Observable<AccountingOverviewDto[]> {
    return this.http.get<AccountingOverviewDto[]>(`${this.envService.backendUrl}/accounting/getPaymentApproved?from=${from}&to=${to}`);
  }

  getPaymentOpen(from: string, to: string): Observable<AccountingOverviewDto[]> {
    return this.http.get<AccountingOverviewDto[]>(`${this.envService.backendUrl}/accounting/getPaymentOpen?from=${from}&to=${to}`);
  }

  getPaymentSent(from: string, to: string): Observable<AccountingOverviewDto[]> {
    return this.http.get<AccountingOverviewDto[]>(`${this.envService.backendUrl}/accounting/getPaymentSent?from=${from}&to=${to}`);
  }

  openPayment(dto: AccountingOverviewDto): Observable<any> {
    return this.http.post(`${this.envService.backendUrl}/accounting/openPayment`, dto);
  }

  sendPayment(dto: PaymentBuidDto): Observable<any> {
    return this.http.post(`${this.envService.backendUrl}/accounting/sendPayment`, dto);
  }

  approvePayment(dto: PaymentBuidDto): Observable<any> {
    return this.http.post(`${this.envService.backendUrl}/accounting/approvePayment`, dto);
  }

  public getGibExcelExport(): Observable<any> {
    const from = moment().subtract(1, "years").format("YYYY") + "-01-01";
    const to = moment().add(1, "years").format("YYYY") + "-12-31";
    return this.http.get(this.envService.backendUrl + `/accounting/getGibExcelExport?from=${from}&to=${to}`, { responseType: "blob" });
  }

  public getTkExcelExport(): Observable<any> {
    const from = moment().subtract(1, "years").format("YYYY") + "-01-01";
    const to = moment().add(1, "years").format("YYYY") + "-12-31";
    return this.http.get(this.envService.backendUrl + `/accounting/getTkExcelExport?from=${from}&to=${to}`, { responseType: "blob" });
  }

  public getEventPdf(buid: string): Observable<any> {
    return this.http.get(this.envService.backendUrl + `/accounting/getEventPdf/${buid}`, { responseType: "blob" });
  }
}
