<div class="row">
  <div class="col-sm-4">
    <br>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-7">

  </div>
  <div class="col-sm-1">
    <!-- <a class="btn btn-secondary add-event-btn" (click)="addEvent()">+</a> -->
  </div>

  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

         <ng-container matColumnDef="process">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'process' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.processName}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'userTask' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.task.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'description' | translate}} </th>
          <td mat-cell *matCellDef="let row" style="max-width: 600px;"> {{row.task.description}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'createdDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.task.created | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="taskRowClicked(row)"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
