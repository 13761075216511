import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {
  _healthScreeningObject: any;
  userRole: string;
  @Output() emitMoreInfo: EventEmitter<{ id: string; topic: string }> = new EventEmitter();
  @Output() emitAddCart = new EventEmitter();

  @Input() set healthScreeningObject(object: any) {
    this._healthScreeningObject = object;
    this.handleShortdescription();
    this.handleMeasuringDevice();
  }

  get healthScreeningObject() {
    return this._healthScreeningObject;
  }

  @Input() imageUrls: string[];
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
  }

  handleShortdescription() {
    if (this._healthScreeningObject.shortDescription && this._healthScreeningObject.shortDescription.length > 150) {
      let value = this._healthScreeningObject.shortDescription;
      value = value.substring(0, 150);
      value = value.substring(0, value.lastIndexOf(" "));
      value += "...";
      this._healthScreeningObject.shortDescription = value;
    }
  }

  handleMeasuringDevice() {
    if (this._healthScreeningObject.measuringDevice && this._healthScreeningObject.measuringDevice.length > 50) {
      let value = this._healthScreeningObject.measuringDevice;
      value = value.substring(0, 50);
      value = value.substring(0, value.lastIndexOf(" "));
      value += "...";
      this._healthScreeningObject.measuringDevice = value;
    }
  }

  routeToInfoPage(id: string, topic: string) {
    this.emitMoreInfo.emit({ id, topic });
  }

  addModuleToCart(hs: any) {
    this.emitAddCart.emit(hs);
  }

  getTopics(): string {
    if (this._healthScreeningObject.topic) {
      const topicsArray = this._healthScreeningObject.topic.split(",");
      let topics: string = "";
      topicsArray.forEach((topic) => {
        topics = topics + this.translateService.instant(topic.trim()) + " / ";
      });
      return topics.substring(0, topics.trim().length - 1);
    }
    return "";
  }
}
