import { UntypedFormControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
  specialCharacterValidator(control: UntypedFormControl): { [key: string]: boolean } {
    const nameRegexp: RegExp = /[!#$%^&*()+\=\[\]{};':"\\|,<>\/?ÄäÜüÖöß]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
  }

  cannotContainSpace(control: UntypedFormControl): ValidationErrors | null {
    if ((control.value as string).indexOf(" ") >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }

  email(control: UntypedFormControl): { [key: string]: boolean } {
    const emailRegexp: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    if (control.value && !emailRegexp.test(control.value)) {
      return { email: true };
    }
  }

  password(control: UntypedFormControl): { [key: string]: boolean } {
    const passwordRegExp: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[A-Za-z\d\W_]{12,}$/;
    if (control.value && !passwordRegExp.test(control.value)) {
      return { password: true };
    }
  }
}
