import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRouterStore from "@ngrx/router-store";
import * as fromRouter from "./router.reducer";
import * as fromActiveEvents from "./active-event.reducer";
import * as fromCancelledEvents from "./cancelled-event.reducer";
import * as fromInactiveEvents from "./inactive-event.reducer";
import * as fromCustomer from "./customer.reducer";
import * as fromTkController from "./tk-controller.reducer";
import * as fromEmployee from "./employee.reducer";
import * as fromHealthScreening from "./healthscreening.reducer";

export interface GibState {
  routerReducer: fromRouter.State;
  activeEvents: fromActiveEvents.ActiveEventsState;
  cancelledEvents: fromCancelledEvents.CancelledEventsState;
  inactiveEvents: fromInactiveEvents.InactiveEventsState;
  customerState: fromCustomer.CustomerState;
  tkControllerState: fromTkController.TkControlerState;
  employeeState: fromEmployee.EmployeeState;
  healthscreeningState: fromHealthScreening.HealthScreeningState;
}

export const reducers: ActionReducerMap<GibState> = {
  routerReducer: fromRouterStore.routerReducer,
  activeEvents: fromActiveEvents.reducer,
  cancelledEvents: fromCancelledEvents.reducer,
  inactiveEvents: fromInactiveEvents.reducer,
  customerState: fromCustomer.reducer,
  tkControllerState: fromTkController.reducer,
  employeeState: fromEmployee.reducer,
  healthscreeningState: fromHealthScreening.reducer,
};

export const getGibState = createFeatureSelector<GibState>("gib");
