<div class="spacer">
  <gib-expansion-panel [title]="faqForm.get('title').value">
    <p [innerHTML]="faqForm.get('description').value"></p>
    <div class="img-wrapper center" *ngIf="imageUrls && imageUrls.length === 1">
      <img src="{{ imageUrls }}" />
    </div>
    <br />
    <div class="row" *ngIf="imageUrls && imageUrls.length > 1">
      <div class="col-sm-12 col-lg-12 col-xl-12 center">
        <h5>{{ "images" | translate }}</h5>
      </div>
      <div class="slideshow-wrapper" *ngIf="imageUrls && imageUrls.length > 1">
        <gib-slideshow [imageUrls]="imageUrls"></gib-slideshow>
        <!-- <slideshow [minHeight]="'400px'" *ngIf="imageUrls && imageUrls.length > 1" dotColor="var(--secondary)" showDots="true" [minHeight]="'200px'" [imageUrls]="imageUrls"></slideshow> -->
      </div>
    </div>
    <div class="row" *ngIf="videos && videos.length !== 0">
      <div class="col-sm-12 center">
        <h5>{{ "videos" | translate }}</h5>
        <div class="col-sm-12 center">
          <gib-video-handler [videos]="videos" (fileDeleted)="loadVideos()" [enableVideoDownload]="true"></gib-video-handler>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="documents && documents.length !== 0">
      <div class="col-sm-12 center">
        <h5>{{ "documents" | translate }}</h5>
        <div class="col-sm-12 center">
          <ul class="list-group">
            <li *ngFor="let document of documents" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              {{ document.title }}
              <span>
                <i class="material-icons mr-4" (click)="onDownload(document)" style="cursor: pointer">get_app</i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </gib-expansion-panel>
</div>
