import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarService, CalendarEntryDto } from "../../../services/calendar.service";
import { EmployeeService } from "../../../services/employee.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ValueLabel } from "../../../components/value-label";
import { TrainerAvailablityFormComponent } from "../components/trainer-availablity-form/trainer-availablity-form.component";
import * as moment from "moment";

@Component({
  selector: "trainer-availability-overview",
  templateUrl: "./trainer-availability-overview.component.html",
  styleUrls: ["./trainer-availability-overview.component.scss"],
})
export class TrainerAvailabilityOverviewComponent implements OnInit {
  @ViewChild("trainerAvailabilityForm") trainerAvailabilityForm: TrainerAvailablityFormComponent;

  selectedTrainer: UntypedFormControl = new UntypedFormControl(null);
  trainerOptions: ValueLabel[] = [];

  entriesForTrainer: CalendarEntryDto[] = [];
  entryToEdit: CalendarEntryDto;
  userRole: string;

  constructor(private employeeService: EmployeeService, private calendarService: CalendarService) {}

  ngOnInit() {
    this.employeeService.findByRoles(["gib_trainer"]).subscribe((res) => {
      for (const trainer of res.body) {
        this.trainerOptions.push({ label: trainer.fullName, value: trainer.id });
      }
    });
    this.selectedTrainer.valueChanges.subscribe((res) => {
      if (res) {
        this.calendarService.getEntriesForUser(res).subscribe((entries) => {
          this.entriesForTrainer = entries.body;
        });
      }
    });
    this.userRole = localStorage.getItem("role");
  }

  reloadEntriesForTrainer() {
    this.calendarService.getEntriesForUser(this.selectedTrainer.value).subscribe((entries) => {
      this.entriesForTrainer = entries.body;
    });
  }

  emptyEntryClicked(date: any) {
    if (this.trainerAvailabilityForm && this.trainerAvailabilityForm.entryForm && !this.trainerAvailabilityForm.entryForm.get("id").value) {
      const dateToSet = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
      this.trainerAvailabilityForm.entryForm.removeControl("date");
      this.trainerAvailabilityForm.entryForm.addControl("date", new UntypedFormControl(dateToSet));
      this.trainerAvailabilityForm.entryForm.get("date").setValidators([Validators.required]);
    }
    if (this.userRole !== "gib_trainer") {
      this.entryToEdit = null;
    }
  }

  editEntry(entry: any) {
    this.entryToEdit = entry;
    this.entryToEdit.userId = this.selectedTrainer.value;
    this.entryToEdit.role = "gib_trainer";
  }

  entryEditCanceledClicked() {
    this.entryToEdit = null;
  }
}
