<mat-card *ngIf="form">
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-6">
          <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
          <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
          <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
          <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
          <gib-input [placeholder]="'username'" [control]="form.get('username')"></gib-input>
          <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
          <gib-input [placeholder]="'phonenumber'" [control]="form.get('phonenumber')"></gib-input>
          <gib-input [placeholder]="'mobilenumber'" [control]="form.get('mobilenumber')"></gib-input>
          <gib-input [placeholder]="'companyname'" [control]="form.get('companyname')"></gib-input>
          <gib-input [placeholder]="'companynameAlias'" [control]="form.get('companynameAlias')"></gib-input>
        </div>
        <div class="col-sm-6">
          <h6> {{ addressType | translate }}</h6>
          <gib-input matInput [placeholder]="'street'" [control]="form.get('addresses').get('0').get('street')"></gib-input>
          <gib-input matInput [placeholder]="'streetno'" [control]="form.get('addresses').get('0').get('number')"></gib-input>
          <gib-input matInput [placeholder]="'zip'" [control]="form.get('addresses').get('0').get('zip')"></gib-input>
          <gib-input matInput [placeholder]="'city'" [control]="form.get('addresses').get('0').get('city')"></gib-input>

          <h6> {{ 'regions' | translate }}</h6>
          <gib-input matInput [placeholder]="'region'" [control]="form.get('region')"></gib-input>
          <gib-input matInput [placeholder]="'teamNr'" [control]="form.get('teamnumber')"></gib-input>
          <mat-divider></mat-divider>
          <gib-radio-button-group [label]="'gpsUser'" [control]="form.get('gpsUser')" [valueLabels]="yesNoOptions"></gib-radio-button-group>
          <gib-textarea [placeholder]="'COMMENT'" [control]="form.get('comment')"></gib-textarea>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>