<!-- <mat-card class="sticky"> -->
<mat-card>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-6">
          <h6>{{'masterData' | translate}}</h6>
          <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
          <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
          <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
          <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
          <gib-input [placeholder]="'username'" [control]="form.get('username')"></gib-input>
          <gib-select [placeholder]="'role'" [control]="form.get('role')" [options]="roles"></gib-select>
          <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
          <gib-input [placeholder]="'phonenumber'" [control]="form.get('phonenumber')"></gib-input>
        </div>
        <div class="col-sm-6">
          <h6>{{'ADRESS' | translate}}</h6>
          <gib-input [placeholder]="'street'" [control]="form.get('addresses').get('0').get('street')"></gib-input>
          <gib-input [placeholder]="'streetno'" [control]="form.get('addresses').get('0').get('number')"></gib-input>
          <gib-input [placeholder]="'zip'" [control]="form.get('addresses').get('0').get('zip')"></gib-input>
          <gib-input [placeholder]="'city'" [control]="form.get('addresses').get('0').get('city')"></gib-input>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <button mat-button (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button *ngIf="!_employee.id" type="button" class="btn btn-primary" (click)="addEmployeeClicked()">{{'ADD' | translate}}</button>
    <button *ngIf="_employee.id" type="button" class="btn btn-primary" (click)="updateEmployeeClicked()">{{'save' | translate}}</button>
  </mat-card-footer>
</mat-card>
