
import * as fromHealthScreenings from "../actions/healthscreening.actions";
import { HealthScreening } from "src/app/services/health-screening.service";

export interface HealthScreeningState {
  healthscreening: HealthScreening;
  loading: boolean;
  loaded: boolean;
}

export const initialState: HealthScreeningState = {
  healthscreening: null,
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromHealthScreenings.HealthScreeningActions): HealthScreeningState {
  switch (action.type) {
    case fromHealthScreenings.LOAD_HEALTHSCREENING: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromHealthScreenings.LOAD_HEALTHSCREENING_SUCCESS: {
      return {
        ...state,
        healthscreening: action.payload.healthscreening,
        loading: false,
        loaded: true,
      };
    }
    case fromHealthScreenings.LOAD_HEALTHSCREENING_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getHealthScreenings = (state: HealthScreeningState) => state.healthscreening;
