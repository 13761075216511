import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { Employee, EmployeeService } from "../../../../services/employee.service";
import { KeycloakConnectorService } from "../../../../services/keycloak-connector.service";

@Component({
  selector: "employees-table",
  templateUrl: "./employees-table.component.html",
  styleUrls: ["./employees-table.component.scss"],
})
export class EmployeesTableComponent implements OnInit {
  sort;
  emps: Employee[];
  expandedElement: Employee | null;
  isExpandable = true;
  canEdit = false;

  @Output() employeeSelected = new EventEmitter<Employee>();
  @Output() addEmployeeClicked = new EventEmitter();
  @Output() employeeDeleted = new EventEmitter();

  @Input() set employees(employees: Employee[]) {
    this.emps = employees;
    this.dataSource = new MatTableDataSource(employees);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  get employees(): Employee[] {
    return this.emps;
  }

  displayedColumns: string[] = ["lastname", "firstname", "role", "phone", "email", "edit", "delete"];
  dataSource: MatTableDataSource<Employee>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private cdr: ChangeDetectorRef, private dialogService: GibDialogService, private translateService: TranslateService, private employeeService: EmployeeService, private keycloakConnector: KeycloakConnectorService) {}

  ngOnInit() {
    const role = localStorage.role;
    if (role === "gib_director" || role === "gib_admin") {
      this.canEdit = true;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  resetDisplayedColumns() {
    this.displayedColumns = ["lastname", "firstname", "role", "phone", "email", "edit", "delete"];
    this.isExpandable = true;
  }

  addEmployee() {
    this.displayedColumns = ["lastname", "firstname", "phone", "edit", "delete"];
    this.addEmployeeClicked.emit(null);
    this.expandedElement = null;
    this.isExpandable = false;
  }

  selectEmployee(emp: Employee) {
    this.displayedColumns = ["lastname", "firstname", "phone", "edit", "delete"];
    this.employeeService.findOne(emp.id).subscribe((res) => {
      this.employeeSelected.emit(res.body);
      this.expandedElement = null;
      this.isExpandable = false;
    });
  }

  openDeleteEmployeeConfirmation(emp: Employee) {
    const fullName = this.employeeService.getFullName(emp);
    const title = this.translateService.instant("USER_DELETE_CONFIRMATION_TITLE");
    const text = this.translateService.instant("USER_DELETE_CONFIRMATION_TEXT", { employeeName: fullName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteEmployee(emp.id));
  }

  deleteEmployee(id: string) {
    this.employeeService.delete(id).subscribe(() => {
      this.keycloakConnector.deleteUser(id).subscribe(() => {
        this.employeeDeleted.emit(null);
      });
    });
  }
}
