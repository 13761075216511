<div *ngIf="!successful" class="h-screen">
  <form class="form-group">
    <h2>{{ "gibHomeWelcome" | translate }}</h2>
    <gib-input [placeholder]="'password'" [control]="codeForm.get('code')"></gib-input>
    <button class="btn btn-primary" type="submit" (click)="getScheduleEntries()">submit</button>
  </form>
</div>
<div *ngIf="successful && this.eventParticipants" class="form-group">
  <gib-fieldset *ngIf="this.eventParticipants?.length > 0" [title]="'participantList'">
    <event-participant *ngFor="let participant of eventParticipants" [eventParticipant]="participant" [afgView]="successful"></event-participant>
  </gib-fieldset>
</div>
