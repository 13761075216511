import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ScheduleEntry, WaitingListEntry } from "./event.service";
import { EnvService } from "./env.service";

@Injectable()
export class WaitingListService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private envService: EnvService) {}

  getWaitingList(eihsId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/waitinglist?eihsId=" + eihsId, this.config);
  }

  createWaitingListEntries(waitingListEntry: WaitingListEntry): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/public/waitinglist", waitingListEntry, this.config);
  }

  deleteWaitingListEntry(waitingListEntryId: number): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/waitinglist?entryId=" + waitingListEntryId, this.config);
  }

  downloadExcel(eihsId: number) {
    return this.http.get(this.envService.backendUrl + "/waitinglist/getWaitingListExcelExport?eihsId=" + eihsId, { responseType: "blob" });
  }
}
