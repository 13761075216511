import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class EventEmitterService {
    private _listeners = new Subject<any>();

    listen(): Observable<any> {
       return this._listeners.asObservable();
    }

    emitEvent() {
       this._listeners.next();
    }

}