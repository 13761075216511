import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from "@angular/core";
import { Meeting } from "../../../../services/meeting.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { trigger, state, transition, style, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";

@Component({
  selector: "meeting-table",
  templateUrl: "./meeting-table.component.html",
  styleUrls: ["./meeting-table.component.scss"],
  animations: [
    trigger("detailExpand", [state("collapsed, void", style({ height: "0px", minHeight: "0" })), state("expanded", style({ height: "*" })), transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")), transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))]),
  ],
})
export class MeetingTableComponent implements OnInit {
  sort;
  _meetings: Meeting[];

  @Input() set meetings(meetings: Meeting[]) {
    this._meetings = meetings;
    this.dataSource = new MatTableDataSource(meetings);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  @Output() meetingSelected = new EventEmitter<Meeting>();
  @Output() deleteMeetingClicked = new EventEmitter<Meeting>();

  displayedColumns: string[] = ["alias", "username", "emergencyPhone", "moduleType", "active", "edit", "delete"];
  dataSource: MatTableDataSource<Meeting>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit() {}

  selectMeeting(meeting: Meeting) {
    this.meetingSelected.emit(meeting);
  }

  openDeleteMeetingConfirmation(meeting: Meeting) {
    const roomName = meeting.alias;
    const title = this.translateService.instant("deleteMeetingConfirmationTitle");
    const text = this.translateService.instant("deleteMeetingConfirmationText", { roomName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteMeetingClicked.emit(meeting));
  }
}
