import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gib-expansion-panel',
  templateUrl: './gib-expansion-panel.component.html',
  styleUrls: ['./gib-expansion-panel.component.scss']
})
export class GibExpansionPanelComponent implements OnInit {

  @Input() title: string;
  @Input() hasError: boolean;

  constructor() { }

  ngOnInit() {
  }

  

}
