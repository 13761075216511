import { Component, Inject, Input } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  title: string;
  text: string;
  eventPrivacyText: string;
  thirdPartyPrivacyText: string;
  votingParticipant: UntypedFormGroup;
  confirmAction: any;
}

@Component({
  selector: "gib-privacy-dialog",
  templateUrl: "./gib-privacy-dialog.component.html",
  styleUrls: ["./gib-privacy-dialog.component.scss"],
})
export class GibPrivacyDialogComponent {
  votingParticipant: UntypedFormGroup;
  isDisabled: boolean = true;

  constructor(public dialogRef: MatDialogRef<GibPrivacyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.votingParticipant) {
      this.votingParticipant = data.votingParticipant;
      this.votingParticipant.get("acceptedEventPrivacy").setValue(false);
      this.votingParticipant.get("acceptedEventPrivacy").enable();
      this.votingParticipant.get("acceptedThirdPartyPrivacy").setValue(false);
      this.votingParticipant.get("acceptedThirdPartyPrivacy").enable();
    }
  }

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.votingParticipant) {
      this.data.confirmAction(this.votingParticipant);
    }
    this.dialogRef.close();
  }
}
