<div class="card">
  <div class="card-body">
    <form [formGroup]="eventForm">
      <div class="form-group">
        <event-timing [eventForm]="eventForm"></event-timing>
        <event-customer-data [eventForm]="eventForm" [buid]="buid" [showPrintButtons]="showPrintButtons" (customerSelected)="module.updateModuleOptions($event, false)" (clearCustomer)="module.updateModuleOptions($event, true)"></event-customer-data>
        <event-location [eventForm]="eventForm"></event-location>
        <gib-module-selection [eventForm]="eventForm" #module [modulesControl]="eventForm.get('eventHealthScreenings')"></gib-module-selection>
        <event-further-information [eventForm]="eventForm"></event-further-information>
        <event-comment [formType]="'inquiry'" [eventForm]="eventForm"></event-comment>
        <div *ngIf="internalNoteForm && (this.userRole === 'gib_admin' || this.userRole === 'gib_director' || this.userRole === 'gib_employee')">
          <hr />
          <internal-note [internalNoteForm]="internalNoteForm"></internal-note>
        </div>
      </div>
    </form>
  </div>
</div>
