import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ValueLabel } from "../../value-label";

export interface DialogCancelationData {
  title: string;
  text: string;
}

@Component({
  selector: "gib-cancelation-dialog",
  templateUrl: "./gib-cancelation-dialog.component.html",
  styleUrls: ["./gib-cancelation-dialog.component.sass"],
})
export class GibCancelationDialogComponent {
  cancelationDate: UntypedFormControl = new UntypedFormControl(moment(new Date()), [Validators.required]);
  forceDelete: UntypedFormControl = new UntypedFormControl(false, [Validators.required])
  reason: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  deleteOptions: ValueLabel[] = [
    { value: "false", label: "cancelation" },
    { value: "true", label: "delete" },
  ];

  constructor(public dialogRef: MatDialogRef<GibCancelationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogCancelationData) {}

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.reason.valid && this.cancelationDate.valid) {
      this.dialogRef.close({ reason: this.reason.value, cancelationDate: this.cancelationDate.value, forceDelete: this.forceDelete.value });
    } else {
      if (!this.cancelationDate.valid) {
        this.cancelationDate.markAsTouched();
      }
      if (!this.reason.valid) {
        this.reason.markAsTouched();
      }
    }
  }
}
