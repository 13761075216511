import { AddressType } from "src/app/services/keycloak-connector.service";
import { ValueLabel } from "./../../../../components/value-label";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SelectOptions } from "src/app/utils/select-options";
import { GibDialogService } from "./../../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "./../../../../helper/form.helper";
import { Customer, CustomerService } from "./../../../../services/customer.service";
import { KeycloakConnectorService } from "./../../../../services/keycloak-connector.service";
import { CustomValidators } from "src/app/utils/custom-validator";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "add-customer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.scss"],
})
export class AddCustomerComponent implements OnInit {
  custmr: Customer;
  form: UntypedFormGroup;
  salutationOptions = SelectOptions.salutationOptions;
  yesNoOptions = SelectOptions.yesNoOptions;

  @Output() customerAdded = new EventEmitter();
  @Input() addressType: AddressType;

  @Input() set customer(cust: Customer) {
    this.custmr = cust;
    this.form = this.initFormGroup();
    this.initFormListener();
  }

  get customer(): Customer {
    return this.custmr;
  }

  constructor(
    private configService: ConfigurationService,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private formHelper: FormHelper,
    private keycloakConnector: KeycloakConnectorService,
    private dialogService: GibDialogService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
  }

  initFormGroup(): UntypedFormGroup {
    const customerForm = this.customerService.mapCustomerToForm(this.custmr);
    customerForm.get("salutation").setValidators(Validators.required);
    customerForm.get("firstname").setValidators(Validators.required);
    customerForm.get("lastname").setValidators(Validators.required);
    customerForm.get("username").setValidators(Validators.required);
    customerForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    customerForm.get("phonenumber").setValidators(Validators.required);
    customerForm.get("companyname").setValidators(Validators.required);
    customerForm.get("companynameAlias").setValidators([Validators.required, this.customValidators.cannotContainSpace]);
    return customerForm;
  }

  initFormListener() {
    this.form.get("firstname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("lastname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
    this.form.get("lastname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("firstname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
  }

  presetUsername() {
    const newUsername = this.form.get("firstname").value.charAt(0).toLowerCase() + this.form.get("lastname").value.charAt(0).toLowerCase() + "-gib";
    this.form.get("username").setValue(newUsername);
  }

  cancel() {
    // do nothing, just emit with null to close the panel
    this.customerAdded.emit(null);
  }

  addCustomerClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "CUSTOMER_ADDED_ERROR_TITLE", () => this.addCustomer());
  }

  addCustomer() {
    this.custmr = this.customerService.mapFormToCustomer(this.form);
    this.keycloakConnector
      .createUser(this.custmr)
      .then((userId) => {
        this.custmr.id = userId;
        this.configService.findAll().subscribe(res => {
          let defaultPassword = res.body.find(item => item.key === 'DEFAULT_PASSWORD').value;
          this.keycloakConnector.resetPassword(this.custmr.id, defaultPassword, true);
          this.customerService.create(this.custmr).subscribe(
            (res) => {
              this.openSuccessFullAddedDialog();
              this.customerAdded.emit(null);
            },
            (error) => {
              this.keycloakConnector.deleteUser(userId).subscribe(() => {});
            }
          );
        });
      })
      .catch((err) => {
        this.openErrorAddedDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      });
  }

  updateCustomerClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "CUSTOMER_EDIT_ERROR_TITLE", () => this.updateCustomer());
  }

  updateCustomer() {
    this.custmr = this.customerService.mapFormToCustomer(this.form);

    this.keycloakConnector.updateUser(this.custmr).then(
      () => {
        this.customerService.update(this.custmr).subscribe((res) => {
          this.openSuccessFullEditDialog();
          this.customerAdded.emit(null);
        });
      },
      (err) => {
        this.openErrorEditDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      }
    );
  }

  private openSuccessFullAddedDialog(): void {
    const custFullName = this.customerService.getFullName(this.customer);
    const title = this.translateService.instant("CUSTOMER_ADDED_SUCCESS_TITLE");
    const text = this.translateService.instant("CUSTOMER_ADDED_SUCCESS_TEXT", { customerName: custFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openSuccessFullEditDialog(): void {
    const custFullName = this.customerService.getFullName(this.customer);
    const title = this.translateService.instant("CUSTOMER_EDIT_SUCCESS_TITLE");
    const text = this.translateService.instant("CUSTOMER_EDIT_SUCCESS_TEXT", { customerName: custFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openErrorAddedDialog(errorMessage: string): void {
    const title = this.translateService.instant("CUSTOMER_ADDED_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  private openErrorEditDialog(errorMessage: string): void {
    const title = this.translateService.instant("CUSTOMER_EDIT_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  addAddressClicked() {}
}
