<div class="row d-flex padding justify-content-between">
  <h2 *ngIf="type === 'INDOOR_OUTDOOR'">{{ "healthscreeningOverview" | translate }}</h2>
  <h2 *ngIf="type === 'ONLINE'">{{ "onlineHealthscreeningOverview" | translate }}</h2>
  <div class="row d-flex actions">
    <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
  </div>
</div>
<div class="row d-flex padding justify-content-between">
  <div class="col-lg-6">
    <gib-multiselect *ngIf="filterDataForm.get('topics').value" [placeholder]="'filter'" class="w-100" [control]="filterDataForm.get('topics')" [options]="topicOptions"></gib-multiselect>
  </div>
  <div class="col-lg-4">
  </div>
  <div class="col-lg-2">
    <div class="row d-flex actions">
      <mat-form-field>
        <input matInput [(ngModel)]="searchTerm" placeholder="{{ 'search' | translate }}" />
      </mat-form-field>
    </div>
  </div>
</div>

<div class="row w-100 d-flex healthscreeningPage mobilePadding box">
  <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let healthscreening of filteredHealthscreenings">
    <preview [healthScreeningObject]="healthscreening" (emitAddCart)="addModuleToCart($event)" (emitMoreInfo)="goToInfoPage($event)" [imageUrls]="healthscreening.imageUrls"></preview>
  </div>
</div>
