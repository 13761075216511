import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Meeting, MeetingService } from "../../../../services/meeting.service";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { FormHelper } from "../../../../helper/form.helper";
import { SelectOptions } from "src/app/utils/select-options";

@Component({
  selector: "meeting-form",
  templateUrl: "./meeting-form.component.html",
  styleUrls: ["./meeting-form.component.scss"],
})
export class MeetingFormComponent implements OnInit {
  meetingForm: UntypedFormGroup;
  moduleTypes = SelectOptions.moduleTypes;

  @Output() meetingCreatedEdited = new EventEmitter<UntypedFormGroup>();

  @Input() set meeting(meeting: Meeting) {
    this.meetingForm = this.createForm(meeting);
  }

  constructor(private meetingService: MeetingService, private formHelper: FormHelper) {}

  ngOnInit() {}

  createForm(meeting: Meeting) {
    const fg = this.meetingService.mapMeetingToForm(meeting);
    fg.get("alias").setValidators(Validators.required);
    fg.get("username").setValidators(Validators.required);
    fg.get("password").setValidators(Validators.required);
    fg.get("emergencyPhone").setValidators(Validators.required);
    fg.get("moduleType").setValidators(Validators.required);
    return fg;
  }

  createEditMeeting() {
    this.formHelper.isFormValidElseShowErrors(this.meetingForm, "createMeetingErrorTitle", () => {
      this.meetingCreatedEdited.emit(this.meetingForm);
    });
  }

  cancel() {
    this.meetingCreatedEdited.emit(null);
  }
}
