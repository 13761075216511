import { Component, OnInit, Input } from '@angular/core';

declare type Placement = "top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right" | "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom";

@Component({
  selector: 'warning-icon',
  templateUrl: './warning-icon.component.html',
  styleUrls: ['./warning-icon.component.scss']
})
export class WarningIconComponent implements OnInit {

  @Input() placement: Placement;
  @Input() title: string;
  @Input() text: string;

  constructor() { }

  ngOnInit() { }

}
