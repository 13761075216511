import { EventService } from "src/app/services/event.service";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { FormHelper } from "./../../../../helper/form.helper";
import { FeedbackService } from "./../../../../services/feedback.service";
import { UntypedFormGroup } from "@angular/forms";
import { SelectOptions } from "src/app/utils/select-options";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "customer-feedback-form",
  templateUrl: "./customer-feedback-form.component.html",
  styleUrls: ["./customer-feedback-form.component.scss"],
  animations: [trigger("visibility", [state("visible", style({ opacity: 1, height: "*" })), state("hidden", style({ opacity: 0, height: "0px" })), transition("visible <=> hidden", animate(500))])],
})
export class CustomerFeedbackFormComponent implements OnInit {
  @Input() eventForm: UntypedFormGroup;
  @Input() eventFeedbackCustomerForm: UntypedFormGroup;
  @Input() buid: string;
  @Input() editMode: boolean;

  @Output() feedbackSubmitted = new EventEmitter();

  showButton = true;
  feedbackOptions = SelectOptions.feedbackOptions;
  feedbackBooleans = SelectOptions.feedbackBooleans;
  result: string;
  resultColor = "#000";

  constructor(private formHelper: FormHelper, private feedbackService: FeedbackService, private translateService: TranslateService, private dialogService: GibDialogService, private eventService: EventService) {}

  ngOnInit() {
    this.formHelper.disableControlsByName(this.eventForm, ["customerCompanyName", "customerContactFirstname", "customerContactLastname", "customerPhone", "eventDate", "startTime", "endTime", "corporateCustomerCompanyName"]);
    this.formHelper.disableControlsByName(this.eventForm.get("eventlocation"), ["street"]);
    this.formHelper.disableControlsByName(this.eventForm.get("eventlocation"), ["number"]);
    this.formHelper.disableControlsByName(this.eventForm.get("eventlocation"), ["city"]);
    this.formHelper.disableControlsByName(this.eventForm.get("eventlocation"), ["zip"]);
    this.editMode = true;
  }

  submitCustomerFeedbackClicked() {
    const title = this.translateService.instant("feedbackTitle");
    const text = this.translateService.instant("feedbackText");
    this.formHelper.isFormValidElseShowErrors(this.eventFeedbackCustomerForm, "feedbackErrorTitle", () => this.dialogService.openConfirmationDialog(title, text, () => this.submitCustomerFeedback()));
  }

  submitCustomerFeedback() {
    const feedback = this.feedbackService.mapFormToCustomerFeedback(this.eventFeedbackCustomerForm);
    const _event = this.eventService.mapFormToEvent(this.eventForm);
    feedback.event = {
      id: _event.id,
    };
    const feedbackBody = {
      feedback,
      buid: this.buid,
    };
    this.feedbackService.submitEventFeedbackCustomer(feedbackBody).subscribe((res) => {
      this.formHelper.disableControls(this.eventFeedbackCustomerForm);
      this.feedbackSubmitted.emit(feedback);
      this.showButton = false;
      this.result = "feedbackSubmitted";
      this.resultColor = "var(--success)";
    });
  }
}
