import { GibDialogService } from "./../../../../../components/dialogs/gib-dialog.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from "@angular/forms";
import { KeycloakService } from "keycloak-angular";
import { ValueLabel } from "../../../../../components/value-label";
import { FormHelper } from "../../../../../helper/form.helper";
import { Customer, CustomerService } from "../../../../../services/customer.service";
import { CorporateCustomerDTO, EventService } from "../../../../../services/event.service";
import { SelectOptions } from "../../../../../utils/select-options";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "event-customer-data",
  templateUrl: "./event-customer-data.component.html",
  styleUrls: ["./event-customer-data.component.scss"],
  // encapsulation: ViewEncapsulation.None,
})
export class EventCustomerDataComponent implements OnInit, OnDestroy {
  salutationOptions = SelectOptions.salutationOptions;
  booleanOptions = SelectOptions.feedbackBooleans;
  customers: ValueLabel[] = [];
  customerContactValueLabels: ValueLabel[] = [];
  customerContactHistory: CorporateCustomerDTO[] = [];
  showCustomerWarning = false;
  userRole;
  showCustomerContactSearchField: boolean = true;

  downloadUrl;
  filename;
  changeListenerSub: Subscription;

  @Input() eventForm: UntypedFormGroup;
  @Input() buid: string;
  @Input() showPrintButtons: boolean;

  @Output() customerSelected = new EventEmitter<Customer>();
  @Output() clearCustomer = new EventEmitter();

  constructor(private customerService: CustomerService, private formHelper: FormHelper, private eventService: EventService, private keycloakService: KeycloakService, private translateService: TranslateService, private snackBar: MatSnackBar, private dialogService: GibDialogService, private router: Router) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    if (this.userRole === "gib_director" || this.userRole === "gib_admin" || this.userRole === "gib_employee") {
      this.loadCustomers();
    }
    this.keycloakService.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        this.loadCustomerContacts();
      }
    });
    this.handleCustomerSelection();
    this.handleCustomerContactSearch();
    this.changeListenerSub = this.eventForm.get("customer").valueChanges.subscribe((change) => {
      this.applyValidators();
    });
    this.applyValidators();
  }

  private applyValidators() {
    if (this.isTK && !this.isInquiry) {
      this.eventForm.get("customerContactRegion").setValidators([Validators.required]);
      this.eventForm.get("customerContactRegion").updateValueAndValidity();
      this.eventForm.get("customerContactTeamnumber").setValidators([Validators.required]);
      this.eventForm.get("customerContactTeamnumber").updateValueAndValidity();
    }
  }

  handleCustomerContactSearch() {
    if (this.eventForm) {
      if (!this.eventForm.get("customerContactSearch")) {
        this.eventForm.addControl("customerContactSearch", new UntypedFormControl(""));
      }
      this.eventForm.get("customerContactSearch")?.valueChanges.subscribe((res) => {
        if (res) {
          const cc = this.findCustomerContactDTO(res);
          if (cc) {
            this.eventForm.get("customerContactSalutation").setValue(cc.salutation);
            this.eventForm.get("customerContactFirstname").setValue(cc.firstname);
            this.eventForm.get("customerContactLastname").setValue(cc.lastname);
            this.eventForm.get("customerContactMail").setValue(cc.email);
            this.eventForm.get("customerContactPhone").setValue(cc.phone);
            this.eventForm.get("customerContactCompanyName").setValue(cc.companyName);
          }
        } else {
          this.eventForm.get("customerContactSalutation").setValue(null);
          this.eventForm.get("customerContactFirstname").setValue("");
          this.eventForm.get("customerContactLastname").setValue("");
          this.eventForm.get("customerContactMail").setValue("");
          this.eventForm.get("customerContactPhone").setValue("");
          this.eventForm.get("customerContactCompanyName").setValue("");
        }
      });
    }
  }

  loadCustomerContacts() {
    if (this.showCustomerContactSearchField && this.hasCompanynameAlias()) {
      this.eventService.getCustomerContactHistory(this.eventForm.get("customer").get("companynameAlias").value).subscribe((res) => {
        this.customerContactHistory = res.body;
        this.createValueLabelsForSuggestSelect();
      });
    }
  }

  hasCompanynameAlias(): boolean {
    if (this.eventForm && this.eventForm.get("customer") && this.eventForm.get("customer").get("companynameAlias") && this.eventForm.get("customer").get("companynameAlias").value) {
      return true;
    }
    return false;
  }

  clearCustomerContactSearch() {
    this.customerContactHistory = [];
    this.customerContactValueLabels = [];
  }

  createValueLabelsForSuggestSelect() {
    for (const cc of this.customerContactHistory) {
      const label = cc.lastname + ", " + cc.firstname;
      this.customerContactValueLabels.push({ label, value: "" + cc.eventId });
    }
  }

  findCustomerContactDTO(eventId: any) {
    for (const cc of this.customerContactHistory) {
      if (cc.eventId === parseInt(eventId, 10)) {
        return cc;
      }
    }
    return null;
  }

  handleCustomerSelection() {
    this.eventForm
      .get("customer")
      .get("id")
      .valueChanges.subscribe((customerId) => {
        if (customerId) {
          this.customerService.getCustomer(customerId).subscribe((customer) => {
            this.prefillFormWithCustomerValues(customer);
            this.customerSelected.emit(customer);
            this.loadCustomerContacts();
          });
        } else {
          this.clearCustomerValues();
          this.clearCustomerContactSearch();
        }
      });
  }

  setDisableEmailCommunication(boolean: boolean) {
    this.eventForm.get("disableEmailCommunication").setValue(boolean);
  }

  prefillFormWithCustomerValues(customer: Customer) {
    this.eventService.prefillFormWithCustomerValues(this.eventForm, customer);
    this.formHelper.disableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
    this.formHelper.disableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
  }

  clearCustomerValues() {
    this.eventService.clearCustomerValuesFromForm(this.eventForm);
    this.formHelper.enableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
    this.formHelper.enableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
  }

  loadCustomers() {
    this.customerService.createCustomerValueLabels().subscribe((res) => {
      this.customers = res;
    });
  }

  copyCustomerInfo() {
    this.eventForm.get("customerContactSalutation").setValue(this.eventForm.get("customerSalutation").value);
    this.eventForm.get("customerContactFirstname").setValue(this.eventForm.get("customerFirstname").value);
    this.eventForm.get("customerContactLastname").setValue(this.eventForm.get("customerLastname").value);
    this.eventForm.get("customerContactPhone").setValue(this.eventForm.get("customerPhone").value);
    this.eventForm.get("customerContactMail").setValue(this.eventForm.get("customerMail").value);
    this.eventForm.get("customerContactCompanyName").setValue(this.eventForm.get("customerCompanyName").value);
    if (this.eventForm.get("customerRegion").value) {
      this.eventForm.get("customerContactRegion").setValue(this.eventForm.get("customerRegion").value);
    }
    if (this.eventForm.get("customerTeamnumber").value) {
      this.eventForm.get("customerContactTeamnumber").setValue(this.eventForm.get("customerTeamnumber").value);
    }
  }

  customerDataClicked() {
    if (this.userRole === "gib_customer") {
      this.showCustomerWarning = true;
    }
  }

  updateMailReceipients() {
    let bookingCopyRecepientMailForObject = this.eventForm.get("bookingCopyRecepientMail").value ? this.eventForm.get("bookingCopyRecepientMail").value.join(", ") : [];
    if (this.eventForm.get("bookingCopyRecepientMailTemp") && this.eventForm.get("bookingCopyRecepientMailTemp").value) {
      if (bookingCopyRecepientMailForObject === "") {
        bookingCopyRecepientMailForObject = this.eventForm.get("bookingCopyRecepientMailTemp").value;
      } else {
        bookingCopyRecepientMailForObject = bookingCopyRecepientMailForObject + ", " + this.eventForm.get("bookingCopyRecepientMailTemp").value;
      }
    }
    this.customerService.updateMailReceipients(bookingCopyRecepientMailForObject).subscribe((res) => {
      this.snackBar.open(this.translateService.instant("mailReceipientsUpdateInProfile"), "", {
        duration: 2000,
      });
    });
  }

  updateEmptyMailReceipients() {
    this.eventForm.removeControl("bookingCopyRecepientMail");
    this.eventForm.addControl("bookingCopyRecepientMail", new UntypedFormArray([]));
    this.customerService.updateMailReceipients(null).subscribe((res) => {
      this.snackBar.open(this.translateService.instant("mailReceipientsDeletedInProfile"), "", {
        duration: 2000,
      });
    });
  }

  openDownloadPdfConfirmation() {
    const title = this.translateService.instant("downloadPdfTitle");
    const text = this.translateService.instant("downloadPdfText");
    if (this.translateService.currentLang === "en") {
      this.filename = "eventinformation.pdf";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "veranstaltungsinformation.pdf";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.printFormToPdf(this.filename));
  }

  printFormToPdf(filename: string) {
    if (this.buid !== null) {
      this.eventService.getSignOffPdfByBuid(this.buid).subscribe((res) => {
        const blob = new Blob([res], { type: " application/pdf" });
        this.downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.download = filename;
        a.href = this.downloadUrl;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(this.downloadUrl);
          document.body.removeChild(a);
        }, 0);
      });
    }
  }

  get isTK(): boolean {
    if (this.userRole !== "gib_customer") {
      const customer = this.customers.find((customer) => customer.value === this.eventForm.get("customer").get("id").value);
      if (customer && customer.data.companynameAlias && customer.data.companynameAlias === "TK") {
        return true;
      }
    } else {
      if (this.eventForm.get("customer")?.get("companynameAlias")?.value === "TK") {
        return true;
      }
    }
    return false;
  }

  get isInquiry(): boolean {
    return this.router.url.includes("event-inquiry-page");
  }

  onCleanCustomer() {
    this.clearCustomer.emit();
  }

  ngOnDestroy() {
    this.changeListenerSub?.unsubscribe();
  }
}
