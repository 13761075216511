<button *ngIf="showButton && color === 'blue'" type="button" class="btn btn-primary grid-button" (click)="openLogisticSheet()" [matTooltipPosition]="'above'" matTooltip="{{ 'logistic_blue' | translate }}">
  <i class="material-icons">local_shipping</i>
</button>
<button *ngIf="showButton && color === 'yellow'" type="button" class="btn btn-secondary grid-button" (click)="openLogisticSheet()" [matTooltipPosition]="'above'" matTooltip="{{ 'logistic_yellow' | translate }}">
  <i class="material-icons">local_shipping</i>
</button>
<button *ngIf="showButton && color === 'green'" type="button" class="btn btn-success grid-button" (click)="openLogisticSheet()" [matTooltipPosition]="'above'" matTooltip="{{ 'logistic_green' | translate }}">
  <i class="material-icons">local_shipping</i>
</button>
<button *ngIf="showButton && color === 'gray'" type="button" class="btn btn-primary grid-button gray" (click)="openLogisticSheet()" [matTooltipPosition]="'above'" matTooltip="{{ 'logistic_grey' | translate }}">
  <i class="material-icons">local_shipping</i>
</button>
