<div class="row">
  <div class="col-sm-12">
    <h1>{{ "trainerAvailabilities" | translate }}</h1>
  </div>
</div>
<mat-tab-group>
  <mat-tab label="{{ 'overview' | translate }}">
    <div class="row zero-margin top-margin-nine">
      <div class="col-sm-12">
        <trainer-availability-overview-grid></trainer-availability-overview-grid>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'detailsForTrainer' | translate }}">
    <div class="row zero-margin top-margin-nine">
      <div class="col-sm-12 col-md-12">
        <gib-suggest-select [placeholder]="'selectTrainer'" [control]="selectedTrainer" [options]="trainerOptions"></gib-suggest-select>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 calendarCol">
        <gib-calendar [entries]="entriesForTrainer" (editEntry)="editEntry($event)" (entryDeleted)="reloadEntriesForTrainer()" (emptyEntryClicked)="emptyEntryClicked($event)"></gib-calendar>
      </div>
      <div *ngIf="entryToEdit" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 formCol">
        <trainer-availablity-form #trainerAvailabilityForm [entry]="entryToEdit" (entryEditCanceled)="entryEditCanceledClicked()" (entryAdded)="reloadEntriesForTrainer()" [forUser]="selectedTrainer.value"></trainer-availablity-form>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
