import { NgModule } from '@angular/core';
import { ComponentsModule } from '../../../components/components.module';
import { UtilsModule } from "../../../utils/utils.module";
import { DocumentTableComponent } from "./components/document-table/document-table.component";
import { DocumentVerificationComponent } from './components/document-verification/document-verification.component';
import { DocumentVerificationOverviewComponent } from './document-verification-overview/document-verification-overview.component';

@NgModule({
  declarations: [
    DocumentVerificationOverviewComponent,
    DocumentTableComponent,
    DocumentVerificationComponent
  ],
  imports: [
    ComponentsModule,
    UtilsModule
  ],
  exports: [
    DocumentVerificationOverviewComponent
  ]
})

export class DocumentModule { }
