import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FeedbackBottomSheetComponent } from '../feedback-bottom-sheet/feedback-bottom-sheet.component';

@Component({
  selector: 'grid-feedback-button',
  templateUrl: './grid-feedback-button.component.html',
  styleUrls: ['./grid-feedback-button.component.scss']
})
export class GridFeedbackButtonComponent implements ICellRendererAngularComp {

  eventId: number;
  buid: string;
  refreshData: any;
  feedbackAvailable: boolean;
  showButton = false;

  constructor(private router: Router, private bottomSheet: MatBottomSheet) { }

  agInit(params: any) {
    this.refreshData = params.refreshData;
    this.eventId = params.data.eventId;
    this.showButton = params.showButton;
  }

  refresh() {
    return true;
  }

  openFeedbackClicked() {
    const sheetData = { eventId: this.eventId};
    this.bottomSheet.open(FeedbackBottomSheetComponent, { data: sheetData });
  }

}
