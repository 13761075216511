import { Observable, Subscription } from "rxjs";
import { FileMetaTaskDTO } from "./services/file.service";
import { ProcessService } from "src/app/services/process-service";
import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { TranslateService } from "@ngx-translate/core";
import { Role } from "./services/keycloak-connector.service";
import { WebSocketAPI, MessageCounter } from "./utils/websocket/websocket-api";
import { BreakpointState, BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { EnvService } from "./services/env.service";
import { KeycloakService } from "keycloak-angular";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { CartService } from "./services/cart.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild("container", { static: true }) container: ElementRef;
  actualComponentRef: any;
  menuIcon = "chevron_left";
  badgeCounter: FileMetaTaskDTO[];
  tasksCounter: number;
  extendedRightRoles: Role[] = ["gib_admin", "gib_director", "gib_employee"];
  webSocketAPI: WebSocketAPI;
  messageCounter: MessageCounter = new MessageCounter();
  isLoggedIn = false;
  userRole: string;
  containerWidth: number;
  isHighRes: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.XLarge, Breakpoints.Large]);
  isHighResMode: boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    translate: TranslateService,
    private processService: ProcessService,
    private breakpointObserver: BreakpointObserver,
    private envService: EnvService,
    private keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService
  ) {
    translate.setDefaultLang("de");
    translate.use("de");
    const lang = localStorage.getItem("gib-locale");
    if (lang) {
      if (lang === "en") {
        translate.use("en");
      } else {
        translate.use("de");
      }
    } else {
      translate.use("de");
    }
    this.webSocketAPI = new WebSocketAPI(this, envService);
    this.webSocketAPI._connect();

    this.matIconRegistry.addSvgIcon("confirm", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/confirm.svg"));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/delete.svg"));
    this.matIconRegistry.addSvgIcon("excel", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/excel.svg"));
    this.matIconRegistry.addSvgIcon("gib_tooltip", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/gib_info.svg"));
    this.matIconRegistry.addSvgIcon("measure", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/measure.svg"));
    this.matIconRegistry.addSvgIcon("plus", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/plus.svg"));
    this.matIconRegistry.addSvgIcon("plus_profile", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/plus_profile.svg"));
    this.matIconRegistry.addSvgIcon("instagram", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/instagram.svg"));
    this.matIconRegistry.addSvgIcon("website", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/website.svg"));
    this.matIconRegistry.addSvgIcon("xing", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/xing.svg"));
    this.matIconRegistry.addSvgIcon("edit_profile", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/edit_profile.svg"));
  }

  ngOnInit() {
    this.keycloakService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
    });
    this.userRole = localStorage.getItem("role");
    if (this.isRoleWithExtendedRights(this.userRole as Role)) {
      this.documentVerificationCounter();
      this.activeTasksCounter();
      this.activeEventsTasksCounter();
      this.activeTrainerTasksCounter();
      this.activeCustomerTasksCounter();
    }
    if (this.container) {
      this.containerWidth = this.container.nativeElement.offsetWidth;
    }
    if (this.userRole === "gib_trainer" && document.querySelector(".container-fluid")) {
      document.querySelector(".container-fluid").setAttribute("style", "padding-left: 15px;");
    }
    const sub = this.breakpointObserver.observe([Breakpoints.XLarge, Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.menuIcon = "chevron_left";
        this.isHighResMode = true;
      } else {
        this.menuIcon = "menu";
        this.isHighResMode = false;
      }
    });
    this.subscriptions.push(sub);
    window.addEventListener("wheel", (event) => this.doNothing());
    this.cartService.restoreCart()
  }

  doNothing() {
    return;
  }

  componentRefChanged(componentRef) {
    this.actualComponentRef = componentRef;
    if (this.actualComponentRef.componentName === "EventOverviewPageComponent" || (this.actualComponentRef && this.actualComponentRef.componentName === "EventTasksPageComponent") || (this.actualComponentRef && this.actualComponentRef.componentName === "MetadataPageComponent")) {
      // if (this.sidenav.opened) {
      //   this.changeContainerWidthPercent(75);
      // } else {
      //   this.changeContainerWidthPercent(85);
      // }
    }
    // else {
    //   this.changeContainerWidth(this.containerWidth);
    // }
  }
  toggleSideNav() {
    this.sidenav.toggle();
    if (this.isHighResMode) {
      if (this.menuIcon === "chevron_left") {
        this.menuIcon = "menu";
      } else {
        this.menuIcon = "chevron_left";
      }
    }
    if (this.sidenav.opened) {
      //this.menuIcon = "chevron_left";
      if ((this.actualComponentRef && this.actualComponentRef.componentName === "EventOverviewPageComponent") || (this.actualComponentRef && this.actualComponentRef.componentName === "EventTasksPageComponent")) {
        // this.changeContainerWidthPercent(75);
      }
    } else {
      //this.menuIcon = "menu";
      if ((this.actualComponentRef && this.actualComponentRef.componentName === "EventOverviewPageComponent") || (this.actualComponentRef && this.actualComponentRef.componentName === "EventTasksPageComponent")) {
        // this.changeContainerWidthPercent(85);
      }
    }
  }

  closeSidenav() {
    this.sidenav.close();
  }

  showSideNav() {
    if (this.userRole && this.isRoleWithExtendedRights(this.userRole as Role)) {
      return true;
    } else {
      return false;
    }
  }

  isRoleWithExtendedRights(role: Role) {
    return this.extendedRightRoles.includes(role);
  }

  handleMessage(message, type) {
    if (type === "documentVerifications") {
      let temp = new MessageCounter();
      temp.customers = this.messageCounter.customers;
      temp.documentVerifications = message;
      temp.events = this.messageCounter.events;
      temp.tasks = this.messageCounter.tasks;
      temp.trainers = this.messageCounter.trainers;
      this.messageCounter = temp;
    } else if (type === "tasks") {
      let temp = new MessageCounter();
      temp.customers = this.messageCounter.customers;
      temp.documentVerifications = this.messageCounter.documentVerifications;
      temp.events = this.messageCounter.events;
      temp.tasks = message;
      temp.trainers = this.messageCounter.trainers;
      this.messageCounter = temp;
    } else if (type === "events") {
      let temp = new MessageCounter();
      temp.customers = this.messageCounter.customers;
      temp.documentVerifications = this.messageCounter.documentVerifications;
      temp.events = message;
      temp.tasks = this.messageCounter.tasks;
      temp.trainers = this.messageCounter.trainers;
      this.messageCounter = temp;
    } else if (type === "trainers") {
      let temp = new MessageCounter();
      temp.customers = this.messageCounter.customers;
      temp.documentVerifications = this.messageCounter.documentVerifications;
      temp.events = this.messageCounter.events;
      temp.tasks = this.messageCounter.tasks;
      temp.trainers = message;
      this.messageCounter = temp;
    } else if (type === "customers") {
      let temp = new MessageCounter();
      temp.customers = message;
      temp.documentVerifications = this.messageCounter.documentVerifications;
      temp.events = this.messageCounter.events;
      temp.tasks = this.messageCounter.tasks;
      temp.trainers = this.messageCounter.trainers;
      this.messageCounter = temp;
    } else if (type === "feedback" || type === "event-sign-off") {
      if (window.location.pathname === "/my-events") {
        window.location.reload();
      }
    }
    if (type !== "documentVerifications") {
      if (this.actualComponentRef && this.actualComponentRef.componentName === "EventTasksPageComponent") {
        this.actualComponentRef.getAllFilteredActiveTasks(this.actualComponentRef.getTaskIdFilter());
      }
    } else {
      if (this.actualComponentRef && this.actualComponentRef.componentName === "DocumentVerificationOverviewComponent") {
        this.actualComponentRef.getFileList();
      }
    }
  }
  documentVerificationCounter() {
    this.processService.getDocumentVerificationCounter().subscribe((res) => {
      this.badgeCounter = res.body.length;
      this.handleMessage(this.badgeCounter, "documentVerifications");
    });
  }
  activeTasksCounter() {
    this.processService.getAllProcessTasks().subscribe((tasks) => {
      this.tasksCounter = tasks.length;
      this.handleMessage(this.tasksCounter, "tasks");
    });
  }
  activeEventsTasksCounter() {
    this.processService.getAllActiveTasks("events").subscribe((tasks) => {
      this.tasksCounter = tasks.body.length;
      this.handleMessage(this.tasksCounter, "events");
    });
  }
  activeTrainerTasksCounter() {
    this.processService.getAllActiveTasks("trainers").subscribe((tasks) => {
      this.tasksCounter = tasks.body.length;
      this.handleMessage(this.tasksCounter, "trainers");
    });
  }
  activeCustomerTasksCounter() {
    this.processService.getAllActiveTasks("customers").subscribe((tasks) => {
      this.tasksCounter = tasks.body.length;
      this.handleMessage(this.tasksCounter, "customers");
    });
  }
  changeContainerWidth(width: number) {
    const style = "width:" + width + "px" + "!important; max-width:" + width + "px" + "!important";
    document.querySelector(".container-fluid").setAttribute("style", style);
  }
  changeContainerWidthPercent(width: number) {
    const style = "max-width:" + width + "vw" + "!important";
    document.querySelector(".container-fluid").setAttribute("style", style);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
