import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

declare type DialogType = 'success' | 'error';

export interface DialogData {
  title: string;
  text: string;
  link: string;
  type: DialogType;
}

@Component({
  selector: 'gib-dialog',
  templateUrl: './gib-dialog.component.html',
  styleUrls: ['./gib-dialog.component.scss']
})
export class GibDialogComponent {

  title: string;
  text: string;
  link: string;

  constructor(public dialogRef: MatDialogRef<GibDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
