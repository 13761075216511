import { MatSnackBar } from "@angular/material/snack-bar";
import { CartService } from "./../../../services/cart.service";
import { HealthScreeningService } from "./../../../services/health-screening.service";
import { FormHelper } from "./../../../helper/form.helper";
import { FileService, FileMeta } from "./../../../services/file.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { HealthScreening } from "src/app/services/health-screening.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "info-health-screening-page",
  templateUrl: "./info-health-screening-page.component.html",
  styleUrls: ["./info-health-screening-page.component.scss"],
})
export class InfoHealthScreeningPageComponent implements OnInit {
  @Input() healthScreeningForm: UntypedFormGroup;

  healthscreening: HealthScreening = new HealthScreening();
  form: UntypedFormGroup;
  images: FileMeta[] = [];
  imageUrls: string[] = [];
  videoUrls: string[] = [];
  documents: FileMeta[] = [];
  downloadUrl;

  marketingVideos: FileMeta[];
  trainingVideos: FileMeta[] = [];
  trainingVideoUrls: string[] = [];
  userRole: string;
  type: string;

  constructor(private route: ActivatedRoute, private healthscreeningService: HealthScreeningService, private fileService: FileService, private formHelper: FormHelper, private router: Router, private cartService: CartService, private _snackBar: MatSnackBar, private translateService: TranslateService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.route.params.subscribe((params) => {
      const id = +params["id"];
      this.type = params["type"];
      this.healthscreeningService.findOne(id).subscribe((res) => {
        this.healthscreening = res.body;
        this.createForm();
        this.loadImages();
        this.loadMarketingVideos();
        this.loadDocuments();
        if (this.userRole !== "gib_customer") {
          this.loadTrainingVideos();
        }
        this.formHelper.disableControls(this.form);
      });
    });
  }

  createForm() {
    this.form = this.healthscreeningService.createHealthscreeningForm(this.healthscreening);
    this.form.get("moduleName").setValidators(Validators.required);
    this.form.get("topic").setValidators(Validators.required);
    this.form.get("shortDescription").setValidators(Validators.required);
    this.form.get("manual").setValidators(Validators.required);
    this.form.get("requiredArea").setValidators(Validators.required);
    this.form.get("power").setValidators(Validators.required);
    this.form.get("location").setValidators(Validators.required);
  }

  loadImages() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_PICTURE").subscribe((res) => {
      this.images = res;
      this.imageUrls = this.fileService.createRessourceUrls(this.images);
    });
  }

  loadDocuments() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_TRAINER_DOCUMENT").subscribe((res) => {
      for (const filemeta of res) {
        this.documents.push(filemeta);
      }
    });
  }

  loadMarketingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_VIDEO_PRESENTATION").subscribe((res) => {
      this.marketingVideos = res;
      this.videoUrls = this.fileService.createRessourceUrls(this.marketingVideos);
    });
  }

  loadTrainingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_VIDEO_TRAINING").subscribe((res) => {
      this.trainingVideos = res;
      this.trainingVideoUrls = this.fileService.createRessourceUrls(this.trainingVideos);
    });
  }

  addModuleToCart() {
    const topics: string[] = this.healthscreening.topic.split(",");
    if (topics.length > 0) {
      this.cartService.addToCart(this.healthscreening, this.type === "ONLINE", topics[0]);
      this.openSnackBar();
    }
  }

  goBackToOverview() {
    this.router.navigate(["/customer-health-screening-overview-page/" + this.type]);
  }

  openSnackBar() {
    const message = this.translateService.instant("moduleAddedToCart");
    const action = "";
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  setTrainingVideoUrls(videoUrls: string[]) {}

  onDownload(file: FileMeta) {
    this.fileService.getFileData(file.id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = this.downloadUrl;
      a.download = file.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }
}
