import { EmployeeService } from "./employee.service";
import { FormHelper } from "./../helper/form.helper";
import { HealthScreeningService } from "./health-screening.service";
import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { EventInquiryHealthscreening, Event } from "./event.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, AbstractControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EnvService } from "./env.service";

export class Feedback {
  id: number;
  overallRating: number;
  overallRatingComment: string;
  constructor() {
    this.id = null;
    this.overallRating = null;
    this.overallRatingComment = "";
  }
}

export class EventFeedbackTrainer extends Feedback {
  eihs: any;
  orgaGib: boolean;
  orgaLocation: boolean;
  orgaCustomer: boolean;
  orgaComment: string;
  measuringDevice: boolean;
  measuringDeviceComment: string;
  generalComment: string;

  constructor() {
    super();
    this.eihs = null;
    this.orgaGib = null;
    this.orgaLocation = null;
    this.orgaCustomer = null;
    this.orgaComment = "";
    this.measuringDevice = null;
    this.measuringDeviceComment = "";
    this.generalComment = "";
  }
}

export class EventFeedbackCustomer extends Feedback {
  event: any;
  trainerContactedYou: boolean;
  trainerOnTime: boolean;
  bookAgain: boolean;
  whyNotBookAgain: string;
  improvements: string;

  constructor() {
    super();
    this.event = null;
    this.trainerContactedYou = null;
    this.trainerOnTime = null;
    this.bookAgain = null;
    this.whyNotBookAgain = "";
    this.improvements = "";
  }
}

@Injectable()
export class FeedbackService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private formHelper: FormHelper, private healthScreeningService: HealthScreeningService, private employeeService: EmployeeService, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  submitEventFeedbackTrainer(feedback: any): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/feedback/submitEventFeedbackTrainer/", feedback, this.config);
  }

  submitEventFeedbackCustomer(feedback: any): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/feedback/submitEventFeedbackCustomer/", feedback, this.config);
  }

  getAllEventFeedbackTrainerForEvent(eventId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/feedback/getAllEventFeedbackTrainerForEvent?eventId=" + eventId, this.config);
  }

  getEventFeedbackCustomerForEvent(eventId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/feedback/getEventFeedbackCustomerForEvent?eventId=" + eventId, this.config);
  }

  mapFormToTrainerFeedback(form: UntypedFormGroup): EventFeedbackTrainer {
    const feedback = form.getRawValue();
    return feedback;
  }

  mapFormToCustomerFeedback(form: UntypedFormGroup): EventFeedbackCustomer {
    const feedback = form.getRawValue();
    return feedback;
  }

  mapTrainerFeedbackToForm(feedback: Feedback): UntypedFormGroup {
    const feedbackForm = this.formBuilder.group(feedback);
    this.transformValueToString(feedbackForm, "overallRating");
    this.transformValueToString(feedbackForm, "orgaGib");
    this.transformValueToString(feedbackForm, "orgaLocation");
    this.transformValueToString(feedbackForm, "orgaCustomer");
    this.transformValueToString(feedbackForm, "measuringDevice");
    return feedbackForm;
  }

  mapCustomerFeedbackToForm(feedback: Feedback): UntypedFormGroup {
    const feedbackForm = this.formBuilder.group(feedback);
    this.transformValueToString(feedbackForm, "overallRating");
    this.transformValueToString(feedbackForm, "trainerContactedYou");
    this.transformValueToString(feedbackForm, "trainerOnTime");
    this.transformValueToString(feedbackForm, "bookAgain");
    return feedbackForm;
  }

  transformValueToString(form: UntypedFormGroup, key: string) {
    if (form.get(key).value) {
      form.get(key).setValue("" + form.get(key).value);
    }
  }

  isBookAgainNoAndNoComment(formGroup: UntypedFormGroup) {
    const isBookAgainNo = formGroup.get("bookAgain").value === "false";
    const isBookAgainCommentEmpty = !formGroup.get("whyNotBookAgain").value || formGroup.get("whyNotBookAgain").value === "";

    if (isBookAgainNo && isBookAgainCommentEmpty) {
      formGroup.get("whyNotBookAgain").setErrors({ required: true });
      formGroup.get("whyNotBookAgain").markAsTouched();
    } else {
      formGroup.get("whyNotBookAgain").setErrors(null);
      formGroup.get("whyNotBookAgain").markAsUntouched();
    }
    return null;
  }

  isMeasuringDeviceBooleanNoAndNoComment(formGroup: UntypedFormGroup) {
    const isMeasuringDeviceBooleanNo = formGroup.get("measuringDevice").value === "false";
    const isMeasuringDeviceCommentEmpty = !formGroup.get("measuringDeviceComment").value || formGroup.get("measuringDeviceComment").value === "";

    if (isMeasuringDeviceBooleanNo && isMeasuringDeviceCommentEmpty) {
      formGroup.get("measuringDeviceComment").setErrors({ required: true });
      formGroup.get("measuringDeviceComment").markAsTouched();
    } else {
      formGroup.get("measuringDeviceComment").setErrors(null);
      formGroup.get("measuringDeviceComment").markAsUntouched();
    }
    return null;
  }
}
