import { EventOverviewService } from "./../../../services/event-overview.service";
import { GibDialogService } from "./../../dialogs/gib-dialog.service";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageCounter } from "src/app/utils/websocket/websocket-api";
import { HealthInsurance, HealthInsuranceService } from "src/app/services/health-insurance.service";

@Component({
  selector: "sidenav-content",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnChanges {
  @Input() messageCounter: MessageCounter;

  treeElements = [];

  downloadUrl;

  userRole: string;

  constructor(private dialogService: GibDialogService, private translateService: TranslateService, private eventOverviewService: EventOverviewService, private healthInsuranceService: HealthInsuranceService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.treeElements = this.createTreeElements();
    this.addHealthscreeningsNavigation();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.treeElements = this.createTreeElements();
    this.addHealthscreeningsNavigation();
  }

  createTreeElements() {
    const elements = [
      {
        rootLabel: "tasks",
        links: [
          { label: "event-tasks-all", route: "event-tasks/all", message: this.messageCounter.tasks },
          { label: "events", route: "event-tasks/events", message: this.messageCounter.events },
          { label: "trainers", route: "event-tasks/trainers", message: this.messageCounter.trainers },
          { label: "customers", route: "event-tasks/customers", message: this.messageCounter.customers },
          {
            label: "documentVerifications",
            route: "document-verification-overview",
            message: this.messageCounter.documentVerifications,
          },
        ],
      },
      {
        rootLabel: "EVENTS",
        links: [
          { label: "overview", route: "event-overview-page" },
          { label: "createInquiry", route: "event-inquiry-page/CALL" },
          { label: "bookEvent", route: "event-booking-page" },
        ],
      },
      {
        rootLabel: "customers",
        links: [
          { label: "overview", route: "customers" },
          { label: "customerRegistration", route: "customer-registration" },
          { label: "healthInsurance", route: "health-insurance" },
        ],
      },
      {
        rootLabel: "healthscreenings",
      },
      {
        rootLabel: "PERSONNEL",
        links: [
          { label: "EMPLOYEES", route: "employees" },
          { label: "TRAINER", route: "trainer" },
          { label: "trainerAvailabilities", route: "trainer-availability-overview" },
        ],
      },
      {
        rootLabel: "administration",
        links: [
          { label: "onlineMeetingRooms", route: "meeting-administration" },
          { label: "mailLog", route: "mail-log" },
          { label: "faqNavigation", route: "gib-faq" },
        ],
      },
    ];
    if (this.userRole === "gib_admin" || this.userRole === "gib_director") {
      elements[5].links.push({ label: "metadata", route: "metadata", message: "" });
      elements[5].links.push({ label: "reports", route: "reports-overview-page", message: "" });
      elements[5].links.push({ label: "configuration", route: "configuration-page", message: "" });
    }
    return elements;
  }

  addHealthscreeningsNavigation() {
    let keys = [];
    this.healthInsuranceService.findAll().subscribe((list) => {
      list.body.forEach((element: HealthInsurance) => {
        keys.push(element.companynameAlias);
      });
      keys.sort();
      let nav = {
        rootLabel: "healthscreenings",
        links: [],
      };
      nav.links.push({ label: "all", route: "health-screening-overview-page/all", message: "" });
      keys.forEach((element) => {
        nav.links.push({ label: element, route: "health-screening-overview-page/" + element, message: "" });
      });

      this.treeElements[3] = nav;
    });
  }
}
