import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileMeta } from '../../../../../services/file.service';

@Component({
  selector: 'document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss']
})
export class DocumentTableComponent implements OnInit {
  sort;
  docs: FileMeta[];

  displayedColumns: string[] = ['fileName', 'created', 'fileType', 'keycloakUser'];
  dataSource: MatTableDataSource<FileMeta>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  @Output() fileSelected = new EventEmitter<FileMeta>();

  @Input() set documents(documents: FileMeta[]) {
    this.docs = documents;
    this.dataSource = new MatTableDataSource(documents);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  get documents(): FileMeta[] {
    return this.docs;
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  selectFileMeta(fileMeta: FileMeta) {
    this.fileSelected.emit(fileMeta);
  }

}
