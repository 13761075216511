import { Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
  selector: "gib-slideshow",
  templateUrl: "./gib-slideshow.component.html",
  styleUrls: ["./gib-slideshow.component.scss"],
})
export class GibSlideshowComponent implements OnInit {
  @Input() imageUrls: string[];
  slideIndex = 0;

  constructor(private elem: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let firstSlide = this.elem.nativeElement.querySelectorAll(".mySlides")[0] as HTMLElement;
    firstSlide.style.display = "block";
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  showSlides(n) {
    let i;
    let slides = this.elem.nativeElement.querySelectorAll(".mySlides");

    if (n > slides.length - 1) {
      this.slideIndex = this.slideIndex - 1;
    }
    if (n < 0) {
      this.slideIndex = this.slideIndex + 1;
    }
    for (i = 0; i < slides.length; i++) {
      let slide = slides[i] as HTMLElement;
      slide.style.display = "none";
    }
    let slide = slides[this.slideIndex] as HTMLElement;
    slide.style.display = "block";
  }
}
