import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MeetingService } from '../../../../services/meeting.service';
import { FormHelper } from '../../../../helper/form.helper';

@Component({
  selector: 'meeting-overview-grid',
  templateUrl: './meeting-overview-grid.component.html',
  styleUrls: ['./meeting-overview-grid.component.scss']
})
export class MeetingOverviewGridComponent implements OnInit {

  dateFrom: UntypedFormControl = new UntypedFormControl(moment());
  dateTo: UntypedFormControl = new UntypedFormControl(moment().add(13, 'day'));
  headerColumns: any[] = [];
  monthWrapperWidth = '0px';
  trainerDayWrapperWidth = '0px';
  schedules: any[] = [];

  constructor(private meetingService: MeetingService, private formHelper: FormHelper) {}

  ngOnInit() {
    this.headerColumns = this.createHeaderColumns();
    this.findSchedules();
    this.dateFrom.valueChanges.subscribe(res => {
      if (res && this.dateTo.value) {
        this.headerColumns = this.createHeaderColumns();
        this.findSchedules();
      }
    });
    this.dateTo.valueChanges.subscribe(res => {
      if (res && this.dateFrom.value) {
        this.headerColumns = this.createHeaderColumns();
        this.findSchedules();
      }
    });
  }

  findSchedules() {
    this.meetingService.findAllSchedules(this.dateFrom.value, this.dateTo.value).subscribe(res => {
      this.schedules = res.body;
      for (const entry of this.schedules) {
        entry.availabilities = this.sortAndExtendEntries(entry.meetingSchedules);
        this.extendEntriesByColorAndMargin(entry.meetingSchedules);
      }
      this.schedules.sort(this.formHelper.sortByProperty('alias'));
    });
  }

  createHeaderColumns() {
    const headerColumns = [];
    const start = moment(moment(this.dateFrom.value).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate());
    const end = moment(moment(this.dateTo.value).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate());
    let days = 0;

    while (end > start || start.format('M') === end.format('M')) {
      const headerColumn = { month: start.format('MMMM'), days: [] };
      const startTemp = moment(start.toDate());
      const endOfMonth = moment(startTemp.toDate()).endOf('month');

      while (end >= startTemp && endOfMonth >= startTemp) {
        const isWeekend = startTemp.toDate().getDay() % 6 === 0;
        headerColumn.days.push({ day: startTemp.format('DD'), isWeekend, date: startTemp.format('YYYY-MM-DD') });
        startTemp.add(1, 'day');
        days++;
      }

      headerColumns.push(headerColumn);
      start.add(1, 'month');
      start.set({ date: 1 });
    }

    this.monthWrapperWidth = (days * 25 + 500) + 'px';
    this.trainerDayWrapperWidth = (days * 25 + 450) + 'px';
    return headerColumns;
  }

  sortAndExtendEntries(entries: any[]) {
    if (entries.length > 0) {
      entries = this.fillMissingEntries(entries);
    } else {
      entries = this.fillAllEntries(entries);
    }
    // cause the first month is right aligned, the elements are missaligned for days < 10, so they has to fill up
    if (this.headerColumns && this.headerColumns[0] && this.headerColumns[0].days  && this.headerColumns[0].days.length < 10) {
      const diffToTen = 10 - this.headerColumns[0].days.length;
      for (let i = 0; i < diffToTen; i++) {
        const dateToAdd = moment(moment(this.headerColumns[0].days[0]).toDate()).subtract((i + 1), 'day').format('YYYY-MM-DD');
        const entryToAdd = { eventDate: dateToAdd, type: 'UNDETERMINED' };
        entries.splice(0, 0, entryToAdd);
      }
    }
    return entries;
  }

   fillAllEntries(entries: any[]) {
    const enrtiesToInsert = [];
    const startDateString = moment(this.dateFrom.value).format('YYYY-MM-DD');
    const endDateString = moment(this.dateTo.value).format('YYYY-MM-DD');
    const diffBetweenStartAndEnd = moment(endDateString).diff(startDateString, 'days');
    if (diffBetweenStartAndEnd > 0) {
      enrtiesToInsert.push({ entry: { eventDate: moment(this.dateFrom.value).format('YYYY-MM-DD'), type: 'UNDETERMINED' }, index: 0 });
      for (let i = 1; i < diffBetweenStartAndEnd + 1; i++) {
        const newDate = moment(moment(this.dateFrom.value).toDate()).add((i), 'day');
        enrtiesToInsert.push({ entry: { eventDate: moment(newDate).format('YYYY-MM-DD'), type: 'UNDETERMINED' }, index: i });
      }
    }
    for (let i = (enrtiesToInsert.length - 1); i >= 0; i--) {
      entries.splice(enrtiesToInsert[i].index, 0, enrtiesToInsert[i].entry);
    }
    entries = this.sortEntriesByDate(entries);
    return entries;
  }

  fillMissingEntries(entries: any[]) {
    const enrtiesToInsert = [];
    entries = this.sortEntriesByDate(entries);
    const startDateString = moment(this.dateFrom.value).format('YYYY-MM-DD');
    const endDateString = moment(this.dateTo.value).format('YYYY-MM-DD');
    const diffStartToFirstEntry = moment(entries[0].eventDate).diff(moment(startDateString), 'days');

    if (diffStartToFirstEntry > 0) {
      for (let i = 0; i < diffStartToFirstEntry; i++) {
        const dateToAdd = moment(startDateString).add(i, 'day');
        enrtiesToInsert.push({ entry: { eventDate: moment(dateToAdd).format('YYYY-MM-DD'), type: 'UNDETERMINED' }, index: i });
      }
    }

    for (let i = 0; i < entries.length - 1; i++) {
      const diff = moment(entries[i + 1].eventDate).diff(moment(entries[i].eventDate), 'days');

      if (diff > 1) {
        for (let f = 0; f < (diff - 1); f++) {
          const newDate = moment(moment(entries[i].eventDate).toDate()).add((1 + f), 'day');
          enrtiesToInsert.push({ entry: { eventDate: moment(newDate).format('YYYY-MM-DD'), type: 'UNDETERMINED' }, index: (i + 1) });
        }
      }
    }

    const diffEndToLastEntry = moment(endDateString).diff(entries[entries.length - 1].eventDate, 'days');
    if (diffEndToLastEntry > 0) {
      for (let i = 0; i < diffEndToLastEntry; i++) {
        const newDate = moment(moment(entries[entries.length - 1].eventDate).toDate()).add((1 + i), 'day');
        enrtiesToInsert.push({ entry: { eventDate: moment(newDate).format('YYYY-MM-DD'), type: 'UNDETERMINED' }, index: 0 });
      }
    }

    for (let i = (enrtiesToInsert.length - 1); i >= 0; i--) {
      entries.splice(enrtiesToInsert[i].index, 0, enrtiesToInsert[i].entry);
    }
    entries = this.sortEntriesByDate(entries);
    return entries;
  }

  sortEntriesByDate(entries: any[]) {
    entries.sort((a, b) => {
      const dateA = moment(a.eventDate).toDate().getTime();
      const dateB = moment(b.eventDate).toDate().getTime();
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
    return entries;
  }

  extendEntriesByColorAndMargin(entries: any[]) {
    for (const entry of entries) {
      if (moment(entry.eventDate).endOf('month').format('YYYY-MM-DD') === entry.eventDate) {
        entry.marginRight = '2px';
      } else {
        entry.marginRight = '0px';
      }
      if (entry.type !== 'UNDETERMINED') {
        entry.color = '#394160';
      }
    }
  }

}
