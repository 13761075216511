import { Action } from "@ngrx/store";
import { Customer } from "src/app/services/customer.service";

export const LOAD_TK_CONTROLLERS = "[TK_CONTROLLERS] Load Customers ";
export const LOAD_TK_CONTROLLERS_SUCCESS = "[TK_CONTROLLERS] Load Customers Success";
export const LOAD_TK_CONTROLLERS_FAIL = "[TK_CONTROLLERS] Load Customers Fail";

export class LoadTkControllers implements Action {
  readonly type = LOAD_TK_CONTROLLERS;
}
export class LoadTkControllersSuccess implements Action {
  readonly type = LOAD_TK_CONTROLLERS_SUCCESS;
  constructor(public payload: { tkControllers: Customer[] }) {}
}
export class LoadTkControllersFail implements Action {
  readonly type = LOAD_TK_CONTROLLERS_FAIL;
  constructor(public payload: any) {}
}

export type TkControllerActions = LoadTkControllers | LoadTkControllersSuccess | LoadTkControllersFail;
