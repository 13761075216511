import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarService, CalendarEntryDto } from "../../../services/calendar.service";
import { TrainerAvailablityFormComponent } from "../components/trainer-availablity-form/trainer-availablity-form.component";
import * as moment from "moment";
import { UntypedFormControl, Validators } from "@angular/forms";

@Component({
  selector: "trainer-calendar-page",
  templateUrl: "./trainer-calendar-page.component.html",
  styleUrls: ["./trainer-calendar-page.component.scss"],
})
export class TrainerCalendarPageComponent implements OnInit {
  entries: CalendarEntryDto[] = [];

  entryToEdit: CalendarEntryDto;

  @ViewChild("trainerAvailabilityForm", { static: true }) trainerAvailabilityForm: TrainerAvailablityFormComponent;

  constructor(private calendarService: CalendarService) {}

  ngOnInit() {
    this.getMyEntries();
  }

  getMyEntries() {
    this.calendarService.getMyEntries().subscribe((res) => {
      this.entries = res.body;
    });
  }

  setEntryToEdit(entry: CalendarEntryDto) {
    this.entryToEdit = entry;
  }

  setStartDate(date: any) {
    if (!this.trainerAvailabilityForm.entryForm.get("id").value) {
      const dateToSet = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
      this.trainerAvailabilityForm.entryForm.removeControl("date");
      this.trainerAvailabilityForm.entryForm.addControl("date", new UntypedFormControl(dateToSet));
      this.trainerAvailabilityForm.entryForm.get("date").setValidators([Validators.required]);
      this.trainerAvailabilityForm.entryForm.get("multipleDays").setValue(false);
    }
  }

  setEndDate(date: any) {
    if (!this.trainerAvailabilityForm.entryForm.get("id").value) {
      const dateToSet = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
      this.trainerAvailabilityForm.entryForm.removeControl("dateTo");
      this.trainerAvailabilityForm.entryForm.addControl("dateTo", new UntypedFormControl(dateToSet));
      this.trainerAvailabilityForm.entryForm.get("multipleDays").setValue(true);
      this.trainerAvailabilityForm.entryForm.get("mondays").setValue(true);
      this.trainerAvailabilityForm.entryForm.get("tuesdays").setValue(true);
      this.trainerAvailabilityForm.entryForm.get("wednesdays").setValue(true);
      this.trainerAvailabilityForm.entryForm.get("thursdays").setValue(true);
      this.trainerAvailabilityForm.entryForm.get("fridays").setValue(true);
    }
  }

  entryEditCanceledClicked() {
    this.entryToEdit = null;
  }
}
