import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { EnvService } from "./env.service";

export interface GlobalRateDto {
  id: string;
  priceOnlineModule: number;
  priceOnsiteModule: number;
  priceOnlineWebinar: number;
  priceOnsiteWebinar: number;
  priceOnlineWorkshop: number;
  priceOnsiteWorkshop: number;
  priceAnonymousEvaluation: number;
  priceEnglish: number;
  priceConsumableMaterials: number;
  cancelShortNotice: number;
  cancelMidNotice: number;
  cancelLongNotice: number;
  specialModuleRates: SpecialModuleRateDto[];
  validFrom: Date;
}

export interface SpecialModuleRateDto {
  id: string;
  globalRates: string;
  modulename: string;
  price: number;
}

@Injectable({
  providedIn: "root",
})
export class GlobalRateService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getAll(): Observable<GlobalRateDto[]> {
    return this.http.get<GlobalRateDto[]>(`${this.envService.backendUrl}/global-rates`);
  }

  get(id: string): Observable<GlobalRateDto> {
    return this.http.get<GlobalRateDto>(`${this.envService.backendUrl}/global-rates/${id}`);
  }

  save(payload: GlobalRateDto): Observable<GlobalRateDto> {
    return this.http.post<GlobalRateDto>(`${this.envService.backendUrl}/global-rates`, payload);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.envService.backendUrl}/global-rates/${id}`);
  }
}
