import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  public envFileLoaded = false;
  public production: boolean = false;
  public backendUrl: string = "";
  public authRedirectUrl: string = "";
  public keycloakRealm: string = "";
  public ssoUrl: string = "";
  constructor() {}
}
