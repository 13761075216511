<h1>{{ "tkController" | translate }}</h1>
<div class="row">
  <div class="{{ selectedTkController ? 'col-sm-12 col-md-12 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12' }}">
    <customer-table #custmrTable [customers]="tkControllers$ | async" (customerSelected)="openCustomerCard($event)" (addCustomerClicked)="openCustomerCard()" (customerDeleted)="fetchCustomers()"></customer-table>
  </div>
  <div *ngIf="selectedTkController" class="col-sm-12 col-md-12 col-lg-6 customer-details">
    <div class="sticky-top" style="top: 50px">
      <add-customer [customer]="selectedTkController" [addressType]="'BILLING'" (customerAdded)="fetchCustomers()"></add-customer>
    </div>
  </div>
</div>
