import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ValueLabel } from "../components/value-label";
import { TranslateService } from "@ngx-translate/core";
import { Employee, TrainerForAssignmentDto } from "./employee.service";
import { FormHelper } from "../helper/form.helper";
import { EnvService } from "./env.service";

declare type HealthScreeningLocation = "INDOOR" | "OUTDOOR" | "ONLINE";
declare type onlinePlatformOption = "GIB" | "TNG3";

export declare type HealthscreeningTopic = "MOVEMENT_SPINE_HEALTH" | "RELAX_STRESS_HANDLING" | "NUTRITION" | "SLEEP_AND_WORK" | "CLIMATE_AND_HEALTH" | "CROSS_TOPIC";

export class HealthScreening {
  id: number;
  moduleType: string;
  topic: HealthscreeningTopic;
  online: boolean;
  onsite: boolean;
  moduleName: string;
  moduleNameEn: string;
  shortDescription: string;
  manual: string;
  area: string;
  preventionPrincipal: string;
  recommendedOnsiteInterval: number;
  alternativeOnsiteInterval: number;
  recommendedOnlineInterval: number;
  durationInMinutes: number;
  maxParticipantsOnline: number;
  maxParticipantsOnsite: number;
  measuringDevice: string;
  manufacturer: string;
  implementationNotes: string;
  power: boolean;
  accessories: string;
  location: HealthScreeningLocation;
  size: string;
  requiredArea: string;
  hint: string;
  swayDe: string;
  swayEn: string;
  technicalRequirements: string;
  priorityPlatform: string;
  onlinePlatform: onlinePlatformOption;
  assignedUsers: TrainerForAssignmentDto[];
  onlineHint: string;
  restrictedVisibility: boolean;
  visibileForCompanynameAliases: any[];
  additionalInformation: string;

  public constructor() {
    this.id = null;
    this.moduleType = "";
    this.online = false;
    this.onsite = false;
    this.moduleName = "";
    this.moduleNameEn = "";
    this.topic = null;
    this.shortDescription = "";
    this.manual = "";
    this.area = "";
    this.preventionPrincipal = "";
    this.recommendedOnsiteInterval = undefined;
    this.alternativeOnsiteInterval = undefined;
    this.recommendedOnlineInterval = undefined;
    this.durationInMinutes = undefined;
    this.maxParticipantsOnline = undefined;
    this.maxParticipantsOnsite = undefined;
    this.measuringDevice = "";
    this.manufacturer = "";
    this.implementationNotes = "";
    this.power = null;
    this.accessories = "";
    this.location = null;
    this.size = "";
    this.requiredArea = "";
    this.hint = "";
    this.swayDe = "";
    this.swayEn = "";
    this.assignedUsers = [];
    this.onlineHint = "";
    this.restrictedVisibility = false;
    this.visibileForCompanynameAliases = [];
    this.technicalRequirements = "";
    this.priorityPlatform = "";
    this.onlinePlatform = null;
    this.additionalInformation = "";
  }
}

export interface HealthScreeningOverview {
  id: number;
  moduleName: string;
  moduleNameEn: string;
  topic: string;
  shortDescription: string;
  measuringDevice: string;
  location: string;
  visibileForCompanynameAliases: string[];
  moduleType: string;
  online: boolean;
  onsite: boolean;
}

@Injectable()
export class HealthScreeningService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formbuilder: UntypedFormBuilder, private translateService: TranslateService, private formHelper: FormHelper, private envService: EnvService) {}

  findAllOverview(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthscreening/overview", this.config);
  }

  findAllForModuleSelection(overrideCustomerCompanynameAlias: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthscreeningForModuleSelection?overrideCustomerCompanynameAlias=" + overrideCustomerCompanynameAlias, this.config);
  }

  findOne(id: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthscreening/" + id, this.config);
  }

  getHealthScreeningOverviewForCompanynameAlias(companynameAlias: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthscreening/overview/forCompanynameAlias/" + companynameAlias, this.config);
  }

  create(healthScreening: HealthScreening): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/healthscreening", healthScreening, this.config);
  }

  update(healthScreening: HealthScreening): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/healthscreening/" + healthScreening.id, healthScreening, this.config);
  }

  getTopics(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/public/healthscreening/topics", this.config);
  }

  delete(id: number): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/healthscreening/" + id, this.config);
  }

  copyFiles(oldHsId: number, newHsId: number): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/healthscreening/copyFiles/" + oldHsId + "/" + newHsId, this.config);
  }

  hasActiveEvents(id: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/healthscreening/hasActiveEvents/" + id, this.config);
  }

  createModuleOptions(overrideCustomerCompanynameAlias: string): Promise<ValueLabel[]> {
    const promise = new Promise<ValueLabel[]>((resolve) => {
      const moduleOptions = [];
      this.findAllForModuleSelection(overrideCustomerCompanynameAlias).subscribe((res) => {
        for (const healthscreening of res.body) {
          const moduleLabel = healthscreening.moduleName + " (" + this.translateService.instant(healthscreening.topic) + ")";
          moduleOptions.push({ label: moduleLabel, value: healthscreening.id });
        }
        resolve(moduleOptions);
      });
    });
    return promise;
  }

  createModuleOptionsGroupedByModuleTopic(overrideCustomerCompanynameAlias: string): Promise<any> {
    const promise = new Promise<any>((resolve) => {
      let groups: { [key: string]: { key: string; modules: any[]; name: string }[] } = {};
      this.findAllForModuleSelection(overrideCustomerCompanynameAlias).subscribe((res) => {
        for (const hs of res.body) {
          const topics: string[] = hs.topic.split(",");
          topics.forEach((t) => {
            const moduleType = hs.moduleType; // HEALTH_MODULE, WEBINAR, ACTIVE_BREAK
            const moduleLabel = hs.moduleName;
            const topic = t.trim(); // MOVEMENT_SPINE_HEALTH, NUTRITION, RELAX_STRESS_HANDLING, SLEEP_AND_WORK, CLIMATE_AND_HEALTH, CROSS_TOPIC
            const topicName = this.translateService.instant(topic);

            if (!groups[moduleType]) {
              groups = {
                ...groups,
                [moduleType]: [],
              };
            }

            let topicFound: boolean = false;
            for (const m of groups[moduleType]) {
              if (m.name === topicName) {
                topicFound = true;
                break;
              }
            }

            if (!topicFound) {
              groups[moduleType].push({
                key: topic,
                modules: [],
                name: topicName,
              });
            }

            const data = {
              moduleType: hs.moduleType,
              online: hs.location?.includes("ONLINE") || hs.online,
              onsite: hs.onsite,
              recommendedOnsiteInterval: hs.recommendedOnsiteInterval,
              alternativeOnsiteInterval: hs.alternativeOnsiteInterval,
              recommendedOnlineInterval: hs.recommendedOnlineInterval,
              durationInMinutes: hs.durationInMinutes,
              maxParticipantsOnline: hs.maxParticipantsOnline,
              maxParticipantsOnsite: hs.maxParticipantsOnsite,
            };

            groups[moduleType]
              .find((t) => t.key === topic)
              .modules.push({
                label: moduleLabel,
                value: hs.id,
                data: { topic, ...data },
              });
          });
        }
        const indexesOfTopicGroupWithoutModules = [];

        Object.keys(groups).forEach((key) => {
          for (let i = groups[key].length - 1; i >= 0; i--) {
            if (groups[key][i].modules.length === 0) {
              indexesOfTopicGroupWithoutModules.push(i);
            }
          }
          for (const index of indexesOfTopicGroupWithoutModules) {
            groups[key].splice(index, 1);
          }
          for (const tg of groups[key]) {
            tg.modules.sort(this.formHelper.sortByLabel);
          }
        });

        const order = { MOVEMENT_SPINE_HEALTH: 1, RELAX_STRESS_HANDLING: 2, NUTRITION: 3, SLEEP_AND_WORK: 4, CLIMATE_AND_HEALTH: 5, CROSS_TOPIC: 6, default: 1000 };
        Object.keys(groups).forEach((key) => {
          const group = groups[key];
          group.sort((a, b) => {
            return (order[a.key] || order.default) - (order[b.key] || order.default);
          });
        });

        resolve(groups);
      });
    });
    return promise;
  }

  createModuleOptionsGroupedByTopic(online: boolean, overrideCustomerCompanynameAlias: string): Promise<any[]> {
    const promise = new Promise<any[]>((resolve) => {
      const topicGroups = [];
      this.findAllForModuleSelection(overrideCustomerCompanynameAlias).subscribe((res) => {
        for (const healthscreening of res.body) {
          const topics: string[] = healthscreening.topic.split(",");
          topics.forEach((topic) => {
            const topicName = this.translateService.instant(topic.trim());
            let topicGroup = { name: topicName, modules: [], key: topic.trim() };
            if (this.topicGroupExists(topicGroups, topicName)) {
              topicGroup = this.getTopicGroupArray(topicGroups, topicName);
            }
            const moduleLabel = healthscreening.moduleName;
            // TODO: needs to be dynamic (pass it as variable)
            const extras = {
              moduleType: healthscreening.moduleType,
              online: healthscreening.online,
              onsite: healthscreening.onsite,
              recommendedOnsiteInterval: healthscreening.recommendedOnsiteInterval,
              alternativeOnsiteInterval: healthscreening.alternativeOnsiteInterval,
              recommendedOnlineInterval: healthscreening.recommendedOnlineInterval,
              durationInMinutes: healthscreening.durationInMinutes,
              maxParticipantsOnline: healthscreening.maxParticipantsOnline,
              maxParticipantsOnsite: healthscreening.maxParticipantsOnsite,
            };

            if (healthscreening.moduleType === "HEALTH_MODULE") {
              if (online) {
                if ((healthscreening.location && healthscreening.location.includes("ONLINE")) || healthscreening.online) {
                  topicGroup.modules.push({ label: moduleLabel, value: healthscreening.id, data: { topic: topic.trim(), ...extras } });
                }
              } else {
                if ((healthscreening.location && (healthscreening.location.includes("INDOOR") || healthscreening.location.includes("OUTDOOR"))) || healthscreening.onsite) {
                  topicGroup.modules.push({ label: moduleLabel, value: healthscreening.id, data: { topic: topic.trim(), ...extras } });
                }
              }
            }
            this.addOrReplaceTopicGroup(topicGroups, topicGroup);
          });
        }
        const indexesOfTopicGroupWithoutModules = [];
        for (let i = topicGroups.length - 1; i >= 0; i--) {
          if (topicGroups[i].modules.length === 0) {
            indexesOfTopicGroupWithoutModules.push(i);
          }
        }
        for (const index of indexesOfTopicGroupWithoutModules) {
          topicGroups.splice(index, 1);
        }
        for (const tg of topicGroups) {
          tg.modules.sort(this.formHelper.sortByLabel);
        }
        resolve(topicGroups);
      });
    });
    return promise;
  }

  topicGroupExistsByParams(topicGroups: any[], moduleType: string, topic: string, online: boolean, onsite: boolean): boolean {
    for (const topicGroup of topicGroups) {
      if (topicGroup.moduleType === moduleType && topicGroup.topic === topic && topicGroup.online === online && topicGroup.onsite === onsite) {
        return true;
      }
    }
    return false;
  }

  topicGroupExists(topicGroups: any[], topicName: string): boolean {
    for (const topicGroup of topicGroups) {
      if (topicGroup.name === topicName) {
        return true;
      }
    }
    return false;
  }

  getTopicGroupArrayByParams(topicGroups: any[], moduleType: string, topic: string, online: boolean, onsite: boolean) {
    for (const topicGroup of topicGroups) {
      if (topicGroup.moduleType === moduleType && topicGroup.topic === topic && topicGroup.online === online && topicGroup.onsite === onsite) {
        return topicGroup;
      }
    }
    return [];
  }

  getTopicGroupArray(topicGroups: any[], topicName: string) {
    for (const topicGroup of topicGroups) {
      if (topicGroup.name === topicName) {
        return topicGroup;
      }
    }
    return [];
  }

  addOrReplaceTopicGroupByParams(topicGroups: any[], topicGroup: any) {
    let tgFound = false;
    for (const tg of topicGroups) {
      if (tg.moduleType === topicGroup.moduleType && tg.topic === topicGroup.topic && tg.online === topicGroup.online && tg.onsite === topicGroup.onsite) {
        tg.modules = topicGroup.modules;
        tgFound = true;
      }
    }
    if (!tgFound) {
      topicGroups.push(topicGroup);
    }
  }

  addOrReplaceTopicGroup(topicGroups: any[], topicGroup: any) {
    let tgFound = false;
    for (const tg of topicGroups) {
      if (tg.name === topicGroup.name) {
        tg.modules = topicGroup.modules;
        tgFound = true;
      }
    }
    if (!tgFound) {
      topicGroups.push(topicGroup);
    }
  }

  createModuleHints(overrideCustomerCompanynameAlias: string): Promise<ValueLabel[]> {
    const promise = new Promise<ValueLabel[]>((resolve) => {
      const moduleOptions = [];
      this.findAllForModuleSelection(overrideCustomerCompanynameAlias).subscribe((res) => {
        for (const healthscreening of res.body) {
          if (healthscreening.hint && healthscreening.hint !== "") {
            const moduleLabel = healthscreening.moduleName + " - " + healthscreening.hint;
            moduleOptions.push({ label: moduleLabel, value: healthscreening.id });
          } else {
            moduleOptions.push({ label: "", value: healthscreening.id });
          }
        }
        resolve(moduleOptions);
      });
    });
    return promise;
  }

  createOnlineModuleHints(overrideCustomerCompanynameAlias: string): Promise<ValueLabel[]> {
    const promise = new Promise<ValueLabel[]>((resolve) => {
      const moduleOptions = [];
      this.findAllForModuleSelection(overrideCustomerCompanynameAlias).subscribe((res) => {
        for (const healthscreening of res.body) {
          if (healthscreening.onlineHint && healthscreening.onlineHint !== "") {
            const moduleLabel = healthscreening.moduleName + " - " + healthscreening.onlineHint;
            moduleOptions.push({ label: moduleLabel, value: healthscreening.id });
          } else {
            moduleOptions.push({ label: "", value: healthscreening.id });
          }
        }
        resolve(moduleOptions);
      });
    });
    return promise;
  }

  createHealthscreeningForm(healthscreening: HealthScreening): UntypedFormGroup {
    const customers = healthscreening.visibileForCompanynameAliases;
    this.correctFormatOfHealthscreening(healthscreening);
    const healthscreeningForm = this.formbuilder.group(healthscreening);
    this.handleAssignedUser(healthscreeningForm, healthscreening.assignedUsers);
    // this.handleVisibileForCompanynameAliases(healthscreeningForm, healthscreening.visibileForCompanynameAliases);
    this.handleArrayFromObject(healthscreening, healthscreeningForm, "topic");
    this.handleArrayFromObject(healthscreening, healthscreeningForm, "location");
    this.handleArrayFromObject(healthscreening, healthscreeningForm, "onlinePlatform");

    // re-map visibileForCompanynameAliases as array
    healthscreeningForm.get("visibileForCompanynameAliases").setValue(customers);
    return healthscreeningForm;
  }

  correctFormatOfHealthscreening(healthscreening: HealthScreening) {
    const array = [];
    if (healthscreening.visibileForCompanynameAliases != null) {
      for (const id of healthscreening.visibileForCompanynameAliases) {
        array.push({ id });
      }
    }
    healthscreening.visibileForCompanynameAliases = array;
  }

  // handleVisibileForCompanynameAliases(form: UntypedFormGroup, visibileForCompanynameAliases: string[]) {
  //   form.removeControl("visibileForCompanynameAliases");
  //   if (!visibileForCompanynameAliases || visibileForCompanynameAliases.length === 0) {
  //     form.addControl("visibileForCompanynameAliases", this.formbuilder.array([]));
  //   } else {
  //     const visibileForCompanynameAliasesArray = [];
  //     for (const id of visibileForCompanynameAliases) {
  //       visibileForCompanynameAliasesArray.push(id);
  //     }
  //     form.addControl("visibileForCompanynameAliases", this.formbuilder.array(visibileForCompanynameAliasesArray));
  //   }
  // }

  handleAssignedUser(form: UntypedFormGroup, assignedUsers: TrainerForAssignmentDto[]) {
    form.removeControl("assignedUsers");
    if (!assignedUsers || assignedUsers.length === 0) {
      form.addControl("assignedUsers", this.formbuilder.array([]));
    } else {
      const assignedUsersGroupArray = [];
      for (const assignedUser of assignedUsers) {
        const assignedUserFormGroup = this.formbuilder.group(assignedUser);
        assignedUsersGroupArray.push(assignedUserFormGroup);
      }
      form.addControl("assignedUsers", this.formbuilder.array(assignedUsersGroupArray));
    }
  }

  mapFormToHealthScreening(form: UntypedFormGroup): HealthScreening {
    const healthscreening = form.getRawValue();
    this.handleArrayFromForm(healthscreening, form, "topic");
    this.handleArrayFromForm(healthscreening, form, "location");
    this.handleArrayFromForm(healthscreening, form, "onlinePlatform");
    return healthscreening;
  }

  handleVisibileForCompanynameAliasesForUpdate(healthscreening: HealthScreening) {
    let formValue = healthscreening.visibileForCompanynameAliases;
    if (formValue) {
      let array = [];
      for (const id of formValue) {
        array.push(id);
      }
      healthscreening.visibileForCompanynameAliases = array;
    } else {
      healthscreening.visibileForCompanynameAliases = [];
    }
  }

  // handleLocationFromObject(healthscreening: HealthScreening, form: UntypedFormGroup) {
  //   if (healthscreening.location) {
  //     form.get("location").setValue(healthscreening.location.split(", "));
  //   } else {
  //     form.get("location").setValue([]);
  //   }
  // }

  // handleLocationFromForm(healthscreening: HealthScreening, form: UntypedFormGroup) {
  //   let locationValue = form.get("location").value;
  //   if (locationValue && locationValue.length > 0) {
  //     locationValue = locationValue.join(", ");
  //   } else {
  //     locationValue = null;
  //   }
  //   healthscreening.location = locationValue;
  // }

  handleArrayFromObject(healthscreening: HealthScreening, form: UntypedFormGroup, property: string) {
    if (healthscreening[property]) {
      if (healthscreening[property]) {
        form.get(property).setValue(healthscreening[property].split(", "));
      } else {
        form.get(property).setValue([]);
      }
    }
  }

  handleArrayFromForm(healthscreening: HealthScreening, form: UntypedFormGroup, property: string) {
    let value = form.get(property).value;
    if (value && value.length > 0) {
      value = value.join(", ");
    } else {
      value = null;
    }
    healthscreening[property] = value;
  }
}
