import { MeetingService } from "./../../../../services/meeting.service";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { EventService } from "../../../../services/event.service";
import { UntypedFormGroup } from "@angular/forms";
import { FormHelper } from "../../../../helper/form.helper";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { trigger, state, transition, style, animate } from "@angular/animations";
import * as moment from "moment";
import { InternalNoteService } from "src/app/services/internal-note.service";
import { EventOverviewDTO } from "../../../../services";
// Store
import { Store } from "@ngrx/store";
import * as fromStore from "../../../../store";

@Component({
  selector: "event-logistics-bottom-sheet",
  templateUrl: "./event-logistics-bottom-sheet.component.html",
  styleUrls: ["./event-logistics-bottom-sheet.component.sass"],
  animations: [trigger("visibility", [state("visible", style({ opacity: 1, height: "*" })), state("hidden", style({ opacity: 0, height: "0px" })), transition("visible <=> hidden", animate(500))])],
})
export class EventLogisticsBottomSheetComponent implements OnInit {
  eihsId: number;
  eihsIndex: string;
  eventForm: UntypedFormGroup;
  internalNoteForm: UntypedFormGroup;
  emailSent = false;
  meetingForm: UntypedFormGroup;
  userRole = "";

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private eventService: EventService,
    private formHelper: FormHelper,
    private dialogService: GibDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private meetingService: MeetingService,
    private internalNoteService: InternalNoteService,
    private store: Store<fromStore.GibState>
  ) {}

  ngOnInit() {
    this.eihsId = this.data.eventInquiryHealthscreeningId;
    this.userRole = localStorage.getItem("role");
    this.eventService.findById(this.data.eventId).subscribe((res) => {
      this.eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(res.body, this.eihsId);
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.handleEventForm();
      this.presetVehicleValues();

      if (this.isCompletedOrRejected) {
        this.eventForm.disable();
      }

      this.cdr.detectChanges();

      this.internalNoteService.getById(this.data.eventId, this.userRole).subscribe((res) => {
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
        this.formHelper.disableControls(this.internalNoteForm);
      });
    });
    this.meetingService.getMeetingForEihsId(this.eihsId).subscribe((res) => {
      this.meetingForm = this.meetingService.mapMeetingToForm(res.body);
      this.formHelper.disableControls(this.meetingForm);
      this.cdr.detectChanges();
    });
  }

  handleEventForm() {
    this.formHelper.disableControlsByName(this.eventForm, ["orderNumber", "eventDate", "corporateCustomerCompanyName", "targetGroup", "corporateCustomerContactSalutation", "corporateCustomerContactFirstname", "corporateCustomerContactLastname", "corporateCustomerContactPhone", "corporateCustomerContactMail"]);
    this.formHelper.disableControlsByName(this.eventForm.get("eventlocation"), ["city"]);
    for (const ehs of this.eventForm.get("eventHealthScreenings")["controls"]) {
      this.formHelper.disableControls(ehs);
      this.formHelper.enableControlsByName(ehs, ["vehicle", "vehiclePassengerCount", "vehicleModuleTimeFrom", "vehicleModuleTimeTo", "vehicleModuleDateFrom", "vehicleModuleDateTo", "urlForTrainer"]);
    }
  }

  presetVehicleValues() {
    if (!this.eventForm.get("eventHealthScreenings").get(this.eihsIndex).get("vehicleModuleDateFrom").value) {
      this.eventForm.get("eventHealthScreenings").get(this.eihsIndex).get("vehicleModuleDateFrom").setValue(this.eventForm.get("eventDate").value);
    }
    if (!this.eventForm.get("eventHealthScreenings").get(this.eihsIndex).get("vehicleModuleDateTo").value) {
      this.eventForm.get("eventHealthScreenings").get(this.eihsIndex).get("vehicleModuleDateTo").setValue(this.eventForm.get("eventDate").value);
    }
  }

  sendLogisticDataToTrainerClicked() {
    let confirmationTitle = "";
    let confirmationText = "";
    const timeStamp = this.eventForm.get("eventHealthScreenings").get(this.eihsIndex).get("trainerEmailTimestamp").value;
    if (timeStamp && timeStamp !== "") {
      const formattedTimestamp = moment(timeStamp).format("DD.MM.YYYY HH:mm");
      confirmationTitle = this.translateService.instant("sendLogisticDataAgainTitle");
      confirmationText = this.translateService.instant("sendLogisticDataAgainText", { sendDate: formattedTimestamp });
    } else {
      confirmationTitle = this.translateService.instant("sendLogisticDataTitle");
      confirmationText = this.translateService.instant("sendLogisticDataText");
    }
    this.dialogService.openConfirmationDialog(confirmationTitle, confirmationText, () => this.sendLogisticDataToTrainer());
  }

  sendLogisticDataToTrainer() {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    this.eventService.sendLogisticsDataToTrainer(event, this.eihsId).subscribe((res) => {
      const updatedEvent: EventOverviewDTO = res.body;
      this.store.dispatch(new fromStore.UpdateEventSuccess(updatedEvent));
      this.emailSent = true;
      this.formHelper.disableControls(this.eventForm);
      this.cdr.detectChanges();
    });
  }

  get isCompletedOrRejected() {
    return this.eventForm.get("state").value === "COMPLETED" || this.eventForm.get("state").value === "REJECTED";
  }
}
