import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'availability', pure: false })
export class AvailabilityPipe implements PipeTransform {

  constructor() {}

  transform(objects: any[], availability: string) {
    if (!availability) {
      return objects;
    } else {
      return objects.filter(
        object => object['availability'] === availability
      );
    }
  }

}
