import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "customer-healthscreening-info-form",
  templateUrl: "./customer-healthscreening-info-form.component.html",
  styleUrls: ["./customer-healthscreening-info-form.component.scss"],
})
export class CustomerHealthscreeningInfoFormComponent implements OnInit {
  @Input() healthscreeningForm: UntypedFormGroup;
  @Input() imageUrls: string[];
  @Input() videoUrls: string[];
  @Output() addedToCartEmit = new EventEmitter();
  userRole: string;

  constructor() {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem("role");
  }

  addToCartEmit() {
    this.addedToCartEmit.emit();
  }

  get isHealthModule(): boolean {
    return this.healthscreeningForm.get("moduleType").value === "HEALTH_MODULE";
  }

  get isOnline(): boolean {
    return (this.healthscreeningForm.get("location").value && this.healthscreeningForm.get("location").value.includes("ONLINE")) || this.healthscreeningForm.get("online").value;
  }

  get isOnsite(): boolean {
    return this.healthscreeningForm.get("onsite").value;
  }
}
