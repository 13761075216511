
import { Action } from "@ngrx/store";
import { ValueLabel } from "src/app/components/value-label";
import { Employee, TrainerForAssignmentDto } from "src/app/services/employee.service";
import { Role } from "src/app/services/keycloak-connector.service";

export const LOAD_EMPLOYEES = "[EMPLOYEES] Load Employees";
export const LOAD_EMPLOYEES_SUCCESS = "[EMPLOYEES] Load Employees Success";
export const LOAD_EMPLOYEES_FAIL = "[EMPLOYEES] Load Employees Fail";
export const LOAD_EMPLOYEES_BY_ROLE = "[EMPLOYEES] Load Employees by role";
export const LOAD_EMPLOYEES_BY_ROLE_SUCCESS = "[EMPLOYEES] Load Employees by role Success";
export const LOAD_EMPLOYEES_BY_ROLE_FAIL = "[EMPLOYEES] Load Employees by role Fail";
export const LOAD_ROLES = "[EMPLOYEES] Load Roles";
export const LOAD_ROLES_SUCCESS = "[EMPLOYEES] Load Roles Success";
export const LOAD_ROLES_FAIL = "[EMPLOYEES] Load Roles Fail";
export const LOAD_TRAINER_FOR_ASSIGNMENT = "[EMPLOYEES] Load Trainer for assignment";
export const LOAD_TRAINER_FOR_ASSIGNMENT_SUCCESS = "[EMPLOYEES] Load Trainer for assignment Success";
export const LOAD_TRAINER_FOR_ASSIGNMENT_FAIL = "[EMPLOYEES] Load Trainer for assignment Fail";

export class LoadEmployees implements Action {
  readonly type = LOAD_EMPLOYEES;
}
export class LoadEmployeesSuccess implements Action {
  readonly type = LOAD_EMPLOYEES_SUCCESS;
  constructor(public payload: {employees: Employee[]}) {}
}
export class LoadEmployeesFail implements Action {
  readonly type = LOAD_EMPLOYEES_FAIL;
  constructor(public payload: any) {}
}
export class LoadEmployeesByRole implements Action {
  readonly type = LOAD_EMPLOYEES_BY_ROLE;
  constructor(public payload: {roles: Role[]}) {}
}
export class LoadEmployeesByRoleSuccess implements Action {
  readonly type = LOAD_EMPLOYEES_BY_ROLE_SUCCESS;
  constructor(public payload: {employees: Employee[]}) {}
}
export class LoadEmployeesByRoleFail implements Action {
  readonly type = LOAD_EMPLOYEES_BY_ROLE_FAIL;
  constructor(public payload: any) {}
}
export class LoadRoles implements Action {
  readonly type = LOAD_ROLES;
}
export class LoadRolesSuccess implements Action {
  readonly type = LOAD_ROLES_SUCCESS;
  constructor(public payload: {roles: ValueLabel[]}) {}
}
export class LoadRolesFail implements Action {
  readonly type = LOAD_ROLES_FAIL;
  constructor(public payload: any) {}
}
export class LoadTrainerForAssignment implements Action {
  readonly type = LOAD_TRAINER_FOR_ASSIGNMENT;
}
export class LoadTrainerForAssignmentSuccess implements Action {
  readonly type = LOAD_TRAINER_FOR_ASSIGNMENT_SUCCESS;
  constructor(public payload: {trainer: TrainerForAssignmentDto[]}) {}
}
export class LoadTrainerForAssignmentFail implements Action {
  readonly type = LOAD_TRAINER_FOR_ASSIGNMENT_FAIL;
  constructor(public payload: any) {}
}

export type EmployeeActions = LoadEmployees | LoadEmployeesSuccess | LoadEmployeesFail | LoadEmployeesByRole | LoadEmployeesByRoleSuccess | LoadEmployeesByRoleFail | LoadRoles | LoadRolesSuccess | LoadRolesFail | LoadTrainerForAssignment | LoadTrainerForAssignmentSuccess | LoadTrainerForAssignmentFail;
