import * as fromTkControllers from "../actions/tk-controller.actions";
import { Customer } from "src/app/services/customer.service";

export interface TkControlerState {
  tkControllers: Customer[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: TkControlerState = {
  tkControllers: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromTkControllers.TkControllerActions): TkControlerState {
  switch (action.type) {
    case fromTkControllers.LOAD_TK_CONTROLLERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromTkControllers.LOAD_TK_CONTROLLERS_SUCCESS: {
      return {
        ...state,
        tkControllers: action.payload.tkControllers,
        loading: false,
        loaded: true,
      };
    }
    case fromTkControllers.LOAD_TK_CONTROLLERS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getTkControllers = (state: TkControlerState) => state.tkControllers;
