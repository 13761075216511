import { GibGridHospitantSelectComponent } from "./../../../../components/gib-grid-hospitant-select/gib-grid-hospitant-select.component";
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { EventOverviewDTO, EventOverviewUpdateDTO, EventOverviewService, Filter } from "../../../../services/event-overview.service";
import { TranslateService } from "@ngx-translate/core";
import { GridOptions, Module, AllCommunityModules } from "@ag-grid-community/all-modules";
import { GibGridInputComponent } from "../../../../components/gib-grid-input/gib-grid-input.component";
import { GibGridTrainerSelectComponent } from "../../../../components/gib-grid-trainer-select/gib-grid-trainer-select.component";
import { GridLogisticButtonComponent } from "../grid-logistic-button/grid-logistic-button.component";
import { GridEventAbortButtonComponent } from "../grid-event-abort-button/grid-event-abort-button.component";
import { GridFeedbackButtonComponent } from "../grid-feedback-button/grid-feedback-button.component";
import { GridEventDetailsButtonComponent } from "../grid-event-details-button/grid-event-details-button.component";
import { DatePipe } from "@angular/common";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "event-grid",
  templateUrl: "./event-grid.component.html",
  styleUrls: ["./event-grid.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class EventGridComponent implements OnInit, OnChanges {
  @Output() refreshData = new EventEmitter();
  @Input() paginationNumber: number;
  @Input() type: string;

  evnts: EventOverviewDTO[] = [];
  modules: Module[] = AllCommunityModules;
  lastEditedIndex;

  cellEditStartValue;

  gridColumnApi;

  gridOptions: GridOptions = {
    context: {
      componentParent: this,
    },
    rowData: [],
    defaultColDef: {
      sortable: false,
      maxWidth: 300,
      filterParams: { newRowsAction: "keep" },
      valueSetter: (params) => {
        const id = params.data.eventInquiryHealthscreeningId;
        const key = params.colDef.field;
        const newValue = params.newValue;

        const eventToUpdate = new EventOverviewUpdateDTO();
        eventToUpdate.eventId = params.data.eventId;
        eventToUpdate.eventInquiryHealthscreeningId = id;
        eventToUpdate.key = key;
        eventToUpdate.value = newValue;

        this.update.emit({ payload: eventToUpdate, date: new Date(Date.parse(params.data.eventDate)) });

        return false;
      },
    },
    columnDefs: this.createColumnDefs(),
    suppressColumnVirtualisation: true,
    rowClassRules: {
      "grid-spacer": (params) => {
        if (!params.node.lastChild) {
          if (params.data.eventDate !== params.node.parent.allLeafChildren[params.node.rowIndex + 1].data.eventDate) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      },
      "grid-berlin-marker": (params) => {
        if (params.data.distanceFromBerlin < params.data.distanceFromHamburg) {
          return true;
        } else {
          return false;
        }
      },
    },
    onGridReady: (params) => {
      this.gridColumnApi = params.columnApi;
    },
    onCellEditingStarted: ($event) => {
      this.cellEditStartValue = $event.value;
    },
    onCellEditingStopped: ($event) => {
      const node = $event.node;
      this.lastEditedIndex = node.rowIndex;
      if ($event.data.eventInquiryHealthscreeningId && $event.colDef.field) {
        if ($event.colDef.field === "timeFrom" || $event.colDef.field === "timeTo") {
          if ($event.value.length === 5) {
            $event.value += ":00";
          }
        }
        if ($event.colDef.field === "distanceFromHamburg" || $event.colDef.field === "distanceFromBerlin") {
          if ($event.value === "") {
            $event.value = 0;
          }
        }
        // this.updateEventOverviewDTO($event.data.eventInquiryHealthscreeningId, $event.colDef.field, $event.value).subscribe(
        //   (res) => {},
        //   (erroredKey) => {
        //     // reset value of node
        //     node.setDataValue(erroredKey, this.cellEditStartValue);
        //   }
        // );
      }
    },
    suppressScrollOnNewData: true,
    onPaginationChanged: ($event) => {
      if ($event.newPage) {
        window.scroll(0, 0);
        this.autoSizeAllColumns();
      }
    },
    rowHeight: 30, // recommended row height for material design data grids,
    frameworkComponents: {
      inputRenderer: GibGridInputComponent,
      selectTrainerRenderer: GibGridTrainerSelectComponent,
      logisticButtonRenderer: GridLogisticButtonComponent,
      abortButtonRenderer: GridEventAbortButtonComponent,
      feedbackButtonRenderer: GridFeedbackButtonComponent,
      eventDetailButtonRenderer: GridEventDetailsButtonComponent,
      selectHospitantRenderer: GibGridHospitantSelectComponent,
    },
    onFilterChanged: (params) => {
      const numberOfColumns = params.columnApi.getAllDisplayedColumns().length;
      let counter = 0;
      const filters: Filter[] = [];

      for (const col of params.columnApi.getAllDisplayedColumns()) {
        const api = params.api.getFilterInstance(col);
        if (api.getModel()) {
          if (api.getModel().filter) {
            // At least one filter is still active
            // return;
            filters.push({
              columnId: col.getColId(),
              filter: api.getModel().filter,
              filterType: api.getModel().filterType,
              type: api.getModel().type,
            });
          }
        } else {
          counter++;
        }
      }
      this.filter.emit(filters);

      this.goToTodayPage();
    },
    onFilterModified: (params) => {},
  };

  columnDefs = this.createColumnDefs();

  rowData = [];

  @Input() set events(events: EventOverviewDTO[]) {
    if (events) {
      this.evnts = events;
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.setRowData(this.evnts);
        setTimeout(() => {
          this.autoSizeAllColumns();
        }, 300);
        if (this.type === "fullYear" && !this.lastEditedIndex) {
          this.goToTodayPage();
        }
      }
    }
  }

  @Input() date: Date;
  @Input() from: Date;
  @Input() to: Date;
  @Input() paging: boolean;
  @Input() set filters(filters: Filter[]) {
    this.filterText = "";
    for (const [i, v] of filters.entries()) {
      const text = this.translate.instant(v.columnId) + " " + this.translate.instant(v.type) + " " + v.filter;
      if (i === filters.length - 1) {
        this.filterText += text;
      } else {
        this.filterText += text + " " + this.translate.instant("and") + " ";
      }
    }
  }
  @Input() set focus(focus: boolean) {
    if (focus) {
      this.goToTodayPage();
    }
  }
  @Output() move = new EventEmitter<{ offset: number; date: Date }>();
  @Output() filter = new EventEmitter<Filter[]>();
  @Output() update = new EventEmitter<{ payload: EventOverviewUpdateDTO; date: Date }>();

  offset = 0;

  dateControl = new FormControl(moment());

  filterText: string;

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.dateControl.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateControl.setValue(ctrlValue);
    datepicker.close();
    this.move.emit({ offset: null, date: this.dateControl.value.toDate() });
  }

  get dateDisplay() {
    if (!this.paging) {
      return this.datePipe.transform(this.from) + " - " + this.datePipe.transform(this.to);
    }
    return this.datePipe.transform(this.date, "MMMM, YYYY");
  }

  moveDate(offset: number) {
    this.offset = offset;
    this.move.emit({ offset, date: null });
  }

  goToTodayPage() {
    let todaysIndex = this.findIndexForTodaysDate(this.evnts);
    let pageWithTodaysIndex = Math.floor(todaysIndex / this.paginationNumber);
    this.gridOptions.api.paginationGoToPage(pageWithTodaysIndex);
    this.gridOptions.api.ensureIndexVisible(this.findIndexForTodaysDate(this.evnts), "top");
  }

  findIndexForTodaysDate(events: EventOverviewDTO[]) {
    const todayDateString = moment().format("YYYY-MM-DD");
    for (let i = 0; i < events.length; i++) {
      if (events[i].eventDate === todayDateString) {
        return i;
      }
    }
    return 0;
  }

  constructor(private translate: TranslateService, private eventOverviewService: EventOverviewService, private datePipe: DatePipe) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.date) {
      this.dateControl.setValue(moment(this.date));
    }
  }

  createColumnDefs() {
    return [
      {
        field: "eventDate",
        headerName: this.translate.instant("calendarWeek"),
        valueFormatter: this.calendarWeekFormatter,
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "editButton",
        headerName: "",
        minWidth: 40,
        cellRendererSelector: (params) => {
          return {
            component: "eventDetailButtonRenderer",
            params: {
              refreshData: () => this.refreshData.emit(null),
              showButton: params.data.state !== "CANCELED" && params.data.eventId !== -1000,
              isEditable: this.isEditable(params),
            },
          };
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "logisticsButton",
        headerName: "",
        minWidth: 40,
        cellRendererSelector: (params) => {
          return {
            component: "logisticButtonRenderer",
            params: {
              refreshData: () => {}, //this.refreshData.emit(null),
            },
          };
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "abortButton",
        headerName: "",
        minWidth: 40,
        cellRendererSelector: (params) => {
          return {
            component: "abortButtonRenderer",
            params: {
              refreshData: () => this.refreshData.emit(null),
              showButton: params.data.state !== "CANCELED" && params.data.eventId !== -1000,
            },
          };
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "feedbackButton",
        headerName: "",
        minWidth: 40,
        cellRendererSelector: (params) => {
          return {
            component: "feedbackButtonRenderer",
            params: {
              refreshData: () => this.refreshData.emit(null),
              showButton: params.data.areFeedbacksAvailable,
            },
          };
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "eventDate",
        headerName: this.translate.instant("day"),
        valueFormatter: this.weekOfDayFormatter,
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "eventDate",
        headerName: this.translate.instant("eventDateShort"),
        valueFormatter: this.dateFormatter,
        suppressNavigable: true,
        pinned: "left",
      },

      {
        field: "cancelationDate",
        headerName: this.translate.instant("cancelationDate"),
        valueFormatter: this.dateFormatter,
        hide: true,
        cellStyle: (params) => {
          const eventDate = moment(params.data.eventDate);
          const cancelDate = moment(params.data.cancelationDate);
          if (params.data.state === "CANCELED" && eventDate.diff(cancelDate, "days") <= 2) {
            return { backgroundColor: "#81C397" };
          } else if (params.data.state === "CANCELED" && eventDate.diff(cancelDate, "days") > 2 && eventDate.diff(cancelDate, "days") <= 5) {
            return { backgroundColor: "#c8c618" };
          } else if (params.data.state === "CANCELED" && eventDate.diff(cancelDate, "days") > 5 && eventDate.diff(cancelDate, "days") <= 14) {
            return { backgroundColor: "#ffa500" };
          } else {
            return {};
          }
        },
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "cancelationDate",
        },
        pinned: "left",
      },
      {
        field: "cancelationReason",
        headerName: this.translate.instant("cancelationReason"),
        filter: "agTextColumnFilter",
        hide: true,
        pinned: "left",
      },
      {
        field: "companynameAlias",
        headerName: this.translate.instant("customer"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "orderNumber",
        headerName: this.translate.instant("orderNumber"),
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "orderNumber",
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        pinned: "left",
      },
      {
        field: "deliveryType",
        headerName: this.translate.instant("deliveryType"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        pinned: "left",
      },
      {
        field: "moduleName",
        headerName: this.translate.instant("moduleNameEventGrid"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "moduleName",
        },
        pinned: "left",
      },

      {
        field: "healthscreeningReference",
        headerName: this.translate.instant("healthscreeningReference"),
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "healthscreeningReference",
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        pinned: "left",
      },
      {
        field: "vehicle",
        headerName: this.translate.instant("vehicle"),
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "vehicle",
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        pinned: "left",
      },
      {
        field: "trainerId",
        headerName: this.translate.instant("TRAINER"),
        cellEditor: "selectTrainerRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "TRAINER",
        },
        valueFormatter: this.trainerFormatter,
        filterValueGetter: this.trainerFormatter,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        cellStyle: (params) => {
          if (params.data.trainerFirsttime && params.data.trainerAppointed) {
            return { backgroundColor: "#ffcaf3" };
          } else if (params.data.trainerAppointed) {
            return { backgroundColor: "#81C397" };
          } else {
            return {};
          }
        },
        pinned: "left",
      },
      // {
      //   field: "hospitantId",
      //   headerName: this.translate.instant("hospitant"),
      //   cellEditor: "selectHospitantRenderer",
      //   editable: (params) => {
      //     if (this.isEditable(params)) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   },
      //   cellEditorParams: {
      //     placeholder: "hospitant",
      //   },
      //   valueFormatter: this.hospitantFormater,
      //   filterValueGetter: this.hospitantFormater,
      //   filter: "agTextColumnFilter",
      //   filterParams: {
      //     filterOptions: ["contains"],
      //     suppressAndOrCondition: true,
      //   },
      // },
      {
        field: "agentNote",
        headerName: this.translate.instant("agentNote"),
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "agentNote",
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "internetboxReference",
        headerName: this.translate.instant("internetboxReference"),
        cellEditor: "inputRenderer",
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditorParams: {
          placeholder: "internetboxReference",
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "meetingAlias",
        headerName: this.translate.instant("meetingAlias"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "presentation",
        headerName: this.translate.instant("presentationShort"),
        valueGetter: (params) => {
          if (params.data && params.data.presentation && !params.data.presentationBillOnly) {
            return "X";
          } else if (params.data && !params.data.presentation && !params.data.presentationBillOnly) {
            return " ";
          } else if (params.data && !params.data.presentation && params.data.presentationBillOnly) {
            return "(X)";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        cellStyle: (params) => {
          return { textAlign: "center" };
        },
      },
      {
        field: "presentationEnglish",
        headerName: this.translate.instant("presentationEnglish"),
        valueGetter: (params) => {
          if (params.data && params.data.presentationEnglish) {
            return "X";
          } else {
            return " ";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        cellStyle: (params) => {
          return { textAlign: "center" };
        },
      },
      {
        field: "anonymousEvaluation",
        headerName: this.translate.instant("anonymousEvaluation"),
        valueGetter: (params) => {
          if (params.data && params.data.anonymousEvaluation && !params.data.anonymousEvaluationBillOnly) {
            return "X";
          } else if (params.data && !params.data.anonymousEvaluation && !params.data.anonymousEvaluationBillOnly) {
            return " ";
          } else if (params.data && !params.data.anonymousEvaluation && params.data.anonymousEvaluationBillOnly) {
            return "(X)";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        cellStyle: (params) => {
          return { textAlign: "center" };
        },
      },
      {
        field: "street",
        headerName: this.translate.instant("street"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "zip",
        headerName: this.translate.instant("zip"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "city",
        headerName: this.translate.instant("city"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "distanceFromHamburg",
        headerName: this.translate.instant("distanceFromHamburg"),
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditor: "inputRenderer",
        cellEditorParams: {
          placeholder: "distanceFromHamburg",
        },
      },
      {
        field: "distanceFromBerlin",
        headerName: this.translate.instant("distanceFromBerlin"),
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditor: "inputRenderer",
        cellEditorParams: {
          placeholder: "distanceFromBerlin",
        },
      },
      {
        field: "timeFrom",
        headerName: this.translate.instant("timeFrom"),
        valueFormatter: this.timeFormatter,
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditor: "inputRenderer",
        cellEditorParams: {
          placeholder: "healthscreeningReference",
        },
      },
      {
        field: "timeTo",
        headerName: this.translate.instant("timeTo"),
        valueFormatter: this.timeFormatter,
        editable: (params) => {
          if (this.isEditable(params)) {
            return true;
          } else {
            return false;
          }
        },
        cellEditor: "inputRenderer",
        cellEditorParams: {
          placeholder: "healthscreeningReference",
        },
      },
      {
        field: "contactPerson",
        headerName: this.translate.instant("contact_person"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        valueFormatter: this.customerContactFormatter,
        filterValueGetter: this.customerContactFormatter,
      },
      {
        field: "customerContactPhone",
        headerName: this.translate.instant("contactPhone"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "corporateCustomerCompanyName",
        headerName: this.translate.instant("company"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "corporateCustomerContact",
        headerName: this.translate.instant("contact_person"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
        valueFormatter: this.corporateCustomerContactFormatter,
        filterValueGetter: this.corporateCustomerContactFormatter,
      },
      {
        field: "corporateCustomerContactPhone",
        headerName: this.translate.instant("contactPhone"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "region",
        headerName: this.translate.instant("region"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
      {
        field: "teamnumber",
        headerName: this.translate.instant("teamNr"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        suppressNavigable: true,
      },
    ];
  }

  isEditable(params: any) {
    return params.data.state !== "COMPLETED" && params.data.state !== "REJECTED" && params.data.state !== "CANCELED" && params.data.state !== "CANCELED_BEFORE_BOOKED" && params.data.state !== "UNANSWERED" && params.data.eventId !== -1000;
  }

  hideButtons() {
    this.gridColumnApi.getAllColumns().forEach((column) => {
      if (column.colId === "logisticsButton") {
        this.gridOptions.columnApi.setColumnVisible(column, false);
      } else if (column.colId === "feedbackButton") {
        this.gridOptions.columnApi.setColumnVisible(column, false);
      } else if (column.colId === "abortButton") {
        this.gridOptions.columnApi.setColumnVisible(column, false);
      } else if (column.colId === "editButton") {
        this.gridOptions.columnApi.setColumnVisible(column, false);
      }
    });
  }

  autoSizeAllColumns() {
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    this.gridColumnApi.getAllColumns().forEach((column) => {
      if (["presentation", "presentationEnglish", "anonymousEvaluation"].includes(column.colId)) {
        this.gridColumnApi.setColumnWidth(column, 50);
      } else {
        this.gridColumnApi.setColumnWidth(column, column.actualWidth - 15);
      }
    });
  }

  calendarWeekFormatter(colData) {
    return moment(colData.value).format("WW");
  }

  dateFormatter(colData) {
    return moment(colData.value).format("Do MMM YY");
  }

  weekOfDayFormatter(colData) {
    return moment(colData.value).format("ddd");
  }

  trainerFormatter(colData) {
    if (colData.data && colData.data.trainerFirstname && colData.data.trainerLastname) {
      return colData.data.trainerFirstname + " " + colData.data.trainerLastname;
    } else {
      return "";
    }
  }

  hospitantFormater(colData) {
    if (colData.data && colData.data.hospitantFirstname && colData.data.hospitantLastname) {
      return colData.data.hospitantFirstname + " " + colData.data.hospitantLastname;
    } else {
      return "";
    }
  }

  customerContactFormatter(colData) {
    if (colData.data && colData.data.customerContactFirstname && colData.data.customerContactLastname) {
      return colData.data.customerContactFirstname + " " + colData.data.customerContactLastname;
    } else if (colData.data && colData.data.customerContactLastname) {
      if (colData.data.customerContactSalutation === "male") {
        return "Herr " + colData.data.customerContactLastname;
      } else {
        return "Frau " + colData.data.customerContactLastname;
      }
    }
    return "";
  }

  corporateCustomerContactFormatter(colData) {
    if (colData.data && colData.data.corporateCustomerContactFirstname && colData.data.corporateCustomerContactLastname) {
      return colData.data.corporateCustomerContactFirstname + " " + colData.data.corporateCustomerContactLastname;
    } else if (colData.data && colData.data.corporateCustomerContactLastname) {
      if (colData.data.corporateCustomerContactSalutation === "male") {
        return "Herr " + colData.data.corporateCustomerContactLastname;
      } else {
        return "Frau " + colData.data.corporateCustomerContactLastname;
      }
    }
    return "";
  }

  timeFormatter(colData) {
    if (colData.value) {
      return colData.value.substring(0, colData.value.lastIndexOf(":"));
    } else {
      return "";
    }
  }

  // updateEventOverviewDTO(id: number, key: string, value: string) {
  //   const eventOverviewUpdateDTO = new EventOverviewUpdateDTO();
  //   eventOverviewUpdateDTO.eventInquiryHealthscreeningId = id;
  //   eventOverviewUpdateDTO.key = key;
  //   eventOverviewUpdateDTO.value = value;
  //   return this.eventOverviewService.updateEvent(eventOverviewUpdateDTO).pipe(
  //     map((res) => {
  //       if (key === "trainerId" || key === "timeFrom" || key === "timeTo" || key === "hospitantId") {
  //         this.refreshData.emit(null);
  //         // this.gridColumnApi.refreshCells();
  //         // this.gridOptions.api.setRowData(this.gridOptions.rowData);
  //       }
  //       this.autoSizeAllColumns();
  //       return res;
  //     }),
  //     catchError((err) => {
  //       return throwError(key);
  //     })
  //   );
  // }
}
