<div *ngIf="paging && !filterText" class="controls d-flex justify-content-between align-items-center" style="height: 30px">
  <button type="button" (click)="moveDate(-1)">
    <img src="/assets/images/chevron-left.svg" alt="" />
  </button>

  <div>
    <mat-form-field>
      <input hidden matInput [matDatepicker]="dp" [formControl]="dateControl" />
      <p>{{ dateControl.value | date : "MMMM, YYYY" : "" : "de" }}</p>
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"> </mat-datepicker>
    </mat-form-field>
  </div>
  <button type="button" (click)="moveDate(+1)">
    <img src="/assets/images/chevron-right.svg" alt="" />
  </button>
</div>

<div *ngIf="!paging" class="controls d-flex justify-content-center align-items-center" style="height: 30px">
  <p>{{ dateDisplay }}</p>
</div>

<div *ngIf="filterText" class="controls d-flex justify-content-center align-items-center" style="height: 30px">
  <p>{{ filterText }}</p>
</div>

<ag-grid-angular *ngIf="gridOptions" [ngStyle]="{ width: '100%', height: '82vh' }" class="ag-theme-material" [gridOptions]="gridOptions" [paginationPageSize]="paginationNumber" [pagination]="false"> </ag-grid-angular>
