import { Meeting, MeetingService } from "./../../../../services/meeting.service";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { EventService, EventInquiryHealthscreening } from "../../../../services/event.service";
import { FormHelper } from "../../../../helper/form.helper";
import { UntypedFormGroup } from "@angular/forms";
import { InternalNoteService } from "src/app/services/internal-note.service";
import { EnvService } from "src/app/services/env.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "trainer-event-details-sheet",
  templateUrl: "./trainer-event-details-sheet.component.html",
  styleUrls: ["./trainer-event-details-sheet.component.scss"],
})
export class TrainerEventDetailsSheetComponent implements OnInit {
  eventId: number;
  eihsId: number;
  eihsIndex: string;
  eventForm: UntypedFormGroup;
  meetingForm: UntypedFormGroup;
  userRole = "";
  selectedTrainer: string;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private eventService: EventService,
    private translate: TranslateService,
    private formHelper: FormHelper,
    private cdr: ChangeDetectorRef,
    private envService: EnvService,
    private meetingService: MeetingService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.eventId = this.data.eventId;
    this.eihsId = this.data.eihsId;
    if (this.data && this.data.selectedTrainerUsername) {
      this.selectedTrainer = this.data.selectedTrainerUsername;
    }
    this.eventService.findForTrainerByBuid(this.data.buid).subscribe((res) => {
      this.eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(res.body, this.eihsId);
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(this.eventForm);
      this.cdr.detectChanges();
    });
    if (this.userRole !== 'gib_afg') {
      this.meetingService.getMeetingForEihsId(this.eihsId).subscribe((res) => {
        this.meetingForm = this.meetingService.mapMeetingToForm(res.body);
        this.formHelper.disableControls(this.meetingForm);
        this.cdr.detectChanges();
      });
    } else {
      this.meetingForm = this.meetingService.mapMeetingToForm(new Meeting())
    }
  }

  showIfAssignedTrainerIsCurrentUser(eihs: UntypedFormGroup) {
    if (eihs && eihs.get("trainer") && eihs.get("trainer").get("0")) {
      return eihs.get("trainer").get("0").get("username").value === this.selectedTrainer;
    }
    return false;
  }

  createLinkFor3rdParty(hsModule: any) {
    let uuid = hsModule.get('scheduleUuid').value
    let url = this.envService.authRedirectUrl + 'afg/schedules/' + uuid
    const copyButton = document.createElement("textarea");
    copyButton.style.position = "fixed";
    copyButton.style.left = "0";
    copyButton.style.top = "0";
    copyButton.style.opacity = "0";
    copyButton.value = url;
    document.body.appendChild(copyButton);
    copyButton.focus();
    copyButton.select();
    document.execCommand("copy");
    document.body.removeChild(copyButton);
    let text = this.translate.instant('linkCopied')
    this.snackbar.open(text, 'ok', {
      duration: 3000
    })
  }
}
