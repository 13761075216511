import { EventOverviewDTO } from './../../services/event-overview.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'event-grid',
  templateUrl: './event-grid-mock.component.html',
  styleUrls: ['./event-grid-mock.component.scss']
})
export class EventGridMockComponent implements OnInit {

  @Input() events: EventOverviewDTO[];
  @Input() paginationNumber: number;
  @Input() type: string;
  @Output() refreshData = new EventEmitter();

  gridOptions = {
    columnApi: {
      setColumnVisible: (a, b) => {

      }
    }
  };
  constructor() {}

  ngOnInit() {


  }

  autoSizeAllColumns() {}

  hideButtons() {}


}
