<div class="editRow">
  <i id="close_icon" placement="left" [ngbTooltip]="'close' | translate" class="material-icons close-icon" (click)="closeBottomSheet()">close</i>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="tab-scroll-overlay">
    </div>
    <mat-tab-group>
      <mat-tab *ngIf="eventForm && eventFeedbackCustomerForm" label="{{'customerFeedbackForm' | translate}}">
        <customer-feedback-form #customerFeedback [editMode]="editMode" [eventFeedbackCustomerForm]="eventFeedbackCustomerForm" [eventForm]="eventForm" [buid]="buid">
        </customer-feedback-form>
      </mat-tab>
      <mat-tab label="{{('feedback' | translate) + '(' + eventFeedbackTrainer[i].eihs.trainer[0].fullName + ')'}}" *ngFor="let feedbackForm of eventFeedbackTrainerForms; let i = index">
        <trainer-feedback-form #trainerFeedback *ngIf="eventForm && feedbackForm" [eventFeedbackTrainerForm]="feedbackForm" [eihs]="eventFeedbackTrainer[i].eihs" [eventForm]="eventForm" [buid]="buid">
        </trainer-feedback-form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>