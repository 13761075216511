<div class="row">
  <div class="col-xl-6 col-md-12">
    <h4>{{ "eventLocationInfo" | translate }}</h4>
    <div class="box">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-suggest-select *ngIf="eventForm.get('ccSearch') && !hideGibSuggestField" [placeholder]="'findCorporateCustomer'" [control]="eventForm.get('ccSearch')" [options]="corporateCustomerValueLabels"></gib-suggest-select>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'corporateCustomerCompanyName'" [control]="eventForm.get('corporateCustomerCompanyName')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'street'" [control]="eventForm.get('eventlocation').get('street')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'streetno'" [control]="eventForm.get('eventlocation').get('number')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'event_location'" [control]="eventForm.get('eventlocation').get('city')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'zip'" [control]="eventForm.get('eventlocation').get('zip')"></gib-input>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-md-12">
    <h4>{{ "contactPersonByCompanyInstitution" | translate }}</h4>
    <div class="box">
      <div class="row">
        <div class="col-xl-12" *ngIf="eventForm && !eventForm.get('corporateCustomerContactSalutation').valid && eventForm.get('corporateCustomerContactSalutation').touched && !eventForm.get('corporateCustomerContactSalutation').disabled">
          <gib-radio-button-group-orange [label]="'salutation'" [control]="eventForm.get('corporateCustomerContactSalutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
        </div>
        <div class="col-xl-12" *ngIf="eventForm && (eventForm.get('corporateCustomerContactSalutation').valid || !eventForm.get('corporateCustomerContactSalutation').touched || eventForm.get('corporateCustomerContactSalutation').disabled)">
          <gib-radio-button-group [label]="'salutation'" [control]="eventForm.get('corporateCustomerContactSalutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'firstname'" [control]="eventForm.get('corporateCustomerContactFirstname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'lastname'" [control]="eventForm.get('corporateCustomerContactLastname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="eventForm.get('corporateCustomerContactPhone')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'email'" [control]="eventForm.get('corporateCustomerContactMail')"></gib-input>
        </div>
      </div>
    </div>
  </div>
</div>
