<div class="row">
  <div class="col-sm-12 col-lg-8 col-xl-8">
    <h1>{{ "faqTitle" | translate }}</h1>
  </div>
  <div class="col-sm-12 col-lg-4 col-xl-4">
    <a class="btn btn-secondary add-faq-btn" (click)="addFaq()">+</a>
  </div>
  <div class="col-lg-12 col-md-12">
    <faq-table #faqTable [faqs]="faqs" (faqSelected)="setSelectedFaq($event)" (refresh)="loadFaqs()"></faq-table>
  </div>
</div>
