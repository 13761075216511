<div>
  <h1>GiB Home</h1>
  <h5>Hier sind ein paar Beispiele für verschiedene Komponenten</h5>

  <!-- <slideshow dotColor="var(--secondary)" autoPlay="true" showDots="true" [minHeight]="'525px'" [imageUrls]="imageUrlArray"></slideshow> -->

  <a class="btn btn-secondary" (click)="login()">Login</a>
  <a class="btn btn-secondary" (click)="logout()">logout</a>
  <button type="button" class="btn btn-primary" (click)="getClaims()">getclaims</button>
  <button type="button" class="btn btn-primary" (click)="users()">users</button>
  <button type="button" class="btn btn-primary" (click)="createUser()">add user</button>
  <button type="button" class="btn btn-primary" (click)="getRoles()">get roles</button>
  <button type="button" class="btn btn-primary" (click)="getUserRoles()">get user roles</button>
  <button type="button" class="btn btn-primary" (click)="getUserInfo()">user info</button>
  <br />
  redirect url: {{ redirectUrl }}
  <br />
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary" role="alert">Das ist ein afuklappbares Panel mit ein paar Eingabekomponenten</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
      <!-- <gib-select [placeholder]="'test test 123'" [options]="options" [(value)]="selectValue"></gib-select> -->
      <!-- <gib-suggest-select [placeholder]="'test test 123'" [options]="options" [(value)]="selectValue"></gib-suggest-select> -->
      selected value: {{ selectValue }}
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Zum Aufklappen hier klicken </mat-panel-title>
        </mat-expansion-panel-header>

        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Label des Eingabefeldes</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Platzhalter des Eingabefeldes" />
            <small id="emailHelp" class="form-text text-muted">Eine Beschreibung des Eingabefeldes</small>
          </div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Noch ein Eingabefeld" value="" />
          </mat-form-field>
          <div class="form-group">
            <label for="exampleInputEmail1">Nicht editierbares Eingabefeld</label>
            <input type="email" class="form-control" id="disabled-input" aria-describedby="emailHelp" readonly value="Das kann gerade nicht editiert werden" />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password Feld mit versteckten Eingaben</label>
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Eine Checkbox</label>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Dropdown mit einer Auswahl</label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect2">Dropdown mit mehreren Auswahlmöglichkeiten</label>
            <select multiple class="form-control" id="exampleFormControlSelect2">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <mat-form-field class="example-full-width">
            <input type="text" placeholder="Dropdown mit Filterfunktion" aria-label="Nutzer" matInput [formControl]="myControl" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Eingabefeld für viel Text</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <label for="exampleFormControlTextarea1">Radiobuttons</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
            <label class="form-check-label" for="exampleRadios1"> Button 1 </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
            <label class="form-check-label" for="exampleRadios2"> Button 2 </label>
          </div>
        </form>
      </mat-expansion-panel>
    </div>
    <div class="col-sm-3"></div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary" role="alert">Interaktive Elemente</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on top">Popover button</button>
      <button type="button" class="btn btn-outline-secondary mr-2" placement="right" ngbTooltip="Tooltip on right">Tooltip button</button>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="dateOfBirth">Date of birth</label>
              <div class="input-group">
                <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
        </div>
      </ng-template>
      <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Modal button</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary" role="alert">Hier haben wir ein paar sogenannte Card-Komponenten:</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" src="assets/images/modules/module1.jpg" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">MESSUNG DER MUSKULÄREN SPANNUNGSVERHÄLTNISSE UND MUSKELFUNKTION</h5>
          <p class="card-text crop">Durch diese Messung werden die Spannungszustände der Schulter- und Nackenmuskulatur gemessen und bewertet.</p>
          <a href="#" class="btn btn-secondary">Mehr erfahren</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" src="assets/images/modules/module1.jpg" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">MESSUNG DER KRAFTFÄHIGKEIT DER RUMPF- UND OBERKÖRPERMUSKULATUR</h5>
          <p class="card-text crop">Durch diese Messung werden die Spannungszustände der Schulter- und Nackenmuskulatur gemessen und bewertet.</p>
          <a href="#" class="btn btn-secondary">Mehr erfahren</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" src="assets/images/modules/module3.jpg" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">MESSUNG DES RISIKOS FÜR HERZINFARKT UND SCHLAGANFALL</h5>
          <p class="card-text crop">Durch diese Messung werden die Spannungszustände der Schulter- und Nackenmuskulatur gemessen und bewertet.</p>
          <a href="#" class="btn btn-secondary">Mehr erfahren</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary" role="alert">Hier sind ein paar Tabs</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-tab-group>
        <mat-tab label="Tab 1">Inhalt Tab 1 </mat-tab>
        <mat-tab label="Tab 2">Inhalt Tab 2 </mat-tab>
        <mat-tab label="Tab 3">Inhalt Tab 3 </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary" role="alert">Ein sogenannter Stepper. Das könnte für Veranstaltungsanfragen interessant sein.</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Veranstaltungsdaten eingeben</ng-template>
            <mat-form-field>
              <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required />
            </mat-form-field>
            <div>
              <button class="btn btn-secondary" matStepperNext>Weiter</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Module auswählen</ng-template>
            <mat-form-field>
              <input matInput placeholder="Address" formControlName="secondCtrl" required />
            </mat-form-field>
            <div>
              <button mat-button matStepperPrevious>Zurück</button>
              <button class="btn btn-secondary" matStepperNext>Weiter</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Fertig</ng-template>
          Bestätige die Anfrage in dem du auf Abschicken klickst.
          <div>
            <button mat-button matStepperPrevious>Zurück</button>
            <button class="btn btn-secondary">Abschicken</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
