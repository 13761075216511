import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ProcessService } from "src/app/services/process-service";
import { GibDialogService } from '../../../../../components/dialogs/gib-dialog.service';
import { FileMetaTaskDTO, FileService } from '../../../../../services/file.service';

@Component({
  selector: 'document-verification',
  templateUrl: './document-verification.component.html',
  styleUrls: ['./document-verification.component.scss'],
  animations: [
    trigger('visibility', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0px' })),
      transition('visible <=> hidden', animate(500)),
    ]),
  ]
})
export class DocumentVerificationComponent implements OnInit {

  file: FileMetaTaskDTO;
  fileData;
  showImage = false;
  downloadUrl;
  showButtons = true;
  result: string;

  @Input() set fileMeta(fileMeta: FileMetaTaskDTO) {
    this.file = fileMeta;
    this.fileData = null;
    this.getFileData();
    this.showImage = this.isImageFile(this.file.fileName);
  }

  get fileMeta() {
    return this.file;
  }

  constructor(private fileService: FileService, private dialogService: GibDialogService,
              private translateService: TranslateService, private processService: ProcessService) {}

  ngOnInit() {}

  getFileData() {
    this.fileService.getFileData(this.file.id).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      this.downloadUrl = window.URL.createObjectURL(blob);
      this.createImageFromBlob(res);
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      this.fileData = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openApproveConfirmation() {
    const title = this.translateService.instant('approveDocumentTitle');
    const text = this.translateService.instant('approveDocumentText');
    this.dialogService.openConfirmationDialog(title, text, () => this.approveDocument());
  }

  approveDocument() {
    this.file.status = 'APPROVED';
    this.processService.completeTask(this.file.taskId, 'APPROVED', null).subscribe(res => {
      this.showButtons = false;
      this.result = 'approved';
    });
  }
  
  openRejectConfirmation() {
    const title = this.translateService.instant('declineDocumentTitle');
    const text = this.translateService.instant('declineDocumentText');
    const confirmationPlaceholderText = 'decliningReason';
    this.dialogService.openConfirmationTextDialog(title, text, confirmationPlaceholderText).subscribe(res => {
      if (res) {
        this.rejectDocument(res);
      }
    });
  }
  
  rejectDocument(reason: string) {
    this.file.status = 'REJECTED';
    this.processService.completeTask(this.file.taskId, 'REJECTED', reason).subscribe(() => {
      this.showButtons = false;
      this.result = 'rejected';
    });
  }

  isImageFile(fileName: string) {
    fileName = fileName.toLowerCase();
    return fileName.endsWith('.bmp') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png');
  }

  downloadFile() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = this.downloadUrl;
    a.download = this.file.fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(this.downloadUrl);
      document.body.removeChild(a);
    }, 0);
  }

}
