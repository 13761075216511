import { Subscription } from "rxjs";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelper } from "../../helper/form.helper";

@Component({
  selector: "gib-textarea",
  templateUrl: "./gib-textarea.component.html",
  styleUrls: ["./gib-textarea.component.scss"],
})
export class GibTextareaComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder) {
      this.placeholder = this.translateService.instant(this.placeholder);
      if (this.hint !== "") {
        this.hint = this.translateService?.instant(this.hint);
      }
    }

    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== "") {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
      if (this.hint !== "") {
        this.hint = this.translateService.instant(hintInputValue);
      }
    });

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
