import { Component, OnInit } from '@angular/core';
import { FileMetaTaskDTO, FileService } from '../../../../services/file.service';

@Component({
  selector: 'document-verification-overview',
  templateUrl: './document-verification-overview.component.html',
  styleUrls: ['./document-verification-overview.component.scss']
})
export class DocumentVerificationOverviewComponent implements OnInit {

  documents: FileMetaTaskDTO[];
  selectedFile: FileMetaTaskDTO;
  componentName = 'DocumentVerificationOverviewComponent';

  constructor(private fileService: FileService) {}

  ngOnInit() {
    this.getFileList();
  }

  getFileList() {
    this.fileService.getFileList('PENDING').subscribe((res) => {
      this.selectedFile = null;
      this.documents = res.body;
    });
  }

  selectFile(fileMeta: FileMetaTaskDTO) {
    this.selectedFile = fileMeta;
  }

}
