import { UntypedFormBuilder } from "@angular/forms";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { EnvService } from "./env.service";

declare type ContactType = "EMAIL" | "PHONE";

export class InternalNote {
  id: number;
  contactType: ContactType;
  sendingOn: Date;
  registration: boolean;
  recordedOn: Date;
  recordedBy: string;
  continuingProcessingContactType: ContactType;
  continuingProcessingOn: Date;
  continuingProcessingBy: string;
  comment: string;

  constructor() {
    this.id = null;
    this.contactType = null;
    this.sendingOn = null;
    this.registration = null;
    this.recordedOn = null;
    this.recordedBy = "";
    this.continuingProcessingContactType = null;
    this.continuingProcessingOn = null;
    this.continuingProcessingBy = "";
    this.comment = "";
  }
}

@Injectable()
export class InternalNoteService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  getById(id: number, role: string): Observable<HttpConfig> {
    if (role === "gib_admin" || role === "gib_director" || role === "gib_employee") {
      return this.http.get(this.envService.backendUrl + "/event/internal/note/" + id, this.config);
    }
  }

  update(internalNote: InternalNote, role: string): Observable<HttpConfig> {
    if (role === "gib_admin" || role === "gib_director" || role === "gib_employee") {
      return this.http.post(this.envService.backendUrl + "/event/internal/note", internalNote, this.config);
    }
  }

  create(internalNote: InternalNote, role: string): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/event/internal/note", internalNote, this.config);
  }

  delete(id: number, role: string): Observable<HttpConfig> {
    if (role === "gib_admin" || role === "gib_director" || role === "gib_employee") {
      return this.http.delete(this.envService.backendUrl + "/event/internal/note/" + id, this.config);
    }
  }

  mapFormToInternalNote(form: UntypedFormGroup): InternalNote {
    const internalNote = form.getRawValue();
    return internalNote;
  }

  mapInternalNoteToForm(internalNote: InternalNote): UntypedFormGroup {
    if (internalNote) {
      const internalNoteForm = this.formBuilder.group(internalNote);
      return internalNoteForm;
    }
  }
}
