
import { Action } from "@ngrx/store";
import { Customer } from "src/app/services/customer.service";

export const LOAD_CUSTOMERS = "[CUSTOMERS] Load Customers ";
export const LOAD_CUSTOMERS_SUCCESS = "[CUSTOMERS] Load Customers Success";
export const LOAD_CUSTOMERS_FAIL = "[CUSTOMERS] Load Customers Fail";

export class LoadCustomers implements Action {
  readonly type = LOAD_CUSTOMERS;
}
export class LoadCustomersSuccess implements Action {
  readonly type = LOAD_CUSTOMERS_SUCCESS;
  constructor(public payload: {customers: Customer[]}) {}
}
export class LoadCustomersFail implements Action {
  readonly type = LOAD_CUSTOMERS_FAIL;
  constructor(public payload: any) {}
}

export type CustomerActions = LoadCustomers | LoadCustomersSuccess | LoadCustomersFail;
