import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'sidenav-tree',
  templateUrl: './sidenav-tree.component.html',
  styleUrls: ['./sidenav-tree.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in'))
    ]),
    trigger('openClose', [
      state('true', style({ height: '*', opacity: 1 })),
      state('false', style({ height: '0px', opacity: 0 })),
      transition('false <=> true', animate(300))
    ])
  ]
})
export class SidenavTreeComponent implements OnInit, OnChanges {

  @Input() treeContent: { rootLabel: string, links: [{ label: string, route: string, message: string }] };

  treeElementOpened = true;
  state = 'rotated';

  constructor() { }

  ngOnInit() { 
    
  }
  ngOnChanges() {

  }

  toggleTreeElement() {
    this.treeElementOpened = !this.treeElementOpened;
    if (this.treeElementOpened) {
      this.state = 'rotated';
    } else {
      this.state = 'default';
    }
  }

}
