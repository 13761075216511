<div class="d-flex justify-content-end">
  <button *ngIf="userRole === 'gib_customer'" type="button" class="btn btn-outline" routerLink="/">
    {{ "back" | translate }}
  </button>
</div>
<div class="d-flex justify-content-between">
  <h2>{{ "bookEvent" | translate }}</h2>
</div>
<br />
<event-booking-form #eventBookingForm [internalNoteForm]="internalNoteForm" [eventForm]="eventForm" [buid]="buid" [showPrintButtons]="showPrintButtons"></event-booking-form>
<br />
<div class="row">
  <div class="col-12 center">
    <button type="button" class="btn btn-primary" *ngIf="!formSubmitted" (click)="startProcessClicked()">
      {{ "submit" | translate }}
    </button>
    <button type="button" class="btn btn-primary actionButton" *ngIf="formSubmitted" (click)="bookEventAgain()">
      {{ "bookEventOneMoreTime" | translate }}
    </button>
    <button type="button" class="btn btn-primary actionButton" *ngIf="this.buid && showPrintButtons" (click)="openDownloadPdfConfirmation()">
      {{ "print" | translate }}
    </button>
  </div>
</div>
