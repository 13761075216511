<div class="row">
  <div class="col-sm-12 col-md-3"></div>
  <div class="col-sm-6 col-md-3">
    <gib-datepicker [placeholder]="'from'" [control]="dateFrom"></gib-datepicker>
  </div>
  <div class="col-sm-6 col-md-3">
    <gib-datepicker [placeholder]="'to'" [control]="dateTo"></gib-datepicker>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-2 center">
    <button class="btn-primary btn excelButton" (click)="openDownloadExcelConfirmation()"> {{ 'downloadExcel' | translate }} </button>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="grid-wrapper" *ngIf="headerColumns.length > 0">
      
      <div class="header-wrapper">
        <div class="name-col">
          <div></div>
          <div class="pointer" (click)="sortByName()">
            {{'trainers' | translate}}
            <i class="material-icons">arrow_drop_up</i>
            <i class="material-icons">arrow_drop_down</i>
          </div>
        </div>
        <div class="last-update-col">
          <div></div>
          <div class="pointer" (click)="sortByDate()">
            {{'lastUpdatedAt' | translate}}
            <i class="material-icons">arrow_drop_up</i>
            <i class="material-icons">arrow_drop_down</i>
          </div>
        </div>
        <div class="month-wrapper" [style.minWidth]="monthWrapperWidth">
          <div class="month-col" *ngFor="let column of headerColumns">
            <div class="month-name">
              {{column.month}}
            </div>
            <div class="{{'month-day ' + (entry.isWeekend ? 'weekend-marker': '')}}" *ngFor="let entry of column.days">
              {{entry.day}}
            </div>
          </div>
        </div>
      </div>

    


      <div class="row-wrapper">
        <div *ngFor="let trainer of entries; let i = index" [style.lineHeight]="'11px'">
          <div class="name-col" [style.top.px]="index*24">
            {{trainer.fullname}}
          </div>
          <div class="last-update-col">
            {{trainer.lastUpdated | amDateFormat:'DD.MM.YYYY'}}
          </div>
          <div class="trainer-day-wrapper" [style.minWidth]="monthWrapperWidth">
            <trainer-availability-box *ngFor="let day of trainer.availabilities" [day]="day"></trainer-availability-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
