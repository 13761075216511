import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "./../../../../components/dialogs/gib-dialog.service";
import { Customer, CustomerService } from "./../../../../services/customer.service";
import { KeycloakConnectorService } from "./../../../../services/keycloak-connector.service";

@Component({
  selector: "customer-table",
  templateUrl: "./customer-table.component.html",
  styleUrls: ["./customer-table.component.scss"],
})
export class CustomerTableComponent implements OnInit {
  sort: any;
  custmrs: Customer[];
  expandedElement: Customer | null;
  isExpandable = true;
  displayedColumns: string[] = ["firstname", "lastname", "phone", "email", "companyname", "gpsUser", "edit", "delete"];
  dataSource: MatTableDataSource<Customer>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  @Output() customerSelected = new EventEmitter<Customer>();
  @Output() addCustomerClicked = new EventEmitter();
  @Output() customerDeleted = new EventEmitter();

  @Input() set customers(customers: Customer[]) {
    this.custmrs = customers;
    this.dataSource = new MatTableDataSource(customers);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  get employees(): Customer[] {
    return this.custmrs;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private keycloakConnector: KeycloakConnectorService,
    private dialogService: GibDialogService
  ) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  resetDisplayedColumns() {
    this.displayedColumns = ["lastname", "firstname", "phone", "email", "companyname", "gpsUser", "edit", "delete"];
    this.isExpandable = true;
  }

  addCustomer() {
    this.displayedColumns = ["lastname", "firstname", "edit", "delete"];
    this.addCustomerClicked.emit(null);
    this.expandedElement = null;
    this.isExpandable = false;
  }

  selectCustomer(custmr: Customer) {
    this.displayedColumns = ["lastname", "firstname", "edit", "delete"];
    this.customerSelected.emit(custmr);
    this.expandedElement = null;
    this.isExpandable = false;
  }

  deleteCustomer(id: string) {
    this.customerService.delete(id).subscribe(() => {
      this.keycloakConnector.deleteUser(id).subscribe(() => {
        this.customerDeleted.emit(null);
      });
    });
  }

  openDeleteCustomerConfirmation(custmr: Customer) {
    const custFullName = this.customerService.getFullName(custmr);
    const title = this.translateService.instant("CUSTOMER_DELETE_CONFIRMATION_TITLE");
    const text = this.translateService.instant("CUSTOMER_DELETE_CONFIRMATION_TEXT", { customerName: custFullName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteCustomer(custmr.id));
  }
}
