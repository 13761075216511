<div class="row">
  <div class="col-sm-12">
    <h1>{{ "onlineMeetingRooms" | translate }}</h1>
  </div>
</div>
<mat-tab-group>
  <mat-tab label="{{ 'configuration' | translate }}">
    <div class="row tab-content-row">
      <div class="{{ selectedMeeting ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-12' }}">
        <div class="row">
          <div class="{{ selectedMeeting ? 'col-sm-3' : 'col-sm-7' }}"></div>
          <div class="{{ selectedMeeting ? 'col-sm-8' : 'col-sm-4' }}">
            <gib-fieldset [title]="'FILTER'">
              <gib-checkbox id="healModuleFilter" [label]="'eventHealthScreenings'" [control]="filterData.get('healthModules')"></gib-checkbox>
              <gib-checkbox id="webinarsFilter" [label]="'interactiveWebinars'" [control]="filterData.get('webinars')"></gib-checkbox>
              <gib-checkbox id="breaksFilter" [label]="'activeBreaks'" [control]="filterData.get('activeBreaks')"></gib-checkbox>
            </gib-fieldset>
          </div>
          <div class="col-sm-1">
            <a class="btn btn-secondary add-healthscreening-btn" (click)="addMeetingClicked()">+</a>
          </div>
          <!-- <div *ngIf="selectedMeeting" class="col-sm-6"></div> -->
        </div>
        <div class="row">
          <div class="col-sm-12">
            <meeting-table [meetings]="allMeetings" (meetingSelected)="selectMeeting($event)" (deleteMeetingClicked)="deleteMeeting($event)"></meeting-table>
          </div>
        </div>
      </div>
      <div *ngIf="selectedMeeting" class="col-md-12 col-lg-6">
        <div class="selected-meeting-wrapper-not">
          <div class="sticky-top" style="top: 50px">
            <meeting-form [meeting]="selectedMeeting" (meetingCreatedEdited)="createEditMeeting($event)"></meeting-form>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="{{ 'overview' | translate }}">
    <meeting-overview-grid></meeting-overview-grid>
  </mat-tab>
</mat-tab-group>
