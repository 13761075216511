import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalRateDto, GlobalRateService } from "../../../services/global-rate.service";
import { Observable, Subscription } from "rxjs";
import { ValueLabel } from "../../../components/value-label";
import { HealthScreeningService } from "../../../services/health-screening.service";
import { map } from "rxjs/operators";

@Component({
  selector: "add-tk-uz-global-rate",
  styleUrls: ["add-tk-uz-global-rate.component.scss"],
  template: `
    <h1>{{ "tkUzGlobalRates" | translate }}</h1>
    <div class="row">
      <div class="col-sm-12">
        <tk-uz-global-rate-form [modules]="modules$ | async" (cancel)="onCancel($event)" (save)="onSave($event)"></tk-uz-global-rate-form>
      </div>
    </div>
  `,
})
export class AddTkUzGlobalRateComponent implements OnInit, OnDestroy {
  modules$: Observable<ValueLabel[]>;
  subscriptions: Subscription[] = [];

  constructor(private router: Router, private globalRateService: GlobalRateService, private healthScreeningService: HealthScreeningService) {}

  ngOnInit(): void {
    this.modules$ = this.healthScreeningService.findAllForModuleSelection("TK").pipe(
      map((res) => res.body),
      map((modules) =>
        modules.map((module) => {
          return {
            value: module.id,
            label: module.moduleName,
          };
        })
      )
    );
  }

  onCancel(event: any): void {
    this.router.navigate(["finance", "tk-uz-global-rates"]);
  }

  onSave(event: GlobalRateDto): void {
    const sub = this.globalRateService.save(event).subscribe(() => {
      this.router.navigate(["finance", "tk-uz-global-rates"]);
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
