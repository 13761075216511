import { GibDialogService } from 'src/app/components/dialogs/gib-dialog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Faq, FaqService } from './../../../../services/faq.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnInit, Output, ViewChild, ElementRef, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'faq-table',
  templateUrl: './faq-table.component.html',
  styleUrls: ['./faq-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FaqTableComponent implements OnInit {

  sort;
  _faqs: Faq[];
  expandedElement: Faq | null;
  userRole: string;

  @Output() faqSelected = new EventEmitter<Faq>();
  @Output() refresh = new EventEmitter();


  @Input() set faqs(faqs: Faq[]) {
    this._faqs = faqs;
    this.dataSource = new MatTableDataSource(faqs);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  get faqs(): Faq[] {
    return this._faqs;
  }

  displayedColumns: string[] = ['companynameAlias', 'sortOrder', 'title', 'description', 'edit', 'delete'];
  dataSource: MatTableDataSource<Faq>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private faqService: FaqService, private dialogService: GibDialogService, private translateService: TranslateService, private cdr: ChangeDetectorRef,) { }

  ngOnInit() {
    this.userRole = localStorage.getItem('role');
  }

  selectFaq(faq: Faq) {
    this.faqSelected.emit(faq);
    this.expandedElement = null;
  }

  openDeleteFaqConfirmation(faq: Faq) {
    const title = this.translateService.instant('FAQ_DELETE_CONFIRMATION_TITLE');
    const text = this.translateService.instant('FAQ_DELETE_CONFIRMATION_TEXT');
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteFaq(faq.id));
  }

  deleteFaq(id: number) {
    this.faqService.deleteFaq(id).subscribe(() => {
      this.refresh.emit(null);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

}