import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FileService } from "../../services/file.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { GibDialogService } from "../dialogs/gib-dialog.service";

@Component({
  selector: "gib-fileupload",
  templateUrl: "./gib-fileupload.component.html",
  styleUrls: ["./gib-fileupload.component.scss"],
})
export class GibFileuploadComponent implements OnInit {
  @ViewChild("file", { static: true }) fileInput: ElementRef;

  fileControl: UntypedFormControl;

  trimmedFileName = "";

  uploadResponse = { status: "", message: "", filePath: "" };
  error;
  uploadComplete = false;
  showProgressBar = false;
  @Input() type;
  @Input() fileType;

  @Input() healthScreeningId: number;
  @Input() userId: string;

  @Input() maxFileSize: number;

  @Input() set control(controlObj: UntypedFormControl) {
    this.fileControl = controlObj;
  }

  @Input() title?: string;

  @Output() fileUploaded = new EventEmitter<null>();

  get control(): UntypedFormControl {
    return this.fileControl;
  }

  constructor(private dialogService: GibDialogService, private fileService: FileService, private translateService: TranslateService) {}

  ngOnInit() {}

  onFileChange(event) {
    this.uploadComplete = false;
    if (event.target.files.length > 0) {
      if (this.fileType === "CUSTOMER_SIGNATURE_DOCUMENT") {
        let fileSuffix = [".jpg", ".jpeg", ".png", ".JPEG", ".JPG", ".PNG"];
        if (fileSuffix.some((el) => event.target.files[0].name.includes(el))) {
          const file = event.target.files[0];
          if (file.size < this.maxFileSize * 1000000) {
            // max 5MB atm 5000000
            this.error = null;
            if (file.name.length > 30) {
              this.trimmedFileName = file.name.substring(0, 15) + "..." + file.name.substring(file.name.length - 7, file.name.length);
            } else {
              this.trimmedFileName = file.name;
            }
            this.fileControl.setValue(file);
          } else {
            this.error = this.translateService.instant("fileTooBig", { maxFileSize: this.maxFileSize });
          }
        } else {
          const text = this.translateService.instant("wrongFileSuffix");
          this.dialogService.openWrongFileTypeSnackbar(text, "ok");
        }
      } else {
        const file = event.target.files[0];
        if (file.size < this.maxFileSize * 1000000) {
          // max 5MB atm 5000000
          this.error = null;
          if (file.name.length > 30) {
            this.trimmedFileName = file.name.substring(0, 15) + "..." + file.name.substring(file.name.length - 7, file.name.length);
          } else {
            this.trimmedFileName = file.name;
          }
          this.fileControl.setValue(file);
        } else {
          this.error = this.translateService.instant("fileTooBig", { maxFileSize: this.maxFileSize });
        }
      }
    }
  }

  onSubmit() {
    this.showProgressBar = true;
    const formData = new FormData();
    formData.append("file", this.fileControl.value);

    if (this.userId) {
      this.fileService.uploadForOtherUser(formData, this.userId, this.type, this.healthScreeningId, this.title ? this.title : undefined).subscribe(
        (res) => {
          this.uploadResponse = res;
          if (res.id) {
            setTimeout(() => {
              this.uploadComplete = true;
              this.fileUploaded.emit(null);
              this.control.setValue(null);
              this.trimmedFileName = "";
              this.fileInput.nativeElement.value = null;
            }, 1000);
          }
        },
        (err) => (this.error = err)
      );
    } else {
      this.fileService.upload(formData, this.type, this.healthScreeningId, this.title ? this.title : undefined).subscribe(
        (res) => {
          this.uploadResponse = res;
          if (res.id) {
            setTimeout(() => {
              this.uploadComplete = true;
              this.fileUploaded.emit(null);
              this.control.setValue(null);
              this.trimmedFileName = "";
              this.fileInput.nativeElement.value = null;
            }, 1000);
          }
        },
        (err) => (this.error = err)
      );
    }
  }
}
