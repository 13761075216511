import { CustomerService } from "./customer.service";
import { EmployeeService } from "./employee.service";
import { EventOverviewService } from "./event-overview.service";
import { HealthScreeningService } from "./health-screening.service";

export const services: any[] = [EventOverviewService, HealthScreeningService,  CustomerService, EmployeeService];

export * from "./event-overview.service";
export * from "./customer.service";
export * from "./employee.service";
export * from "./health-screening.service";
