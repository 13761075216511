import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewChildren, Inject, QueryList } from "@angular/core";
import { FeedbackService, EventFeedbackCustomer, EventFeedbackTrainer } from "src/app/services/feedback.service";
import { ActivatedRoute } from "@angular/router";
import { FormHelper } from "src/app/helper/form.helper";
import { UntypedFormGroup } from "@angular/forms";
import { CustomerFeedbackFormComponent } from "../customer-feedback-form/customer-feedback-form.component";
import { TrainerFeedbackFormComponent } from "../trainer-feedback-form/trainer-feedback-form.component";
import { EventService } from "src/app/services/event.service";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from "@angular/material/bottom-sheet";
import { InternalNoteService } from "src/app/services/internal-note.service";

@Component({
  selector: "feedback-bottom-sheet",
  templateUrl: "./feedback-bottom-sheet.component.html",
  styleUrls: ["./feedback-bottom-sheet.component.scss"],
})
export class FeedbackBottomSheetComponent implements OnInit {
  eventForm: UntypedFormGroup;
  internalNoteForm: UntypedFormGroup;
  eventFeedbackCustomerForm: UntypedFormGroup;
  eventFeedbackCustomer: EventFeedbackCustomer;
  eventFeedbackTrainer: EventFeedbackTrainer[];
  eventFeedbackTrainerForms: UntypedFormGroup[] = [];
  editmode = false;
  eventId: number;
  buid: string;
  userRole = "";

  @ViewChild("customerFeedback") feedbackForm: CustomerFeedbackFormComponent;
  @ViewChildren("trainerFeedback") trainerFeedbackComponents: QueryList<TrainerFeedbackFormComponent>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private feedbackService: FeedbackService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private formHelper: FormHelper,
    private bottomSheet: MatBottomSheet,
    private internalNoteService: InternalNoteService
  ) {}

  ngOnInit() {
    this.eventId = this.data.eventId;
    this.loadEvent();
  }

  loadEvent() {
    this.eventService.findById("" + this.eventId).subscribe((res) => {
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.cdr.detectChanges();
      this.formHelper.disableControls(this.eventForm);
      this.loadEventFeedbackCustomer();
      this.loadEventFeedbackTrainer();

      this.internalNoteService.getById(this.data.eventId, this.userRole).subscribe((res) => {
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
        this.formHelper.disableControls(this.internalNoteForm);
      });
    });
  }

  loadEventFeedbackCustomer() {
    this.feedbackService.getEventFeedbackCustomerForEvent(this.eventId).subscribe((res) => {
      this.eventFeedbackCustomer = res.body;
      this.eventFeedbackCustomerForm = this.feedbackService.mapCustomerFeedbackToForm(this.eventFeedbackCustomer);
      this.formHelper.disableControls(this.eventFeedbackCustomerForm);
      this.cdr.detectChanges();
      this.feedbackForm.showButton = false;
    });
  }

  loadEventFeedbackTrainer() {
    this.feedbackService.getAllEventFeedbackTrainerForEvent(this.eventId).subscribe((res) => {
      this.eventFeedbackTrainer = res.body;
      for (const feedback of this.eventFeedbackTrainer) {
        const feedbackForm = this.feedbackService.mapTrainerFeedbackToForm(feedback);
        this.formHelper.disableControls(feedbackForm);
        this.eventFeedbackTrainerForms.push(feedbackForm);
      }
      this.cdr.detectChanges();
      this.trainerFeedbackComponents.forEach((component) => {
        component.showButton = false;
      });
    });
  }
  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }
}
