import { AfterContentChecked, Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { GibModuleSelectionComponent } from "src/app/components/gib-module-selection/gib-module-selection.component";
import { ValueLabel } from "src/app/components/value-label";

@Component({
  selector: "event-inquiry-form",
  templateUrl: "./event-inquiry-form.component.html",
  styleUrls: ["./event-inquiry-form.component.scss"],
})
export class EventInquiryFormComponent implements OnInit, AfterContentChecked {
  @Input() editMode: boolean;
  @Input() eventForm: UntypedFormGroup;
  @Input() internalNoteForm: UntypedFormGroup;
  @Input() buid: string;
  @Input() showPrintButtons: boolean;

  @ViewChild("module") moduleSelection: GibModuleSelectionComponent;

  userRole: string;

  customers: ValueLabel[] = [];

  private _valid: boolean = true;

  constructor() {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
  }

  ngAfterContentChecked(): void {
    if (this.moduleSelection) {
      this._valid = this.moduleSelection.itemsValid;
    }
  }

  get itemsValid() {
    return this._valid;
  }
}
