import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gib-h5',
  templateUrl: './gib-h5.component.html',
  styleUrls: ['./gib-h5.component.sass']
})
export class GibH5Component implements OnInit {
  @Input() disabled: boolean;
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
