import { HealthScreeningService, HealthScreening } from "src/app/services/health-screening.service";
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "gib-module-replace",
  templateUrl: "./gib-module-replace.component.html",
  styleUrls: ["./gib-module-replace.component.scss"],
})
export class GibModuleReplaceComponent implements OnInit {
  _modulesControl: UntypedFormArray;
  hideLabel: boolean = false;

  @Input() editMode: boolean;
  @Input() eventInquiryHealthscreening: UntypedFormGroup;
  @Input() customer: UntypedFormGroup;
  @Input() set modulesControl(controlObj: UntypedFormArray) {
    this._modulesControl = controlObj;
    this._modulesControl.valueChanges.subscribe((res) => {
      if (this._modulesControl.disabled) {
        this.selectControl.disable();
        this.onlineSelectControl.disable();
      } else {
        this.selectControl.enable();
        this.onlineSelectControl.enable();
      }
    });
  }

  get modulesControl(): UntypedFormArray {
    return this._modulesControl;
  }

  selectControl: UntypedFormControl = new UntypedFormControl([]);
  onlineSelectControl: UntypedFormControl = new UntypedFormControl([]);
  moduleGroups: any[] = [];
  onlineModuleGroups: any[] = [];

  constructor(private healthscreeningService: HealthScreeningService) {}

  ngOnInit() {
    if (this.customer && this.customer.get("companynameAlias").value) {
      this.createModuleOptions(this.customer.get("companynameAlias").value);
    } else {
      this.createModuleOptions("");
    }
  }

  createModuleOptions(overrideCustomerCompanynameAlias: string) {
    this.healthscreeningService.createModuleOptionsGroupedByTopic(false, overrideCustomerCompanynameAlias).then((result) => {
      this.moduleGroups = result;
      this.healthscreeningService.createModuleOptionsGroupedByTopic(true, overrideCustomerCompanynameAlias).then((res) => {
        this.onlineModuleGroups = res;
      });
    });
  }

  replaceModuleClicked(module: any) {
    const hs = this.healthscreeningService.findOne(module.value).subscribe((res) => {
      const hsForm = this.healthscreeningService.createHealthscreeningForm(res.body);
      this.eventInquiryHealthscreening.get("name").setValue(module.label);
      this.eventInquiryHealthscreening.removeControl("healthscreening");
      this.eventInquiryHealthscreening.addControl("healthscreening", hsForm);
      this.eventInquiryHealthscreening.get("healthscreeningInvoicename").setValue(module.label);
    });
    this.hideLabel = true;
  }
}
