<div>
  <!-- <button class="btn-primary btn excelButton" (click)="openDownloadExcelConfirmation()"> {{ 'downloadExcel' | translate }} </button> -->
  <mat-tab-group (selectedTabChange)="clicked($event)">
    <mat-tab label="{{ 'eventOverview' | translate }}">
      <event-grid
        #grid1
        [type]="'fullYear'"
        [paginationNumber]="paginationNumber"
        [events]="eventsOverview$ | async"
        [paging]="true"
        [date]="eventsOverviewDate"
        [filters]="eventsOverviewFilters"
        [focus]="focus"
        (move)="onChange($event, 1)"
        (filter)="onFilter($event, 1)"
        (update)="onUpdate($event)"
        (refreshData)="onRefreshData()"
      ></event-grid>
    </mat-tab>

    <mat-tab label="{{ 'eventTwoWeeks' | translate }}">
      <event-grid
        #grid2
        [type]="'twoWeeks'"
        [paginationNumber]="paginationNumberTwoWeeks"
        [events]="eventsTwoWeeks$ | async"
        [paging]="false"
        [from]="startOfWeek"
        [to]="endOfSecondWeek"
        [filters]="eventsOverviewTwoWeeksFilters"
        [focus]="focus"
        (filter)="onFilter($event, 2)"
        (update)="onUpdate($event)"
        (refreshData)="onRefreshData()"
      ></event-grid>
    </mat-tab>

    <mat-tab label="{{ 'EVENTS' | translate }}">
      <event-grid
        #grid3
        [type]="'all'"
        [paginationNumber]="paginationNumber"
        [events]="events$ | async"
        [paging]="true"
        [date]="eventsDate"
        (move)="onChange($event, 3)"
        [filters]="eventsFilters"
        (filter)="onFilter($event, 3)"
        (update)="onUpdate($event)"
        (refreshData)="onRefreshData()"
      ></event-grid>
    </mat-tab>

    <mat-tab label="{{ 'canceledEvents' | translate }}">
      <event-grid
        #grid4
        [type]="'canceled'"
        [paginationNumber]="paginationNumber"
        [events]="eventsCancelled$ | async"
        [paging]="true"
        [date]="eventsCancellationsDate"
        (move)="onChange($event, 4)"
        [filters]="cancelledFilters"
        (filter)="onFilter($event, 4)"
      ></event-grid>
    </mat-tab>

    <mat-tab label="{{ 'rejectedEvents' | translate }}">
      <event-grid
        #grid5
        [type]="'inactive'"
        [paginationNumber]="paginationNumber"
        [events]="eventsInactive$ | async"
        [paging]="true"
        [date]="eventsRejectedDate"
        (move)="onChange($event, 5)"
        [filters]="rejectedFilters"
        (filter)="onFilter($event, 5)"
      ></event-grid>
    </mat-tab>

    <mat-tab disabled style="opacity: 1" id="aantoniou25">
      <ng-template mat-tab-label style="opacity: 1">
        <button class="btn-primary btn" (click)="openDownloadExcelConfirmation()">{{ "downloadExcel" | translate }}</button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
