<svg xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  id="svg-icon"
  class="svg-icon"
  viewBox="0 0 45 45"
>
  <path
  [class.green]="color === 'green'"
  [class.navy]="color === 'navy'"
  [class.blue]="color === 'blue'"
  [class.grey]="color === 'grey'"
  d="M22.5,0C10.07,0,0,10.07,0,22.5s10.07,22.5,22.5,22.5,22.5-10.07,22.5-22.5S34.93,0,22.5,0ZM33.04,18.16l-11.81,11.81s-.07.07-.1.11c-.45.45-1.04.67-1.63.67s-1.18-.22-1.63-.67l-5.92-5.92c-.9-.9-.9-2.35,0-3.25s2.35-.9,3.25,0l4.29,4.29,10.29-10.29c.9-.9,2.35-.9,3.25,0s.9,2.35,0,3.25h0Z"
/>
</svg>
