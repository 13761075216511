import { MailLogService } from "./../../../../services/mail-log.service";
import { GibDialogService } from "./../../../../components/dialogs/gib-dialog.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Component, OnInit, Output, Input, ViewChild, ChangeDetectorRef, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MailLog } from "src/app/services/mail-log.service";
import { trigger, state, transition, style, animate } from "@angular/animations";
import { UntypedFormControl } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "mail-log-table",
  templateUrl: "./mail-log-table.component.html",
  styleUrls: ["./mail-log-table.component.scss"],
  animations: [trigger("detailExpand", [state("collapsed", style({ height: "0px", minHeight: "0" })), state("expanded", style({ height: "*" })), transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))])],
})
export class MailLogTableComponent implements OnInit {
  mails: any[];
  displayedColumns: string[] = ["created", "status", "sendTo", "cc", "bcc", "subject", "delete"];

  dataSource: MatTableDataSource<any>;

  dateFrom: UntypedFormControl = new UntypedFormControl(null);
  dateTo: UntypedFormControl = new UntypedFormControl(null);

  @Output() refresh = new EventEmitter();

  @Input() set emails(emails: any[]) {
    this.mails = emails;

    this.dataSource = new MatTableDataSource(emails);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private dialogService: GibDialogService, private mailLogService: MailLogService) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.mails);
    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  openDeleteMailConfirmation(mail: MailLog) {
    const title = this.translateService.instant("MAIL_DELETE_CONFIRMATION_TITLE");
    const text = this.translateService.instant("MAIL_DELETE_CONFIRMATION_TEXT");
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteEmail(mail.id));
  }

  deleteEmail(id: number) {
    this.mailLogService.deleteMail(id).subscribe(() => {
      this.refresh.emit(null);
    });
  }

  openDeleteAllMailConfirmation() {
    if (this.dateFrom.value && this.dateTo.value) {
      const title = this.translateService.instant("FROM_TO_MAIL_DELETE_CONFIRMATION_TITLE");
      const text = this.translateService.instant("FROM_TO_MAIL_DELETE_CONFIRMATION_TEXT", { from: moment(this.dateFrom.value).format("DD.MM.YYYY"), to: moment(this.dateTo.value).format("DD.MM.YYYY") });
      this.dialogService.openConfirmationDialog(title, text, () => this.deleteMailsFromTo(this.dateFrom.value, this.dateTo.value));
    } else {
      const title = this.translateService.instant("ALL_MAIL_DELETE_CONFIRMATION_TITLE");
      const text = this.translateService.instant("ALL_MAIL_DELETE_CONFIRMATION_TEXT");
      this.dialogService.openConfirmationDialog(title, text, () => this.deleteMails());
    }
  }

  deleteMails() {
    this.mailLogService.deleteAllEmails().subscribe(() => {
      this.refresh.emit();
    });
  }

  deleteMailsFromTo(from: any, to: any) {
    this.mailLogService.deleteMailFromTo(from, to).subscribe((res) => {
      this.refresh.emit();
    });
  }
}
