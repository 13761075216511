<mat-card *ngIf="entryForm">
  <mat-card-content>
    <form [formGroup]="entryForm">
      <div class="row">
        <div *ngIf="!entryForm.get('id').value" class="col-sm-12">
          <gib-checkbox [label]="'multipleDays'" [control]="entryForm.get('multipleDays')" (click)="toggleMultipleDays($event)"></gib-checkbox>
        </div>
        <div *ngIf="!entryForm.get('multipleDays').value" class="col-sm-12">
          <gib-datepicker [placeholder]="'date'" [control]="entryForm.get('date')"></gib-datepicker>
        </div>
        <div *ngIf="entryForm.get('multipleDays').value" class="col-sm-6">
          <gib-datepicker [placeholder]="'from'" [control]="entryForm.get('date')"></gib-datepicker>
        </div>
        <div *ngIf="entryForm.get('multipleDays').value" class="col-sm-6">
          <gib-datepicker [placeholder]="'to'" [control]="entryForm.get('dateTo')"></gib-datepicker>
        </div>
        <div *ngIf="entryForm.get('multipleDays').value" class="col-sm-12">
          <div class="d-flex justify-content-between">
            <gib-checkbox [label]="'mondayShort'" [control]="entryForm.get('mondays')"></gib-checkbox>
            <gib-checkbox [label]="'tuesdayShort'" [control]="entryForm.get('tuesdays')"></gib-checkbox>
            <gib-checkbox [label]="'wednesdayShort'" [control]="entryForm.get('wednesdays')"></gib-checkbox>
            <gib-checkbox [label]="'thursdayShort'" [control]="entryForm.get('thursdays')"></gib-checkbox>
            <gib-checkbox [label]="'fridayShort'" [control]="entryForm.get('fridays')"></gib-checkbox>
            <gib-checkbox [label]="'saturdayShort'" [control]="entryForm.get('saturdays')"></gib-checkbox>
            <gib-checkbox [label]="'sundayShort'" [control]="entryForm.get('sundays')"></gib-checkbox>
          </div>
        </div>
        <div class="col-sm-12">
          <gib-select [placeholder]="'availableNotAvailable'" [control]="entryForm.get('entryType')" [options]="trainerAvailabilityOptions"></gib-select>
        </div>
        <div class="col-sm-6">
          <gib-timepicker [placeholder]="'from'" [control]="entryForm.get('trainerAvailabilityTimeFrom')"></gib-timepicker>
        </div>
        <div class="col-sm-6">
          <gib-timepicker [placeholder]="'to'" [control]="entryForm.get('trainerAvailabilityTimeTo')"></gib-timepicker>
        </div>
        <div class="col-sm-12">
          <gib-input [placeholder]="'maxDistanceKm'" [control]="entryForm.get('trainerAvailabilityDistance')"></gib-input>
        </div>
        <div class="col-sm-12">
          <gib-textarea [placeholder]="'comment'" [control]="entryForm.get('comment')"></gib-textarea>
        </div>
        <div class="col-sm-12 center">
          <button *ngIf="!entryForm.get('id').value && !forUser" type="button" class="btn btn-primary" (click)="submitEntriesClicked()">{{ "submit" | translate }}</button>
          <button *ngIf="entryForm.get('id').value" type="button" class="btn btn-primary" (click)="editEntryClicked()">{{ "save" | translate }}</button>
          <button *ngIf="entryForm.get('id').value" id="cancel-edit-calendar-entry" mat-button (click)="cancelEdit()">{{ "CANCEL" | translate }}</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
