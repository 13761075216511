import { EventService, Event } from "./../../../services/event.service";
import { FeedbackService, EventFeedbackCustomer } from "./../../../services/feedback.service";
import { FormHelper } from "./../../../helper/form.helper";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { invalid } from "moment";
import { TokenHelperService } from "src/app/services/token-helper.service";

@Component({
  selector: "customer-feedback-page",
  templateUrl: "./customer-feedback-page.component.html",
  styleUrls: ["./customer-feedback-page.component.scss"],
})
export class CustomerFeedbackPageComponent implements OnInit {
  editMode = true;

  buid: string;

  eventForm: UntypedFormGroup;
  eventFeedbackCustomerForm: UntypedFormGroup;

  constructor(private formHelper: FormHelper, private feedbackService: FeedbackService, private eventService: EventService, private route: ActivatedRoute, private tokenHelperService: TokenHelperService) {}

  ngOnInit() {
    this.loadEventData();
    this.eventFeedbackCustomerForm = this.initFormGroup();
  }

  loadEventData() {
    this.route.queryParams.subscribe((values) => {
      this.buid = values["buid"];
      this.eventService.findByBuid(this.buid).subscribe((res) => {
        const event = res.body;
        if (event.customer.id !== this.tokenHelperService.getUserId()) {
          return;
        }
        this.eventForm = this.eventService.mapEventToForm(event);
      });
    });
  }

  initFormGroup(): UntypedFormGroup {
    const feedback = new EventFeedbackCustomer();
    const feedbackForm = this.feedbackService.mapCustomerFeedbackToForm(feedback);
    feedbackForm.get("overallRating").setValidators([Validators.required]);
    feedbackForm.get("trainerContactedYou").setValidators([Validators.required]);
    feedbackForm.get("trainerOnTime").setValidators([Validators.required]);
    feedbackForm.get("bookAgain").setValidators([Validators.required]);
    feedbackForm.setValidators(this.feedbackService.isBookAgainNoAndNoComment);
    return feedbackForm;
  }

  startEditMode() {
    this.editMode = true;
    this.formHelper.enableControls(this.eventFeedbackCustomerForm);
  }
}
