<div class="editRow">
  <button *ngIf="editMode" mat-button (click)="cancel()">{{ "CANCEL" | translate }}</button>
  <button *ngIf="editMode && (eventForm || trainerForEventForm)" class="btn btn-primary" (click)="updateTask()">{{ "save" | translate }}</button>
  <i
    id="edit_icon"
    *ngIf="!editMode && (eventForm || trainerForEventForm) && task && task.task && task.task.taskDefinitionKey !== 'ut_inform_about_failure_on_call' && task.task.taskDefinitionKey !== 'ut_trainer_call_customer' && task.task.taskDefinitionKey !== 'ut_trainer_feedback'"
    placement="left"
    [ngbTooltip]="'EDIT' | translate"
    class="material-icons edit-icon"
    (click)="startEditMode()"
    >edit</i
  >
  <i id="close_icon" *ngIf="!editMode && (eventForm || trainerForEventForm)" placement="left" [ngbTooltip]="'close' | translate" class="material-icons close-icon" (click)="closeBottomSheet()">close</i>
</div>
<event-booking-form
  *ngIf="eventForm && task && task.task && (task.task.taskDefinitionKey === 'ut_inform_about_failure_on_call' || task.task.taskDefinitionKey === 'ut_trainer_call_customer' || task.task.taskDefinitionKey === 'ut_check_availability')"
  [eventForm]="eventForm"
  [internalNoteForm]="internalNoteForm"
  [task]="task"
  [showPrintButtons]="showPrintButtons"
  [buid]="buid"
  [chatElements]="chatElements"
></event-booking-form>
<event-complete-form
  *ngIf="eventForm && task && task.task && task.task.taskDefinitionKey !== 'ut_inform_about_failure_on_call' && task.task.taskDefinitionKey !== 'ut_check_availability' && task.task.taskDefinitionKey !== 'ut_trainer_call_customer' && task.task.taskDefinitionKey !== 'ut_trainer_feedback'"
  [internalNoteForm]="internalNoteForm"
  [showPrintButtons]="showPrintButtons"
  [eventForm]="eventForm"
  [buid]="buid"
  [task]="task"
  [chatElements]="chatElements"
></event-complete-form>
<trainer-feedback-form *ngIf="feedbackForm && eventForm && task && task.task && task.task.taskDefinitionKey === 'ut_trainer_feedback'" [buid]="task.buid" (feedbackSubmitted)="submitTrainerFeedbackTask()" [eventFeedbackTrainerForm]="feedbackForm" [eventForm]="eventForm" [eihs]="eihs"></trainer-feedback-form>
<h1 *ngIf="customerForm" style="text-align: center">{{ "customerData" | translate }}</h1>
<customer-form *ngIf="customerForm" [form]="customerForm"></customer-form>
<trainer-for-module-selection *ngIf="trainerForEventForm" [eventForm]="trainerForEventForm" [disabled]="!editMode"></trainer-for-module-selection>
<div *ngIf="task && !editMode" [@visibility]="showButtons && !editMode ? 'visible' : 'hidden'" class="button-div">
  <gib-icon-button *ngFor="let b of buttonProps" [icon]="b.icon" [text]="b.text" [type]="b.type" (click)="b.action()"></gib-icon-button>
</div>
<div [@visibility]="!showButtons ? 'visible' : 'hidden'" class="button-div">
  <p [ngStyle]="{ color: resultColor }">
    <b>{{ result | translate }}</b>
  </p>
</div>
