import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, catchError, concatMap } from "rxjs/operators";

import * as eventActions from "../actions/cancelled-event.actions";
import * as fromServices from "../../services";

@Injectable()
export class CancelledEventEffects {
  constructor(private actions$: Actions, private eventOverviewService: fromServices.EventOverviewService) {}

  loadCancelledEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.LOAD_CANCELLED_EVENTS),
      map((action: eventActions.LoadCancelledEvents) => action.payload),
      concatMap((payload) => {
        const period: string = payload.from.substring(5, 7) + "/" + payload.from.substring(0, 4);
        return this.eventOverviewService.getCanceledEventsWithDate(payload.from, payload.to).pipe(
          map((response) => new eventActions.LoadCancelledEventsSuccess(response.body, period)),
          catchError((error) => of(new eventActions.LoadCancelledEventsFail(error)))
        );
      })
    );
  });
}
