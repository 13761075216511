import { KeycloakService } from "keycloak-angular";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  isLoggedIn = false;
  role = "";

  constructor(private keycloakService: KeycloakService, private router: Router) {}

  ngOnInit() {
    this.role = localStorage.getItem("role");
    this.keycloakService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
    });
    if (this.role === "gib_tkcontrolling") {
      this.router.navigate(["/finance/tk-uz-overview"]);
    }
  }

  login() {
    this.keycloakService.login();
  }
}
