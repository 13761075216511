import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";
import { EnvService } from "./env.service";

export class MailLog {
  id: number;
  created: string;
  status: string;
  sendFrom: string;
  sendTo: string;
  cc: string;
  bcc: string;
  subject: string;
  content: string;

  constructor() {
    this.id = null;
    this.created = "";
    this.status = "";
    this.sendFrom = "";
    this.sendTo = "";
    this.cc = "";
    this.subject = "";
    this.content = "";
  }
}

@Injectable()
export class MailLogService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {}

  // REST
  public getAllEmails(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/mail/log/getAll", this.config);
  }

  public deleteMail(id: number): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/mail/log/delete/" + id, this.config);
  }

  public deleteAllEmails(): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/mail/log/deleteAll", this.config);
  }

  public deleteMailFromTo(from: any, to: any): Observable<HttpConfig> {
    const dateFrom = moment(from).format("YYYY-MM-DD");
    const dateTo = moment(to).format("YYYY-MM-DD");
    return this.http.delete(this.envService.backendUrl + "/mail/log/deleteFromTo?from=" + dateFrom + "&to=" + dateTo, this.config);
  }

  // mapping functions
  mapMailLogToForm(mailLog: MailLog): UntypedFormGroup {
    const mailLogForm = this.formBuilder.group(mailLog);
    return mailLogForm;
  }

  mapFormToMailLog(form: UntypedFormGroup): MailLog {
    const mailLog = form.getRawValue();
    return mailLog;
  }

  // Helper functions
}
