import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CustomerEventOverviewDTO } from "../../../../services/event.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as XLSX from "xlsx";

@Component({
  selector: "event-table",
  templateUrl: "./event-table.component.html",
  styleUrls: ["./event-table.component.scss"],
})
export class EventTableComponent implements OnInit {
  @ViewChild("TABLE", { static: true }) table: ElementRef;
  sort;
  evnts: CustomerEventOverviewDTO[];

  @Output() eventSelected = new EventEmitter();
  @Output() signOffEventSelected = new EventEmitter();
  @Output() eventBookAgainClicked = new EventEmitter();

  @Input() set events(events: CustomerEventOverviewDTO[]) {
    this.evnts = events;
    this.dataSource = new MatTableDataSource(events);
    this.dataSource.filterPredicate = this.customPredicate;
    this.dataSource.paginator = this.paginator;

    this.sort.sort({ id: "eventDate", start: "asc" } as MatSortable);
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.dataSource.sort = this.sort;
    }

    this.cdr.detectChanges();
  }

  initialDisplayedColumns = ["eventDate", "time", "state", "corporateCustomerCompanyName", "modules", "street", "city", "book"];
  displayedColumns: string[] = this.initialDisplayedColumns;
  dataSource: MatTableDataSource<CustomerEventOverviewDTO>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private cdr: ChangeDetectorRef, private router: Router, private translateService: TranslateService) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  eventRowClicked(event: CustomerEventOverviewDTO) {
    this.eventSelected.emit(event);
  }

  signOffClicked(event: CustomerEventOverviewDTO) {
    this.signOffEventSelected.emit(event);
  }

  resetDisplayedColumns() {
    this.displayedColumns = this.initialDisplayedColumns;
  }

  customPredicate(data, filter): boolean {
    const eventDateFilter = data.eventDate ? data.eventDate.toLowerCase().includes(filter) : false;
    const eventStateFilter = data.state ? data.state.toLowerCase().includes(filter) : false;
    const timeFilter = data.time ? data.time.toLowerCase().includes(filter) : false;
    const corporateCustomerCompanyNameFilter = data.corporateCustomerCompanyName ? data.corporateCustomerCompanyName.toLowerCase().includes(filter) : false;
    const modulesFilter = data.moduleName ? data.moduleName.toLowerCase().includes(filter) : false;
    const streetFilter = data.street ? data.street.toLowerCase().includes(filter) : false;
    const cityFilter = data.city ? data.city.toLowerCase().includes(filter) : false;

    return eventDateFilter || eventStateFilter || timeFilter || corporateCustomerCompanyNameFilter || modulesFilter || streetFilter || cityFilter;
  }

  exportExcel() {
    const name = this.translateService.instant("myEvents").replace(/ /g, "_");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement, { raw: true, dateNF: "dd.MM.yyyy" });
    for (let i = 0; i <= this.evnts.length; i++) {
      delete ws["H" + (i + 1)];
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, name + ".xlsx");
  }

  selectedEventToBookAgain(event: CustomerEventOverviewDTO) {
    this.eventBookAgainClicked.emit(event);
  }
}
