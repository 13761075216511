<div class="row" *ngIf="this.isSignable">
  <div class="col-sm-12">
    <gib-checkbox *ngIf="this.userRole === 'gib_customer'" [label]="'signoffConfirmation'" [control]="eventForm.get('signoffConfirmation')"></gib-checkbox>
  </div>
</div>
<div class="row signature-image-row" *ngIf="this.isSignable">
  <div class="col-sm-12">
    <img *ngIf="this.userRole === 'gib_customer' && this.eventForm.get('signoffConfirmation').value === true" class="signature" src="{{ imageUrl }}" />
  </div>
</div>
<div class="row signature-30px-row" *ngIf="this.userRole === 'gib_customer' && this.eventForm.get('signoffConfirmation').value === true">
  <div class="col-sm-12">
    <gib-input [placeholder]="'signOffComment'" [control]="eventForm.get('signoffComment')"></gib-input>
  </div>
</div>
<div class="row signature-30px-row" *ngIf="eventForm.get('signoffConfirmationTimestamp').value">
  <div class="col-sm-12">
    <gib-input [placeholder]="'signOffdate'" [control]="eventForm.get('signoffConfirmationTimestamp')"></gib-input>
  </div>
</div>
<div class="row signature-30px-row" *ngIf="this.isSignable">
  <div class="col-sm-12">
    <button *ngIf="this.userRole === 'gib_customer' && this.eventForm.get('signoffConfirmation').value === true && this.signatureSubmitted === false" type="button" class="btn btn-primary" (click)="signoffClicked()">{{ "submit_signoff" | translate }}</button>
    <p *ngIf="this.sumbitButtonClicked" [ngStyle]="{ color: 'var(--success)' }">
      <b>{{ "signoff_submitted_succesfully" | translate }}</b>
    </p>
  </div>
</div>
