<div class="row">
  <div class="col-sm-4">
    <br>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-8"></div>

  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)">

        <ng-container matColumnDef="processName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'process' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.processName}} </td>
        </ng-container>

        <ng-container matColumnDef="processStep">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'processStep' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.processStep}} </td>
        </ng-container>

        <ng-container matColumnDef="approver">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'approver' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.approver}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="assignee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'assignee' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.assignee}} </td>
        </ng-container> -->

        <ng-container matColumnDef="outcome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'outcome' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.outcome}} </td>
        </ng-container>

        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'cancelationReason' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.reason}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'createdDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.created | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'completedDate' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.completed | amDateFormat:'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
