import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError, concatMap } from "rxjs/operators";
import * as employeeActions from "../actions/employee.actions";
import * as fromServices from "../../services";
import { TranslateService } from "@ngx-translate/core";
import { FormHelper } from "src/app/helper/form.helper";

@Injectable()
export class EmployeeEffects {
  constructor(
    private actions$: Actions,
    private employeeService: fromServices.EmployeeService,
    private translateService: TranslateService,
    private formHelper: FormHelper
  ) {}

  loadEmployeesByRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(employeeActions.LOAD_EMPLOYEES_BY_ROLE),
      map((action: employeeActions.LoadEmployeesByRole) => action.payload),
      concatMap((payload) => {
        return this.employeeService.findByRoles(payload.roles).pipe(
          map((response) => {
            return new employeeActions.LoadEmployeesByRoleSuccess({employees: response.body})
          },
            catchError((error) => of(new employeeActions.LoadEmployeesByRoleFail(error)))
          )
        );
      })
    );
  });

  loadEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(employeeActions.LOAD_EMPLOYEES),
      concatMap(() => {
        return this.employeeService.findAll().pipe(
          map((response) => {
            let employees = response.body
            return new employeeActions.LoadEmployeesSuccess({employees: employees})
          },
            catchError((error) => of(new employeeActions.LoadEmployeesFail(error)))
          )
        );
      })
    );
  });

  loadRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(employeeActions.LOAD_ROLES),
      concatMap(() => {
        return this.employeeService.getRolesFromBackend().pipe(
          map((response) => {
            const roles = [];
            for (const role of response.body) {
              roles.push({
                label: this.translateService.instant(role),
                value: role,
              });
            }
            let roleValueLabels = roles
            return new employeeActions.LoadRolesSuccess({roles: roleValueLabels})
          },
            catchError((error) => of(new employeeActions.LoadRolesFail(error)))
          )
        );
      })
    );
  });

  loadTrainerForAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(employeeActions.LOAD_TRAINER_FOR_ASSIGNMENT),
      concatMap(() => {
        return this.employeeService.getTrainerForAssignment().pipe(
          map((response) => {
            let trainer = response.body
            trainer.sort(this.formHelper.sortByLastname)
            return new employeeActions.LoadTrainerForAssignmentSuccess({trainer: trainer})
          },
            catchError((error) => of(new employeeActions.LoadRolesFail(error)))
          )
        );
      })
    );
  });
}
