<mat-tab-group>
  <mat-tab label="{{ 'module' | translate }}">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ "editModule" | translate }} - {{ this.healthInsuranceOwnerString | translate }}</h1>
      </div>
      <div class="col-sm-12" *ngIf="form">
        <h5>{{ "data" | translate }}</h5>
        <edit-health-screening-form [healthScreeningForm]="form" [role]="userRole" [step]="-1"></edit-health-screening-form>
        <h5>{{ "trainerAssignment" | translate }}</h5>
        <gib-drag-and-drop-box [availableItems]="availableTrainer$ | async" [(assignedItems)]="healthscreening.assignedUsers" [shownKey]="'officialName'" [availableLabel]="'allTrainer'" [assignedLabel]="'moduleTrainer'"></gib-drag-and-drop-box>
        <br />
        <button type="button" class="btn btn-primary" (click)="updateDataClicked()">
          {{ "saveData" | translate }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <h5>{{ "images" | translate }}</h5>
        <gib-fileupload (fileUploaded)="loadImages()" id="healthscreening_image_upload" [control]="imageUploadForm.get('imageControl')" [type]="'HEALTHSCREENING_PICTURE'" [healthScreeningId]="healthscreening.id" [maxFileSize]="5"></gib-fileupload>
        <gib-image-handler [images]="images" (fileDeleted)="loadImages()" (imageUrlsGenerated)="setImageUrls($event)"></gib-image-handler>
      </div>
      <div class="col-sm-6">
        <h5>{{ "videos" | translate }}</h5>
        <gib-fileupload (fileUploaded)="loadVideosByType()" *ngIf="videoUploadForm.get('videoType').value" id="healtscreening_video_upload" [control]="videoUploadForm.get('videoControl')" [type]="videoUploadForm.get('videoType').value" [healthScreeningId]="healthscreening.id" [maxFileSize]="100"></gib-fileupload>
        <br *ngIf="videoUploadForm.get('videoType').value" />
        <gib-select [placeholder]="'videoTypeHint'" [control]="videoUploadForm.get('videoType')" [options]="videoTypes"></gib-select>
        <div class="row">
          <div class="col-sm-6" *ngIf="trainingVideos && trainingVideos.length > 0">
            <h5>{{ "HEALTHSCREENING_VIDEO_TRAINING" | translate }}</h5>
            <gib-video-handler [videos]="trainingVideos" (fileDeleted)="loadTrainingVideos()" (videoUrlsGenerated)="setTrainingVideoUrls($event)"></gib-video-handler>
          </div>
          <div class="col-sm-6" *ngIf="marketingVideos && marketingVideos.length > 0">
            <h5>{{ "HEALTHSCREENING_VIDEO_PRESENTATION" | translate }}</h5>
            <gib-video-handler [videos]="marketingVideos" (fileDeleted)="loadMarketingVideos()" (videoUrlsGenerated)="setMarketingVideoUrls($event)"></gib-video-handler>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-primary" (click)="backClicked()">
          {{ "backToOverview" | translate }}
        </button>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'customerDocuments' | translate }} (DE)">
    <mat-card>
      <mat-card-content>
        <!-- <gib-documents *ngIf="healthscreening" [healthScreeningId]="healthscreening.id" [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_DE'"></gib-documents> -->
        <div class="row">
          <div *ngIf="isOnline" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
            <div class="mt-3">
              <h6>{{ "descriptionsOnlineMailDe" | translate }}</h6>
              <div class="mb-3">
                <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONLINE'" [healthScreeningForm]="form"></attachement-handler>
              </div>
            </div>
          </div>
          <div *ngIf="isOnsite" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
            <div class="mt-3">
              <h6>{{ "descriptionsOnsiteMailDe" | translate }}</h6>
              <div class="mb-3">
                <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONSITE'" [healthScreeningForm]="form"></attachement-handler>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'customerDocuments' | translate }} (EN)">
    <mat-card>
      <mat-card-content>
        <!-- <gib-documents *ngIf="healthscreening" [healthScreeningId]="healthscreening.id" [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_EN'"></gib-documents> -->

        <div class="row">
          <div *ngIf="isOnline" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
            <div class="mt-3">
              <h6>{{ "descriptionsOnlineMailEn" | translate }}</h6>
              <div class="mb-3">
                <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONLINE'" [healthScreeningForm]="form"></attachement-handler>
              </div>
            </div>
          </div>
          <div *ngIf="isOnsite" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
            <div class="mt-3">
              <h6>{{ "descriptionsOnsiteMailEn" | translate }}</h6>
              <div class="mb-3">
                <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONSITE'" [healthScreeningForm]="form"></attachement-handler>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'trainerDocuments' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents *ngIf="healthscreening" [healthScreeningId]="healthscreening.id" [fileType]="'HEALTHSCREENING_TRAINER_DOCUMENT'"></gib-documents>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="false" label="{{ 'healthscreeningSecurity' | translate }}">
    <mat-card>
      <mat-card-content *ngIf="form">
        <gib-checkbox [label]="'setRestrictedVisibility'" [control]="form.get('restrictedVisibility')"></gib-checkbox>
        <gib-drag-and-drop-box [availableItems]="companynameAliases" [(assignedItems)]="healthscreening.visibileForCompanynameAliases" [shownKey]="'id'" [availableLabel]="'allAliases'" [assignedLabel]="'visibleFor'" *ngIf="form.get('restrictedVisibility').value"></gib-drag-and-drop-box>
        <br />
        <div class="center">
          <button type="button" class="btn btn-primary" (click)="updateDataCliked()">
            {{ "saveData" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
