<div class="row">
  <div class="col-sm-12">
    <h1>{{'configuration' | translate}}</h1>
  </div>
</div>
<div class="row" *ngFor="let entry of configEntryArray['controls'];let i = index">
  <div class="col-sm-4">
    <label>
      {{entry.get('key').value | translate}}
    </label>
  </div>
  <div class="col-sm-7">
    <gib-input [placeholder]="'value'" [control]="entry.get('value')"></gib-input>
  </div>
  <div class="col-sm-1">
    <i *ngIf="entry.get('value').disabled && !editOngoing" class="material-icons" (click)="startEdit(i)">edit</i>
    <i *ngIf="!entry.get('value').disabled" class="material-icons" (click)="saveEntry(i)">save</i>
    <i *ngIf="!entry.get('value').disabled" class="material-icons" (click)="cancelEdit(i)">cancel</i>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-sm-12">
    <h3>
      {{'places' | translate}}
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-sm-4" *ngFor="let addressForm of addressArray['controls']; let i = index">
    <div class="row">
      <div class="col-sm-12">
        <h5>{{addressForm.get('type').value | translate}}</h5>
      </div>
      <div class="col-sm-12">
        <gib-input [placeholder]="'street'" [control]="addressForm.get('street')"></gib-input>
        <gib-input [placeholder]="'streetno'" [control]="addressForm.get('number')"></gib-input>
        <gib-input [placeholder]="'zip'" [control]="addressForm.get('zip')"></gib-input>
        <gib-input [placeholder]="'city'" [control]="addressForm.get('city')"></gib-input>
      </div>
      <div class="col-sm-12 center">
        <i *ngIf="addressForm.disabled && !addressEditOngoing" class="material-icons" (click)="startEditAddress(i)">edit</i>
        <i *ngIf="!addressForm.disabled" class="material-icons" (click)="saveAddress(i)">save</i>
        <i *ngIf="!addressForm.disabled" class="material-icons" (click)="cancelEditAddress(i)">cancel</i>
      </div>
    </div>
  </div>
</div>
