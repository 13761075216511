import { AddFaqBottomSheetComponent } from './../components/add-faq-bottom-sheet/add-faq-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Faq } from 'src/app/services/faq.service';
import { FormHelper } from './../../../helper/form.helper';
import { FaqService } from './../../../services/faq.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gib-faq-page',
  templateUrl: './gib-faq-page.component.html',
  styleUrls: ['./gib-faq-page.component.scss']
})
export class GibFaqPageComponent implements OnInit {

  faqs: any[] = [];
  selectedFaq: Faq;

  constructor(private faqService: FaqService, private formHelper: FormHelper, private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.loadFaqs();
  }

  loadFaqs() {
    this.faqService.findAll().subscribe(response => {
      this.faqs = [];
      response.body.sort(this.formHelper.sortByProperty('title'));
      for (const faq of response.body) {
        this.faqs.push(faq);
      }
    });
  }

  addFaq() {
    this.selectedFaq = new Faq();
    this.openBottomSheet();
  }

  setSelectedFaq(faq: Faq) {
    this.selectedFaq = faq;
    this.openBottomSheet();
  }

  openBottomSheet() {
    const sheetData = { selectedFaq: this.selectedFaq };
    this.bottomSheet.open(AddFaqBottomSheetComponent, { data: sheetData }).afterDismissed().subscribe(() => {
      this.loadFaqs();
    });
  }








}