import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { ValueLabel } from "src/app/components/value-label";
import { EmployeeService } from "../../services/employee.service";
import { PhoneNumberUtil } from "google-libphonenumber";
import { KeycloakService } from "keycloak-angular";
import { EnvService } from "src/app/services/env.service";

export interface User {
  name: string;
}

@Component({
  selector: "sandbox-page",
  templateUrl: "./sandbox-page.component.html",
  styleUrls: ["./sandbox-page.component.scss"],
})
export class SandboxPageComponent implements OnInit {
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;

  closeResult: string;

  testValue = "abc";
  redirectUrl: string;

  selectValue = "2";

  myControl = new UntypedFormControl();
  options: ValueLabel[] = [
    { label: "Sarah", value: "1" },
    { label: "Gabor", value: "2" },
    { label: "Ben", value: "3" },
    { label: "Pascal", value: "4" },
    { label: "Till", value: "5" },
    { label: "Cassian", value: "6" },
  ];

  // imageUrlArray: (string | IImage)[] = [{ url: "assets/images/pic1.jpg" }, { url: "assets/images/pic2.jpg" }, { url: "assets/images/pic3.jpg" }, { url: "assets/images/Mouth-Expressions.jpg" }];

  filteredOptions: Observable<ValueLabel[]>;

  constructor(private formBuilder: UntypedFormBuilder, private modalService: NgbModal, private employeeService: EmployeeService, private keycloak: KeycloakService, private envService: EnvService) {
    this.redirectUrl = envService.authRedirectUrl;
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput("015759507933", "DE");
    // 0 for fixed line | 1 for mobile
  }

  login() {
    // this.oauthService.initImplicitFlow();
  }

  logout() {
    // this.oauthService.logOut();
    this.keycloak.logout();
  }

  getClaims() {
    // let claims = this.oauthService.getIdentityClaims();
    // if (!claims) {
    //   return null;
    // }
    // return claims;
  }

  users() {
    // this.employeeService.getUsers().subscribe(res => {
    // });
  }

  createUser() {
    const user = {};
    // this.employeeService.addUser(user).subscribe(res => {
    // });
  }

  getRoles() {
    // this.employeeService.getRoles().subscribe(res => {
    // });
  }

  getUserRoles() {
    const userId = "4c7e0387-718b-411c-a204-e9dc3ed2d038";
    // this.employeeService.getUserRoles(userId).then(res => {
    // });
  }

  getUserInfo() {
    const userId = "4c7e0387-718b-411c-a204-e9dc3ed2d038";
    // this.employeeService.getUserInfo(userId).subscribe(res => {
    // });
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith<string | ValueLabel>(""),
      map((value) => (typeof value === "string" ? value : value.label)),
      map((label) => (label ? this.filter(label) : this.options.slice()))
    );
  }

  displayFn(user?: ValueLabel): string | undefined {
    return user ? user.label : undefined;
  }

  private filter(label: string): ValueLabel[] {
    const filterValue = label.toLowerCase();

    return this.options.filter((option) => option.label.toLowerCase().indexOf(filterValue) === 0);
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
