import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { EventLogisticsBottomSheetComponent } from "../event-logistics-bottom-sheet/event-logistics-bottom-sheet.component";
import * as moment from "moment";
import { EventOverviewService } from "src/app/services/event-overview.service";

@Component({
  selector: "grid-logistic-button",
  templateUrl: "./grid-logistic-button.component.html",
  styleUrls: ["./grid-logistic-button.component.scss"],
})
export class GridLogisticButtonComponent implements ICellRendererAngularComp {
  eventId: number;
  eventInquiryHealthscreeningId: number;
  color: string;
  showButton = false;
  refreshData: any;
  public params;
  lastEditedIndex;
  initialTrainerId: string;

  constructor(private bottomSheet: MatBottomSheet, private eventOverviewService: EventOverviewService) {}

  agInit(params: any) {
    this.params = params;
    this.refreshData = params.refreshData;
    if (params.data.trainerId) {
      this.initialTrainerId = params.data.trainerId;
    }
    this.lastEditedIndex = params.node.rowIndex;
    this.eventId = params.data.eventId;
    this.eventInquiryHealthscreeningId = params.data.eventInquiryHealthscreeningId;
    this.handleButtonColors(params);
  }

  handleButtonColors(params: any) {
    if (params.data.trainerId && params.data.trainerId !== "") {
      this.showButton = true;
      if (params.data.state === "COMPLETED" || params.data.state === "REJECTED") {
        this.color = "gray";
        this.eventOverviewService.initialColor.set(this.eventInquiryHealthscreeningId, "gray");
        return;
      }
      if (params.data.lastUpdated && this.trainerChanged() && this.trainerIdChanged()) {
        this.color = "blue";
        this.eventOverviewService.initialColor.set(this.eventInquiryHealthscreeningId, "blue");
        this.eventOverviewService.trainerChanged.set(this.eventInquiryHealthscreeningId, null);
        this.eventOverviewService.initialTrainerId.set(this.eventInquiryHealthscreeningId, null);
      } else {
        this.color = this.eventOverviewService.initialColor.get(this.eventInquiryHealthscreeningId);
        if (this.trainerEmailIsBeforeLastUpdate(params) && params.data.lastUpdated) {
          this.color = "yellow";
          this.eventOverviewService.initialColor.set(this.eventInquiryHealthscreeningId, "yellow");
        } else if (this.lastUpdateIsBeforeTrainerEmail(params)) {
          this.color = "green";
          this.eventOverviewService.initialColor.set(this.eventInquiryHealthscreeningId, "green");
        } else if (this.initialColorIsUndefined()) {
          this.color = "blue";
          this.eventOverviewService.initialColor.set(this.eventInquiryHealthscreeningId, "blue");
        }
      }
    }
  }

  private trainerEmailIsBeforeLastUpdate(params: any) {
    return moment(params.data.trainerEmailTimestamp).isBefore(params.data.lastUpdated);
  }

  private lastUpdateIsBeforeTrainerEmail(params: any) {
    return moment(params.data.lastUpdated).isBefore(params.data.trainerEmailTimestamp);
  }

  private initialColorIsUndefined() {
    return this.eventOverviewService.initialColor.get(this.eventInquiryHealthscreeningId) == undefined;
  }

  private trainerIdChanged() {
    return this.getInitialTrainerId() !== this.initialTrainerId;
  }

  private getInitialTrainerId() {
    return this.eventOverviewService.initialTrainerId.get(this.eventInquiryHealthscreeningId);
  }

  private trainerChanged() {
    return this.eventOverviewService.trainerChanged.get(this.eventInquiryHealthscreeningId) === true;
  }

  refresh() {
    return true;
  }

  openLogisticSheet() {
    const sheetData = { eventId: this.eventId, eventInquiryHealthscreeningId: this.eventInquiryHealthscreeningId };
    this.bottomSheet
      .open(EventLogisticsBottomSheetComponent, { data: sheetData })
      .afterDismissed()
      .subscribe(() => {
        this.refreshData();
        this.params.context.componentParent.lastEditedIndex = this.lastEditedIndex;
      });
  }
}
