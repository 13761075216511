import { Theme } from './symbols';
export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--primary': '#389BFF',
    '--on-primary': '#fff',
    '--secondary': '#B2B4B7',
    '--on-secondary': '#fff',
  }
};
