import { Action } from "@ngrx/store";
import { HealthScreening } from "src/app/services/health-screening.service";

export const LOAD_HEALTHSCREENING = "[HEALTHSCREENING] Load Healthscreening ";
export const LOAD_HEALTHSCREENING_SUCCESS = "[HEALTHSCREENING] Load Healthscreening Success";
export const LOAD_HEALTHSCREENING_FAIL = "[HEALTHSCREENING] Load Healthscreening Fail";

export class LoadHealthscreening implements Action {
  readonly type = LOAD_HEALTHSCREENING;
  constructor(public payload: {id: number}) {}
}
export class LoadHealthscreeningSuccess implements Action {
  readonly type = LOAD_HEALTHSCREENING_SUCCESS;
  constructor(public payload: {healthscreening: HealthScreening}) {}
}
export class LoadHealthscreeningFail implements Action {
  readonly type = LOAD_HEALTHSCREENING_FAIL;
  constructor(public payload: any) {}
}

export type HealthScreeningActions = LoadHealthscreening | LoadHealthscreeningSuccess | LoadHealthscreeningFail;
