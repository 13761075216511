<div *ngIf="eventInThePast()">
  <h2>{{ "eventInThePast" | translate }}</h2>
</div>
<div *ngIf="!eventInThePast()">
  <h2 *ngIf="moduleName">{{ ("scheduleVoting" | translate) + getModuleName() + " " + ("atTranslate" | translate) + " " + (eventDate | amDateFormat : "DD.MM.YYYY") }}</h2>
  <h6 *ngIf="moduleName && onlineModule">{{ "scheduleVotingInfo1" | translate }}{{ this.participantRegistrationTime }}{{ "scheduleVotingInfo2" | translate }}</h6>
  <div *ngIf="showWaitingList" style="text-align: center">
    <button class="btn btn-primary" type="submit" (click)="waitingListClicked()">{{ "waitinglist" | translate }}</button>
  </div>
  <div style="text-align: right; padding: 10px">
    <i class="material-icons actionButton" (click)="downloadPdf()" placement="left" [ngbTooltip]="'print' | translate">get_app</i>
    <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-card>
        <voting-participant *ngFor="let votingParticipant of votingParticipants" [votingParticipant]="votingParticipant" [sendAppointmentlistToCorporateCustomer]="votingParticipant.get('sendAppointmentlistToCorporateCustomer').value" [companynameAlias]="companynameAlias"></voting-participant>
      </mat-card>
    </div>
  </div>
  <div class="schedule-footer">
    <a href="https://www.gib-gesundheit.de/impressum/">Impressum</a>
    &nbsp;
    <a href="https://www.gib-gesundheit.de/datenschutzerklaerung/">Datenschutz</a>
  </div>
</div>
