import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { UntypedFormGroup, UntypedFormBuilder, ValidationErrors } from "@angular/forms";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { KeycloakUser, Role, Address } from "./keycloak-connector.service";
import { TranslateService } from "@ngx-translate/core";
import { HealthScreening } from "./health-screening.service";
import { EnvService } from "./env.service";

export declare type EmployeeState = "APPLICATION" | "TRAINING" | "VISIT" | "ACTIVE" | "INACTIVE";

export class TrainerForAssignmentDto {
  id: string;
  officialName: string;
}

export class Employee extends KeycloakUser {
  phonenumber: string;
  blacklisted: boolean;
  imageUrl: string;
  qualifications: string;
  preventionCourse: boolean;
  engPresentation: boolean;
  gerPresentation: boolean;
  engAdvice: boolean;
  carDrivingLicense: boolean;
  carKfz: boolean;
  carDrivingLicenseCopy: boolean;
  comment: string;
  location: string | Array<string>;
  state: EmployeeState;
  addedAt: Date;
  assignedHealthscreenings: HealthScreening[];
  lastAppointedDate: Date;

  public constructor() {
    super();
    this.phonenumber = "";
    this.blacklisted = false;
    this.imageUrl = null;
    this.qualifications = "";
    this.preventionCourse = false;
    this.engPresentation = false;
    this.gerPresentation = false;
    this.engAdvice = false;
    this.carDrivingLicense = false;
    this.carKfz = false;
    this.carDrivingLicenseCopy = false;
    this.comment = "";
    this.location = "";
    this.state = null;
    this.addedAt = null;
    this.assignedHealthscreenings = [];
    this.lastAppointedDate = null;
  }
}

@Injectable()
export class EmployeeService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private translateService: TranslateService, private formbuilder: UntypedFormBuilder, private envService: EnvService) {}

  // REST

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees", this.config);
  }

  findByRoles(roles: Role[]): Observable<HttpConfig> {
    let url = this.envService.backendUrl + "/employees/byRoles";
    url += "?roles=" + roles[0];
    for (let i = 1; i < roles.length; i++) {
      url += "&roles=" + roles[i];
    }
    return this.http.get(url, this.config);
  }

  findOne(id: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees/" + id, this.config);
  }

  create(emp: Employee): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/employees", emp, this.config);
  }

  update(emp: Employee): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/employees/" + emp.id, emp, this.config);
  }

  delete(employeeId: string): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/employees/" + employeeId, this.config);
  }

  getRolesFromBackend(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees/roles", this.config);
  }

  getTrainerForHealthScreening(eihsId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees/trainers/getAllTrainersAssigedForHealthscreening?eihsId=" + eihsId, this.config);
  }

  setAppointments(emp: Employee): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/trainer/selection/markAllTrainerAllocationsAsAppointed", emp, this.config);
  }

  getAllTrainersByState(state: EmployeeState): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees/trainers/getAllTrainersByState?state=" + state, this.config);
  }

  getTrainerForAssignment(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/employees/trainersForAssignment", this.config)
  }

  getFullName(emp: Employee): string {
    return emp.firstname + " " + emp.lastname;
  }

  mapFormToEmployee(form: UntypedFormGroup): Employee {
    const employee = form.getRawValue();
    this.handleLocationForObject(employee);
    return employee;
  }

  mapEmployeeToForm(emp: Employee) {
    const employeeForm = this.formbuilder.group(emp);
    this.handleLocationForForm(employeeForm);
    this.handleAddresses(employeeForm, emp.addresses);
    this.handleHealthscreenings(employeeForm, emp.assignedHealthscreenings);
    return employeeForm;
  }

  handleAddresses(form: UntypedFormGroup, addresses: Address[]) {
    form.removeControl("addresses");
    if (addresses && addresses.length > 0) {
      const addressFormGroupArray = [];
      for (const address of addresses) {
        addressFormGroupArray.push(this.formbuilder.group(address));
      }
      form.addControl("addresses", this.formbuilder.array(addressFormGroupArray));
    } else {
      const address = new Address();
      address.type = "HOME";
      form.addControl("addresses", this.formbuilder.array([this.formbuilder.group(address)]));
    }
  }

  handleHealthscreenings(form: UntypedFormGroup, healthscreenings: HealthScreening[]) {
    form.removeControl("assignedHealthscreenings");
    if (!healthscreenings || healthscreenings.length === 0) {
    } else {
      const healthscreeningGroupArray = [];
      for (const hs of healthscreenings) {
        const vis = hs.visibileForCompanynameAliases;
        hs.visibileForCompanynameAliases = [];
        const hsForm = this.formbuilder.group(hs);
        hsForm.removeControl("visibileForCompanynameAliases");
        hsForm.addControl("visibileForCompanynameAliases", this.formbuilder.array(vis));
        healthscreeningGroupArray.push(hsForm);
      }
      form.addControl("assignedHealthscreenings", this.formbuilder.array(healthscreeningGroupArray));
    }
  }

  private handleLocationForObject(employee: Employee) {
    if (Array.isArray(employee.location)) {
      employee.location = employee.location.join(", ");
    }
  }

  private handleLocationForForm(form: UntypedFormGroup) {
    let locationValue = form.get("location").value;
    if (locationValue) {
      locationValue = locationValue.split(", ");
    } else {
      locationValue = [];
    }
    form.get("location").setValue(locationValue);
  }

  checkDrivingLicenseAndImage(formGroup: UntypedFormGroup): ValidationErrors | null {
    const drivingLicenseCheckbox = formGroup.controls.carDrivingLicenseCopy.value;
    const drivingLicenseCopyFile = formGroup.controls.carDrivingLicenseCopyFile.value;

    if (drivingLicenseCheckbox && (!drivingLicenseCopyFile || !drivingLicenseCopyFile.id)) {
      formGroup.controls.carDrivingLicenseCopy.setErrors({ noDrivingLicenseImg: true });
      formGroup.controls.carDrivingLicenseCopy.markAsTouched();
    } else {
      formGroup.controls.carDrivingLicenseCopy.setErrors(null);
      formGroup.controls.carDrivingLicenseCopy.markAsUntouched();
    }
    return null;
  }
}
