import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError, concatMap } from "rxjs/operators";
import * as tkControllerActions from "../actions/tk-controller.actions";
import * as fromServices from "../../services";
import { FormHelper } from "src/app/helper/form.helper";

@Injectable()
export class TkControlerEffects {
  constructor(private actions$: Actions, private customerService: fromServices.CustomerService, private formHelper: FormHelper) {}

  loadTkControllers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(tkControllerActions.LOAD_TK_CONTROLLERS),
      concatMap(() => {
        return this.customerService.findAllTkControllers().pipe(
          map(
            (response) => {
              return new tkControllerActions.LoadTkControllersSuccess({ tkControllers: response.body });
            },
            catchError((error) => of(new tkControllerActions.LoadTkControllersFail(error)))
          )
        );
      })
    );
  });
}
