import { Component, OnInit, Input } from "@angular/core";
import { FileService, FileMeta, FileType } from "../../../../services/file.service";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

@Component({
  selector: "attachement-handler",
  templateUrl: "./attachement-handler.component.html",
  styleUrls: ["./attachement-handler.component.scss"],
})
export class AttachementHandlerComponent implements OnInit {
  @Input() healthScreeningForm: UntypedFormGroup;
  @Input() fileType: FileType = "HEALTHSCREENING_ATTACHMENT";

  hsAttachementControl: UntypedFormControl = new UntypedFormControl(null);
  attachements: FileMeta[] = [];
  attachementData;
  userRole;

  constructor(private fileService: FileService, private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.loadAttachements();
  }

  loadAttachements() {
    if (this.healthScreeningForm.get("id").value !== null) {
      this.fileService.getFileMetaByHealthscreening(this.healthScreeningForm.get("id").value, this.fileType).subscribe((res) => {
        this.attachements = res;
      });
    }
  }

  openDeleteConfirmation(filemeta: FileMeta) {
    const fileName = filemeta.fileName;
    const title = this.translateService.instant("deleteFileConfirmationTitle");
    const text = this.translateService.instant("deleteFileConfirmationText", { fileName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteFile(filemeta.id));
  }

  deleteFile(id: number) {
    this.fileService.delete(id).subscribe(() => {
      this.loadAttachements();
    });
  }

  downloadFile(filemeta: FileMeta) {
    let downloadUrl = "";
    this.fileService.getFileData(filemeta.id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      downloadUrl = window.URL.createObjectURL(blob);
      this.createImageFromBlob(res);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = downloadUrl;
      a.download = filemeta.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  createImageFromBlob(attachement: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.attachementData = reader.result;
      },
      false
    );

    if (attachement) {
      reader.readAsDataURL(attachement);
    }
  }
}
