import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  errors: Array<string>;
}

@Component({
  selector: 'gib-error-dialog',
  templateUrl: './gib-error-dialog.component.html',
  styleUrls: ['./gib-error-dialog.component.scss']
})
export class GibErrorDialogComponent {

  constructor(public dialogRef: MatDialogRef<GibErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
