import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Meeting } from '../../../../services/meeting.service';

@Component({
  selector: 'meeting-table-details',
  templateUrl: './meeting-table-details.component.html',
  styleUrls: ['./meeting-table-details.component.scss']
})
export class MeetingTableDetailsComponent implements OnInit {

  @Input() meeting: Meeting;

  constructor() {}

  ngOnInit() {}


}
