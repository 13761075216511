import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checkmark-icon',
  templateUrl: './checkmark-icon.component.html',
  styleUrls: ['./checkmark-icon.component.scss']
})
export class CheckmarkIconComponent implements OnInit {
  @Input() color: string = 'navy';

  constructor() { }

  ngOnInit(): void {
  }

}
