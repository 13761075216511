import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { FileMeta, FileService } from "src/app/services/file.service";
import { Chat, EventService } from "../../../../services/event.service";

@Component({
  selector: "event-complete-form",
  templateUrl: "./event-complete-form.component.html",
  styleUrls: ["./event-complete-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EventCompleteFormComponent implements OnInit {
  @Input() eventForm: UntypedFormGroup;
  @Input() internalNoteForm: UntypedFormGroup;
  @Input() chatElements: Chat[];
  @Input() task: any;
  @Input() selectedIndex: number;
  @Input() buid: string;
  @Input() showPrintButtons: boolean;
  @Input() allowModuleReplace: boolean;
  @Input() editMode: boolean;
  @Input() isEditable: boolean;

  documents: FileMeta[] = [];
  downloadUrl: string;
  userRole: string;

  constructor(private fileService: FileService, private eventService: EventService, private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit() {
    this.selectedIndex++;
    this.userRole = localStorage.getItem("role");
    this.loadFiles();
  }

  hasAnonymousEvaluation(): boolean {
    for (var hs of this.eventForm.get("eventHealthScreenings").value) {
      if (hs.anonymousEvaluation) {
        return true;
      }
    }
    return false;
  }

  hasAnonymousEvaluationDocuments(): boolean {
    return this.documents && this.documents.length !== 0;
  }

  loadFiles() {
    this.fileService.getFileMetasByUserAndType(this.buid, "ANONYMOUS_EVALUATION").subscribe((filemetas) => {
      for (const filemeta of filemetas) {
        this.documents.push(filemeta);
      }
    });
  }

  onDownload(file: FileMeta) {
    this.fileService.getFileData(file.id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = this.downloadUrl;
      a.download = file.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  sendAnonymousEvaluationNotificationClicked() {
    let confirmationTitle = "";
    let confirmationText = "";
    confirmationTitle = this.translateService.instant("sendAnonymousEvaluationNotificationTitle");
    confirmationText = this.translateService.instant("sendAnonymousEvaluationNotificationText");
    this.dialogService.openConfirmationDialog(confirmationTitle, confirmationText, () => this.sendAnonymousEvaluationNotification());
  }

  sendAnonymousEvaluationNotification() {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    this.eventService.sendAnonymousEvaluationNotification(this.buid).subscribe((res) => {});
  }
}
