import { Theme } from './symbols';
export const defaultTheme: Theme = {
  name: 'default',
  properties: {
    '--background': 'rgba(0, 12, 31, 0.9)',
    '--hover': '#7f7f7f',
    '--primary': '#394160',
    '--on-primary': '#7f7f7f',
    '--secondary': '#c8c618',
    '--on-secondary': '#0656a2',
    '--warn': '#f07746',
    '--success': "#199415",
    '--error': '#ad2121',
    '--validation-error': '#f07746'
  }
};
