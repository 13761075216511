import { FileService, FileMeta } from "./../../../../services/file.service";
import { UntypedFormGroup } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "faq-expansion-panel",
  templateUrl: "./faq-expansion-panel.component.html",
  styleUrls: ["./faq-expansion-panel.component.scss"],
})
export class FaqExpansionPanelComponent implements OnInit {
  imageUrls: string[] = [];
  videoUrls: string[] = [];
  videos: FileMeta[] = [];
  documents: FileMeta[] = [];
  downloadUrl;

  @Input() faqForm: UntypedFormGroup;

  constructor(private fileService: FileService) {}

  ngOnInit() {
    this.loadFiles();
  }

  loadFiles() {
    if (this.faqForm) {
      this.fileService.getFileMetaByHealthscreening(this.faqForm.get("id").value, "FAQ_DOCUMENT").subscribe((filemetas) => {
        for (const filemeta of filemetas) {
          if (this.isImage(filemeta)) {
            const imageUrl = this.fileService.createRessourceUrl(filemeta.id);
            this.imageUrls.push(imageUrl);
          } else if (this.isVideo(filemeta)) {
            this.videos.push(filemeta);
          } else {
            this.documents.push(filemeta);
          }
        }
      });
    }
  }

  isVideo(filemeta: FileMeta) {
    let filename = filemeta.fileName.toLowerCase();
    if (filename.includes(".mp4")) {
      return true;
    }
    if (filename.includes(".avi")) {
      return true;
    }
    if (filename.includes(".m4v")) {
      return true;
    }
    if (filename.includes(".mpeg")) {
      return true;
    }
    if (filename.includes(".mpg")) {
      return true;
    }
    if (filename.includes(".mov")) {
      return true;
    }
    if (filename.includes(".mkv")) {
      return true;
    }
    if (filename.includes(".wmv")) {
      return true;
    }
    return false;
  }

  isImage(filemeta: FileMeta) {
    let filename = filemeta.fileName.toLowerCase();
    if (filename.includes(".jpg")) {
      return true;
    }
    if (filename.includes(".png")) {
      return true;
    }
    if (filename.includes(".gif")) {
      return true;
    }
    if (filename.includes(".tiff")) {
      return true;
    }
    if (filename.includes(".jpeg")) {
      return true;
    }
    if (filename.includes(".bmp")) {
      return true;
    }
    return false;
  }

  onDownload(file: FileMeta) {
    this.fileService.getFileData(file.id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = this.downloadUrl;
      a.download = file.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }
}
