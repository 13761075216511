import * as fromCustomers from "../actions/customer.actions";
import { Customer } from "src/app/services/customer.service";

export interface CustomerState {
  customers: Customer[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: CustomerState = {
  customers: [],
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromCustomers.CustomerActions): CustomerState {
  switch (action.type) {
    case fromCustomers.LOAD_CUSTOMERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromCustomers.LOAD_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        customers: action.payload.customers,
        loading: false,
        loaded: true,
      };
    }
    case fromCustomers.LOAD_CUSTOMERS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getCustomers = (state: CustomerState) => state.customers;
