import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";

export class FeedbackReport {
  id: number;
  overallRating1: number;
  overallRating2: number;
  overallRating3: number;
  overallRating4: number;
  constructor() {
    this.id = null;
    this.overallRating1 = null;
    this.overallRating2 = null;
    this.overallRating3 = null;
    this.overallRating4 = null;
  }
}

export class CustomerFeedbackReport extends FeedbackReport {
  trainerContactedYouTrue: number;
  trainerContactedYouFalse: number;
  trainerOnTimeTrue: number;
  trainerOnTimeFalse: number;
  bookAgainTrue: number;
  bookAgainFalse: number;
  overallRatingComment: string[];
  whyNotBookAgain: string[];
  improvements: string[];
  constructor() {
    super();
    this.trainerContactedYouTrue = null;
    this.trainerContactedYouFalse = null;
    this.trainerOnTimeTrue = null;
    this.trainerOnTimeFalse = null;
    this.bookAgainTrue = null;
    this.bookAgainFalse = null;
    this.overallRatingComment = [];
    this.whyNotBookAgain = [];
    this.improvements = [];
  }
}

export class TrainerFeedbackReport extends FeedbackReport {
  orgaLocationTrue: number;
  orgaLocationFalse: number;
  orgaCustomerTrue: number;
  orgaCustomerFalse: number;
  measuringDeviceTrue: number;
  measuringDeviceFalse: number;
  overallRatingComment: string[];
  orgaComment: string[];
  measuringDeviceComment: string[];
  generalComment: string[];
  constructor() {
    super();
    this.orgaLocationTrue = null;
    this.orgaLocationFalse = null;
    this.orgaCustomerTrue = null;
    this.orgaCustomerFalse = null;
    this.measuringDeviceTrue = null;
    this.measuringDeviceFalse = null;
    this.overallRatingComment = [];
    this.orgaComment = [];
    this.measuringDeviceComment = [];
    this.generalComment = [];
  }
}

@Injectable()
export class ReportService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  getCustomerFeedbackReport(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/reports/customerFeedbackReport", this.config);
  }

  getTrainerFeedbackReport(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/reports/trainerFeedbackReport", this.config);
  }
}
