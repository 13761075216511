import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountingService, AccountingOverviewDto } from "../../../services/accounting.service";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";

@Component({
  selector: "tk-uz-overview-page",
  templateUrl: "./tk-uz-overview-page.component.html",
  styleUrls: ["./tk-uz-overview-page.component.scss"],
})
export class TkUzOverviewPageComponent implements OnInit, OnDestroy {
  events$: Observable<AccountingOverviewDto[]>;
  paymentOpenTkEvents$: Observable<AccountingOverviewDto[]>;
  paymentApprovedTkEvents$: Observable<AccountingOverviewDto[]>;
  tkUzOverviewDate = new Date();
  subscriptions: Subscription[] = [];
  userRole: string;
  activeTabIndex = 0;
  oldTabIndex = 0;

  constructor(private service: AccountingService, private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem("role");
    this.refreshEntries();
  }

  onTabChange(index: number): void {
    // This was implemented to fix the rendering problem of the mat-table when switching tabs. The 2nd and 3rd tabs sticky columns where not working properly.
    this.oldTabIndex = this.activeTabIndex;
    this.activeTabIndex = index;
  }

  onTabAnimationDone() {
    this.oldTabIndex = null;
  }

  shouldShowTable(index: number): boolean {
    //This was implemented to have the same behavior as the mat-tab-group. When switching tabs the table from and switching to should be rendered so the scolling effect looks fine.
    //Otherwise the table from would be instantly disabled and it would not look smooth.
    return this.activeTabIndex === index || this.oldTabIndex === index;
  }

  private refreshEntries() {
    const from = this.getFromDate(this.tkUzOverviewDate);
    const to = this.getToDate(this.tkUzOverviewDate);
    if (this.userRole === "gib_tkcontrolling") {
      this.events$ = this.service.getTkEvents(from, to);
      this.paymentOpenTkEvents$ = this.service.getPaymentSent(from, to);
      this.paymentApprovedTkEvents$ = this.service.getPaymentApproved(from, to);
    } else {
      this.events$ = this.service.getGibEvents(from, to);
      this.paymentOpenTkEvents$ = this.service.getPaymentOpen(from, to);
      this.paymentApprovedTkEvents$ = this.service.getPaymentApproved(from, to);
    }
  }

  onChange(event: { offset: number; date: Date }, grid: number) {
    const date: Date = new Date(this.tkUzOverviewDate.getTime());
    if (event.offset) {
      date.setDate(1);
      date.setMonth(this.tkUzOverviewDate.getMonth() + event.offset);
    } else {
      date.setDate(1);
      date.setMonth(event.date.getMonth());
      date.setFullYear(event.date.getFullYear());
    }
    this.tkUzOverviewDate = new Date(date.getTime());
    this.refreshEntries();
  }

  onRelease(dto: AccountingOverviewDto): void {
    const sub = this.service
      .openPayment(dto)
      .pipe(take(1))
      .subscribe(() => {
        this.refreshEntries();
      });
    this.subscriptions.push(sub);
  }

  onRefresh(e: any): void {
    this.refreshEntries();
  }

  private getFromDate(date: Date): string {
    const month = this.getPaddedMonth(date);
    const from = date.getFullYear() + "-" + month + "-" + "01";
    return from;
  }

  private getToDate(date: Date): string {
    const month = this.getPaddedMonth(date);
    const daysInMonth = this.getDaysInMonth(date.getMonth() + 1, date.getFullYear());
    const to = date.getFullYear() + "-" + month + "-" + daysInMonth;
    return to;
  }

  private getPaddedMonth(date: Date): string {
    return ("0" + (date.getMonth() + 1)).slice(-2);
  }

  private getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  openDownloadExcelConfirmation() {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadExcelConfirmationText");
    let filename = "GiB_Auftragsübersicht_TK.xlsx";
    if (this.userRole === "gib_tkcontrolling") {
      this.dialogService.openConfirmationDialog(title, text, () => this.getTkExcelExport(filename));
    } else {
      this.dialogService.openConfirmationDialog(title, text, () => this.getGibExcelExport(filename));
    }
  }

  getGibExcelExport(filename: string) {
    this.service.getGibExcelExport().subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      let downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  getTkExcelExport(filename: string) {
    this.service.getTkExcelExport().subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      let downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
