import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Employee, EmployeeService } from "src/app/services/employee.service";
import { CustomValidators } from "src/app/utils/custom-validator";
import { GibDialogService } from "../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../helper/form.helper";
import { KeycloakUser } from "../../services/keycloak-connector.service";
import { ProfileService } from "../../services/profile.service";
import { Customer, CustomerService } from "./../../services/customer.service";

declare type ProfileType = "customer" | "employee";

@Component({
  selector: "profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit {
  profileType: ProfileType;
  editMode = false;
  user: KeycloakUser;
  companynameAlias: string;

  form: UntypedFormGroup;

  constructor(
    private employeeService: EmployeeService,
    private dialogService: GibDialogService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private formHelper: FormHelper,
    private customerService: CustomerService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.profileService.getMyProfile().subscribe((profile) => {
      if (profile.body.role === "gib_customer" || profile.body.role === "gib_tkcontrolling") {
        this.profileType = "customer";
        this.companynameAlias = profile.body.companynameAlias;
        this.user = profile.body;
        this.form = this.initCustomerProfileForm(profile.body);
        this.formHelper.disableControls(this.form);
      } else {
        this.profileType = "employee";
        this.user = profile.body;
        this.form = this.initEmployeeProfileForm(profile.body);
        this.formHelper.disableControls(this.form);
      }
    });
  }

  saveProfileClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "profileEditErrorTitle", () => this.saveProfile());
  }

  saveProfile() {
    const profile = this.profileType;
    if (profile === "customer") {
      const customer = this.customerService.mapFormToCustomer(this.form);
      customer.role = this.user.role;
      this.profileService.updateProfile(customer).subscribe((res) => {
        const title = this.translateService.instant("profileEditedSuccessTitle");
        const text = this.translateService.instant("profileEditedSuccessText");
        this.dialogService.openDialog(title, text, "success");
        this.editMode = false;
        this.formHelper.disableControls(this.form);
      });
    } else if (profile === "employee") {
      const employee = this.employeeService.mapFormToEmployee(this.form);
      employee.role = this.user.role;
      this.profileService.updateProfile(employee).subscribe((res) => {
        const title = this.translateService.instant("profileEditedSuccessTitle");
        const text = this.translateService.instant("profileEditedSuccessText");
        this.dialogService.openDialog(title, text, "success");
        this.editMode = false;
        this.formHelper.disableControls(this.form);
      });
    }
  }

  initCustomerProfileForm(customer: Customer) {
    const customerForm = this.customerService.mapCustomerToForm(customer);
    customerForm.get("firstname").setValidators([Validators.required]);
    customerForm.get("lastname").setValidators([Validators.required]);
    customerForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    customerForm.get("salutation").setValidators([Validators.required]);
    customerForm.get("phonenumber").setValidators([Validators.required]);
    customerForm.get("companyname").setValidators([Validators.required]);
    if (this.companynameAlias === "TK" && this.user.role === "gib_customer") {
      customerForm.get("region").setValidators([Validators.required]);
      customerForm.get("teamnumber").setValidators([Validators.required]);
      customerForm.get("teamleadFirstname").setValidators([Validators.required]);
      customerForm.get("teamleadLastname").setValidators([Validators.required]);
      customerForm.get("teamleadEmail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
      customerForm.get("teamleadPhonenumber").setValidators([Validators.required]);
    }
    return customerForm;
  }

  initEmployeeProfileForm(employee: Employee) {
    const employeeForm = this.employeeService.mapEmployeeToForm(employee);
    employeeForm.addControl("carDrivingLicenseCopyFile", new UntypedFormControl(""));
    employeeForm.get("username").setValidators([Validators.required]);
    employeeForm.get("firstname").setValidators([Validators.required]);
    employeeForm.get("lastname").setValidators([Validators.required]);
    employeeForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    employeeForm.get("phonenumber").setValidators([Validators.required]);
    employeeForm.setValidators(this.employeeService.checkDrivingLicenseAndImage);
    return employeeForm;
  }
}
