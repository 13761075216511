import { Injectable } from "@angular/core";
import jwtDecode from "jwt-decode";
@Injectable({
  providedIn: "root",
})
export class TokenHelperService {
  constructor() {}
  getToken(): string {
    return localStorage.getItem("token");
  }
  decodeToken(): any {
    return jwtDecode(this.getToken());
  }
  // getRoles(): string[] {
  //     return this.decodeToken().roles;
  // }
  // getFullname(): string {
  //     let token = this.decodeToken();
  //     return token.given_name + " " + token.family_name;
  // }
  getUserId(): string {
    return this.decodeToken().sub;
  }
  getEmail(): string {
    return this.decodeToken().email;
  }
  // getFirstname(): string {
  //     return this.decodeToken().family_name;
  // }
  // getLastname(): string {
  //     return this.decodeToken().given_name;
  // }
  // containsRole(role: string): boolean {
  //     return this.getRoles().includes(role);
  // }
  // isLoggedIn(): boolean {
  //     if (this.getToken()) {
  //         return true;
  //     }
  //     return false;
  // }
  // isExpired(): boolean {
  //     try {
  //         const decodeToken = this.decodeToken();
  //         const expirationDate = new Date(new Date(0).setUTCSeconds(decodeToken.exp));
  //         if (new Date() < expirationDate) {
  //             return false;
  //         }
  //         return true;
  //     } catch (error) {
  //         return true;
  //     }
  // }
  // logout() {
  //     localStorage.removeItem("token");
  // }
}
