<!-- <mat-card class="sticky"> -->
<mat-card style="margin-top: 15px">
  <mat-card-content>
    <form [formGroup]="meetingForm">
      <div class="row">
        <div class="col-sm-12">
          <gib-checkbox [label]="'active'" [control]="meetingForm.get('active')"></gib-checkbox>
          <gib-input [placeholder]="'alias'" [control]="meetingForm.get('alias')"></gib-input>
          <gib-input [placeholder]="'username'" [control]="meetingForm.get('username')"></gib-input>
          <gib-input [placeholder]="'password'" [control]="meetingForm.get('password')"></gib-input>
          <gib-input [placeholder]="'emergencyPhone'" [control]="meetingForm.get('emergencyPhone')"></gib-input>
          <gib-select [placeholder]="'moduleType'" [control]="meetingForm.get('moduleType')" [options]="moduleTypes"></gib-select>
          <gib-textarea [placeholder]="'furtherInformation'" [control]="meetingForm.get('info')"></gib-textarea>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <button mat-button (click)="cancel()">{{ "CANCEL" | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="createEditMeeting()">{{ (meetingForm.get("id").value ? "save" : "ADD") | translate }}</button>
  </mat-card-footer>
</mat-card>
