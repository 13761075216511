import { FormHelper } from "./../../helper/form.helper";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "gib-radio-button-group-orange",
  templateUrl: "./gib-radio-button-group-orange.component.html",
  styleUrls: ["./gib-radio-button-group-orange.component.scss"],
})
export class GibRadioButtonGroupOrangeComponent implements OnInit {
  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;
  @Input() showRequired: boolean = true;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;

    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}
}
