<h1 style="text-align: center">{{'customerRegistration' | translate}}</h1>
<div class="row align-items-center">
  <div class="aa-parent">
    <div></div>
    <div class="col-12" style="max-width: 400px;">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="form">
            <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
            <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
            <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
            <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
            <gib-input [placeholder]="'username'" [control]="form.get('username')"></gib-input>
            <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
          </form>
        </mat-card-content>
      </mat-card>
      </div>
    <div></div>
  </div>
</div>
<div class="row">
  <div class="col-4"></div>
  <div class="col-4" style="text-align: center">
    <button type="button" class="btn btn-primary" (click)="startRegistration()" *ngIf="!registrationStarted">{{'signup' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="resetForm()" *ngIf="registrationStarted">{{'startAnotherRegistration' | translate}}</button>
  </div>
  <div class="col-4"></div>
</div>