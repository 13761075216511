<mat-card>
  <div class="imgContainer" *ngIf="imageUrls">
    <img src="{{ imageUrls[0] }}" />
    <!-- <img src="/assets/images/homepage/faq.jpg" /> -->
  </div>
  <mat-card-content>
    <h4>{{ _healthScreeningObject.moduleName }}</h4>
    <!-- <p class="preview-topic">{{ _healthScreeningObject.topic | translate }}</p> -->
    <p class="preview-topic">{{ getTopics() }}</p>
    <p class="short_description">{{ _healthScreeningObject.shortDescription }}</p>
    <p class="measuringDevice" *ngIf="_healthScreeningObject.measuringDevice != ''">
      <mat-icon class="material-icons" svgIcon="measure">measure</mat-icon>
      <b> {{ _healthScreeningObject.measuringDevice }}</b>
      <br />
      <br />
    </p>
    <div class="d-flex justify-content-around" *ngIf="userRole === 'gib_customer' || userRole === 'gib_trainer'">
      <button class="btn btn-secondary" (click)="routeToInfoPage(_healthScreeningObject.id, _healthScreeningObject.topic)">
        {{ "moreInfo" | translate }}
      </button>
      <button class="btn btn-primary" *ngIf="this.userRole === 'gib_customer'" (click)="addModuleToCart(_healthScreeningObject)">
        {{ "cart" | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
