import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";

export class Lock {
  entityName: string;
  id: string;
  username: string;
  timeout: number;

  constructor() {
    this.entityName = null;
    this.id = "";
    this.username = "";
    this.timeout = 0;
  }
}

@Injectable({
  providedIn: "root",
})
export class LockingService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private envService: EnvService) {}

  check(lock: Lock): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/locking/check", lock, this.config);
  }

  lock(lock: Lock): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/locking/lock", lock, this.config);
  }

  release(lock: Lock): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/locking/release", lock, this.config);
  }
}
