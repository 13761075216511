import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";

import * as fromStore from "../store";

@Injectable()
export class EventsGuard implements CanActivate {
  private whiteList: string[] = ["/event-overview-page"];

  constructor(private store: Store<fromStore.GibState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const found = this.whiteList.includes(state.url);
    if (!found) {
      this.store.dispatch(new fromStore.ClearCancelledEvents());
      this.store.dispatch(new fromStore.ClearInactiveEvents());
    }
    return of(true);
  }
}
