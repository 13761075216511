import { FaqFormComponent } from "./components/faq-form/faq-form.component";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "src/app/components/components.module";
import { UtilsModule } from "../../utils/utils.module";
import { NgModule } from "@angular/core";
import { TrainerFaqPageComponent } from "./trainer-faq-page/trainer-faq-page.component";
import { FaqTableComponent } from "./components/faq-table/faq-table.component";
import { AddFaqBottomSheetComponent } from "./components/add-faq-bottom-sheet/add-faq-bottom-sheet.component";
import { GibFaqPageComponent } from "./gib-faq-page/gib-faq-page.component";
import { FaqExpansionPanelComponent } from "./components/faq-expansion-panel/faq-expansion-panel.component";
import { AppRoutingModule } from "src/app/app-routing.module";

@NgModule({
  declarations: [TrainerFaqPageComponent, FaqFormComponent, FaqTableComponent, AddFaqBottomSheetComponent, GibFaqPageComponent, FaqExpansionPanelComponent],
  imports: [UtilsModule, ComponentsModule, TranslateModule, AppRoutingModule],
  exports: [TrainerFaqPageComponent, FaqFormComponent, FaqTableComponent, GibFaqPageComponent],
})
export class FaqModule {}
