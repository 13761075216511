import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { GlobalRateDto, GlobalRateService } from "../../../services/global-rate.service";
import { HealthScreeningService } from "../../../services/health-screening.service";
import { ValueLabel } from "../../../components/value-label";
import { map } from "rxjs/operators";

@Component({
  selector: "edit-tk-uz-global-rate",
  styleUrls: ["edit-tk-uz-global-rate.component.scss"],
  template: `
    <h1>
      {{ "editTkUzGlobalRate" | translate }}
    </h1>
    <div class="row">
      <div class="col-sm-12">
        <tk-uz-global-rate-form [modules]="modules$ | async" [globalRate]="globalRate$ | async" (cancel)="onCancel($event)" (save)="onSave($event)"></tk-uz-global-rate-form>
      </div>
    </div>
  `,
})
export class EditTkUzGlobalRateComponent implements OnInit, OnDestroy {
  globalRate$: Observable<GlobalRateDto>;
  modules$: Observable<ValueLabel[]>;
  subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute, private globalRateService: GlobalRateService, private router: Router, private healthScreeningService: HealthScreeningService) {}

  ngOnInit(): void {
    const sub = this.route.params.subscribe((params: Params) => {
      const id: string = params.id;
      if (id) {
        this.globalRate$ = this.globalRateService.get(id);
      }
    });
    this.subscriptions.push(sub);
    this.modules$ = this.healthScreeningService.findAllForModuleSelection("TK").pipe(
      map((res) => res.body),
      map((modules) =>
        modules.map((module) => {
          return {
            value: module.id,
            label: module.moduleName,
          };
        })
      )
    );
  }

  onCancel(event: any): void {
    this.router.navigate(["finance", "tk-uz-global-rates"]);
  }

  onSave(event: GlobalRateDto): void {
    const sub = this.globalRateService.save(event).subscribe(() => {
      this.router.navigate(["finance", "tk-uz-global-rates"]);
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
