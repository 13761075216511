import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gib-check-close',
  templateUrl: './gib-check-close.component.html',
  styleUrls: ['./gib-check-close.component.scss']
})
export class GibCheckCloseComponent implements OnInit {

  @Input() value: boolean;

  constructor() { }

  ngOnInit() {
  }

}
