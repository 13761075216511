<div class="card">
  <div class="card-body">
    <div class="form-group">
      <event-logistics-form *ngIf="eventForm" [meetingForm]="meetingForm" [logisticsForm]="eventForm" [eventInquiryHealthscreeningIndex]="eihsIndex"></event-logistics-form>
    </div>
  </div>
</div>
<div *ngIf="!isCompletedOrRejected" class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10 mat-dialog-title">
    <button *ngIf="!emailSent" type="button" class="btn btn-primary" (click)="sendLogisticDataToTrainerClicked()">{{ "sendLogisticDataToTrainer" | translate }}</button>
    <div [@visibility]="emailSent ? 'visible' : 'hidden'" class="button-div">
      <p [ngStyle]="{ color: 'var(--success)' }">
        <b>{{ "logisticDataWasSent" | translate }}</b>
      </p>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
