import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { catchError, map } from 'rxjs/operators';
import { throwError, EMPTY } from "rxjs";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { GibServerError } from '../../components/dialogs/gib-server-error-dialog/gib-server-error-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface HttpConfig {
  body ? : any;
  headers ? : HttpHeaders;
  observe ? : any;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private keycloak: KeycloakService, private dialogService: GibDialogService, private router: Router, private translateService: TranslateService) {}

  intercept(req: HttpRequest < any > , next: HttpHandler) {
    const token: string = localStorage.getItem('token');
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }

    return next.handle(req).pipe(map((event: HttpEvent < any > ) => {
      if (event instanceof HttpResponse) {
        // handle http responses here
      }
      return event;
    }), catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          this.handleError400(error);
          break;
          // session timeout from keycloak response has status 0
        case 0:
        case 401:
          this.keycloak.login();
          break;
        case 409:
          // do nothing.. this should only come from keycloak.. these errors are handled separately where the calls are made
          break;
        case 500:
          this.handleError500(error);
          break;
        default:
          this.handleUnexpectedError(error);
          break;
      }

      return throwError(error);
    }));
  }

  handleError400(error) {
    if (error.error && error.error.errorCode === 'CUSTOMER_NOT_FOUND' && this.router && this.router.url && this.router.url.includes('customer-registration/submit')) {
      this.dialogService.openErrorDialog('invalidLink', []);
    } else if (error.error && error.error.errorCode === 'PROCESS_META_NOT_FOUND' && this.router && this.router.url && this.router.url.includes('/event-inquiry/customer-response/submit')) {
      this.dialogService.openErrorDialog('invalidLink', []);
    } else if (error.error && error.error.errorCode) {
      const errorInfos = [];
      if (error.error.gibErrorInfos) {
        for (let info of error.error.gibErrorInfos) {
          if (info.errorParam) {
            errorInfos.push(this.translateService.instant(info.errorCode, { errorParam: info.errorParam }));
          } else {
            errorInfos.push(this.translateService.instant(info.errorCode));
          }
        }
      }
      this.dialogService.openErrorDialog(error.error.errorCode, errorInfos);
    }
  }

  handleError500(error) {
    // defined GibServerErrorException
    if (error.error && error.error.errorCode) {
      this.dialogService.openServerErrorDialog(error.error);
    } else {
      // unexpected server exception
      const errorObject = new GibServerError();
      errorObject.errorCode = error.error.error + ' | ' + error.error.message + ' | ' + error.error.path;
      errorObject.date = error.error.timestamp;
      this.dialogService.openServerErrorDialog(errorObject);
    }
  }

  handleUnexpectedError(error) {
    const errorObject = new GibServerError();
    errorObject.errorCode = error.name + ' | ' + error.message;
    errorObject.date = new Date();
    this.dialogService.openServerErrorDialog(errorObject);
  }

}