import { Subscription } from 'rxjs';
import { FormHelper } from './../../helper/form.helper';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'gib-timepicker',
  templateUrl: './gib-timepicker.component.html',
  styleUrls: ['./gib-timepicker.component.scss']
})
export class GibTimepickerComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  isRequired: boolean;
  requiredErrorMessage: string;
  langSubscription: Subscription;
  disableClick: boolean;

  @Input() placeholder: string;
  minGapIntern = 15;
  minGap = 15;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);

    const placeholderInputValue = this.placeholder;

    if (this.placeholder && this.placeholder !== '') {
      this.placeholder = this.translateService.instant(this.placeholder);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== '') {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
    });

  }

  @Input() set minutesGap(value: number) {
    if (value) {
      this.minGapIntern = value;
      this.minGap = value;
    } else {
      this.minGapIntern = 15;
      this.minGap = 15;
    }
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  focusOut(value: number) {
    this.minGap = value;
    this.disableClick = true;
  }

  focusIn(value: number) {
    this.minGap = value;
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

}
