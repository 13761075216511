import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { KeycloakUser } from "./keycloak-connector.service";
import { EnvService } from "./env.service";

@Injectable()
export class ProfileService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private envService: EnvService) {}

  // REST

  getMyProfile(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/myProfile", this.config);
  }

  updateProfile(user: KeycloakUser): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/updateProfile", user, this.config);
  }

  // HELPER
}
