<div class="card trainer-feedback-form">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ "feedbackHeader" | translate }}</h1>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-10">
            <gib-input [placeholder]="'companyname'" [control]="eventForm.get('customerCompanyName')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input [placeholder]="'firstname'" [control]="eventForm.get('customerContactFirstname')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input [placeholder]="'lastname'" [control]="eventForm.get('customerContactLastname')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="eventForm.get('customerPhone')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-4">
            <gib-datepicker [placeholder]="'eventDate'" [control]="eventForm.get('eventDate')"></gib-datepicker>
          </div>
          <div class="col-sm-4">
            <gib-timepicker [placeholder]="'from'" [control]="eventForm.get('startTime')"></gib-timepicker>
          </div>
          <div class="col-sm-4">
            <gib-timepicker [placeholder]="'to'" [control]="eventForm.get('endTime')"></gib-timepicker>
          </div>
          <div class="col-sm-12">
            <gib-input [placeholder]="'corporateCustomerCompanyName'" [control]="eventForm.get('corporateCustomerCompanyName')"></gib-input>
          </div>
          <div class="col-sm-9">
            <gib-input [placeholder]="'street'" [control]="eventForm.get('eventlocation').get('street')"></gib-input>
          </div>
          <div class="col-sm-3">
            <gib-input [placeholder]="'orderNumber'" [control]="eventForm.get('eventlocation').get('number')"></gib-input>
          </div>
          <div class="col-sm-9">
            <gib-input [placeholder]="'city'" [control]="eventForm.get('eventlocation').get('city')"></gib-input>
          </div>
          <div class="col-sm-3">
            <gib-input [placeholder]="'zip'" [control]="eventForm.get('eventlocation').get('zip')"></gib-input>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'overallRating'" [control]="eventFeedbackCustomerForm.get('overallRating')" [valueLabels]="feedbackOptions"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'overallRatingComment'" [control]="eventFeedbackCustomerForm.get('overallRatingComment')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'trainerContactedYou'" [control]="eventFeedbackCustomerForm.get('trainerContactedYou')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'trainerOnTime'" [control]="eventFeedbackCustomerForm.get('trainerOnTime')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'bookAgain'" [control]="eventFeedbackCustomerForm.get('bookAgain')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'whyNotBookAgain'" [control]="eventFeedbackCustomerForm.get('whyNotBookAgain')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'improvements'" [control]="eventFeedbackCustomerForm.get('improvements')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
    </div>
  </div>
</div>
<div *ngIf="showButton" class="row">
  <div class="col-sm-12 center">
    <button class="btn btn-primary" (click)="submitCustomerFeedbackClicked()">
      <i class="material-icons">feedback</i>
      {{ "submitFeedback" | translate }}
    </button>
  </div>
</div>
<div [@visibility]="!showButton ? 'visible' : 'hidden'" class="col-sm-12 center">
  <p [ngStyle]="{ color: resultColor }">
    <b>{{ result | translate }}</b>
  </p>
</div>
