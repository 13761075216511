import { RouterEffects } from "./router.effect";
import { ActiveEventEffects } from "./active-event.effects";
import { CancelledEventEffects } from "./cancelled-event.effects";
import { InactiveEventEffects } from "./inactive-event.effects";
import { CustomerEffects } from "./customer.effects";
import { TkControlerEffects } from "./tk-controller.effects";
import { EmployeeEffects } from "./employee.effects";
import { HealthScreeningEffects } from "./healthscreening.effects";

export const effects: any[] = [RouterEffects, HealthScreeningEffects, EmployeeEffects, CustomerEffects, ActiveEventEffects, CancelledEventEffects, InactiveEventEffects, TkControlerEffects];

export * from "./router.effect";
export * from "./active-event.effects";
export * from "./cancelled-event.effects";
export * from "./inactive-event.effects";
export * from "./customer.effects";
export * from "./tk-controller.effects";
export * from "./employee.effects";
export * from "./healthscreening.effects";
