import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UntypedFormBuilder } from "@angular/forms";
import { UntypedFormGroup } from "@angular/forms";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";

export class Faq {
  id: number;
  sortOrder: number;
  title: string;
  description: string;
  companynameAlias: string;

  constructor() {
    this.id = null;
    this.sortOrder = 0;
    this.title = "";
    this.description = "";
    this.companynameAlias = "";
  }
}

@Injectable()
export class FaqService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  createupdateFaq(faq: Faq): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/faq/createupdate", faq, this.config);
  }

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/faq/getAllFaq", this.config);
  }
  findMy(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/faq/getMyFaq", this.config);
  }

  deleteFaq(id: number): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/faq/" + id, this.config);
  }

  mapFormToFaq(form: UntypedFormGroup): Faq {
    const faq = form.getRawValue();
    return faq;
  }

  mapFaqToForm(faq: Faq): UntypedFormGroup {
    const form = this.formBuilder.group(faq);
    return form;
  }
}
