<h1 style="text-align: center">{{'trainerSelection' | translate}}</h1>
<br>
<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-8">
    <div class="card">
      <div class="card-body">
        <event-timing [eventForm]="eventForm"></event-timing>
        <hr>
        <event-location [eventForm]="eventForm"></event-location>
      </div>
    </div>
  </div>
  <div class="col-sm-2"></div>
</div>
<hr>
<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-8">
    <div class="card">
      <div class="card-body">
        <trainer-for-module-selector *ngFor="let eventHealthScreening of eventForm.get('eventHealthScreenings')['controls']" [eventHealthScreening]="eventHealthScreening" [disabled]="disabled"></trainer-for-module-selector>
      </div>
    </div>
  </div>
  <div class="col-sm-2"></div>
</div>
