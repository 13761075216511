import { Component, OnDestroy, OnInit } from "@angular/core";
import { GlobalRateDto, GlobalRateService } from "../../../services/global-rate.service";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "tk-uz-global-rates-page",
  styleUrls: ["tk-uz-global-rates-page.component.scss"],
  template: `
    <h1>
      {{ "tkUzGlobalRates" | translate }}
    </h1>
    <div class="row">
      <div class="col-sm-12">
        <tk-uz-global-rates-table [globalRates]="globalRates$ | async" (add)="onAddGlobalRate($event)" (edit)="onEditGlobalRate($event)" (delete)="onDeleteGlobalRate($event)"></tk-uz-global-rates-table>
      </div>
    </div>
  `,
})
export class TkUzGlobalRatesPageComponent implements OnInit, OnDestroy {
  globalRates$: Observable<GlobalRateDto[]>;
  subscriptions: Subscription[] = [];

  constructor(private globalRateService: GlobalRateService, private router: Router) {}

  ngOnInit(): void {
    this.globalRates$ = this.globalRateService.getAll();
  }

  onAddGlobalRate(event: any): void {
    this.router.navigate(["finance", "tk-uz-global-rates", "add"]);
  }

  onEditGlobalRate(id: string): void {
    this.router.navigate(["finance", "tk-uz-global-rates", id]);
  }

  onDeleteGlobalRate(id: string): void {
    const sub = this.globalRateService
      .delete(id)
      .pipe(take(1))
      .subscribe(() => {
        this.globalRates$ = this.globalRateService.getAll();
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
