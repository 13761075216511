<h1> {{ 'CUSTOMER' | translate }} </h1>
<div class="row">
  <div class="{{selectedCustomer ? 'col-sm-12 col-md-12 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12'}}">
    <customer-table #custmrTable [customers]="customers$ | async" (customerSelected)="openCustomerCard($event)" (addCustomerClicked)="openCustomerCard()" (customerDeleted)="fetchCustomers()"></customer-table>
  </div>
  <div *ngIf="selectedCustomer" class="col-sm-12 col-md-12 col-lg-6 customer-details">
    <div class="sticky-top" style="top: 50px">
      <add-customer [customer]="selectedCustomer" [addressType]="'BILLING'" (customerAdded)="fetchCustomers()"></add-customer>
    </div>
  </div>
</div>
