import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { GlobalRateDto } from "../../../../services/global-rate.service";
import { ValueLabel } from "../../../../components/value-label";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FormHelper } from "./../../../../helper/form.helper";
import * as uuid from "uuid";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";

@Component({
  selector: "tk-uz-global-rate-form",
  styleUrls: ["tk-uz-global-rate-form.component.scss"],
  template: `
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title></mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <h4>{{ "healthscreenings" | translate }}</h4>
            <div class="box">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnlineModule'" [control]="priceOnlineModule"></gib-input-currency>
                </div>
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnsiteModule'" [control]="priceOnsiteModule"></gib-input-currency>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnlineWebinar'" [control]="priceOnlineWebinar"></gib-input-currency>
                </div>
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnsiteWebinar'" [control]="priceOnsiteWebinar"></gib-input-currency>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnlineWorkshop'" [control]="priceOnlineWorkshop"></gib-input-currency>
                </div>
                <div class="col-md-6 col-sm-12">
                  <gib-input-currency [placeholder]="'priceOnsiteWorkshop'" [control]="priceOnsiteWorkshop"></gib-input-currency>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <h4>{{ "specialModuleRates" | translate }}</h4>
            <div class="box">
              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field *ngIf="modules && modules.length > 0" class="example-full-width">
                    <mat-label>{{ "module" | translate }}</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="module" [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                      <mat-option *ngFor="let option of modules" [value]="option.value">{{ option.label }}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngFor="let specialModuleRate of specialModuleRates.controls; index as i" class="col-sm-12">
                <div class="d-flex align-items-center">
                  <gib-input-currency [placeholder]="specialModuleRate.get('modulename').value" [control]="specialModuleRate.get('price')"></gib-input-currency>
                  <i class="ml-2 material-icons" style="cursor: pointer;" (click)="onDeleteSpecialModuleRate(i)">delete</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <h4>Extras</h4>
            <div class="box">
              <div class="row">
                <div class="col-sm-12">
                  <gib-input-currency [placeholder]="'priceAnonymousEvaluation'" [control]="priceAnonymousEvaluation"></gib-input-currency>
                </div>
                <div class="col-sm-12">
                  <gib-input-currency [placeholder]="'priceEnglish'" [control]="priceEnglish"></gib-input-currency>
                </div>
                <div class="col-sm-12">
                  <gib-input-currency [placeholder]="'priceConsumableMaterials'" [control]="priceConsumableMaterials"></gib-input-currency>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <h4>{{ "CANCELATION_FEES" | translate }}</h4>
            <div class="box">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <gib-input-percentage [placeholder]="'cancelShortNotice'" [control]="cancelShortNotice"></gib-input-percentage>
                </div>
                <div class="col-md-4 col-sm-12">
                  <gib-input-percentage [placeholder]="'cancelMidNotice'" [control]="cancelMidNotice"></gib-input-percentage>
                </div>
                <div class="col-md-4 col-sm-12">
                  <gib-input-percentage [placeholder]="'cancelLongNotice'" [control]="cancelLongNotice"></gib-input-percentage>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-sm-12"></div>
        </div>
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <h4>{{ "validFrom" | translate }}</h4>
            <div class="box">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <gib-datepicker [placeholder]="'validFrom'" [control]="validFrom"></gib-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button (click)="onCancel()">{{ "CANCEL" | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="onSave()">{{ "save" | translate }}</button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class TkUzGlobalRateFormComponent implements OnInit, OnChanges {
  @Input() modules: ValueLabel[] = [];
  @Input() globalRate: GlobalRateDto;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<GlobalRateDto> = new EventEmitter();

  form: FormGroup = this.fb.group({
    id: [uuid.v4()],
    priceOnlineModule: [null, Validators.required],
    priceOnsiteModule: [null, Validators.required],
    priceOnlineWebinar: [null, Validators.required],
    priceOnsiteWebinar: [null, Validators.required],
    priceOnlineWorkshop: [null, Validators.required],
    priceOnsiteWorkshop: [null, Validators.required],
    priceAnonymousEvaluation: [null, Validators.required],
    priceEnglish: [null, Validators.required],
    priceConsumableMaterials: [null, Validators.required],
    cancelShortNotice: [null, Validators.required],
    cancelMidNotice: [null, Validators.required],
    cancelLongNotice: [null, Validators.required],
    specialModuleRates: this.fb.array([]),
    validFrom: [null, Validators.required],
  });

  moduleForm: FormGroup = this.fb.group({
    module: [],
  });

  constructor(private fb: FormBuilder, private formHelper: FormHelper, private translateService: TranslateService, private dialogService: GibDialogService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.globalRate) {
      this.emptySpecialModuleRates();
      this.form.patchValue(this.globalRate);
      if (this.globalRate.specialModuleRates) {
        for (const item of this.globalRate.specialModuleRates) {
          const fg: FormGroup = this.fb.group({
            id: [item.id],
            globalRates: [item.globalRates],
            modulename: [item.modulename],
            price: [item.price, Validators.required],
          });
          this.specialModuleRates.push(fg);
        }
      }
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.addSpecialModuleRate(this.getModuleName(value));
    this.module.setValue(null);
  }

  onDeleteSpecialModuleRate(index: number): void {
    this.specialModuleRates.removeAt(index);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onSave(): void {
    const title = this.translateService.instant("tkUzGlobalRateFormTitle");
    const text = this.translateService.instant("tkUzGlobalRateFormText");
    this.formHelper.isFormValidElseShowErrors(this.form, "tkUzGlobalRateFormErrorTitle", () => this.dialogService.openConfirmationDialog(title, text, () => this.save.emit(this.form.getRawValue())));
  }

  private addSpecialModuleRate(moduleName: string): void {
    const fg: FormGroup = this.fb.group({
      id: [uuid.v4()],
      globalRates: [this.id.value],
      modulename: [moduleName],
      price: [null, Validators.required],
    });
    const index = this.specialModuleRates.controls.findIndex((smr) => smr.get("modulename").value === moduleName);
    if (index === -1) {
      this.specialModuleRates.push(fg);
    }
  }

  private getModuleName(value: string): string {
    const index = this.modules.findIndex((modules) => modules.value === value);
    if (index !== -1) {
      return this.modules[index].label;
    }
    return null;
  }

  private emptySpecialModuleRates(): void {
    while (this.specialModuleRates.controls.length) {
      this.specialModuleRates.removeAt(0);
    }
  }

  get id(): FormControl {
    return this.form.get("id") as FormControl;
  }

  get priceOnlineModule(): FormControl {
    return this.form.get("priceOnlineModule") as FormControl;
  }

  get priceOnsiteModule(): FormControl {
    return this.form.get("priceOnsiteModule") as FormControl;
  }

  get priceOnlineWebinar(): FormControl {
    return this.form.get("priceOnlineWebinar") as FormControl;
  }

  get priceOnsiteWebinar(): FormControl {
    return this.form.get("priceOnsiteWebinar") as FormControl;
  }

  get priceOnlineWorkshop(): FormControl {
    return this.form.get("priceOnlineWorkshop") as FormControl;
  }

  get priceOnsiteWorkshop(): FormControl {
    return this.form.get("priceOnsiteWorkshop") as FormControl;
  }

  get priceAnonymousEvaluation(): FormControl {
    return this.form.get("priceAnonymousEvaluation") as FormControl;
  }

  get priceEnglish(): FormControl {
    return this.form.get("priceEnglish") as FormControl;
  }

  get priceConsumableMaterials(): FormControl {
    return this.form.get("priceConsumableMaterials") as FormControl;
  }

  get cancelShortNotice(): FormControl {
    return this.form.get("cancelShortNotice") as FormControl;
  }

  get cancelMidNotice(): FormControl {
    return this.form.get("cancelMidNotice") as FormControl;
  }

  get cancelLongNotice(): FormControl {
    return this.form.get("cancelLongNotice") as FormControl;
  }

  get validFrom(): FormControl {
    return this.form.get("validFrom") as FormControl;
  }

  get specialModuleRates(): FormArray {
    return this.form.get("specialModuleRates") as FormArray;
  }

  get module(): FormControl {
    return this.moduleForm.get("module") as FormControl;
  }
}
