<div class="row">
  <div class="col-sm-4">
    <gib-input [placeholder]="'alias'" [control]="meetingForm.get('alias')"></gib-input>
    <gib-input [placeholder]="'emergencyPhone'" [control]="meetingForm.get('emergencyPhone')"></gib-input>
  </div>
  <div class="col-sm-4">
    <gib-input [placeholder]="'username'" [control]="meetingForm.get('username')"></gib-input>
    <gib-input [placeholder]="'password'" [control]="meetingForm.get('password')"></gib-input>
  </div>
  <div class="col-sm-4">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <gib-textarea [placeholder]="'info'" [control]="meetingForm.get('info')"></gib-textarea>
  </div>
</div>