import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'trainer-for-module-selection',
  templateUrl: './trainer-for-module-selection.component.html',
  styleUrls: ['./trainer-for-module-selection.component.sass']
})
export class TrainerForModuleSelectionComponent implements OnInit {

  @Input() eventForm: UntypedFormGroup;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {
  }

}
