import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "../../../components/value-label";
import { Employee, EmployeeService } from "../../../services/employee.service";
import { EmployeesTableComponent } from "../components/employees-table/employees-table.component";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { getAllEmployees, getRoleValueLabels} from "../../../store";
import { Role } from "src/app/services/keycloak-connector.service";

@Component({
  selector: "employees-page",
  templateUrl: "./employees-page.component.html",
  styleUrls: ["./employees-page.component.scss"],
})
export class EmployeesPageComponent implements OnInit, OnDestroy {
  @ViewChild("emptable", { static: true }) employeeTable: EmployeesTableComponent;

  employees$: Observable<Employee[]> = this.store.select(getAllEmployees);
  roleValueLabels$: Observable<ValueLabel[]> = this.store.select(getRoleValueLabels);
  selectedEmployee: Employee;
  rolesToFind = ["gib_admin", "gib_director", "gib_employee"] as Role[]

  constructor(
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private store: Store<fromStore.GibState>
  ) {}

  ngOnInit() {
    this.store.dispatch(
      new fromStore.LoadEmployeesByRole({roles: this.rolesToFind})
    )
    this.store.dispatch(
      new fromStore.LoadRoles()
    )
  }

  fetchEmployees() {
    this.employeeTable.resetDisplayedColumns();
    this.selectedEmployee = null;
    this.store.dispatch(
      new fromStore.LoadEmployeesByRole({roles: this.rolesToFind})
    )
  }

  openEmployeeCard(emp?: Employee) {
    if (!emp) {
      this.selectedEmployee = new Employee();
    } else {
      this.selectedEmployee = emp;
    }
  }

  ngOnDestroy(): void {
  }
}
