import * as fromCancelledEvents from "../actions/cancelled-event.actions";
import { EventOverviewDTO } from "src/app/services/event-overview.service";

export interface CancelledEventsState {
  entities: { [period: string]: { [id: string]: EventOverviewDTO } };
  loading: boolean;
  loaded: boolean;
}

export const initialState: CancelledEventsState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromCancelledEvents.CancelledEventActions): CancelledEventsState {
  switch (action.type) {
    case fromCancelledEvents.LOAD_CANCELLED_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromCancelledEvents.LOAD_CANCELLED_EVENTS_SUCCESS: {
      const events = action.payload;
      const period = action.period;
      const entities = events.reduce(
        (entities: { [period: string]: { [id: string]: EventOverviewDTO } }, event: EventOverviewDTO) => {
          return {
            ...entities,
            [period]: {
              ...entities[period],
              [event.reduxId]: event,
            },
          };
        },
        {
          ...state.entities,
        }
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromCancelledEvents.LOAD_CANCELLED_EVENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromCancelledEvents.REMOVE_CANCELLED_EVENTS: {
      const period = action.period;
      const { [period]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
        loading: false,
        loaded: false,
      };
    }
    case fromCancelledEvents.CLEAR_CANCELLED_EVENTS: {
      return {
        ...state,
        entities: {},
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getCancelledEventsEntities = (state: CancelledEventsState) => state.entities;
export const getCancelledEventsLoading = (state: CancelledEventsState) => state.loading;
export const getCancelledEventsLoaded = (state: CancelledEventsState) => state.loaded;
