<div class="row">
  <div class="col-sm-2">
    <gib-input [placeholder]="'name'" [control]="trainer.get('fullName')"></gib-input>
  </div>
  <div class="col-sm-2">
    <gib-input [placeholder]="'phonenumber'" [control]="trainer.get('phonenumber')"></gib-input>
  </div>
  <div class="col-sm-2">
    <gib-input [placeholder]="'moduleName'" [control]="eventHealthscreening.get('name')"></gib-input>
  </div>
  <div class="col-sm-2">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="{{'PRESENTATION' | translate}}" value="{{eventHealthscreening.get('presentation').value ? ' X ' : ' - '}}" disabled />
    </mat-form-field>
  </div>
  <div class="col-sm-2">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="{{'ENGLISH' | translate}}" value="{{eventHealthscreening.get('presentationEnglish').value ? ' X ' : ' - '}}" disabled />
    </mat-form-field>
  </div>
  <div class="col-sm-2">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="{{'anonymusEvaluation' | translate}}" value="{{eventHealthscreening.get('anonymousEvaluation').value ? 'X' : ' - '}}" disabled />
    </mat-form-field>
  </div>
</div>
