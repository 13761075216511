import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { moveItemInArray, transferArrayItem, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'gib-drag-and-drop-box',
  templateUrl: './gib-drag-and-drop-box.component.html',
  styleUrls: ['./gib-drag-and-drop-box.component.scss']
})
export class GibDragAndDropBoxComponent implements OnInit {

  filteredAvailableItems = [];
  assignedItemsIntern = [];
  availableItemsIntern = [];

  @Input() availableLabel: string;
  @Input() assignedLabel: string;

  @Input() shownKey = '';
  @Input() set availableItems(items: any[]) {
    this.availableItemsIntern = items;
    this.filteredAvailableItems = this.filterOutAlreadyAssignedItemsFromAvailableItems(items);
  }
  get availableItems() {
    return this.availableItemsIntern;
  }
  @Input() set assignedItems(items: any[]) {
    this.assignedItemsIntern = items;
    this.filteredAvailableItems = this.filterOutAlreadyAssignedItemsFromAvailableItems(this.availableItems);
  }
  get assignedItems() {
    return this.assignedItemsIntern;
  }
  @Output() assignedItemsChange = new EventEmitter();
  
  constructor(private router: Router) {}
  
  ngOnInit() {
  }
  
  filterOutAlreadyAssignedItemsFromAvailableItems(items: any[]) {
    const filteredAvailableItems = [];
    if (items && this.assignedItemsIntern) {
      for (const item of items) {
        let itemAssigned = false;
        for (const assignedItem of this.assignedItemsIntern) {
          if (assignedItem.id === item.id) {
            itemAssigned = true;
          }
        }
        if (!itemAssigned) {
          filteredAvailableItems.push(item);
        }
      }
    }
    return filteredAvailableItems;
  }
  
  drop(event: CdkDragDrop < string[] > ) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
        this.assignedItemsChange.emit(this.assignedItemsIntern);
      }
    }
    
    addToAssignedItems(item: any, index: number) {
      this.assignedItemsIntern.push(item);
      this.filteredAvailableItems.splice(index, 1);
      this.assignedItemsChange.emit(this.assignedItemsIntern);
    }
    
    removeFromAssignedItems(item: any, index: number) {
      this.filteredAvailableItems.push(item);
      this.assignedItemsIntern.splice(index, 1);
      this.assignedItemsChange.emit(this.assignedItemsIntern);
    }
    
    addAll() {
      this.assignedItemsIntern = this.availableItemsIntern;
      this.filteredAvailableItems = [];
      this.assignedItemsChange.emit(this.assignedItemsIntern);
    }
    
  removeAll() {
    this.filteredAvailableItems = this.availableItemsIntern;
    this.assignedItemsIntern = [];
    this.assignedItemsChange.emit(this.assignedItemsIntern);
  }

}
