import { Action } from "@ngrx/store";
import { EventOverviewDTO } from "../../services/event-overview.service";

// load inactive events
export const LOAD_INACTIVE_EVENTS = "[Events] Load Inactive Events";
export const LOAD_INACTIVE_EVENTS_SUCCESS = "[Events] Load Inactive Events Success";
export const LOAD_INACTIVE_EVENTS_FAIL = "[Events] Load Inactive Events Fail";

export class LoadInactiveEvents implements Action {
  readonly type = LOAD_INACTIVE_EVENTS;
  constructor(public payload: { from: string; to: string }) {}
}

export class LoadInactiveEventsSuccess implements Action {
  readonly type = LOAD_INACTIVE_EVENTS_SUCCESS;
  constructor(public payload: EventOverviewDTO[], public period: string) {}
}

export class LoadInactiveEventsFail implements Action {
  readonly type = LOAD_INACTIVE_EVENTS_FAIL;
  constructor(public payload: any) {}
}

// remove inactive events
export const REMOVE_INACTIVE_EVENTS = "[Events] Remove Inactive Events";
export const CLEAR_INACTIVE_EVENTS = "[Events] Clear Inactive Events";

export class RemoveInactiveEvents implements Action {
  readonly type = REMOVE_INACTIVE_EVENTS;
  constructor(public period: string) {}
}

export class ClearInactiveEvents implements Action {
  readonly type = CLEAR_INACTIVE_EVENTS;
}

export type InactiveEventActions = LoadInactiveEvents | LoadInactiveEventsSuccess | LoadInactiveEventsFail | RemoveInactiveEvents | ClearInactiveEvents;
