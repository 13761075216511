import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'gib-multi-input',
  templateUrl: './gib-multi-input.component.html',
  styleUrls: ['./gib-multi-input.component.scss']
})
export class GibMultiInputComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() tempControl: UntypedFormControl;


  constructor() {}

  ngOnInit() {
  }

  deleteInput(index: number) {
    if (this.tempControl && this.tempControl.enabled) {
      this.control.value.splice(index, 1);
    }
  }

  addInput() {
    if (this.tempControl && this.tempControl.valid && this.tempControl.value !== '') {
      this.control.value.push(this.tempControl.value);
      this.tempControl.setValue('');
    }
  }

}
