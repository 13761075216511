import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  text: string;
  confirmAction: any;
}

@Component({
  selector: 'gib-confirmation-dialog',
  templateUrl: './gib-confirmation-dialog.component.html',
  styleUrls: ['./gib-confirmation-dialog.component.scss']
})
export class GibConfirmationDialogComponent {

  constructor(public dialogRef: MatDialogRef<GibConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    this.data.confirmAction();
    this.dialogRef.close();
  }

}
