import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormHelper } from "src/app/helper/form.helper";

@Component({
  selector: "gib-radio-button-group-vertical-v2",
  styleUrls: ["gib-radio-button-group-vertical-v2.component.scss"],
  template: `
    <mat-radio-group class="vertical-radio-group" aria-label="Select an option" [formControl]="inputControl" [disabled]="inputControl.disabled">
      <mat-radio-button [value]="valueLabel.value" *ngFor="let valueLabel of valueLabels">
        <label>{{ valueLabel.label | translate }}</label>
      </mat-radio-button>
    </mat-radio-group>
  `,
})
export class GibRadioButtonGroupVerticalV2Component implements OnInit {
  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}
}
