import { LangChangeEvent } from '@ngx-translate/core';
// import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService, CustomerFeedbackReport, TrainerFeedbackReport } from './../../../services/report.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit {

  type: string = '';
  currentUrl: string = '';
  map: Record < string,
  number > ;
  stringArrayMap: Record < string,
  string[] > ;
  optionsOverallRating: any;
  // customer feedback options
  trainerContactedYou: any;
  trainerOnTime: any;
  bookAgain: any;
  // trainer feedback options
  orgaGib: any;
  orgaLocation: any;
  orgaCustomer: any;
  measuringDevice: any;
  langSubscription: Subscription;

  constructor(private reportService: ReportService, private translateService: TranslateService, private router: Router
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateService.get(`languages.${event.lang === 'en' ? 'de' : 'en'}`).subscribe((res: string) => {
        if (this.currentUrl.includes('customerFeedbackReport')) {
          this.type = 'customerFeedbackReport';
          this.reportService.getCustomerFeedbackReport().subscribe(res => {
            this.map = res.body.countMap;
            this.stringArrayMap = res.body.stringArrayMap;
            this.createOptions();
          });
        } else if (this.currentUrl.includes('trainerFeedbackReport')) {
          this.type = 'trainerFeedbackReport';
          this.reportService.getTrainerFeedbackReport().subscribe(res => {
            this.map = res.body.countMap;
            this.stringArrayMap = res.body.stringArrayMap;
            this.createOptions();
          });
        }
      }).unsubscribe();
    });
    if (this.currentUrl.includes('customerFeedbackReport')) {
      this.type = 'customerFeedbackReport';
      this.reportService.getCustomerFeedbackReport().subscribe(res => {
        this.map = res.body.countMap;
        this.stringArrayMap = res.body.stringArrayMap;
        this.createOptions();
      });
    } else if (this.currentUrl.includes('trainerFeedbackReport')) {
      this.type = 'trainerFeedbackReport';
      this.reportService.getTrainerFeedbackReport().subscribe(res => {
        this.map = res.body.countMap;
        this.stringArrayMap = res.body.stringArrayMap;
        this.createOptions();
      });
    }
  }

  createOptions() {
    // customer feedback options
    this.optionsOverallRating = this.createFeedbackReport();
    this.optionsOverallRating.title = {
        text: this.translateService.instant('optionsOverallRatingReport'),
        fontSize: 18,
      },
      this.optionsOverallRating.data = [
        { type: this.translateService.instant('veryGood'), value: this.map.overallRating4 },
        { type: this.translateService.instant('good'), value: this.map.overallRating3 },
        { type: this.translateService.instant('bad'), value: this.map.overallRating2 },
        { type: this.translateService.instant('veryBad'), value: this.map.overallRating1 }
      ];

    this.trainerContactedYou = this.createFeedbackReport();
    this.trainerContactedYou.title = {
        text: this.translateService.instant('trainerContactedYouReport'),
        fontSize: 18,
      },
      this.trainerContactedYou.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.trainerContactedYouTrue },
        { type: this.translateService.instant('no'), value: this.map.trainerContactedYouFalse }
      ];

    this.trainerOnTime = this.createFeedbackReport();
    this.trainerOnTime.title = {
        text: this.translateService.instant('trainerOnTimeReport'),
        fontSize: 18,
      },
      this.trainerOnTime.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.trainerOnTimeTrue },
        { type: this.translateService.instant('no'), value: this.map.trainerOnTimeFalse }
      ];

    this.bookAgain = this.createFeedbackReport();
    this.bookAgain.title = {
        text: this.translateService.instant('bookAgainReport'),
        fontSize: 18,
      },
      this.bookAgain.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.bookAgainTrue },
        { type: this.translateService.instant('no'), value: this.map.bookAgainFalse }
      ];

    // trainer feedback options
    this.optionsOverallRating = this.createFeedbackReport();
    this.optionsOverallRating.title = {
        text: this.translateService.instant('optionsOverallRatingReport'),
        fontSize: 18,
      },
      this.optionsOverallRating.data = [
        { type: this.translateService.instant('veryGood'), value: this.map.overallRating4 },
        { type: this.translateService.instant('good'), value: this.map.overallRating3 },
        { type: this.translateService.instant('bad'), value: this.map.overallRating2 },
        { type: this.translateService.instant('veryBad'), value: this.map.overallRating1 }
      ];

    this.orgaGib = this.createFeedbackReport();
    this.orgaGib.title = {
        text: this.translateService.instant('orgaGibReport'),
        fontSize: 18,
      },
      this.orgaGib.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.orgaGibTrue },
        { type: this.translateService.instant('no'), value: this.map.orgaGibFalse }
      ];

    this.orgaLocation = this.createFeedbackReport();
    this.orgaLocation.title = {
        text: this.translateService.instant('orgaLocationReport'),
        fontSize: 18,
      },
      this.orgaLocation.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.orgaLocationTrue },
        { type: this.translateService.instant('no'), value: this.map.orgaLocationFalse }
      ];

    this.orgaCustomer = this.createFeedbackReport();
    this.orgaCustomer.title = {
        text: this.translateService.instant('orgaCustomerReport'),
        fontSize: 18,
      },
      this.orgaCustomer.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.orgaCustomerTrue },
        { type: this.translateService.instant('no'), value: this.map.orgaCustomerFalse }
      ];

    this.measuringDevice = this.createFeedbackReport();
    this.measuringDevice.title = {
        text: this.translateService.instant('measuringDeviceReport'),
        fontSize: 18,
      },
      this.measuringDevice.data = [
        { type: this.translateService.instant('reportLayoutYes'), value: this.map.measuringDeviceTrue },
        { type: this.translateService.instant('no'), value: this.map.measuringDeviceFalse }
      ];
  }



  createFeedbackReport() {
    return {
      data: [],
      series: [{
        type: 'bar',
        xKey: 'type',
        yKeys: ['value'],
        fills: ['#394160'],
        strokes: ['#394160'],
        highlightStyle: { fill: '#c8c618' },
      }, ],
      title: '',
      axes: [{
          type: 'category',
          position: 'left',
          label: { width: '200px' },
        },
        {
          type: 'number',
          position: 'bottom'
        },
      ],
      legend: { enabled: false },
    };
  }

}