import { FaqModule } from "./trainer-faq/faq.module";
import { AdministrationModule } from "./administration/administration.module";
import { EventsModule } from "./events/events.module";
import { HealthScreeningModule } from "./health-screening/health-screening.module";
import { NgModule } from "@angular/core";
import { HomePageModule } from "./home-page/home-page.module";
import { UtilsModule } from "../utils/utils.module";
import { ComponentsModule } from "../components/components.module";
import { EmployeesPageModule } from "./employees/employees.module";
import { SandboxPageComponent } from "./sandbox-page/sandbox-page.component";
import { ProfileModule } from "./profile-page/profile.module";
import { DocumentModule } from "./administration/documents/document.module";
import { CustomerModule } from "./customer/customer.module";
import { TrainerModule } from "./trainer/trainer.module";
import { LoginPageComponent } from "./login-page/login-page.component";
import { PrivacyPageComponent } from "./privacy-page/privacy-page.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { AppRoutingModule } from "../app-routing.module";
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';

@NgModule({
  declarations: [SandboxPageComponent, LoginPageComponent, PrivacyPageComponent, NotFoundPageComponent, UnauthorizedPageComponent],
  imports: [HomePageModule, EmployeesPageModule, UtilsModule, ComponentsModule, ProfileModule, HealthScreeningModule, EventsModule, CustomerModule, TrainerModule, AdministrationModule, FaqModule, AppRoutingModule],
  exports: [HomePageModule, EmployeesPageModule, SandboxPageComponent, ProfileModule, DocumentModule, EventsModule, TrainerModule, FaqModule],
})
export class PagesModule {}
