import { MailLogTableComponent } from './../components/mail-log-table/mail-log-table.component';
import { MailLogService } from './../../../services/mail-log.service';
import { FormHelper } from './../../../helper/form.helper';
import { ProcessService } from './../../../services/process-service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'mail-log-page',
  templateUrl: './mail-log-page.component.html',
  styleUrls: ['./mail-log-page.component.scss']
})
export class MailLogPageComponent implements OnInit {

  @ViewChild('mailLogTable', { static: true }) mailLogTable: MailLogTableComponent;

  emails: any[];

  constructor(private mailLogService: MailLogService, private formHelper: FormHelper) { }

  ngOnInit() {
    this.getEmails();
  }

  getEmails() {
    this.mailLogService.getAllEmails().subscribe(response => {
      this.emails = response.body;
    });
  }


}
