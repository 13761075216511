<div class="d-flex flex-row-reverse mb-3">
  <i class="material-icons" style="cursor: pointer" (click)="onClose()">close</i>
</div>

<div class="row">
  <div class="col-sm-8">
    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-primary" role="alert">{{ "healthscreening" | translate }}</div>
      </div>
    </div>
    <mat-card>
      <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="onSelectionChange($event)">
        <mat-step [stepControl]="healthScreeningForm">
          <ng-template matStepLabel>{{ "basicSettings" | translate }}</ng-template>
          <edit-health-screening-form [healthScreeningForm]="healthScreeningForm" [step]="1"></edit-health-screening-form>
          <button type="button" class="btn btn-primary" (click)="onBasicSettingsNext(stepper)">{{ "continue" | translate }}</button>
        </mat-step>
        <mat-step [stepControl]="healthScreeningForm">
          <ng-template matStepLabel>{{ "dataInput" | translate }}</ng-template>
          <edit-health-screening-form [healthScreeningForm]="healthScreeningForm" [step]="2"></edit-health-screening-form>
          <button mat-button matStepperPrevious>{{ "back" | translate }}</button>
          <button id="createButton" type="button" *ngIf="!healthScreeningObject.id" class="btn btn-primary" (click)="createHealthScreeningClicked()" matStepperNext>{{ "continue" | translate }}</button>
          <button id="editButton" type="button" *ngIf="healthScreeningObject.id" class="btn btn-primary" (click)="editHealthScreeningClicked()" matStepperNext>{{ "continue" | translate }}</button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ "trainerAssignment" | translate }}</ng-template>
          <gib-drag-and-drop-box [availableItems]="availableTrainer" [(assignedItems)]="healthScreeningObject.assignedUsers" [shownKey]="'officialName'" [availableLabel]="'allTrainer'" [assignedLabel]="'moduleTrainer'"></gib-drag-and-drop-box>
          <button mat-button matStepperPrevious>{{ "back" | translate }}</button>
          <button type="button" class="btn btn-primary" matStepperNext>{{ "continue" | translate }}</button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ "addFotos" | translate }}</ng-template>
          <gib-fileupload (fileUploaded)="loadImages()" id="healthscreening_image_upload" [control]="imageUploadForm.get('imageControl')" [type]="'HEALTHSCREENING_PICTURE'" [healthScreeningId]="healthScreeningObject.id" [maxFileSize]="5"></gib-fileupload>
          <gib-image-handler [images]="images" (fileDeleted)="loadImages()" (imageUrlsGenerated)="setImageUrls($event)"></gib-image-handler>
          <button mat-button matStepperPrevious>{{ "back" | translate }}</button>
          <button type="button" class="btn btn-primary" matStepperNext>{{ "continue" | translate }}</button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ "addVideos" | translate }}</ng-template>
          <gib-select [placeholder]="'videoTypeHint'" [control]="videoUploadForm.get('videoType')" [options]="videoTypes"></gib-select>
          <gib-fileupload
            (fileUploaded)="loadVideosByType()"
            *ngIf="videoUploadForm.get('videoType').value"
            id="healtscreening_video_upload"
            [control]="videoUploadForm.get('videoControl')"
            [type]="videoUploadForm.get('videoType').value"
            [healthScreeningId]="healthScreeningObject.id"
            [maxFileSize]="100"
          ></gib-fileupload>
          <div class="row">
            <div class="col-sm-6" *ngIf="videosTraining && videosTraining.length > 0">
              <h6>{{ "HEALTHSCREENING_VIDEO_TRAINING" | translate }}</h6>
              <gib-video-handler [videos]="videosTraining" (fileDeleted)="loadTrainingVideos()" (videoUrlsGenerated)="setTrainingVideoUrls($event)"></gib-video-handler>
            </div>
            <div class="col-sm-6" *ngIf="videosMarketing && videosMarketing.length > 0">
              <h6>{{ "HEALTHSCREENING_VIDEO_PRESENTATION" | translate }}</h6>
              <gib-video-handler [videos]="videosMarketing" (fileDeleted)="loadMarketingVideos()" (videoUrlsGenerated)="setMarketingVideoUrls($event)"></gib-video-handler>
            </div>
          </div>
          <button mat-button matStepperPrevious>{{ "back" | translate }}</button>
          <button type="button" class="btn btn-primary" matStepperNext>{{ "continue" | translate }}</button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ "addShortDescriptions" | translate }}</ng-template>
          <div class="row">
            <div class="col-sm-12">
              <h5>{{ "addShortDescriptions" | translate }}</h5>
            </div>
          </div>
          <div class="row">
            <div *ngIf="isOnline" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
              <div class="mt-3">
                <h6>{{ "descriptionsOnlineInfoPage" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONLINE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
              <div class="mt-3">
                <h6>{{ "descriptionsOnlineMailDe" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONLINE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
              <div class="mt-3">
                <h6>{{ "descriptionsOnlineMailEn" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONLINE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
            </div>
            <div *ngIf="isOnsite" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
              <div class="mt-3">
                <h6>{{ "descriptionsOnsiteInfoPage" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONSITE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
              <div class="mt-3">
                <h6>{{ "descriptionsOnsiteMailDe" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONSITE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
              <div class="mt-3">
                <h6>{{ "descriptionsOnsiteMailEn" | translate }}</h6>
                <div class="mb-3">
                  <attachement-handler [fileType]="'HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONSITE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-vertical-stepper>

      <div>
        <button mat-button id="cancelButton" (click)="cancelClicked()">{{ "CANCEL" | translate }}</button>
        <button mat-button type="button" class="btn btn-primary" (click)="saveAndBackToOverview()">{{ "addModule" | translate }}</button>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-4">
    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-primary" role="alert">
          {{ "preview" | translate }}
        </div>
      </div>
    </div>
    <preview [healthScreeningObject]="healthScreeningForm" [imageUrls]="imageUrls"></preview>
  </div>
</div>
