<div class="row">
  <div class="col-xl-12">
    <div class="d-flex justify-content-between">
      <h4>{{ "internalNotesTitle" | translate }}</h4>
    </div>
  </div>
  <div class="col-xl-6 col-md-12">
    <div class="box">
      <div class="row">
        <div class="col-xl-12">
          <gib-radio-button-group
            [label]="'contactType'"
            [control]="internalNoteForm.get('contactType')"
            [valueLabels]="contactTypeOptions"
          ></gib-radio-button-group>
        </div>
        <div class="col-xl-12">
          <gib-datepicker
            [placeholder]="'dispatchedByLabel'"
            [control]="internalNoteForm.get('sendingOn')"
          ></gib-datepicker>
        </div>
        <div class="col-xl-12">
          <gib-radio-button-group
            [label]="'registrationLabel'"
            [control]="internalNoteForm.get('registration')"
            [valueLabels]="yesNoOptions"
          ></gib-radio-button-group>
        </div>
        <div class="col-xl-12">
          <gib-datepicker
            [placeholder]="'recordedOnLabel'"
            [control]="internalNoteForm.get('recordedOn')"
          ></gib-datepicker>
        </div>
        <div class="col-xl-12">
          <gib-input [placeholder]="'recordedByLabel'" [control]="internalNoteForm.get('recordedBy')"></gib-input>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-md-12">
    <div class="box">
      <div class="row">
        <div class="col-xl-12">
          <gib-radio-button-group
            [label]="'continuingProcessingContactTypeLabel'"
            [control]="internalNoteForm.get('continuingProcessingContactType')"
            [valueLabels]="contactTypeOptions"
          ></gib-radio-button-group>
        </div>
        <div class="col-xl-12">
          <gib-datepicker
            [placeholder]="'continuingProcessingOnLabel'"
            [control]="internalNoteForm.get('continuingProcessingOn')"
          ></gib-datepicker>
        </div>
        <div class="col-xl-12">
          <gib-input
            [placeholder]="'continuingProcessingByLabel'"
            [control]="internalNoteForm.get('continuingProcessingBy')"
          ></gib-input>
        </div>
        <div class="col-xl-12">
          <gib-textarea [placeholder]="'comment'" [control]="internalNoteForm.get('comment')"></gib-textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-sm-12 title-col">
    <gib-h5 [text]="'internalNotesTitle'" [disabled]="internalNoteForm.get('recordedBy').disabled"></gib-h5>
  </div>
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-12">
        <gib-radio-button-group
          [label]="'contactType'"
          [control]="internalNoteForm.get('contactType')"
          [valueLabels]="contactTypeOptions"
        ></gib-radio-button-group>
      </div>
      <div class="col-sm-12">
        <gib-datepicker
          [placeholder]="'dispatchedByLabel'"
          [control]="internalNoteForm.get('sendingOn')"
        ></gib-datepicker>
      </div>
      <div class="col-sm-12">
        <gib-radio-button-group
          [label]="'registrationLabel'"
          [control]="internalNoteForm.get('registration')"
          [valueLabels]="yesNoOptions"
        ></gib-radio-button-group>
      </div>
      <div class="col-sm-12">
        <gib-datepicker
          [placeholder]="'recordedOnLabel'"
          [control]="internalNoteForm.get('recordedOn')"
        ></gib-datepicker>
      </div>
      <div class="col-sm-12">
        <gib-input [placeholder]="'recordedByLabel'" [control]="internalNoteForm.get('recordedBy')"></gib-input>
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="col-sm-12">
      <gib-radio-button-group
        [label]="'continuingProcessingContactTypeLabel'"
        [control]="internalNoteForm.get('continuingProcessingContactType')"
        [valueLabels]="contactTypeOptions"
      ></gib-radio-button-group>
    </div>
    <div class="col-sm-12">
      <gib-datepicker
        [placeholder]="'continuingProcessingOnLabel'"
        [control]="internalNoteForm.get('continuingProcessingOn')"
      ></gib-datepicker>
    </div>
    <div class="col-sm-12">
      <gib-input
        [placeholder]="'continuingProcessingByLabel'"
        [control]="internalNoteForm.get('continuingProcessingBy')"
      ></gib-input>
    </div>
    <div class="col-sm-12">
      <gib-textarea [placeholder]="'comment'" [control]="internalNoteForm.get('comment')"></gib-textarea>
    </div>
  </div>
</div> -->
