import { AddTrainerComponent } from './components/add-trainer/add-trainer.component';
import { NgModule } from '@angular/core';

import { UtilsModule } from "../../utils/utils.module";
import { EmployeesTableComponent } from './components/employees-table/employees-table.component';
import { ComponentsModule } from '../../components/components.module';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { EmployeesPageComponent } from './employees-page/employees-page.component';
import { EmployeeTableDetailsComponent } from './components/employees-table/components/employee-table-details/employee-table-details.component';
import { TrainerPageComponent } from './trainer-page/trainer-page.component';
import { TrainerTableDetailsComponent } from './components/trainer-table/components/trainer-table-details/trainer-table-details.component';
import { TrainerTableComponent } from './components/trainer-table/trainer-table.component';
import { AddPersonBottomSheetComponent } from './components/add-person-bottom-sheet/add-person-bottom-sheet.component';

@NgModule({
    declarations: [
        EmployeesPageComponent,
        EmployeesTableComponent,
        AddEmployeeComponent,
        EmployeeTableDetailsComponent,
        TrainerPageComponent,
        TrainerTableComponent,
        AddTrainerComponent,
        TrainerTableDetailsComponent,
        AddPersonBottomSheetComponent
    ],
    imports: [
        ComponentsModule,
        UtilsModule
    ],
    exports: [
        EmployeesPageComponent,
        TrainerPageComponent
    ]
})

export class EmployeesPageModule {}
