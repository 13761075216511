import { FormGroup, ValidatorFn } from "@angular/forms";

export function moduleItemValidator(areItemsValid: boolean): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    if (!areItemsValid) {
      return {
        invalidItems: true,
      };
    }
    return null;
  };
}
