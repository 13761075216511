<div class="row headerRow">
  <div class="col-sm-12 col-lg-4 col-xl-4">
    <h1>{{ "healthscreenings" | translate }} - {{ this.companynameAlias | translate }}</h1>
  </div>
  <div class="col-sm-12 col-lg-1 col-xl-1"></div>
  <div class="col-9 col-sm-10 col-lg-6 col-xl-6">
    <gib-fieldset [title]="'FILTER'">
      <gib-checkbox id="onsiteFilter" [label]="'healthscreeningOverview'" [control]="filterData.get('onsite')"></gib-checkbox>&nbsp;&nbsp;
      <gib-checkbox id="onlineFilter" [label]="'onlineModules'" [control]="filterData.get('online')"></gib-checkbox>
      <gib-checkbox id="webinarsFilter" [label]="'interactiveWebinars'" [control]="filterData.get('webinars')"></gib-checkbox>
      <gib-checkbox id="breaksFilter" [label]="'activeBreaks'" [control]="filterData.get('activeBreaks')"></gib-checkbox>
    </gib-fieldset>
  </div>
  <div class="col-3 col-sm-2 col-lg-1 col-xl-1">
    <a class="btn btn-secondary add-healthscreening-btn" routerLink="/create-health-screening-page/{{ companynameAlias }}">+</a>
  </div>
</div>
<div class="row" *ngFor="let topic of topics">
  <div class="col-sm-12">
    <div class="alert alert-primary" role="alert">{{ topic | translate }}</div>
  </div>
  <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let healthscreening of healthscreenings | topicString : topic">
    <preview [healthScreeningObject]="healthscreening" [imageUrls]="healthscreening.imageUrls"></preview>
    <div class="card action-button-card">
      <div class="card-body">
        <button mat-button class="btn btn-secondary" (click)="goToEditPage(healthscreening.id)">
          <i id="edit_icon" class="material-icons edit-icon" [ngbTooltip]="'EDIT' | translate">edit</i>
        </button>
        <button mat-button class="btn btn-secondary" (click)="copyHealthScreening(healthscreening.id)">
          <i id="copy_icon" class="material-icons copy-icon" [ngbTooltip]="'COPY' | translate">library_books</i>
        </button>
        <button mat-button class="btn btn-danger" (click)="openDeleteConfirmation(healthscreening)">
          <i class="material-icons delete-icon" [ngbTooltip]="'DELETE' | translate">delete</i>
        </button>
      </div>
    </div>
  </div>
</div>
