<h1>{{'EMPLOYEES' | translate}}</h1>
<div class="row">
  <div class="{{selectedEmployee ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-12'}}">
    <employees-table #emptable [employees]="employees$ | async" (employeeSelected)="openEmployeeCard($event)" (addEmployeeClicked)="openEmployeeCard()" (employeeDeleted)="fetchEmployees()"></employees-table>
  </div>
  <div *ngIf="selectedEmployee" class="col-md-12 col-lg-6 employee-details">
    <div class="sticky-top" style="top: 50px">
      <add-employee [employee]="selectedEmployee" [roles]="roleValueLabels$ | async" (employeeAdded)="fetchEmployees()"></add-employee>
    </div>
  </div>
</div>
