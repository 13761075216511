import { GibDialogService } from "./../../dialogs/gib-dialog.service";
import { CartService } from "./../../../services/cart.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { TokenHelperService } from "src/app/services/token-helper.service";
@Component({
  selector: "gib-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  count: string;
  userRole: string;
  lang: string;
  username: string;
  userEmail: string;
  langSubscription: Subscription;
  constructor(private keycloakService: KeycloakService, private router: Router, private cartService: CartService, private translate: TranslateService, private tokenHelperService: TokenHelperService) {}
  ngOnInit() {
    this.keycloakService.getToken().then((res) => {
      this.userEmail = this.tokenHelperService.getEmail();
    });
    this.keycloakService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
    });
    this.userRole = localStorage.getItem("role");

    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();
    this.langSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.lang = event.lang;
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });
  }
  onLangClick() {
    if (this.translate.currentLang === "de") {
      this.translate.use("en");
      localStorage.setItem("gib-locale", "en");
    } else {
      this.translate.use("de");
      localStorage.setItem("gib-locale", "de");
    }
  }
  cartItemCount() {
    return this.cartService.getCart().length.toString();
  }
  cartHasItems() {
    return this.cartService.hasItems();
  }
  login() {
    this.keycloakService.login();
  }
  logout() {
    this.router.navigate(["/"]).then(() => {
      this.keycloakService.logout().then(() => {
        this.isLoggedIn = false;
      });
    });
  }
  bookModules() {
    this.router.navigate(["/event-inquiry-page/FORM"]);
  }
  clearCart() {
    this.cartService.clearCart();
  }
  openNav() {
    document.getElementById("toolbarNav").style.width = "100%";
  }

  closeNav() {
    document.getElementById("toolbarNav").style.width = "0%";
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
