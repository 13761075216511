<gib-fileupload
  *ngIf="userRole == 'gib_admin' || userRole == 'gib_director' || userRole == 'gib_employee'"
  (fileUploaded)="loadAttachements()"
  id="healthscreening_attachment_upload"
  [control]="hsAttachementControl"
  [type]="fileType"
  [healthScreeningId]="healthScreeningForm.get('id').value"
  [maxFileSize]="5"
></gib-fileupload>
<div class="spacer"></div>
<div class="row attachment-row" *ngFor="let att of attachements">
  <div class="col-sm-9">
    {{ att.fileName }}
  </div>
  <div class="col-sm-3">
    <i *ngIf="userRole == 'gib_admin' || userRole == 'gib_director' || userRole == 'gib_employee'" class="material-icons" (click)="openDeleteConfirmation(att)">delete</i>
    <i class="material-icons" (click)="downloadFile(att)">get_app</i>
  </div>
</div>
