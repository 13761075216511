import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormHelper } from "src/app/helper/form.helper";

export interface DialogData {
  title: string;
  text: string;
  eventPrivacyText: string;
  thirdPartyPrivacyText: string;
  votingParticipant: UntypedFormGroup;
  confirmAction: any;
}

@Component({
  selector: "gib-waitinglist-dialog",
  templateUrl: "./gib-waitinglist-dialog.component.html",
  styleUrls: ["./gib-waitinglist-dialog.component.sass"],
})
export class GibWaitinglistDialogComponent {
  votingParticipant: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<GibWaitinglistDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.votingParticipant) {
      this.votingParticipant = data.votingParticipant;
      this.votingParticipant.get("acceptedEventPrivacy").setValue(false);
      this.votingParticipant.get("acceptedEventPrivacy").enable();
      this.votingParticipant.get("acceptedThirdPartyPrivacy").setValue(false);
      this.votingParticipant.get("acceptedThirdPartyPrivacy").enable();
    }
  }

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.votingParticipant) {
      this.data.confirmAction(this.votingParticipant);
    }
    this.dialogRef.close();
  }

  submitButtonDisabled(): boolean {
    return !this.votingParticipant.valid || !this.votingParticipant.get("acceptedEventPrivacy").value;
  }

  showCompanyName(): boolean {
    return this.data.votingParticipant && this.data.votingParticipant.get("scheduleEntryCompanynameRequired").value;
  }
}
