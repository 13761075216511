<mat-tab-group [selectedIndex]="selectedIndex">
  <mat-tab label="{{ 'formData' | translate }}">
    <event-booking-form *ngIf="eventForm" [eventForm]="eventForm" [internalNoteForm]="internalNoteForm" [buid]="buid" [showPrintButtons]="showPrintButtons"></event-booking-form>
    <div class="row" *ngIf="chatElements && chatElements.length > 0">
      <div class="col-sm-12">
        <gib-chat [chatElements]="chatElements"></gib-chat>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ eventInquiryHealthscreening.get('name').value ? eventInquiryHealthscreening.get('name').value : 'name not set...' }}" *ngFor="let eventInquiryHealthscreening of eventForm.get('eventHealthScreenings')['controls']">
    <schedule-model [allowModuleReplace]="allowModuleReplace" [isEditable]="isEditable" [editMode]="editMode" [eventForm]="eventForm" [eventInquiryHealthscreening]="eventInquiryHealthscreening" #hs></schedule-model>
  </mat-tab>
  <mat-tab *ngIf="(this.userRole === 'gib_director' || this.userRole === 'gib_admin' || this.userRole === 'gib_employee') && hasAnonymousEvaluation()" label="{{ 'anonymusEvaluation' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents [userId]="buid" [fileType]="'ANONYMOUS_EVALUATION'" (documentEvent)="loadFiles()"></gib-documents>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="hasAnonymousEvaluationDocuments()">
      <mat-card-content>
        <button type="button" class="btn btn-primary" (click)="sendAnonymousEvaluationNotificationClicked()">{{ "sendAnonymousEvaluationNotification" | translate }}</button>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="this.userRole === 'gib_customer' && hasAnonymousEvaluationDocuments()" label="{{ 'anonymusEvaluation' | translate }}">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-sm-12">
            <h5>{{ "documents" | translate }}</h5>
            <div class="row">
              <div class="col-sm-6 center">
                <ul class="list-group">
                  <li *ngFor="let document of documents" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    {{ document.title }} - {{ document.fileName }}
                    <span>
                      <i class="material-icons mr-4" (click)="onDownload(document)" style="cursor: pointer">get_app</i>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-sm-6 center"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
