<div class="row">
  <div class="col-sm-2">
    {{'username' | translate}}:
  </div>
  <div class="col-sm-2">
    {{employee.username | translate}}
  </div>
  <div class="col-sm-2">
    {{'role' | translate}}:
  </div>
  <div class="col-sm-2">
    {{employee.role | translate}}
  </div>
</div>
<div class="row">
  <div class="col-sm-4">
    <gib-fieldset [title]="'CAR'">
      <div class="row">
        <div class="col-sm-6">
          {{'DRIVING_LICENSE' | translate}}:
        </div>
        <div class="col-sm-6">
          <gib-check-close [value]="employee.carDrivingLicense"></gib-check-close>
        </div>
        <div class="col-sm-6">
          {{'KFZ' | translate}}:
        </div>
        <div class="col-sm-6">
          <gib-check-close [value]="employee.carKfz"></gib-check-close>
        </div>
        <div class="col-sm-6">
          {{'DRIVING_LICENSE_COPY' | translate}}:
        </div>
        <div class="col-sm-6">
          <gib-check-close [value]="employee.carDrivingLicenseCopy"></gib-check-close>
        </div>
      </div>
    </gib-fieldset>
  </div>
  <div class="col-sm-4">
    <gib-fieldset [title]="'ENGLISH'">
      <div class="row">
        <div class="col-sm-6">
          {{'PRESENTATION' | translate}}:
        </div>
        <div class="col-sm-6">
          <gib-check-close [value]="employee.engPresentation"></gib-check-close>
        </div>
        <div class="col-sm-6">
          {{'ADVICE' | translate}}:
        </div>
        <div class="col-sm-6">
          <gib-check-close [value]="employee.engAdvice"></gib-check-close>
        </div>
      </div>
    </gib-fieldset>
  </div>
  <div class="col-sm-4">
    <div class="row">
      <div class="col-sm-6">
        {{'preventionCourse' | translate}}:
      </div>
      <div class="col-sm-6">
        <gib-check-close [value]="employee.preventionCourse"></gib-check-close>
      </div>
       <div class="col-sm-6">
        {{'blacklisted' | translate}}:
      </div>
      <div class="col-sm-6">
        <gib-check-close [value]="employee.blacklisted"></gib-check-close>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-2">
    {{'qualifications' | translate}}:
  </div>
  <div class="col-sm-4">
    <p>{{employee.qualifications}}</p>
  </div>
</div>
<div class="row">
  <div class="col-sm-2">
    {{'COMMENT' | translate}}:
  </div>
  <div class="col-sm-4">
    <p>{{employee.comment}}</p>
  </div>
</div>
