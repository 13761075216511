<h1>{{ "yourBooking" | translate }}</h1>
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <event-booking-form *ngIf="eventForm" [eventForm]="eventForm" [chatElements]="chatElements"></event-booking-form>
  </div>
  <div class="col-sm-1"></div>
  <div class="col-sm-3"></div>
  <div class="col-sm-6 button-div">
    <!-- <div [@visibility]="showSubmitButtons ? 'visible': 'hidden'" class="button-div">
     <button *ngIf="!showBookingButtons()" class="btn btn-primary" (click)="openSubmitConfirmationClicked()">
        <i class="material-icons">check</i>
        {{'submitData' | translate}}
      </button>
      <button *ngIf="showBookingButtons()" class="btn btn-success" (click)="openBokkingConfirmationClicked()">
        <i class="material-icons">check</i>
        {{'book' | translate}}
      </button>
      <button *ngIf="showBookingButtons()" class="btn btn-secondary" (click)="openQueryConfirmation()">
        <i class="material-icons">chat</i>
        {{'query' | translate}}
      </button>
      <button *ngIf="showBookingButtons()" class="btn btn-danger" (click)="openRejectConfirmation()">
        <i class="material-icons">close</i>
        {{'storn' | translate}}
      </button>
    </div> -->
    <!-- <div [@visibility]="!showSubmitButtons ? 'visible': 'hidden'">
      <p [ngStyle]="{'color': resultColor}"><b>{{result | translate}}</b></p>
    </div> -->
  </div>
  <div class="col-sm-3"></div>
</div>
