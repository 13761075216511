import { NgModule } from "@angular/core";

import { UtilsModule } from "../../utils/utils.module";
import { ComponentsModule } from "../../components/components.module";
import { EditHealthScreeningFormComponent } from "./components/edit-health-screening-form/edit-health-screening-form.component";
import { PreviewComponent } from "./components/preview/preview.component";
import { AppRoutingModule } from "../../app-routing.module";
import { CreateHealthScreeningPageComponent } from "./create-health-screening-page/create-health-screening-page.component";
import { HealthScreeningOverviewPageComponent } from "./health-screening-overview-page/health-screening-overview-page.component";
import { EditHealthScreeningPageComponent } from "./edit-health-screening-page/edit-health-screening-page.component";
import { CustomerHealthScreeningOverviewPageComponent } from "./customer-health-screening-overview-page/customer-health-screening-overview-page.component";
import { InfoHealthScreeningPageComponent } from "./info-health-screening-page/info-health-screening-page.component";
import { AttachementHandlerComponent } from "./components/attachement-handler/attachement-handler.component";
import { CustomerHealthscreeningInfoFormComponent } from "./components/customer-healthscreening-info-form/customer-healthscreening-info-form.component";

@NgModule({
  declarations: [
    EditHealthScreeningFormComponent,
    PreviewComponent,
    CreateHealthScreeningPageComponent,
    HealthScreeningOverviewPageComponent,
    EditHealthScreeningPageComponent,
    CustomerHealthScreeningOverviewPageComponent,
    InfoHealthScreeningPageComponent,
    AttachementHandlerComponent,
    CustomerHealthscreeningInfoFormComponent,
  ],
  imports: [ComponentsModule, UtilsModule, AppRoutingModule],
  exports: [CreateHealthScreeningPageComponent, HealthScreeningOverviewPageComponent, EditHealthScreeningPageComponent],
})
export class HealthScreeningModule {}
