<div class="row">
  <div class="col-sm-6">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-6">
  </div>
  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'fileName' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.fileName}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'created' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.created | amDateFormat: 'DD.MM.YYYY HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'fileType' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{('FILE_TYPE_' + row.fileType) | translate}} </td>
        </ng-container>


        <ng-container matColumnDef="keycloakUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'user' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.keycloak_fullname}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectFileMeta(row)">
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
