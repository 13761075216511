<mat-card>
  <div class="row">
    <div class="col-lg-6">
      <gib-select [placeholder]="'companynameAlias'" [control]="faqForm.get('companynameAlias')" [options]="companynameAliases"></gib-select>
      <gib-input [placeholder]="'title'" [control]="faqForm.get('title')"></gib-input>
      <gib-input [placeholder]="'sortOrder'" [control]="faqForm.get('sortOrder')"></gib-input>
      <gib-textarea [placeholder]="'description'" [control]="faqForm.get('description')"></gib-textarea>
    </div>
    <div class="col-lg-6">
      <mat-card *ngIf="faq.id">
        <mat-card-content>
          <gib-documents [healthScreeningId]="faq.id" [fileType]="'FAQ_DOCUMENT'" [fileUploadSingleLine]="false"></gib-documents>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <mat-card-footer>
    <div class="center">
      <button mat-button (click)="cancel()">
        {{ "CANCEL" | translate }}
      </button>
      <button *ngIf="!_faq.id" type="button" class="btn btn-primary" (click)="addFaqClicked()">
        {{ "ADD" | translate }}
      </button>
      <button *ngIf="_faq.id" type="button" class="btn btn-primary" (click)="updateFaqClicked()">
        {{ "save" | translate }}
      </button>
    </div>
  </mat-card-footer>
</mat-card>