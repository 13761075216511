import { Action } from "@ngrx/store";
import { EventOverviewDTO } from "../../services/event-overview.service";

// load cancelled events
export const LOAD_CANCELLED_EVENTS = "[Events] Load Cancelled Events";
export const LOAD_CANCELLED_EVENTS_SUCCESS = "[Events] Load Cancelled Events Success";
export const LOAD_CANCELLED_EVENTS_FAIL = "[Events] Load Cancelled Events Fail";

export class LoadCancelledEvents implements Action {
  readonly type = LOAD_CANCELLED_EVENTS;
  constructor(public payload: { from: string; to: string }) {}
}

export class LoadCancelledEventsSuccess implements Action {
  readonly type = LOAD_CANCELLED_EVENTS_SUCCESS;
  constructor(public payload: EventOverviewDTO[], public period: string) {}
}

export class LoadCancelledEventsFail implements Action {
  readonly type = LOAD_CANCELLED_EVENTS_FAIL;
  constructor(public payload: any) {}
}

// remove active events
export const REMOVE_CANCELLED_EVENTS = "[Events] Remove Cancelled Events";
export const CLEAR_CANCELLED_EVENTS = "[Events] Clear Cancelled Events";

export class RemoveCancelledEvents implements Action {
  readonly type = REMOVE_CANCELLED_EVENTS;
  constructor(public period: string) {}
}

export class ClearCancelledEvents implements Action {
  readonly type = CLEAR_CANCELLED_EVENTS;
}

export type CancelledEventActions = LoadCancelledEvents | LoadCancelledEventsSuccess | LoadCancelledEventsFail | RemoveCancelledEvents | ClearCancelledEvents;
