<div class="row">
  <div class="col-sm-3">
    {{'username' | translate}}:
  </div>
  <div class="col-sm-9">
    <b>{{meeting.username}}</b>
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    {{'password' | translate}}:
  </div>
  <div class="col-sm-9">
    <b>{{meeting.password}}</b>
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    {{'emergencyPhone' | translate}}:
  </div>
  <div class="col-sm-9">
    {{meeting.emergencyPhone}}
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    {{'furtherInformation' | translate}}:
  </div>
  <div class="col-sm-9">
    <p>
      {{meeting.info}}
    </p>
  </div>
</div>
