import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'event-logistics-form',
  templateUrl: './event-logistics-form.component.html',
  styleUrls: ['./event-logistics-form.component.scss']
})
export class EventLogisticsFormComponent implements OnInit {

  @Input() logisticsForm: UntypedFormGroup;
  @Input() userRole: string;
  @Input() meetingForm: UntypedFormGroup;

  @Input() eventInquiryHealthscreeningIndex: string;

  constructor() { }

  ngOnInit() {
  }

}
