import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'trainer-task-table',
  templateUrl: './trainer-task-table.component.html',
  styleUrls: ['./trainer-task-table.component.scss']
})
export class TrainerTaskTableComponent implements OnInit {

  sort;
  tsks: any[];
  displayedColumns: string[] = ['process', 'name', 'description', 'createdAt'];
  dataSource: MatTableDataSource < any > ;

  @Output() taskSelected = new EventEmitter();

  @Input() set tasks(tasks: any[]) {
    this.tsks = tasks;
    if (tasks && tasks.length > 0) {
      for (const task of tasks) {
        task.processName = this.translateService.instant(task.task.processDefinitionId.substring(0, task.task.processDefinitionId.indexOf(':')));
      }
    }

    this.dataSource = new MatTableDataSource(tasks);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService) { }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  taskRowClicked(task: any) {
    this.taskSelected.emit(task);
  }

  customFilterPredicate(row, filter): boolean {
    let prozessFilter = false;
    let taskNameFilter = false;
    prozessFilter = row.processName.toLowerCase().includes(filter);
    taskNameFilter = row.task.name.toLowerCase().includes(filter);
    return prozessFilter || taskNameFilter;
  }
}
