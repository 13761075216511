<div *ngIf="!isLoggedIn" theme>
  <div #container class="container-nopadding container-fluid">
    <router-outlet (activate)="componentRefChanged($event)"></router-outlet>
  </div>
</div>

<div *ngIf="isLoggedIn && (userRole === 'gib_employee' || userRole === 'gib_admin' || userRole === 'gib_director')" theme>
  <mat-sidenav-container class="gib-container">
    <mat-sidenav id="sidenav" #sidenav mode="side" fixedInViewport="false" [mode]="(isHighRes | async)?.matches ? 'side' : 'over'" [opened]="(isHighRes | async)?.matches" *ngIf="showSideNav()">
      <sidenav-content (closeSidenav)="closeSidenav()" [messageCounter]="messageCounter"></sidenav-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <gib-toolbar></gib-toolbar>
      <sidenav-extension id="sidenav-extension" *ngIf="showSideNav()" [menuIcon]="menuIcon" (menuClicked)="toggleSideNav()"> </sidenav-extension>
      <img src="assets/images/homepage/background_1920x1080.png" id="bg" alt="" class="bg-img" />
      <!-- <div #container class="container"> -->
      <div #container class="container-fluid">
        <loader></loader>
        <router-outlet (activate)="componentRefChanged($event)"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="isLoggedIn && (userRole === 'gib_customer' || userRole === 'gib_afg' || userRole === 'gib_trainer')">
  <gib-toolbar></gib-toolbar>
  <loader></loader>
  <router-outlet (activate)="componentRefChanged($event)"></router-outlet>
</div>
