import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { UntypedFormArray, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Address } from '../../../services/keycloak-connector.service';

@Component({
  selector: 'configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss']
})
export class ConfigurationPageComponent implements OnInit {

  configEntryArray: UntypedFormArray = new UntypedFormArray([]);
  editOngoing = false;
  tempValue = '';
  addressArray: UntypedFormArray = new UntypedFormArray([]);
  addressEditOngoing = false;
  tempAddress: any;

  constructor(private configService: ConfigurationService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.loadEntries();
    this.loadAddresses();
  }

  loadEntries() {
    this.configService.findAll().subscribe(res => {
      this.configEntryArray = this.configService.mapEntriesToFormArray(res.body);
    });
  }

  startEdit(index: number) {
    this.configEntryArray.get("" + index).get('value').enable();
    this.editOngoing = true;
    this.tempValue = this.configEntryArray.get("" + index).get('value').value;
  }

  saveEntry(index: number) {
    const entry = {
      key: this.configEntryArray.get("" + index).get('key').value,
      value: this.configEntryArray.get("" + index).get('value').value
    };
    this.configService.updateEntry(entry).subscribe(res => {
      this.editOngoing = false;
      this.loadEntries();
    });
  }

  cancelEdit(index: number) {
    this.configEntryArray.get("" + index).get('value').disable();
    this.configEntryArray.get("" + index).get('value').setValue(this.tempValue);
    this.editOngoing = false;
  }

  loadAddresses() {
    this.addressArray = new UntypedFormArray([]);
    this.configService.findAddresses().subscribe(res => {
      if (res.body && res.body.length > 0) {
        for (const address of res.body) {
          const fg = this.formBuilder.group(address);
          fg.disable();
          this.addressArray.push(fg);
        }
      }
    });
  }

  startEditAddress(index: number) {
    this.addressArray.get("" + index).enable();
    this.addressEditOngoing = true;
    this.tempAddress = this.addressArray.get("" + index).value;
  }

  saveAddress(index: number) {
    const address = new Address();
    address.id = this.addressArray.get("" + index).get('id').value;
    address.type = this.addressArray.get("" + index).get('type').value;
    address.street = this.addressArray.get("" + index).get('street').value;
    address.number = this.addressArray.get("" + index).get('number').value;
    address.zip = this.addressArray.get("" + index).get('zip').value;
    address.city = this.addressArray.get("" + index).get('city').value;

    this.configService.updateAddress(address).subscribe(res => {
      this.addressEditOngoing = false;
      this.loadAddresses();
    });
  }

  cancelEditAddress(index: number) {
    this.addressArray.get("" + index).disable();
    this.addressArray.get("" + index).setValue(this.tempAddress);
    this.addressEditOngoing = false;
  }

}
