<div class="row">
  <div class="col-xl-2 col-md-12 col-sm-12">
    <gib-datepicker [placeholder]="'eventDate'" [control]="eventForm.get('eventDate')"></gib-datepicker>
  </div>
  <div class="col-xl-2 col-md-6 col-sm-6 col-6">
    <gib-timepicker [placeholder]="'from'" [control]="eventForm.get('startTime')"></gib-timepicker>
  </div>
  <div class="col-xl-2 col-md-6 col-sm-6 col-6">
    <gib-timepicker [placeholder]="'to'" [control]="eventForm.get('endTime')"></gib-timepicker>
  </div>
  <div class="col-xl-6" style="text-align: right">
    <span> {{ "isRequiredHint" | translate }} </span>
  </div>
</div>
