import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CalendarEntryDto, CalendarService } from "../../../../services/calendar.service";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ValueLabel } from "../../../../components/value-label";
import { SelectOptions } from "../../../../utils/select-options";
import * as moment from "moment";
import { FormHelper } from "../../../../helper/form.helper";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "trainer-availablity-form",
  templateUrl: "./trainer-availablity-form.component.html",
  styleUrls: ["./trainer-availablity-form.component.scss"],
})
export class TrainerAvailablityFormComponent implements OnInit {
  @Output() entryAdded = new EventEmitter();
  @Output() entryEditCanceled = new EventEmitter();

  @Input() set entry(entry: CalendarEntryDto) {
    if (entry) {
      this.initEntryForm(entry);
    }
  }

  @Input() forUser: string;

  entryForm: UntypedFormGroup;
  trainerAvailabilityOptions: ValueLabel[] = SelectOptions.trainerAvailabilityOptions;
  userId = "";
  userRole = "";

  constructor(private calendarService: CalendarService, private formHelper: FormHelper, private dialogService: GibDialogService, private translateService: TranslateService) {
    this.initEntryForm(new CalendarEntryDto());
    this.userId = localStorage.getItem("userId");
    this.userRole = localStorage.getItem("role");
  }

  ngOnInit() {}

  initEntryForm(entry: CalendarEntryDto) {
    this.entryForm = this.calendarService.mapObjectToForm(entry);
    this.entryForm.addControl("dateTo", new UntypedFormControl(moment().add(1, "day")));
    this.entryForm.addControl("multipleDays", new UntypedFormControl(false));
    this.entryForm.addControl("mondays", new UntypedFormControl(true));
    this.entryForm.addControl("tuesdays", new UntypedFormControl(true));
    this.entryForm.addControl("wednesdays", new UntypedFormControl(true));
    this.entryForm.addControl("thursdays", new UntypedFormControl(true));
    this.entryForm.addControl("fridays", new UntypedFormControl(true));
    this.entryForm.addControl("saturdays", new UntypedFormControl(false));
    this.entryForm.addControl("sundays", new UntypedFormControl(false));
    this.entryForm.get("date").setValidators([Validators.required]);
    this.entryForm.get("trainerAvailabilityDistance").setValidators(this.formHelper.isValidNumber());
    this.entryForm.get("entryType").valueChanges.subscribe((res) => {
      if (res === "UNAVAILABLE") {
        this.entryForm.get("trainerAvailabilityTimeFrom").disable();
        this.entryForm.get("trainerAvailabilityTimeTo").disable();
        this.entryForm.get("trainerAvailabilityDistance").disable();
        this.entryForm.get("trainerAvailabilityTimeFrom").setValue("0:00");
        this.entryForm.get("trainerAvailabilityTimeTo").setValue("23:45");
      } else {
        this.entryForm.get("trainerAvailabilityTimeFrom").enable();
        this.entryForm.get("trainerAvailabilityTimeTo").enable();
        this.entryForm.get("trainerAvailabilityDistance").enable();
        this.entryForm.get("trainerAvailabilityTimeFrom").setValue("");
        this.entryForm.get("trainerAvailabilityTimeTo").setValue("");
      }
    });
    this.entryForm.get("date").valueChanges.subscribe((res) => {
      if (res && this.entryForm.get("multipleDays").value) {
        const startDate = moment(this.entryForm.get("date").value);
        const dateTo = moment(startDate).add(1, "day").toDate();
        this.entryForm.get("dateTo").setValue(dateTo);
      }
    });
  }

  atLeastOneDaySelected() {
    if (this.entryForm.get("mondays").value || this.entryForm.get("tuesdays").value || this.entryForm.get("wednesdays").value || this.entryForm.get("thursdays").value || this.entryForm.get("fridays").value || this.entryForm.get("saturdays").value || this.entryForm.get("sundays").value) {
      return true;
    }
    return false;
  }

  submitEntriesClicked() {
    this.formHelper.markControlsAsTouched(this.entryForm);
    if (this.entryForm.valid) {
      if (this.entryForm.get("multipleDays").value && !this.atLeastOneDaySelected()) {
        const title = this.translateService.instant("noDaysSelectedTitle");
        const text = this.translateService.instant("noDaysSelectedText");
        this.dialogService.openDialog(title, text, "success");
      } else if (this.entryForm.get("multipleDays").value && this.atLeastOneDaySelected()) {
        const entries = this.calendarService.mapFormToObjects(this.entryForm);
        for (const entry of entries) {
          if (this.forUser) {
            entry.userId = this.forUser;
            entry.role = "gib_trainer";
          } else {
            entry.userId = this.userId;
            entry.role = this.userRole;
          }
        }
        this.submitEntries(entries);
      } else {
        const entry = this.calendarService.mapFormToObject(this.entryForm);
        if (this.forUser) {
          entry.userId = this.forUser;
          entry.role = "gib_trainer";
        } else {
          entry.userId = this.userId;
          entry.role = this.userRole;
        }
        this.submitEntries([entry]);
      }
    }
  }

  submitEntries(entries: CalendarEntryDto[]) {
    this.calendarService.create(entries).subscribe((res) => {
      this.initEntryForm(new CalendarEntryDto());
      this.entryAdded.emit(null);
      //const title = this.translateService.instant('availabilitesSavedSuccessTitle');
      //this.dialogService.openDialog(title, "", "success");
    });
  }

  editEntryClicked() {
    this.formHelper.markControlsAsTouched(this.entryForm);
    if (this.entryForm.valid) {
      const entry = this.calendarService.mapFormToObject(this.entryForm);
      if (this.forUser) {
        entry.userId = this.forUser;
        entry.role = "gib_trainer";
      } else {
        entry.userId = this.userId;
        entry.role = this.userRole;
      }
      this.editEntry(entry);
    }
  }

  editEntry(entry: CalendarEntryDto) {
    this.calendarService.update(entry).subscribe((res) => {
      this.initEntryForm(new CalendarEntryDto());
      this.entryAdded.emit(null);
      if (this.userRole !== "gib_trainer") {
        this.entryEditCanceled.emit(null);
      }
    });
  }

  cancelEdit() {
    if (this.userRole === "gib_trainer") {
      this.initEntryForm(new CalendarEntryDto());
      this.entryEditCanceled.emit(null);
    } else {
      this.entryEditCanceled.emit(null);
    }
  }

  toggleMultipleDays(event: any) {
    this.entryForm.get("mondays").setValue(true);
    this.entryForm.get("tuesdays").setValue(true);
    this.entryForm.get("wednesdays").setValue(true);
    this.entryForm.get("thursdays").setValue(true);
    this.entryForm.get("fridays").setValue(true);
    if (this.entryForm.get("date").value) {
      const startDate = moment(this.entryForm.get("date").value);
      const dateTo = moment(startDate).add(1, "day").toDate();
      this.entryForm.get("dateTo").setValue(dateTo);
    }
  }
}
