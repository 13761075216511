import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "event-waitlist",
  templateUrl: "./event-waitlist.component.html",
  styleUrls: ["./event-waitlist.component.sass"],
})
export class EventWaitlistComponent implements OnInit {
  @Input() waitingListEntry: UntypedFormGroup;
  @Input() eventInquiryHealthscreening: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}

  scheduleEntryCompanynameRequired(): boolean {
    return this.eventInquiryHealthscreening && this.eventInquiryHealthscreening.get("scheduleEntryCompanynameRequired") && this.eventInquiryHealthscreening.get("scheduleEntryCompanynameRequired").value;
  }
}
