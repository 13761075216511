import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "gib-icon-button",
  templateUrl: "./gib-icon-button.component.html",
  styleUrls: ["./gib-icon-button.component.scss"],
})
export class GibIconButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() type: string;

  constructor() {}

  ngOnInit() {}
}
