import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromCancelledEvents from "../reducers/cancelled-event.reducer";

export const getCancelledEventsState = createSelector(fromFeature.getGibState, (state: fromFeature.GibState) => state.cancelledEvents);
export const getCancelledEventsEntities = createSelector(getCancelledEventsState, fromCancelledEvents.getCancelledEventsEntities);
export const getCancelledEvents = (period: string) =>
  createSelector(getCancelledEventsEntities, (entities) => {
    if (entities && entities[period]) {
      return Object.keys(entities[period]).map((id) => entities[period][id]);
    }
    return [];
  });
export const getCancelledEventsPeriods = createSelector(getCancelledEventsEntities, (entities) => {
  return Object.keys(entities);
});
export const getCancelledEventsLoaded = createSelector(getCancelledEventsState, fromCancelledEvents.getCancelledEventsLoaded);
export const getCancelledEventsLoading = createSelector(getCancelledEventsState, fromCancelledEvents.getCancelledEventsLoading);
