import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError, concatMap } from "rxjs/operators";

import * as eventActions from "../actions/active-event.actions";
import * as fromServices from "../../services";

@Injectable()
export class ActiveEventEffects {
  constructor(private actions$: Actions, private eventOverviewService: fromServices.EventOverviewService) {}

  loadActiveEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.LOAD_ACTIVE_EVENTS),
      map((action: eventActions.LoadActiveEvents) => action.payload),
      concatMap((payload) => {
        const period: string = payload.from.substring(5, 7) + "/" + payload.from.substring(0, 4);
        return this.eventOverviewService.getActiveEventsWithEmptyDates(payload.from, payload.to).pipe(
          map((response) => new eventActions.LoadActiveEventsSuccess(response.body, period)),
          catchError((error) => of(new eventActions.LoadActiveEventsFail(error)))
        );
      })
    );
  });

  updateEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.UPDATE_EVENT),
      map((action: eventActions.UpdateEvent) => action),
      switchMap((action) => {
        return this.eventOverviewService.updateEvent(action.payload).pipe(
          map((response) => {
            return new eventActions.UpdateEventSuccess(response);
          }),
          catchError((error) => of(new eventActions.UpdateEventFail(error)))
        );
      })
    );
  });
}
