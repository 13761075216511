import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { CalendarView, CalendarEvent } from "angular-calendar";
import { isSameMonth, isSameDay } from "date-fns";
import { CalendarEihsDto, CalendarEntryDto, CalendarEntryType, CalendarService } from "../../services/calendar.service";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { Employee } from "../../services/employee.service";
import { ProfileService } from "../../services/profile.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { TrainerEventDetailsSheetComponent } from "../../pages/trainer/components/trainer-event-details-sheet/trainer-event-details-sheet.component";
import { EventService } from "src/app/services/event.service";

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  green: {
    primary: "#92cb91",
    secondary: "#f0f5ed",
  },
  blue: {
    primary: "#394160",
    secondary: "#c8c618",
  },
};

@Component({
  selector: "gib-calendar",
  templateUrl: "./gib-calendar.component.html",
  styleUrls: ["./gib-calendar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GibCalendarComponent implements OnInit {
  events: CalendarEvent[] = [];
  eventsAsync: CalendarEvent[] = [];
  currentUser: Employee;
  calendarEntries: CalendarEntryDto[];

  @Output() editEntry = new EventEmitter<CalendarEntryDto>();
  @Output() entryDeleted = new EventEmitter();
  @Output() emptyEntryClicked = new EventEmitter();
  @Output() emptyEntryShiftClicked = new EventEmitter();

  @Input() set entries(entries: CalendarEntryDto[]) {
    this.calendarEntries = entries;
    this.mapEntriesToCalendarEvents(this.calendarEntries);
  }

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  activeDayIsOpen = false;
  locale = "de";

  constructor(
    private translateService: TranslateService,
    private calendarService: CalendarService,
    private profileService: ProfileService,
    private bottomSheet: MatBottomSheet,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.getMyProfile();
  }

  dayClicked(event: any): void {
    if (event.sourceEvent.shiftKey) {
      this.emptyEntryShiftClicked.emit(event.day.date);
    } else {
      if (isSameMonth(event.day.date, this.viewDate)) {
        if ((isSameDay(this.viewDate, event.day.date) && this.activeDayIsOpen === true) || event.day.events.length === 0) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = event.day.date;
      }
      if (event.day.events && event.day.events.length > 0) {
        // do nothing
      } else {
        this.emptyEntryClicked.emit(event.day.date);
      }
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  mapEntriesToCalendarEvents(entries: CalendarEntryDto[]) {
    this.eventsAsync = [];
    for (const entry of entries) {
      if (entry.eihsDto !== undefined && entry.eihsDto.length !== 0) {
        for (const eihsDto of entry.eihsDto) {
          const event: CalendarEvent = {
            start: this.createDate(entry.date, eihsDto.eventStartTime),
            end: this.createDate(entry.date, eihsDto.eventEndTime),
            color: this.setColorByEntryType(entry.entryType),
            title: this.createTitle(entry, eihsDto),
            meta: this.createMeta(entry),
            actions: this.createEventActions(eihsDto, entry.selectedTrainerUsername),
          };
          this.eventsAsync.push(event);
        }
      } else {
        const event: CalendarEvent = {
          start: this.createDate(entry.date, entry.trainerAvailabilityTimeFrom),
          end: this.createDate(entry.date, entry.trainerAvailabilityTimeTo),
          color: this.setColorByEntryType(entry.entryType),
          title: this.createTitle(entry),
          meta: this.createMeta(entry),
          actions: this.createActions(entry),
        };
        this.eventsAsync.push(event);
      }
    }
    this.events = this.eventsAsync.slice();
    //this.events = events;
  }

  createMeta(entry: CalendarEntryDto) {
    const meta = {};
    meta["type"] = entry.entryType;
    meta["timeFrom"] = entry.trainerAvailabilityTimeFrom;
    meta["timeTo"] = entry.trainerAvailabilityTimeTo;
    meta["distance"] = entry.trainerAvailabilityDistance;
    meta["id"] = entry.id;
    if (entry.eihsDto !== undefined && entry.eihsDto.length !== 0) {
      meta["location"] = entry.eihsDto[0].city;
      meta["distance"] = this.calcDistance(entry.eihsDto[0]);
      meta["timeFrom"] = entry.eihsDto[0].eventStartTime;
      meta["timeTo"] = entry.eihsDto[0].eventEndTime;
      meta["moduleName"] = entry.eihsDto[0].moduleName;
    }
    return meta;
  }

  calcDistance(entry: CalendarEihsDto) {
    if (this.currentUser) {
      if (this.currentUser.location && this.currentUser.location.includes("Hamburg") && this.currentUser.location.includes("Berlin")) {
        if (entry.distanceFromHamburg > entry.distanceFromBerlin) {
          return entry.distanceFromBerlin / 2;
        } else {
          return entry.distanceFromHamburg / 2;
        }
      } else if (this.currentUser.location && this.currentUser.location.includes("Hamburg") && !this.currentUser.location.includes("Berlin")) {
        return entry.distanceFromHamburg / 2;
      } else if (this.currentUser.location && !this.currentUser.location.includes("Hamburg") && this.currentUser.location.includes("Berlin")) {
        return entry.distanceFromBerlin / 2;
      } else {
        if (entry.distanceFromHamburg > entry.distanceFromBerlin) {
          return entry.distanceFromBerlin / 2;
        } else {
          return entry.distanceFromHamburg / 2;
        }
      }
    }
    return "";
  }

  createActions(entry: CalendarEntryDto): any {
    return [
      {
        label: '<i class="material-icons">edit</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.editEntry.emit(entry);
        },
      },
      {
        label: '<i class="material-icons">delete</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.calendarService.delete(entry.id).subscribe((res) => {
            this.entryDeleted.emit(null);
          });
        },
      },
    ];
  }

  createEventActions(eihsDto: CalendarEihsDto, selectedTrainerUsername: string) {
    return [
      {
        label: '<i class="material-icons">info</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          const data = { buid: eihsDto.buid, eihsId: eihsDto.eihsId, selectedTrainerUsername: selectedTrainerUsername };
          this.bottomSheet.open(TrainerEventDetailsSheetComponent, { data });
        },
      },
    ];
  }

  createDate(date: any, time: any) {
    if (time) {
      const hour = parseInt(time.substring(0, 2), 10);
      const minute = parseInt(time.substring(3, 5), 10);
      return moment(new Date(date)).set({ hour, minute }).toDate();
    } else {
      return moment(new Date(date)).toDate();
    }
  }

  createTitle(entry: CalendarEntryDto, eihsDto?: CalendarEihsDto) {
    let title = "";
    if (entry.entryType === "UNAVAILABLE") {
      title = this.translateService.instant("not_available");
    } else if (entry.entryType === "AVAILABLE") {
      title = this.translateService.instant("available") + ": " + entry.trainerAvailabilityTimeFrom + "-" + entry.trainerAvailabilityTimeTo + " | " + entry.trainerAvailabilityDistance + "km";
    } else if (entry.entryType === "APPOINTED" && entry.eihsDto !== undefined && entry.eihsDto.length !== 0) {
      const modulePresentation = eihsDto.modulePresentation ? "X" : "-";
      const moduleEnglish = eihsDto.moduleEnglish ? "X" : "-";
      const anonymEvaluation = eihsDto.anonymEvaluation ? "X" : "-";
      const companynameAlias = eihsDto.customerCompanynameAlias ? eihsDto.customerCompanynameAlias : "-";
      title =
        this.translateService.instant("customer") +
        ": " +
        companynameAlias +
        " | " +
        eihsDto.moduleName +
        ": " +
        moment(entry.date).format("DD.MM.YYYY") +
        "(" +
        moment(entry.date).format("ddd") +
        ") | " +
        eihsDto.moduleTimeFrom +
        "-" +
        eihsDto.moduleTimeTo +
        " | " +
        this.translateService.instant("presentationShort") +
        ": " +
        modulePresentation +
        " | " +
        this.translateService.instant("presentationEnglish") +
        ": " +
        moduleEnglish +
        " | " +
        this.translateService.instant("anonymousEvaluation") +
        ": " +
        anonymEvaluation;
    }
    if (entry.comment) {
      title += " | " + entry.comment;
    }
    return title;
  }

  setColorByEntryType(entryType: CalendarEntryType) {
    if (entryType === "AVAILABLE") {
      return colors.green;
    } else if (entryType === "UNAVAILABLE") {
      return colors.red;
    } else if (entryType === "APPOINTED") {
      return colors.blue;
    }
  }

  getMyProfile() {
    this.profileService.getMyProfile().subscribe((res) => {
      this.currentUser = res.body;
      this.mapEntriesToCalendarEvents(this.calendarEntries);
    });
  }
}
