import { EmployeeService } from 'src/app/services/employee.service';
import { MatSelect } from '@angular/material/select';
import { Component, OnInit, ViewChild, Type } from '@angular/core';

@Component({
  selector: 'gib-grid-hospitant-select',
  templateUrl: './gib-grid-hospitant-select.component.html',
  styleUrls: ['./gib-grid-hospitant-select.component.scss']
})
export class GibGridHospitantSelectComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  placeholder = '';

  options = [];
  

  value: string;
  constructor(private employeeService: EmployeeService) {}

  ngOnInit() {}

  agInit(params: any): void {
    this.placeholder = params.placeholder;
    this.value = params.value;
    this.loadHospitants();
  }

  getValue() {
    return this.value;
  }

  loadHospitants() {
    this.employeeService.getAllTrainersByState('VISIT').subscribe(res => {
      for (const emp of res.body) {
        this.options.push({
          label: emp.employeeFullname,
          value: emp.employeeId,
          availability: emp.availability
        });
      }
      this.focusSelect();
    });
  }

  focusSelect() {
    window.setTimeout(() => {
      if (this.select) {
        this.select.focus();
      }
    }, 0);
  }
}
