import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'gib-grid-input',
  templateUrl: './gib-grid-input.component.html',
  styleUrls: ['./gib-grid-input.component.scss']
})
export class GibGridInputComponent implements ICellEditorAngularComp {
  @ViewChild('input', { static: true }) input: ElementRef;
  placeholder = '';

  value: string;

  constructor() {}

  ngOnInit() {}

  agInit(params: any): void {
    this.placeholder = params.placeholder;
    this.value = params.value;
    window.setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  getValue() {
    return this.value;
  }

}
