import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFeedbackReport, TrainerFeedbackReport } from './../../../../services/report.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'report-chart',
  templateUrl: './report-chart.component.html',
  styleUrls: ['./report-chart.component.scss']
})
export class ReportChartComponent implements OnInit {

  @Input() options: Record<string, number>;

  constructor() {}

  ngOnInit() {

  }

}