<div class="row indication-row" *ngFor="let input of control.value; let i = index">
  <div class="col-10">
    <mat-label>{{ input }}</mat-label>
  </div>
  <div class="col-2">
    <mat-icon class="material-iconsz" svgIcon="delete" (click)="deleteInput(i)" *ngIf="!control.disabled"></mat-icon>
  </div>

  <hr />
</div>

<div class="row" *ngIf="!control.disabled || (control.value && control.value.length === 0)">
  <div class="col-10">
    <gib-input [placeholder]="placeholder" [control]="tempControl"></gib-input>
  </div>
  <div class="col-2 bottom-col">
    <mat-icon class="material-iconsz" svgIcon="plus_profile" *ngIf="!tempControl.disabled" (click)="addInput()"></mat-icon>
  </div>
</div>
