<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content [formGroup]="votingParticipant">
  <p [innerHTML]="data.text"></p>
  <mat-checkbox formControlName="acceptedEventPrivacy">
    <div [innerHTML]="data.eventPrivacyText"></div>
  </mat-checkbox>
  <br />
  <div *ngIf="data.thirdPartyPrivacyText !== ''">
    <mat-checkbox formControlName="acceptedThirdPartyPrivacy">
      <div [innerHTML]="data.thirdPartyPrivacyText"></div>
    </mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelAction()">{{'CANCEL' | translate}}</button>
  <button [disabled]="!this.votingParticipant.get('acceptedEventPrivacy').value" class="btn btn-secondary" mat-button (click)="confirmAction()" cdkFocusInitial>Ok</button>
</div>