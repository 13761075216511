import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { KeycloakUser, Address } from "./keycloak-connector.service";
import { Event } from "./event.service";
import { Observable } from "rxjs";
import { ValueLabel } from "../components/value-label";
import { EnvService } from "./env.service";

declare type State = "ACTIVE" | "DELETED";

export class Customer extends KeycloakUser {
  phonenumber: string;
  mobilenumber: string;
  companyname: string;
  password: string;
  region: string;
  teamnumber: string;
  comment: string;

  companynameAlias: string;
  state: State;
  bookingCopyRecepientMail: string;
  gpsUser: boolean;

  constructor() {
    super();
    this.role = "gib_customer";
    this.phonenumber = "";
    this.companyname = "";
    this.region = "";
    this.teamnumber = "";
    this.password = "";
    this.companynameAlias = "";
    this.state = "ACTIVE";
    this.mobilenumber = "";
    this.comment = "";
    this.bookingCopyRecepientMail = "";
    this.gpsUser = true;
  }
}

@Injectable()
export class CustomerService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private envService: EnvService) {}

  // REST

  startCustomerRegistration(customer: Customer) {
    return this.http.post(this.envService.backendUrl + "/customer/register/start", customer, this.config);
  }

  submitRegistration(customer: Customer) {
    return this.http.post(this.envService.backendUrl + "/public/customer/register/complete", customer, this.config);
  }

  getCustomer(customerId: string) {
    return this.http.get<Customer>(this.envService.backendUrl + "/public/customer?customerId=" + customerId);
  }

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/customer", this.config);
  }

  createCustomerValueLabels(): Observable<ValueLabel[]> {
    return new Observable((observer) => {
      this.findAll().subscribe((res) => {
        const customerValueLabels = [];
        for (const customer of res.body) {
          const customerLabel = customer.officialName + " (" + customer.companyname + ")";
          customerValueLabels.push({ label: customerLabel, value: customer.id, data: { companynameAlias: customer.companynameAlias } });
        }
        observer.next(customerValueLabels);
        observer.complete();
      });
    });
  }

  delete(customerId: string): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/customer/" + customerId, this.config);
  }

  create(customer: Customer): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/customer", customer, this.config);
  }

  update(customer: Customer): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/customer/" + customer.id, customer, this.config);
  }

  updateMailReceipients(bookingCopyRecepientMail: string): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/customer/updateBookingCopyRecepientMail", bookingCopyRecepientMail, this.config);
  }

  getAllCompanynameAliases(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/customer/getAllCompanynameAliases", this.config);
  }

  // Helper functions

  getFullName(customer: Customer): string {
    return customer.firstname + " " + customer.lastname;
  }

  mapCustomerToForm(customer: Customer): UntypedFormGroup {
    const customerForm = this.formBuilder.group(customer);
    const addresses = customer.addresses;
    customerForm.removeControl("addresses");
    if (addresses && addresses.length > 0) {
      const addressFormGroupArray = [];
      for (const address of addresses) {
        addressFormGroupArray.push(this.formBuilder.group(address));
      }
      customerForm.addControl("addresses", this.formBuilder.array(addressFormGroupArray));
    } else {
      const address = new Address();
      address.type = "BILLING";
      customerForm.addControl("addresses", this.formBuilder.array([this.formBuilder.group(address)]));
    }
    this.handleBookingCopyRecepientMailFromObject(customer, customerForm);
    return customerForm;
  }

  mapFormToCustomer(form: UntypedFormGroup): Customer {
    const customer = form.getRawValue();
    this.handleBookingCopyRecepientMailFromForm(customer, form);
    return customer;
  }

  handleBookingCopyRecepientMailFromForm(customer: Customer, form: UntypedFormGroup) {
    let bookingCopyRecepientMailForObject = form.get("bookingCopyRecepientMail").value ? form.get("bookingCopyRecepientMail").value.join(", ") : [];
    if (form.get("bookingCopyRecepientMailTemp") && form.get("bookingCopyRecepientMailTemp").value) {
      if (bookingCopyRecepientMailForObject === "") {
        bookingCopyRecepientMailForObject = form.get("bookingCopyRecepientMailTemp").value;
      } else {
        bookingCopyRecepientMailForObject = bookingCopyRecepientMailForObject + ", " + form.get("bookingCopyRecepientMailTemp").value;
      }
    }
    customer.bookingCopyRecepientMail = bookingCopyRecepientMailForObject;
  }

  handleBookingCopyRecepientMailFromObject(event: Customer, form: UntypedFormGroup) {
    const bookingCopyRecepientMailForForm = event.bookingCopyRecepientMail ? event.bookingCopyRecepientMail.split(", ") : [];
    form.get("bookingCopyRecepientMail").setValue(bookingCopyRecepientMailForForm);
  }

  mapEventToCustomerForm(event: Event): UntypedFormGroup {
    const customer = new Customer();
    customer.addresses[0] = new Address();
    customer.salutation = event.customerSalutation;
    customer.firstname = event.customerFirstname;
    customer.lastname = event.customerLastname;
    customer.phonenumber = event.customerPhone;
    customer.email = event.customerMail;
    customer.companyname = event.customerCompanyName;
    customer.addresses[0].type = "BILLING";
    customer.addresses[0].street = event.customerAddress && event.customerAddress.street ? event.customerAddress.street : "";
    customer.addresses[0].number = event.customerAddress && event.customerAddress.number ? event.customerAddress.number : "";
    customer.addresses[0].zip = event.customerAddress && event.customerAddress.zip ? event.customerAddress.zip : "";
    customer.addresses[0].city = event.customerAddress && event.customerAddress.city ? event.customerAddress.city : "";
    return this.mapCustomerToForm(customer);
  }
}
