<div class="row">
  <div class="col-sm-12 col-md-4">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>
  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="companynameAlias">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'companynameAlias' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.companynameAlias}} </td>
        </ng-container>

        <ng-container matColumnDef="sortOrder">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'sortOrder' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.sortOrder}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'title' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.title}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'description' | translate}} </th>
          <td mat-cell *matCellDef="let row" maxlength="100"> {{row.description | slice:0:100}} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectFaq(row)">edit</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteFaqConfirmation(row)">delete</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>