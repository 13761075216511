import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { FormHelper } from "src/app/helper/form.helper";
import { EventService } from "src/app/services/event.service";
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";
import { ScheduleEntry } from "../../../../services/event.service";
import { ScheduleService } from "src/app/services/schedule-vote.service";
import { TranslateService } from "@ngx-translate/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { WaitingListService } from "src/app/services/waiting-list.service";
import { ValueLabel } from "src/app/components/value-label";
import { ValueLabelBoolean } from "src/app/components/value-label-boolean";

@Component({
  selector: "schedule-model",
  templateUrl: "./schedule-model.component.html",
  styleUrls: ["./schedule-model.component.scss"],
})
export class ScheduleModelComponent implements OnInit, OnChanges {
  @Input() eventInquiryHealthscreening: UntypedFormGroup;
  @Input() eventForm: UntypedFormGroup;
  @Input() allowModuleReplace: boolean;
  @Input() editMode: boolean;
  @Input() isEditable: boolean;
  breakTimeMinutesGap = 15;

  userRole: string;
  url: string = window.location.href;
  downloadUrl;
  filename: string;

  onlineModuleOptions: ValueLabelBoolean[] = [
    { value: true, label: this.translateService.instant("online") },
    { value: false, label: this.translateService.instant("onsite") },
  ];

  presentationEnglishOptions: ValueLabelBoolean[] = [
    { value: true, label: this.translateService.instant("english") },
    { value: false, label: this.translateService.instant("german") },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private formHelper: FormHelper,
    private scheduleService: ScheduleService,
    private dialogService: GibDialogService,
    private translateService: TranslateService,
    private bottomSheet: MatBottomSheet,
    private waitingListService: WaitingListService
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.initCheckBoxHandling();
    this.handleTimingChange();

    if (this.moduleType === "HEALTH_MODULE") {
      this.eventInquiryHealthscreening.get("timing").setValidators([this.formHelper.isValidNumber()]);
      this.setDefaultValues();
    } else {
      this.eventInquiryHealthscreening.get("maxParticipants").setValidators([this.formHelper.isValidNumber()]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private setDefaultValues() {
    const moduleType = this.eventInquiryHealthscreening.get("moduleType").value;
    const online = this.eventInquiryHealthscreening.get("onlineModule").value;
    if (moduleType === "HEALTH_MODULE") {
      if (!this.eventInquiryHealthscreening.get("timing").value) {
        let timing;
        if (online) {
          timing = this.eventInquiryHealthscreening.get("onlineInterval").value;
        } else {
          timing = this.eventInquiryHealthscreening.get("onsiteInterval").value;
        }
        this.eventInquiryHealthscreening.get("timing").setValue(timing);
      }
    }
  }

  initCheckBoxHandling() {
    this.eventInquiryHealthscreening.get("presentation").valueChanges.subscribe((value) => {
      if (value) {
        this.eventInquiryHealthscreening.get("presentationBillOnly").setValue(false);
      }
    });
    this.eventInquiryHealthscreening.get("presentationBillOnly").valueChanges.subscribe((value) => {
      if (value) {
        this.eventInquiryHealthscreening.get("presentation").setValue(false);
        this.eventInquiryHealthscreening.get("presentationTimeFrom").setValue("");
        this.eventInquiryHealthscreening.get("presentationTimeTo").setValue("");
      }
    });
    this.eventInquiryHealthscreening.get("anonymousEvaluation").valueChanges.subscribe((value) => {
      if (value) {
        this.eventInquiryHealthscreening.get("anonymousEvaluationBillOnly").setValue(false);
        this.eventInquiryHealthscreening.get("attachmentPrivacy").setValue(true);
      }
    });
    this.eventInquiryHealthscreening.get("anonymousEvaluationBillOnly").valueChanges.subscribe((value) => {
      if (value) {
        this.eventInquiryHealthscreening.get("anonymousEvaluation").setValue(false);
      }
    });
  }

  handleTimingChange() {
    if (this.eventInquiryHealthscreening.get("timing").value) {
      this.setBreakTimeMinutesGap(this.eventInquiryHealthscreening.get("timing").value);
    }
    this.eventInquiryHealthscreening.get("timing").valueChanges.subscribe((res) => {
      this.setBreakTimeMinutesGap(res);
    });
  }

  setBreakTimeMinutesGap(value: any) {
    const valueToSet = parseInt(value, 10);
    if (!isNaN(valueToSet)) {
      this.breakTimeMinutesGap = valueToSet;
    } else {
      this.breakTimeMinutesGap = 15;
    }
  }

  isCreateButtonEnabled() {
    if (this.moduleType === "HEALTH_MODULE") {
      return (
        this.eventInquiryHealthscreening.get("timeFrom").value &&
        this.eventInquiryHealthscreening.get("timeTo").value &&
        this.eventInquiryHealthscreening.get("timing").value &&
        !this.eventInquiryHealthscreening.get("timeFrom").disabled
      );
    } else {
      return (
        this.eventInquiryHealthscreening.get("timeFrom").value &&
        this.eventInquiryHealthscreening.get("timeTo").value &&
        this.eventInquiryHealthscreening.get("maxParticipants").value &&
        !this.eventInquiryHealthscreening.get("timeFrom").disabled
      );
    }
  }

  createEventParticipants() {
    if (
      this.eventInquiryHealthscreening &&
      this.eventInquiryHealthscreening.get("scheduleEntries") &&
      this.eventInquiryHealthscreening.get("scheduleEntries")["controls"] &&
      this.eventInquiryHealthscreening.get("scheduleEntries")["controls"].length !== 0
    ) {
      const title = this.translateService.instant("regenscheduleEntriesTitle");
      const text = this.translateService.instant("regenscheduleEntriesText");
      this.dialogService.openConfirmationDialog(title, text, () => {
        if (this.moduleType === "HEALTH_MODULE") {
          this.createEventParticipantsExecution();
        } else {
          this.createEventParticipantsForWebinarAndActiveBreak();
        }
      });
    } else {
      if (this.moduleType === "HEALTH_MODULE") {
        this.createEventParticipantsExecution();
      } else {
        this.createEventParticipantsForWebinarAndActiveBreak();
      }
    }
  }

  createEventParticipantsForWebinarAndActiveBreak() {
    const timeFrom = this.eventInquiryHealthscreening.get("timeFrom").value;
    const timeTo = this.eventInquiryHealthscreening.get("timeTo").value;
    const maxParticipants: number = this.eventInquiryHealthscreening.get("maxParticipants").value;

    const timeFromAsDate = this.createDateOfString(timeFrom);
    const timeToAsDate = this.createDateOfString(timeTo);
    const presentationTimeFrom = this.createDateOfString(this.eventInquiryHealthscreening.get("presentationTimeFrom").value);
    const presentationTimeTo = this.createDateOfString(this.eventInquiryHealthscreening.get("presentationTimeTo").value);

    const scheduleEntries = [];

    const start = this.createDateOfString(timeFrom).getHours() * 60 + this.createDateOfString(timeFrom).getMinutes();
    let end = this.createDateOfString(timeTo).getHours() * 60 + this.createDateOfString(timeTo).getMinutes();
    // corner case 24:00 end time
    if (end === 0) {
      end = 1440;
    }
    const availableMinutes = [];
    for (let i = 0; i <= 3600; i++) {
      availableMinutes.push(0);
    }

    for (let i = start; i <= end; i++) {
      availableMinutes[i] = 1;
    }

    // add 15 minutes gab for presentation time at the beginning and at the end
    if (presentationTimeFrom && presentationTimeTo) {
      this.fillSlotWithTime(availableMinutes, new Date(presentationTimeFrom.getTime() - 15 * 60000), new Date(presentationTimeTo.getTime() + 15 * 60000));
      let slot = this.eventService.createScheduleEntry(
        this.getTimeStringFromDate(new Date(presentationTimeFrom.getTime() - 15 * 60000)),
        this.getTimeStringFromDate(presentationTimeFrom),
        "UNAVAILABLE",
        ""
      );
      scheduleEntries.push(this.formBuilder.group(slot));
      slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(presentationTimeTo), this.getTimeStringFromDate(new Date(presentationTimeTo.getTime() + 15 * 60000)), "UNAVAILABLE", "");
      scheduleEntries.push(this.formBuilder.group(slot));
      slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(presentationTimeFrom), this.getTimeStringFromDate(presentationTimeTo), "BLOCKED", "PRESENTATION");
      scheduleEntries.push(this.formBuilder.group(slot));
    }

    for (let i = 0; i < maxParticipants; i++) {
      const slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(timeFromAsDate), this.getTimeStringFromDate(timeToAsDate), "OPEN", null);
      scheduleEntries.push(this.formBuilder.group(slot));
    }

    scheduleEntries.sort(this.sortByTimeFrom);
    this.eventInquiryHealthscreening.removeControl("scheduleEntries");
    this.eventInquiryHealthscreening.addControl("scheduleEntries", this.formBuilder.array(scheduleEntries));
    for (const scheduleEntry of this.eventInquiryHealthscreening.get("scheduleEntries")["controls"]) {
      this.formHelper.disableControlsByName(scheduleEntry, ["timeFrom", "timeTo"]);
    }
  }

  createEventParticipantsExecution() {
    const timeFrom = this.eventInquiryHealthscreening.get("timeFrom").value;
    const timeTo = this.eventInquiryHealthscreening.get("timeTo").value;
    const timePerSlot = this.eventInquiryHealthscreening.get("timing").value;

    const timeFromAsDate = this.createDateOfString(timeFrom);

    const presentationTimeFrom = this.createDateOfString(this.eventInquiryHealthscreening.get("presentationTimeFrom").value);
    const presentationTimeTo = this.createDateOfString(this.eventInquiryHealthscreening.get("presentationTimeTo").value);
    const breakTime1From = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime1From").value);
    const breakTime1To = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime1To").value);
    const breakTime2From = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime2From").value);
    const breakTime2To = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime2To").value);
    const breakTime3From = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime3From").value);
    const breakTime3To = this.createDateOfString(this.eventInquiryHealthscreening.get("breakTime3To").value);

    const scheduleEntries = [];

    const start = this.createDateOfString(timeFrom).getHours() * 60 + this.createDateOfString(timeFrom).getMinutes();
    let end = this.createDateOfString(timeTo).getHours() * 60 + this.createDateOfString(timeTo).getMinutes();
    // corner case 24:00 end time
    if (end === 0) {
      end = 1440;
    }
    const availableMinutes = [];
    for (let i = 0; i <= 3600; i++) {
      availableMinutes.push(0);
    }

    for (let i = start; i <= end; i++) {
      availableMinutes[i] = 1;
    }

    if (this.eventInquiryHealthscreening.get("shortPresentation").value) {
      const shortPresentationEndDate = new Date(timeFromAsDate.getTime() + timePerSlot * 60000);
      this.fillSlotWithTime(availableMinutes, timeFromAsDate, shortPresentationEndDate);
      const slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(timeFromAsDate), this.getTimeStringFromDate(shortPresentationEndDate), "BLOCKED", "shortPresentation");
      scheduleEntries.push(this.formBuilder.group(slot));
    }

    // add 15 minutes gab for presentation time at the beginning and at the end
    if (presentationTimeFrom && presentationTimeTo) {
      this.fillSlotWithTime(availableMinutes, new Date(presentationTimeFrom.getTime() - 15 * 60000), new Date(presentationTimeTo.getTime() + 15 * 60000));
      let slot = this.eventService.createScheduleEntry(
        this.getTimeStringFromDate(new Date(presentationTimeFrom.getTime() - 15 * 60000)),
        this.getTimeStringFromDate(presentationTimeFrom),
        "UNAVAILABLE",
        ""
      );
      scheduleEntries.push(this.formBuilder.group(slot));
      slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(presentationTimeTo), this.getTimeStringFromDate(new Date(presentationTimeTo.getTime() + 15 * 60000)), "UNAVAILABLE", "");
      scheduleEntries.push(this.formBuilder.group(slot));
      slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(presentationTimeFrom), this.getTimeStringFromDate(presentationTimeTo), "BLOCKED", "PRESENTATION");
      scheduleEntries.push(this.formBuilder.group(slot));
    }

    if (breakTime1From && breakTime1To) {
      this.fillSlotWithTime(availableMinutes, breakTime1From, breakTime1To);
      const slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(breakTime1From), this.getTimeStringFromDate(breakTime1To), "BLOCKED", "break");
      scheduleEntries.push(this.formBuilder.group(slot));
    }
    if (breakTime2From && breakTime2To) {
      this.fillSlotWithTime(availableMinutes, breakTime2From, breakTime2To);
      const slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(breakTime2From), this.getTimeStringFromDate(breakTime2To), "BLOCKED", "break");
      scheduleEntries.push(this.formBuilder.group(slot));
    }
    if (breakTime3From && breakTime3To) {
      this.fillSlotWithTime(availableMinutes, breakTime3From, breakTime3To);
      const slot = this.eventService.createScheduleEntry(this.getTimeStringFromDate(breakTime3From), this.getTimeStringFromDate(breakTime3To), "BLOCKED", "break");
      scheduleEntries.push(this.formBuilder.group(slot));
    }

    while (this.getNextAvailableMinute(availableMinutes, timePerSlot) !== -1) {
      const ep = new ScheduleEntry();
      const startMinute = this.getNextAvailableMinute(availableMinutes, timePerSlot);
      const hours = Math.floor(startMinute / 60);
      const minutes = startMinute % 60;

      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      ep.timeFrom = this.getTimeStringFromDate(date);
      ep.timeTo = this.getTimeStringFromDate(moment(date).add(timePerSlot, "m").toDate());
      ep.scheduletype = "OPEN";
      if (this.eventInquiryHealthscreening.get("onlineModule")) {
        ep.onlineModule = true;
      } else {
        ep.onlineModule = false;
      }

      this.fillSlotWithTime(availableMinutes, date, moment(date).add(timePerSlot, "m").toDate());
      scheduleEntries.push(this.formBuilder.group(ep));
    }
    scheduleEntries.sort(this.sortByTimeFrom);
    this.eventInquiryHealthscreening.removeControl("scheduleEntries");
    this.eventInquiryHealthscreening.addControl("scheduleEntries", this.formBuilder.array(scheduleEntries));
    for (const scheduleEntry of this.eventInquiryHealthscreening.get("scheduleEntries")["controls"]) {
      this.formHelper.disableControlsByName(scheduleEntry, ["timeFrom", "timeTo"]);
    }
  }

  fillSlotWithTime(timeSlotArray: Array<number>, from: Date, to: Date) {
    if (from && to) {
      const start = from.getHours() * 60 + from.getMinutes();
      let end = to.getHours() * 60 + to.getMinutes();
      // corner case 24:00 end time
      if (end === 0) {
        end = 1440;
      }
      for (let i = start; i < end; i++) {
        timeSlotArray[i] = 0;
      }
    }
  }

  getNextAvailableMinute(timeSlotArray: Array<number>, timePerSlot: number): number {
    let fromIndex = 0;
    let slotAvailable = false;
    let firstAvailableMinute = timeSlotArray.indexOf(1, fromIndex);
    while (firstAvailableMinute !== -1) {
      firstAvailableMinute = timeSlotArray.indexOf(1, fromIndex);
      slotAvailable = true;
      for (let i = firstAvailableMinute; i < firstAvailableMinute + +timePerSlot; i++) {
        if (timeSlotArray[i] === 0) {
          slotAvailable = false;
          fromIndex = i;
          break;
        }
      }
      if (slotAvailable) {
        return firstAvailableMinute;
      }
    }
    return -1;
  }

  canTimeSlotBeSet(from: Date, to: Date, toCheck: Date, timePerSlot: number) {
    return !this.isDateBetweenDates(from, to, toCheck) && !this.isDateBetweenDates(from, to, moment(toCheck).add(timePerSlot, "m").toDate());
  }

  isAfterDate(reference: Date, toCheck: Date) {
    return toCheck.getTime() > reference.getTime();
  }

  isDateBetweenDates(from: Date, to: Date, toCheck: Date) {
    if (!from || !to) {
      return false;
    }
    const isBetween = toCheck.getTime() > from.getTime() && toCheck.getTime() < to.getTime();
    return isBetween;
  }

  createDateOfString(dateString: string): Date {
    if (dateString && dateString !== "") {
      const date = new Date();
      const hours = +dateString.substring(0, dateString.indexOf(":"));
      const minutes = +dateString.substring(dateString.indexOf(":") + 1);
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      return date;
    } else {
      return null;
    }
  }

  getTimeStringFromDate(date: Date): string {
    const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
    const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    return hours + ":" + minutes;
  }

  getDiffInMinutes(from: string, to: string): number {
    const dateFrom = this.createDateOfString(from);
    const dateTo = this.createDateOfString(to);
    const diffMs = dateTo.getTime() - dateFrom.getTime();
    const minutes = Math.round(diffMs / 1000 / 60);
    return minutes;
  }

  sortByTimeFrom(a, b) {
    const timeFromA = a.get("timeFrom").value;
    const timeFromB = b.get("timeFrom").value;
    const dateA = new Date();
    dateA.setHours(+timeFromA.substring(0, timeFromA.indexOf(":")));
    dateA.setMinutes(+timeFromA.substring(timeFromA.indexOf(":") + 1));
    const dateB = new Date();
    dateB.setHours(+timeFromB.substring(0, timeFromB.indexOf(":")));
    dateB.setMinutes(+timeFromB.substring(timeFromB.indexOf(":") + 1));

    if (dateA.getTime() < dateB.getTime()) {
      return -1;
    } else if (dateA.getTime() > dateB.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }

  unselectCheckbox(event: any, checkbox: string) {
    // only unselect checkbox if the other value was true
    if (!event.checked) {
      return;
    } else {
      if (checkbox === "onlineSchedule") {
        this.eventInquiryHealthscreening.get("onlineSchedule").setValue(!event.checked);
        this.eventInquiryHealthscreening.get("onlineModule").setValue(!event.checked);
      } else if (checkbox === "hybrid") {
        this.eventInquiryHealthscreening.get("hybrid").setValue(!event.checked);
      } else {
        return;
      }
    }
  }

  copyScheduleListLink() {
    const moduleId = this.eventInquiryHealthscreening.get("scheduleUuid").value;
    const link = window.location.protocol + "//" + window.location.host + "/schedule-voting?uuid=" + moduleId;
    const copyButton = document.createElement("textarea");

    copyButton.style.position = "fixed";
    copyButton.style.left = "0";
    copyButton.style.top = "0";
    copyButton.style.opacity = "0";
    copyButton.value = link;
    document.body.appendChild(copyButton);
    copyButton.focus();
    copyButton.select();
    document.execCommand("copy");
    document.body.removeChild(copyButton);
  }

  openDownloadExcelConfirmation() {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadParticipantsListConfirmationText");
    if (this.translateService.currentLang === "en") {
      this.filename = "participantsList.xlsx";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "TeilnehmerListe.xlsx";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.downloadParticipantsListAsExcel(this.filename));
  }

  openDownloadWaitingListExcelConfirmation() {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadWaitingListConfirmationText");
    if (this.translateService.currentLang === "en") {
      this.filename = "waitinglist.xlsx";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "Warteliste.xlsx";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.downloadWaitingListAsExcel(this.filename));
  }

  downloadParticipantsListAsExcel(filename: string) {
    const moduleId = this.eventInquiryHealthscreening.get("id").value;
    this.scheduleService.downloadExcel(moduleId).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  downloadWaitingListAsExcel(filename: string) {
    const moduleId = this.eventInquiryHealthscreening.get("id").value;
    this.waitingListService.downloadExcel(moduleId).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  addSlotBefore() {
    const moduleId = this.eventInquiryHealthscreening.get("id").value;
    const title = this.translateService.instant("addScheduleEntryTitle");
    const text = this.translateService.instant("addScheduleEntryBefore");
    this.dialogService.openConfirmationDialog(title, text, () =>
      this.scheduleService.addScheduleEntryBefore(moduleId).subscribe((res) => {
        this.bottomSheet.dismiss();
      })
    );
  }

  addSlotAfter() {
    const moduleId = this.eventInquiryHealthscreening.get("id").value;
    const title = this.translateService.instant("addScheduleEntryTitle");
    const text = this.translateService.instant("addScheduleEntryAfter");
    this.dialogService.openConfirmationDialog(title, text, () =>
      this.scheduleService.addScheduleEntryAfter(moduleId).subscribe((res) => {
        this.bottomSheet.dismiss();
      })
    );
  }

  get moduleType() {
    return this.eventInquiryHealthscreening.value.moduleType;
  }

  get isOnline(): boolean {
    var onlineModuleRegExp = new RegExp(this.eventInquiryHealthscreening.value.onlineModule);
    return onlineModuleRegExp.test("true");
  }

  get isOnsite(): boolean {
    return !this.isOnline;
  }

  clearTime(event: any) {
    if (!event.checked) {
      this.eventInquiryHealthscreening.get("presentationTimeFrom").setValue(null);
      this.eventInquiryHealthscreening.get("presentationTimeTo").setValue(null);
    }
  }
}
