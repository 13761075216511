<div class="card">
  <div class="card-body">
    <form [formGroup]="eventForm">
      <div class="form-group">
        <event-timing [eventForm]="eventForm"></event-timing>
        <event-customer-data [eventForm]="eventForm" [buid]="buid" [showPrintButtons]="showPrintButtons" (customerSelected)="module.updateModuleOptions($event, true)"></event-customer-data>
        <event-location [eventForm]="eventForm"></event-location>
        <gib-module-selection [eventForm]="eventForm" [modulesControl]="eventForm.get('eventHealthScreenings')" #module></gib-module-selection>
        <event-further-information [eventForm]="eventForm"></event-further-information>
        <event-comment [formType]="'booking'" [eventForm]="eventForm"></event-comment>
        <div class="row" *ngIf="chatElements && chatElements.length > 0">
          <div class="col-sm-12">
            <gib-chat [chatElements]="chatElements"></gib-chat>
          </div>
        </div>
        <div *ngIf="internalNoteForm && (this.userRole === 'gib_admin' || this.userRole === 'gib_director' || this.userRole === 'gib_employee')">
          <hr />
          <internal-note [internalNoteForm]="internalNoteForm"></internal-note>
        </div>
        <event-signoff [eventForm]="eventForm"></event-signoff>
      </div>
    </form>
  </div>
</div>
