<div class="controls d-flex justify-content-between align-items-center" style="height: 50px">
  <button type="button" (click)="moveDate(-1)">
    <img src="/assets/images/chevron-left.svg" alt="" />
  </button>

  <div class="d-flex align-items-center">
    <div>
      <mat-form-field>
        <input hidden matInput [matDatepicker]="dp" [formControl]="dateControl" />
        <p>{{ dateControl.value | date : "MMMM, YYYY" : "" : "de" }}</p>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"> </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="ml-3">
      <span>{{ ("totalCosts" | translate) + " " + this.localizedMonthYear + ": " + this.formattedCost + " € Brutto" }}</span>
      <button
        *ngIf="type === 'pendingInvoices' && this.userRole === 'gib_tkcontrolling'"
        class="btn btn-primary ml-3"
        style="width: auto !important; height: auto !important"
        (click)="openApprovePayment()"
      >
        {{ "accApprove" | translate }}
      </button>
      <button
        *ngIf="type === 'pendingInvoices' && this.userRole !== 'gib_tkcontrolling'"
        class="btn btn-primary ml-3"
        style="width: auto !important; height: auto !important"
        (click)="openSendPayment()"
      >
        {{ "accSend" | translate }}
      </button>
    </div>
  </div>

  <button type="button" (click)="moveDate(+1)">
    <img src="/assets/images/chevron-right.svg" alt="" />
  </button>
</div>
<section class="mat-elevation-z8" [class.tk-uz-table-container-gib]="userRole !== 'gib_tkcontrolling'" [class.tk-uz-table-container-tk]="userRole === 'gib_tkcontrolling'" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="eventDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accEventDate" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.eventDate | date }}</td>
    </ng-container>
    <ng-container matColumnDef="orderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accOrderNumber" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.orderNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="deliveryType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accDeliveryType" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.deliveryType | translate }}</td>
    </ng-container>
    <ng-container matColumnDef="moduleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accModuleName" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.moduleName }}</td>
    </ng-container>
    <ng-container matColumnDef="moduleType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accModuleType" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.moduleType | translate }}</td>
    </ng-container>
    <ng-container matColumnDef="topic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "topic" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.topic | translate }}</td>
    </ng-container>
    <ng-container matColumnDef="durationInMilliseconds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "durationInMilliseconds" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.durationInMilliseconds / 3600000 | number : "1.1-2" }}</td>
    </ng-container>
    <ng-container matColumnDef="targetGroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "targetGroup" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ mapTargetGroup(row.targetGroup) }}</td>
    </ng-container>
    <ng-container matColumnDef="corporateCustomerCompanyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accCorporateCustomerCompanyName" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.corporateCustomerCompanyName }}</td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accLocation" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.location }}</td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "region" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.region }}</td>
    </ng-container>
    <ng-container matColumnDef="teamnumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "teamnumber" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.teamnumber }}</td>
    </ng-container>
    <ng-container matColumnDef="presentationEnglish">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "presentationEnglish" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.presentationEnglish ? " X " : " - " }}</td>
    </ng-container>
    <ng-container matColumnDef="anonymousEvaluation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "anonymousEvaluation" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.anonymousEvaluation ? " X " : " - " }}</td>
    </ng-container>
    <ng-container matColumnDef="consumableMaterials">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accConsumableMaterials" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.consumableMaterials ? " X " : " - " }}</td>
    </ng-container>
    <ng-container matColumnDef="costPercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "costPercentage" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.costPercentage + "%" }}</td>
    </ng-container>
    <ng-container matColumnDef="costTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "costTotal" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.costTotal | number : "1.2-2" }}</td>
    </ng-container>
    <ng-container matColumnDef="accountingStatus" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accountingStatus" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.accountingStatus | translate }} <br />{{ row.accountingStatusChange != null ? " " + ("atTranslate" | translate) + " " + row.accountingStatusChange : "" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="edit" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <span class="material-icons" style="cursor: pointer" (click)="onEdit(row)"> description </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="release" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "accActions" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <button class="btn" [class.btn-primary-yellow]="row.paymentStatus !== 'PAYMENT_DRAFT'" [class.btn-primary]="row.paymentStatus === 'PAYMENT_DRAFT'" (click)="onRelease(row)">
          {{ "accRelease" | translate }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="paymentStatus" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "paymentStatus" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span [innerHTML]="getTranslatedPaymentStatus(row)"></span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background: #202e3f"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
  </table>
  <mat-paginator class="sticky-paginator" [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</section>
