<!-- <div class="wrapper"> -->
<mat-tab-group *ngIf="userRole !== 'gib_customer' && form" class="mr-3 ml-3">
  <mat-tab label="{{ form.get('moduleName').value }}">
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6 center">
        <div class="img-wrapper" *ngIf="imageUrls && imageUrls.length == 1">
          <!-- <img src="/assets/images/homepage/faq.jpg" /> -->
          <img src="{{ imageUrls[0] }}" />
        </div>
        <div class="slideshow-wrapper" *ngIf="imageUrls && imageUrls.length > 1">
          <gib-slideshow [imageUrls]="imageUrls"></gib-slideshow>
          <!-- <slideshow [minHeight]="'350px'" *ngIf="imageUrls && imageUrls.length > 1" dotColor="var(--secondary)" showDots="true" [imageUrls]="imageUrls"></slideshow> -->
        </div>
      </div>
      <div class="col-sm-3"></div>
      <div class="col-sm-12 mt-3" *ngIf="form">
        <mat-card>
          <mat-card-content>
            <edit-health-screening-form [healthScreeningForm]="form" [role]="userRole" [step]="-1"></edit-health-screening-form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'videos' | translate }}">
    <div class="row">
      <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" *ngIf="userRole !== 'gib_customer' && trainingVideos && trainingVideos.length > 0">
        <h5>{{ "HEALTHSCREENING_VIDEO_TRAINING" | translate }}</h5>
        <div class="col-sm-10">
          <gib-video-handler [videos]="trainingVideos" (fileDeleted)="loadTrainingVideos()" (videoUrlsGenerated)="setTrainingVideoUrls($event)"></gib-video-handler>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'trainerDocuments' | translate }}" *ngIf="userRole === 'gib_trainer'">
    <div class="row" *ngIf="documents && documents.length !== 0">
      <div class="col-sm-12 center">
        <h5>{{ "documents" | translate }}</h5>
        <div class="col-sm-12 center">
          <ul class="list-group">
            <li *ngFor="let document of documents" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              {{ document.title }} - {{ document.fileName }}
              <span>
                <i class="material-icons mr-4" (click)="onDownload(document)" style="cursor: pointer">get_app</i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div *ngIf="userRole === 'gib_customer' && form">
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-outline" (click)="goBackToOverview()">{{ "back" | translate }}</button>
  </div>
  <div class="d-flex justify-content-between">
    <h2>{{ healthscreening.moduleName }}</h2>
  </div>
  <customer-healthscreening-info-form [healthscreeningForm]="form" (addedToCartEmit)="addModuleToCart()" [imageUrls]="imageUrls" [videoUrls]="videoUrls"> </customer-healthscreening-info-form>
</div>
