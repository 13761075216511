import { FeedbackReport } from './../../../../services/report.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-tile',
  templateUrl: './report-tile.component.html',
  styleUrls: ['./report-tile.component.scss']
})
export class ReportTileComponent implements OnInit {

  @Input() url: string;
  @Input() title: string;
  @Input() image: string;
  
  constructor(private router: Router) { }

  ngOnInit() {}

  routeToReport() {
    let linkTo = '/reports-page/' + this.url;
    this.router.navigate([linkTo]);
  }
}
