<div class="card trainer-feedback-form">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ 'feedbackHeader' | translate }}</h1>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-10">
            <gib-input [placeholder]="'companyname'" [control]="eventForm.get('customerCompanyName')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input [placeholder]="'firstname'" [control]="eventForm.get('customerContactFirstname')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input [placeholder]="'lastname'" [control]="eventForm.get('customerContactLastname')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="eventForm.get('customerPhone')"></gib-input>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-4">
            <gib-input [placeholder]="'orderNumber'" [control]="eventForm.get('orderNumber')"></gib-input>
          </div>
          <div class="col-sm-8"></div>
          <div class="col-sm-4">
            <gib-datepicker [placeholder]="'eventDate'" [control]="eventForm.get('eventDate')"></gib-datepicker>
          </div>
          <div class="col-sm-4">
            <gib-timepicker [placeholder]="'from'" [control]="eventForm.get('startTime')"></gib-timepicker>
          </div>
          <div class="col-sm-4">
            <gib-timepicker [placeholder]="'to'" [control]="eventForm.get('endTime')"></gib-timepicker>
          </div>
          <div class="col-sm-12">
            <gib-input [placeholder]="'corporateCustomerCompanyName'" [control]="eventForm.get('corporateCustomerCompanyName')"></gib-input>
          </div>
          <div class="col-sm-12">
            <gib-input [placeholder]="'trainer'" [control]="eventForm.get('eventHealthScreenings').get(eihsIndex).get('trainer').get('0').get('fullName')"></gib-input>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'orgaGib'" [control]="eventFeedbackTrainerForm.get('orgaGib')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'orgaLocation'" [control]="eventFeedbackTrainerForm.get('orgaLocation')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'orgaCustomer'" [control]="eventFeedbackTrainerForm.get('orgaCustomer')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'orgaComment'" [control]="eventFeedbackTrainerForm.get('orgaComment')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-radio-button-group-vertical [label]="'measuringDeviceBoolean'" [control]="eventFeedbackTrainerForm.get('measuringDevice')" [valueLabels]="feedbackBooleans"></gib-radio-button-group-vertical>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'measuringDeviceComment'" [control]="eventFeedbackTrainerForm.get('measuringDeviceComment')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <gib-input [placeholder]="'generalComment'" [control]="eventFeedbackTrainerForm.get('generalComment')"></gib-input>
      </div>
      <div class="col-sm-1"></div>
    </div>
  </div>
</div>
<div *ngIf="showButton" class="row">
  <div class="col-sm-12 center">
    <button class="btn btn-primary" (click)="submitTrainerFeedbackClicked()">
      <i class="material-icons">feedback</i>
      {{'submitFeedback' | translate}}
    </button>
  </div>
</div>
