import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GibDialogService } from 'src/app/components/dialogs/gib-dialog.service';
import { FormHelper } from 'src/app/helper/form.helper';
import { EventService } from 'src/app/services/event.service';
import { EventEmitterService } from 'src/app/services/eventEmitterService';
import { HealthInsurance, HealthInsuranceService } from 'src/app/services/health-insurance.service';

@Component({
  selector: 'add-healthinsurance',
  templateUrl: './add-healthinsurance.component.html',
  styleUrls: ['./add-healthinsurance.component.sass']
})
export class AddHealthinsuranceComponent implements OnInit {

  _healthInsurance: HealthInsurance;
  filename: string;
  downloadUrl: string;
  hasLogo: boolean;

  @Input() set healthInsurance(hi: HealthInsurance) {
    this._healthInsurance = hi;

    this.form = this.initFormGroup();
  }

  get healthInsurance(): HealthInsurance {
    return this._healthInsurance;
  }

  @Output() healthInsuranceAdded = new EventEmitter<string>();

  form: UntypedFormGroup;


  constructor(private dialogService: GibDialogService, private eventEmitterService: EventEmitterService,
    private formHelper: FormHelper, private healthInsuranceService: HealthInsuranceService,
    private translateService: TranslateService, private eventService: EventService) { }

  ngOnInit() {
  }

  initFormGroup(): UntypedFormGroup {
    const hiForm = this.healthInsuranceService.mapHealthInsuranceToForm(this._healthInsurance);

    if (this._healthInsurance.companynameAlias) {
      hiForm.get('companynameAlias').disable();
      /*hiForm.get('privacyStatementDe').setValidators(Validators.required);
      hiForm.get('privacyStatementEn').setValidators(Validators.required);
      hiForm.get('privacyStatement3rdPartyDe').setValidators(Validators.required);
      hiForm.get('privacyStatement3rdPartyEn').setValidators(Validators.required);*/
    } else {
      hiForm.get('companynameAlias').setValidators(Validators.required);
    }
    return hiForm;
  }

  addHealthInsuranceClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, 'HEALTHINSURANCE_ADDED_ERROR_TITLE', () => this.createHealthInsurance());
  }

  createHealthInsurance() {
    const hi = this.healthInsuranceService.mapFormToHealthInsurance(this.form);
    this.healthInsuranceService.exists(hi.companynameAlias).subscribe(res => {
      if (res.body) {
        const text = this.translateService.instant('HEALTHINSURANCE_ADDED_ALREADY_EXISTS', { companynameAlias: hi.companynameAlias });
        this.dialogService.openErrorDialog('HEALTHINSURANCE_ADDED_ERROR_TITLE', [text]);
      } else {
        this.healthInsuranceService.createupdateFaq(hi).subscribe(res => {
          this.openSuccessFullAddedDialog(res.body);
          this.healthInsuranceAdded.emit(null);
          this.eventEmitterService.emitEvent();
        });
      }
    });
  }

  updateHealthInsuranceClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, 'HEALTHINSURANCE_EDIT_ERROR_TITLE', () => this.updateHealthInsurance());
  }

  updateHealthInsurance() {
    const hi = this.healthInsuranceService.mapFormToHealthInsurance(this.form);
    this.healthInsuranceService.createupdateFaq(hi).subscribe(res => {
      this.openSuccessFullEditDialog(res.body);
      this.healthInsuranceAdded.emit(null);
      this.eventEmitterService.emitEvent();
    });
  }

  cancel() {
    // do nothing, just emit with null to close the panel
    this.healthInsuranceAdded.emit(null);
  }

  private openSuccessFullAddedDialog(hi: HealthInsurance): void {
    const title = this.translateService.instant('HEALTHINSURANCE_ADDED_SUCCESS_TITLE');
    const text = this.translateService.instant('HEALTHINSURANCE_ADDED_SUCCESS_TEXT', { companynameAlias: hi.companynameAlias });
    this.dialogService.openDialog(title, text, "success");
  }

  private openSuccessFullEditDialog(hi: HealthInsurance): void {
    const title = this.translateService.instant('HEALTHINSURANCE_EDIT_SUCCESS_TITLE');
    const text = this.translateService.instant('HEALTHINSURANCE_EDIT_SUCCESS_TEXT', { companynameAlias: hi.companynameAlias });
    this.dialogService.openDialog(title, text, "success");
  }

  onDocumentEvent() {

  }

  onHasFilesEvent(hasFiles: boolean) {
    this.hasLogo = hasFiles;
  }

  openDownloadPdfConfirmation() {
    const title = this.translateService.instant('downloadPdfTitle');
    const text = this.translateService.instant('downloadPdfText');
    if (this.translateService.currentLang === 'en') {
      this.filename = 'eventinformation.pdf';
    } else if (this.translateService.currentLang === 'de') {
      this.filename = 'veranstaltungsinformation.pdf';
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.printFormToPdf(this.filename));
  }

  printFormToPdf(filename: string) {
    this.eventService.getSignOffDummy(this._healthInsurance.companynameAlias).subscribe(res => {
      const blob = new Blob([res], { type: ' application/pdf' });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

}
