import { NgModule } from "@angular/core";
import { AppRoutingModule } from "src/app/app-routing.module";
import { ComponentsModule } from "../../components/components.module";
import { UtilsModule } from "../../utils/utils.module";
import { CustomerProfileComponent } from "./components/customer-profile/customer-profile.component";
import { EmployeeProfileComponent } from "./components/employee-profile/employee-profile.component";
import { ProfilePageComponent } from "./profile-page.component";

@NgModule({
  declarations: [ProfilePageComponent, EmployeeProfileComponent, CustomerProfileComponent],
  imports: [ComponentsModule, UtilsModule, AppRoutingModule],
  exports: [ProfilePageComponent],
})
export class ProfileModule {}
