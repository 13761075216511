import { Component, ViewChild } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { MatSelect } from "@angular/material/select";
import { CalendarEntryType } from "src/app/services/calendar.service";
import { EventOverviewService } from "src/app/services/event-overview.service";
import { take } from "rxjs/operators";
import { GibDialogService } from "../dialogs/gib-dialog.service";
import { EmployeeService } from "src/app/services/employee.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "gib-grid-trainer-select",
  templateUrl: "./gib-grid-trainer-select.html",
  styleUrls: ["./gib-grid-trainer-select.scss"],
})
export class GibGridTrainerSelectComponent implements ICellEditorAngularComp {
  @ViewChild("select") select: MatSelect;
  placeholder = "";
  initialValue: string;

  options = [];
  eventInquiryHealthscreeningId: number = null;

  value: string;
  constructor(
    private employeeService: EmployeeService,
    private dialogService: GibDialogService,
    private translate: TranslateService,
    private eventOverviewService: EventOverviewService
  ) {}

  ngOnInit() {}

  agInit(params: any): void {
    this.placeholder = params.placeholder;
    this.value = params.value;
    this.loadTrainer(params.data.eventInquiryHealthscreeningId);
    this.eventInquiryHealthscreeningId = params.data.eventInquiryHealthscreeningId;
  }

  getValue() {
    return this.value;
  }

  loadTrainer(id: number) {
    this.employeeService
      .getTrainerForHealthScreening(id)
      .pipe(take(1))
      .subscribe((res) => {
        for (const emp of res.body) {
          this.options.push({
            label: emp.employeeFullname,
            value: emp.employeeId,
            availability: emp.availability,
          });
        }
        this.focusSelect();
      });
  }

  selectedTrainer(option: any) {
    if (this.eventOverviewService.initialTrainerId.get(this.eventInquiryHealthscreeningId) === option.value) {
      this.eventOverviewService.trainerChanged.set(this.eventInquiryHealthscreeningId, false);
    } else {
      this.eventOverviewService.trainerChanged.set(this.eventInquiryHealthscreeningId, true);
    }
  }

  openForceTrainerAssignDialog(trainer) {
    let title = this.translate.instant('warning')
    let text = this.translate.instant('dualAssignTrainer')
    this.dialogService.openConfirmationDialog(title, text, () => {
      this.selectedTrainer(trainer)
      this.value = trainer.value
      this.select.close()
    })
  }

  focusSelect() {
    window.setTimeout(() => {
      this.select?.focus();
      this.eventOverviewService.trainerChanged.set(this.eventInquiryHealthscreeningId, null);
      this.eventOverviewService.initialTrainerId.set(this.eventInquiryHealthscreeningId, this.select.value);
    }, 0);
  }

  isPopup(): boolean {
    return true;
  }
}
