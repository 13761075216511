import { Action } from "@ngrx/store";
import { EventOverviewDTO, EventOverviewUpdateDTO } from "../../services/event-overview.service";

// load active events
export const LOAD_ACTIVE_EVENTS = "[Events] Load Active Events";
export const LOAD_ACTIVE_EVENTS_SUCCESS = "[Events] Load Active Events Success";
export const LOAD_ACTIVE_EVENTS_FAIL = "[Events] Load Active Events Fail";

export class LoadActiveEvents implements Action {
  readonly type = LOAD_ACTIVE_EVENTS;
  constructor(public payload: { from: string; to: string }) {}
}

export class LoadActiveEventsSuccess implements Action {
  readonly type = LOAD_ACTIVE_EVENTS_SUCCESS;
  constructor(public payload: EventOverviewDTO[], public period: string) {}
}

export class LoadActiveEventsFail implements Action {
  readonly type = LOAD_ACTIVE_EVENTS_FAIL;
  constructor(public payload: any) {}
}

// remove active events
export const REMOVE_ACTIVE_EVENTS = "[Events] Remove Active Events";
export const CLEAR_ACTIVE_EVENTS = "[Events] Clear Active Events";

export class RemoveActiveEvents implements Action {
  readonly type = REMOVE_ACTIVE_EVENTS;
  constructor(public period: string) {}
}

export class ClearActiveEvents implements Action {
  readonly type = CLEAR_ACTIVE_EVENTS;
}

// update event
export const UPDATE_EVENT = "[Events] Update Event";
export const UPDATE_EVENT_FAIL = "[Events] Update Event Fail";
export const UPDATE_EVENT_SUCCESS = "[Events] Update Event Success";

export class UpdateEvent implements Action {
  readonly type = UPDATE_EVENT;
  constructor(public payload: EventOverviewUpdateDTO) {}
}

export class UpdateEventSuccess implements Action {
  readonly type = UPDATE_EVENT_SUCCESS;
  constructor(public payload: EventOverviewDTO) {}
}

export class UpdateEventFail implements Action {
  readonly type = UPDATE_EVENT_FAIL;
  constructor(public payload: any) {}
}

export type ActiveEventActions = LoadActiveEvents | LoadActiveEventsSuccess | LoadActiveEventsFail | RemoveActiveEvents | ClearActiveEvents | UpdateEvent | UpdateEventSuccess | UpdateEventFail;
