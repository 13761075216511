import { FeedbackReport } from './../../../services/report.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reports-overview-page',
  templateUrl: './reports-overview-page.component.html',
  styleUrls: ['./reports-overview-page.component.scss']
})
export class ReportsOverviewPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
