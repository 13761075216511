<form id="customer_profile" *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <gib-h5 [text]="'masterData'" [disabled]="form.get('firstname').disabled"></gib-h5>
      <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
      <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
      <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
      <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
      <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
      <gib-input [placeholder]="'phonenumber'" [control]="form.get('phonenumber')"></gib-input>
      <gib-input [placeholder]="'mobilenumber'" [control]="form.get('mobilenumber')"></gib-input>
      <gib-input [placeholder]="'companyname'" [control]="form.get('companyname')"></gib-input>
      <hr *ngIf="companynameAlias === 'TK'" />
      <gib-h5 *ngIf="companynameAlias === 'TK'" [text]="'regions'" [disabled]="form.get('firstname').disabled"></gib-h5>
      <gib-input *ngIf="companynameAlias === 'TK'" matInput [placeholder]="'region'" [control]="form.get('region')"></gib-input>
      <gib-input *ngIf="companynameAlias === 'TK'" matInput [placeholder]="'teamNr'" [control]="form.get('teamnumber')"></gib-input>
    </div>
    <div class="col-sm-5">
      <gib-h5 [text]="'ADRESS'" [disabled]="form.get('firstname').disabled"></gib-h5>
      <gib-input [placeholder]="'street'" [control]="form.get('addresses').get('0').get('street')"></gib-input>
      <gib-input [placeholder]="'streetno'" [control]="form.get('addresses').get('0').get('number')"></gib-input>
      <gib-input [placeholder]="'zip'" [control]="form.get('addresses').get('0').get('zip')"></gib-input>
      <gib-input [placeholder]="'city'" [control]="form.get('addresses').get('0').get('city')"></gib-input>
      <hr *ngIf="companynameAlias === 'TK'" />
      <gib-h5 *ngIf="companynameAlias === 'TK'" [text]="'CONTACT_TEAMLEAD'" [disabled]="form.get('firstname').disabled"></gib-h5>
      <gib-input *ngIf="companynameAlias === 'TK'" [placeholder]="'firstname'" [control]="form.get('teamleadFirstname')"></gib-input>
      <gib-input *ngIf="companynameAlias === 'TK'" [placeholder]="'lastname'" [control]="form.get('teamleadLastname')"></gib-input>
      <gib-input *ngIf="companynameAlias === 'TK'" [placeholder]="'email'" [control]="form.get('teamleadEmail')"></gib-input>
      <gib-input *ngIf="companynameAlias === 'TK'" [placeholder]="'phonenumber'" [control]="form.get('teamleadPhonenumber')"></gib-input>
      <gib-fieldset [title]="'bookingCopyRecepientMail'" [disabled]="form.get('bookingCopyRecepientMail').disabled">
        <gib-multi-input [placeholder]="'email'" [control]="form.get('bookingCopyRecepientMail')" [tempControl]="form.get('bookingCopyRecepientMailTemp')"></gib-multi-input>
      </gib-fieldset>
    </div>
    <div class="col-sm-1">
      <i id="edit_icon" class="material-icons edit-icon" (click)="startEditMode()" *ngIf="!editMode">edit</i>
    </div>
  </div>
</form>
