import { Subscription } from "rxjs";
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelper } from "../../helper/form.helper";

@Component({
  selector: "gib-input",
  templateUrl: "./gib-input.component.html",
  styleUrls: ["./gib-input.component.scss"],
})
export class GibInputComponent implements OnInit, OnDestroy, OnChanges {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  noValidNumberErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() type = "text";
  @Input() disabled;

  // isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    // this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== "") {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
      if (this.hint !== "") {
        this.hint = this.translateService.instant(hintInputValue);
      }
    });

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.noValidNumberErrorMessage = this.formHelper.createNoValidNumberErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {
    if (this.disabled) {
      this.inputControl.disable();
    }
  }

  trim() {
    this.formHelper.trimWhitespace(this.control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      const { currentValue, previousValue } = changes.disabled;
      if (previousValue !== null) {
        if (currentValue) {
          this.inputControl.disable();
        } else {
          this.inputControl.enable();
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
