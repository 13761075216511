import { environment } from "./../../../../../../environments/environment";
import { KeycloakService } from "keycloak-angular";
import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { SelectOptions } from "../../../../../utils/select-options";
import { CorporateCustomerDTO, EventService } from "../../../../../services/event.service";
import { ValueLabel } from "../../../../../components/value-label";

@Component({
  selector: "event-location",
  templateUrl: "./event-location.component.html",
  styleUrls: ["./event-location.component.scss"],
})
export class EventLocationComponent implements OnInit {
  salutationOptions = SelectOptions.salutationOptions;

  corporateCustomerHistory: CorporateCustomerDTO[] = [];

  corporateCustomerValueLabels: ValueLabel[] = [];

  hideGibSuggestField: boolean = false;

  userRole: string;

  @Input() eventForm: UntypedFormGroup;

  constructor(private eventService: EventService, private keycloakService: KeycloakService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.checkGibSuggest();
    this.handleCCSearch();

    this.keycloakService.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn && this.userRole !== "gib_trainer" && this.userRole !== "gib_afg") {
        this.eventService.getCorporateCustomerHistory().subscribe((res) => {
          this.corporateCustomerHistory = res.body;
          this.createValueLabelsForSuggestSelect();
        });
      }
    });
  }

  checkGibSuggest() {
    const URL = window.location.href;
    if (URL.includes("st_mail_confirmation_reservation_ag")) {
      this.hideGibSuggestField = true;
    } else if (URL.includes("trainer")) {
      this.hideGibSuggestField = true;
    } else {
      this.hideGibSuggestField = false;
    }
  }

  handleCCSearch() {
    this.eventForm.addControl("ccSearch", new UntypedFormControl(""));
    this.eventForm.get("ccSearch").valueChanges.subscribe((res) => {
      if (res) {
        const cc = this.findCorporateCustomerDTO(res);
        if (cc) {
          this.eventForm.get("corporateCustomerContactSalutation").setValue(cc.salutation);
          this.eventForm.get("corporateCustomerContactFirstname").setValue(cc.firstname);
          this.eventForm.get("corporateCustomerContactLastname").setValue(cc.lastname);
          this.eventForm.get("corporateCustomerContactMail").setValue(cc.email);
          this.eventForm.get("corporateCustomerContactPhone").setValue(cc.phone);
          this.eventForm.get("corporateCustomerCompanyName").setValue(cc.companyName);
          this.eventForm.get("eventlocation").get("street").setValue(cc.street);
          this.eventForm.get("eventlocation").get("number").setValue(cc.number);
          this.eventForm.get("eventlocation").get("zip").setValue(cc.zip);
          this.eventForm.get("eventlocation").get("city").setValue(cc.city);
        }
      } else {
        this.eventForm.get("corporateCustomerContactSalutation").setValue(null);
        this.eventForm.get("corporateCustomerContactFirstname").setValue("");
        this.eventForm.get("corporateCustomerContactLastname").setValue("");
        this.eventForm.get("corporateCustomerContactMail").setValue("");
        this.eventForm.get("corporateCustomerContactPhone").setValue("");
        this.eventForm.get("corporateCustomerCompanyName").setValue("");
        this.eventForm.get("eventlocation").get("street").setValue("");
        this.eventForm.get("eventlocation").get("number").setValue("");
        this.eventForm.get("eventlocation").get("zip").setValue("");
        this.eventForm.get("eventlocation").get("city").setValue("");
      }
    });
  }

  createValueLabelsForSuggestSelect() {
    for (const cc of this.corporateCustomerHistory) {
      const label = cc.companyName + " (" + cc.lastname + ", " + cc.firstname + ")";
      this.corporateCustomerValueLabels.push({ label, value: "" + cc.eventId });
    }
  }

  findCorporateCustomerDTO(eventId: any) {
    for (const cc of this.corporateCustomerHistory) {
      if (cc.eventId === parseInt(eventId, 10)) {
        return cc;
      }
    }
    return null;
  }
}
