import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType } from "@angular/common/http";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

export declare type FileType =
  | "DRIVING_LICENSE"
  | "PROFILE_PICTURE"
  | "HEALTHSCREENING_PICTURE"
  | "HEALTHSCREENING_VIDEO_TRAINING"
  | "HEALTHSCREENING_VIDEO_PRESENTATION"
  | "TRAINER_DOCUMENT"
  | "HEALTHSCREENING_ATTACHMENT"
  | "HEALTHSCREENING_ATTACHMENT_ONLINE"
  | "HEALTHSCREENING_ATTACHMENT_ONSITE"
  | "TRAINER_PROFILE_DOCUMENT"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_EN"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_DE"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONLINE"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONLINE"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_DE_ONSITE"
  | "HEALTHSCREENING_CUSTOMER_DOCUMENT_EN_ONSITE"
  | "HEALTHSCREENING_TRAINER_DOCUMENT"
  | "FAQ_DOCUMENT"
  | "CUSTOMER_SIGNATURE_DOCUMENT"
  | "ANONYMOUS_EVALUATION";
declare type FileStatus = "APPROVED" | "REJECTED" | "PENDING" | "NOT_REQUIRED" | "EXPIRED";

export class FileMeta {
  id: number;
  title: string;
  notice: string;
  created: Date;
  fileName: string;
  mimetype: string;
  fileType: string;
  keycloakUser: string;
  size: number;
  status: FileStatus;
}

export class FileMetaTaskDTO {
  id: number;
  created: Date;
  fileName: string;
  fileType: FileType;
  keycloak_user: string;
  keycloak_fullname: string;
  size: number;
  status: FileStatus;
  taskId: string;
  instanceId: string;
}

@Injectable()
export class FileService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  // REST

  public upload(data, type: FileType, healthScreeningId: number, title: string = "GiB") {
    let uploadURL = this.envService.backendUrl + "/files/data/create?title=" + title + "&file_type=" + type;
    if (healthScreeningId) {
      uploadURL += "&healthscreening=" + healthScreeningId;
    }

    return this.http
      .post<any>(uploadURL, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", message: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  public uploadForOtherUser(data, userId: string, type: FileType, healthScreeningId: number, title: string = "GiB") {
    let uploadURL = this.envService.backendUrl + "/files/data/createForUser?userId=" + userId + "&title=" + title + "&file_type=" + type;
    if (healthScreeningId) {
      uploadURL += "&healthscreening=" + healthScreeningId;
    }

    return this.http
      .post<any>(uploadURL, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", message: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  public delete(id: number) {
    // TODO: check if the process is also terminated when there is a running one
    return this.http.delete(this.envService.backendUrl + "/files/meta/" + id, this.config);
  }

  public getFileList(status: FileStatus): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/files/meta?status=" + status, this.config);
  }

  public getFileData(id: number) {
    return this.http.get(this.envService.backendUrl + "/files/data/" + id, { responseType: "blob" });
  }

  public getFileDataDecrypted(id: number) {
    return this.http.get(this.envService.backendUrl + "/files/data/decrypted/" + id, { responseType: "blob" });
  }

  public getFileMetaByUserAndType(id: number, type: FileType) {
    return this.http.get(this.envService.backendUrl + "/files/meta/byUserAndType/?user=" + id + "&type=" + type);
  }

  public getFileMetasByUserAndType(id: string, type: FileType) {
    return this.http.get<FileMeta[]>(this.envService.backendUrl + "/files/meta/byUserAndTypeList?user=" + id + "&type=" + type);
  }

  public getFileMetaByHealthscreening(id: number, type: FileType): Observable<FileMeta[]> {
    return this.http.get<FileMeta[]>(this.envService.backendUrl + "/files/meta/byHealthscreening?healthscreening=" + id + "&type=" + type);
  }

  public getFileMetaByFaq(id: number, type: FileType): Observable<FileMeta[]> {
    return this.http.get<FileMeta[]>(this.envService.backendUrl + "/files/meta/byFaq?faq=" + id + "&type=" + type);
  }

  // Helper functions
  public createRessourceUrls(files: FileMeta[]): string[] {
    const ressourceUrls = [];
    if (files && files.length > 0) {
      for (const filemeta of files) {
        ressourceUrls.push(this.createRessourceUrl(filemeta.id));
      }
    }
    return ressourceUrls;
  }

  public createRessourceUrl(id: number) {
    return this.envService.backendUrl + "/files/data/" + id + "?access_token=" + this.token;
  }
  public createDecryptedRessourceUrl(id: number) {
    return this.envService.backendUrl + "/files/data/decrypted/" + id + "?access_token=" + this.token;
  }
}
