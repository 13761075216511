import { NgModule } from '@angular/core';
import { CustomerRegistrationSubmitPageComponent } from './customer-registration-submit-page/customer-registration-submit-page.component';
import { CustomerRegistrationPageComponent } from './customer-registration-page/customer-registration-page.component';
import { ComponentsModule } from '../../components/components.module';
import { UtilsModule } from '../../utils/utils.module';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { CustomerTableComponent } from './components/customer-table/customer-table.component';
import { HealthinsurancePageComponent } from './healthinsurance-page/healthinsurance-page.component';
import { HealthinsuranceTableComponent } from './components/healthinsurance-table/healthinsurance-table.component';
import { AddHealthinsuranceBottomSheetComponent } from './components/add-healthinsurance-bottom-sheet/add-healthinsurance-bottom-sheet.component';
import { AddHealthinsuranceComponent } from './components/add-healthinsurance/add-healthinsurance.component';
import { EventParticipantComponent } from '../events/components/event-participant/event-participant.component';

@NgModule({
    declarations: [
        CustomerRegistrationPageComponent,
        CustomerRegistrationSubmitPageComponent,
        CustomerPageComponent,
        AddCustomerComponent,
        CustomerTableComponent,
        HealthinsurancePageComponent,
        HealthinsuranceTableComponent,
        AddHealthinsuranceBottomSheetComponent,
        AddHealthinsuranceComponent,
    ],
    imports: [
        ComponentsModule,
        UtilsModule
    ],
    exports: [
        CustomerRegistrationPageComponent,
        CustomerRegistrationSubmitPageComponent,
        CustomerPageComponent
    ]
})
export class CustomerModule { }
