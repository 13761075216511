import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "event-comment",
  templateUrl: "./event-comment.component.html",
  styleUrls: ["./event-comment.component.sass"],
})
export class EventCommentComponent implements OnInit {
  @Input() eventForm: UntypedFormGroup;
  @Input() formType: "booking" | "inquiry";

  userRole: string;

  constructor() {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
  }
}
