import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { ValueLabel } from "../../../components/value-label";
import { FormHelper } from "../../../helper/form.helper";
import { EmployeeService, TrainerForAssignmentDto } from "../../../services/employee.service";
import { FileMeta, FileService } from "../../../services/file.service";
import { HealthScreening, HealthScreeningService } from "../../../services/health-screening.service";
import { SelectOptions } from "../../../utils/select-options";
import { CustomerService } from "src/app/services/customer.service";
import { Location } from "@angular/common";
import { atLeastOneCheckboxCheckedValidator } from "src/app/validators/checkbox.validator";
import { combineLatest, forkJoin, merge, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { getAllTrainersFromAssignment } from "../../../store";

@Component({
  selector: "edit-health-screening-page",
  templateUrl: "./edit-health-screening-page.component.html",
  styleUrls: ["./edit-health-screening-page.component.scss"],
})
export class EditHealthScreeningPageComponent implements OnInit {
  @Input() healthScreeningForm: UntypedFormGroup;

  availableTrainer$: Observable<TrainerForAssignmentDto[]> = this.store.select(getAllTrainersFromAssignment);
  healthscreening: HealthScreening = new HealthScreening();
  form: UntypedFormGroup;
  images: FileMeta[] = [];
  imageUrls: string[] = [];
  trainingVideos: FileMeta[];
  marketingVideos: FileMeta[];
  companynameAliases = [];
  healthInsuranceOwnerString: String = "";

  imageUploadForm = this.formbuilder.group({
    imageControl: [null, []],
  });

  videoUploadForm = this.formbuilder.group({
    videoControl: [null, []],
    videoType: [null, []],
  });

  videoTypes: ValueLabel[] = SelectOptions.videoTypes;
  userRole: string;

  constructor(
    private route: ActivatedRoute,
    private healthscreeningService: HealthScreeningService,
    private fileService: FileService,
    private formbuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private formHelper: FormHelper,
    private employeeService: EmployeeService,
    private customerService: CustomerService,
    private location: Location,
    private store: Store<fromStore.GibState>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadTrainerForAssignment())
    this.userRole = localStorage.getItem("role");
    this.route.params.subscribe((params) => {
      const id = +params["id"];
      this.healthscreeningService.findOne(id).subscribe((res) => {
        this.healthscreening = res.body;
        this.updateHealthInsuranceOwnerString();
        this.loadImages();
        this.createForm();
        this.loadMarketingVideos();
        this.loadTrainingVideos();
        this.loadCompanynameAlias();
        if (this.healthscreening.assignedUsers) {
          this.healthscreening.assignedUsers.sort(this.formHelper.sortByLastname);
        }
      });
    });
  }

  createForm() {
    this.form = this.healthscreeningService.createHealthscreeningForm(this.healthscreening);

    this.form.get("moduleType").setValidators(Validators.required);
    this.form.get("topic").setValidators(Validators.required);
    this.form.setValidators(atLeastOneCheckboxCheckedValidator(1, ["online", "onsite"]));

    this.form.get("moduleName").setValidators(Validators.required);
    this.form.get("moduleNameEn").setValidators(Validators.required);
    this.form.get("shortDescription").setValidators(Validators.required);

    this.setValidators();

    merge(this.form.get("moduleType").valueChanges, this.form.get("online").valueChanges, this.form.get("onsite").valueChanges).subscribe((data) => {
      this.setValidators();
    });
  }

  private setValidators() {
    const isHealthModule = this.form.get("moduleType").value === "HEALTH_MODULE";
    const isOnline = this.form.get("online").value;
    const isOnsite = this.form.get("onsite").value;

    if (isHealthModule) {
      if (isOnsite) {
        this.form.get("recommendedOnsiteInterval").setValidators(Validators.required);
        this.form.controls["recommendedOnsiteInterval"].updateValueAndValidity();
      } else {
        this.form.get("recommendedOnsiteInterval").removeValidators(Validators.required);
        this.form.controls["recommendedOnlineInterval"].updateValueAndValidity();
      }
      if (isOnline) {
        this.form.get("recommendedOnlineInterval").setValidators(Validators.required);
        this.form.controls["recommendedOnlineInterval"].updateValueAndValidity();
      } else {
        this.form.get("recommendedOnlineInterval").removeValidators(Validators.required);
        this.form.controls["recommendedOnlineInterval"].updateValueAndValidity();
      }
      this.form.get("durationInMinutes").clearValidators();
      this.form.controls["durationInMinutes"].updateValueAndValidity();
      this.form.get("maxParticipantsOnsite").clearValidators();
      this.form.controls["maxParticipantsOnsite"].updateValueAndValidity();
      this.form.get("maxParticipantsOnline").clearValidators();
      this.form.controls["maxParticipantsOnline"].updateValueAndValidity();
    } else {
      this.form.get("recommendedOnsiteInterval").clearValidators();
      this.form.controls["recommendedOnsiteInterval"].updateValueAndValidity();
      this.form.get("recommendedOnlineInterval").clearValidators();
      this.form.controls["recommendedOnlineInterval"].updateValueAndValidity();
      this.form.get("durationInMinutes").setValidators(Validators.required);
      this.form.controls["durationInMinutes"].updateValueAndValidity();
      if (isOnsite) {
        this.form.get("maxParticipantsOnsite").setValidators(Validators.required);
        this.form.controls["maxParticipantsOnsite"].updateValueAndValidity();
      } else {
        this.form.get("maxParticipantsOnsite").clearValidators();
        this.form.controls["maxParticipantsOnsite"].updateValueAndValidity();
      }
      if (isOnline) {
        this.form.get("maxParticipantsOnline").setValidators(Validators.required);
        this.form.controls["maxParticipantsOnline"].updateValueAndValidity();
      } else {
        this.form.get("maxParticipantsOnline").clearValidators();
        this.form.controls["maxParticipantsOnline"].updateValueAndValidity();
      }
    }

    if (isOnsite) {
      this.form.get("size").setValidators(Validators.required);
      this.form.get("size").updateValueAndValidity();
      this.form.get("power").setValidators(Validators.required);
      this.form.get("power").updateValueAndValidity();
      this.form.get("location").setValidators(Validators.required);
      this.form.get("location").updateValueAndValidity();
    } else {
      this.form.get("size").removeValidators(Validators.required);
      this.form.get("size").updateValueAndValidity();
      this.form.get("power").removeValidators(Validators.required);
      this.form.get("power").updateValueAndValidity();
      this.form.get("location").removeValidators(Validators.required);
      this.form.get("location").updateValueAndValidity();
    }
  }

  loadImages() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_PICTURE").subscribe((res) => {
      this.images = res;
    });
  }

  loadTrainingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_VIDEO_TRAINING").subscribe((res) => {
      this.trainingVideos = res;
    });
  }

  loadMarketingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthscreening.id, "HEALTHSCREENING_VIDEO_PRESENTATION").subscribe((res) => {
      this.marketingVideos = res;
    });
  }

  loadVideosByType() {
    if (this.videoUploadForm.get("videoType").value === "HEALTHSCREENING_VIDEO_TRAINING") {
      this.loadTrainingVideos();
    } else if (this.videoUploadForm.get("videoType").value === "HEALTHSCREENING_VIDEO_PRESENTATION") {
      this.loadMarketingVideos();
    }
  }

  updateDataClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "editHealthScreeningErrorTitle", () => this.updateData());
  }

  updateData() {
    const healthScreening = this.healthscreeningService.mapFormToHealthScreening(this.form);
    healthScreening.id = this.healthscreening.id;
    healthScreening.assignedUsers = this.healthscreening.assignedUsers;
    if (healthScreening.visibileForCompanynameAliases && healthScreening.visibileForCompanynameAliases.length > 0) {
      healthScreening.restrictedVisibility = true;
    } else {
      healthScreening.restrictedVisibility = false;
    }
    this.healthscreeningService.update(healthScreening).subscribe((res) => {
      this.healthscreening = res.body;
      this.updateHealthInsuranceOwnerString();
      this.healthscreeningService.correctFormatOfHealthscreening(this.healthscreening);
      this.healthscreening.assignedUsers.sort(this.formHelper.sortByLastname);
      this.showUpdateSuccessfullDialog();
    });
  }

  updateHealthInsuranceOwnerString() {
    if (this.healthscreening.restrictedVisibility && this.healthscreening.visibileForCompanynameAliases && this.healthscreening.visibileForCompanynameAliases.length > 0) {
      this.healthInsuranceOwnerString = this.healthscreening.visibileForCompanynameAliases.join(", ");
    } else {
      this.healthInsuranceOwnerString = "all";
    }
  }

  showUpdateSuccessfullDialog() {
    const title = this.translateService.instant("moduleEditedTitle");
    const text = this.translateService.instant("modulEditedText");
    this.dialogService.openDialog(title, text, "success");
  }

  setImageUrls(imageUrls: string[]) {}

  setTrainingVideoUrls(videoUrls: string[]) {}

  setMarketingVideoUrls(videoUrls: string[]) {}

  loadCompanynameAlias() {
    this.customerService.getAllCompanynameAliases().subscribe((aliasList) => {
      if (aliasList && aliasList.body) {
        this.companynameAliases = aliasList.body;
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  get isOnline(): boolean {
    if (this.form) {
      return this.form.get("online").value;
    }
    return false;
  }

  get isOnsite(): boolean {
    if (this.form) {
      return this.form.get("onsite").value;
    }
    return false;
  }
}
