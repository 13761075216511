import { OnInit, Component, OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core";
import { FileService, FileMeta } from "src/app/services/file.service";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../dialogs/gib-dialog.service";
import { UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "gib-documents",
  templateUrl: "./gib-documents.component.html",
  styleUrls: ["./gib-documents.component.scss"],
})
export class GibDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userId: string;
  @Input() fileType;
  @Input() healthScreeningId: number;
  @Input() fileUploadSingleLine: boolean = true;
  @Input() maxFileCount: number = 999;
  @Output() documentEvent = new EventEmitter<null>();
  @Output() hasFilesEvent = new EventEmitter<boolean>();

  control = new UntypedFormControl("");
  files: FileMeta[] = [];
  subscription: Subscription;
  title = new UntypedFormControl("");
  downloadUrl: string;

  constructor(private translateService: TranslateService, private dialogService: GibDialogService, private fileService: FileService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.healthScreeningId) {
      this.subscription = this.fileService.getFileMetaByHealthscreening(this.healthScreeningId, this.fileType).subscribe((f) => {
        this.files = f;
        this.hasFilesEvent.emit(this.files.length > 0);
      });
    } else {
      this.subscription = this.fileService.getFileMetasByUserAndType(this.userId, this.fileType).subscribe((f) => {
        this.files = f;
        this.hasFilesEvent.emit(this.files.length > 0);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.healthScreeningId) {
      this.subscription = this.fileService.getFileMetaByHealthscreening(this.healthScreeningId, this.fileType).subscribe((f) => {
        this.files = f;
        this.hasFilesEvent.emit(this.files.length > 0);
      });
    }
  }

  onFileUploaded() {
    if (this.healthScreeningId) {
      this.fileService.getFileMetaByHealthscreening(this.healthScreeningId, this.fileType).subscribe((f) => {
        this.files = f;
        this.changeDetector.markForCheck();
        this.hasFilesEvent.emit(this.files.length > 0);
      });
    } else {
      this.fileService.getFileMetasByUserAndType(this.userId, this.fileType).subscribe((f) => {
        this.files = f;
        this.changeDetector.markForCheck();
        this.hasFilesEvent.emit(this.files.length > 0);
      });
    }
    this.title.setValue("");
    this.documentEvent.emit(null);
  }

  onDownload(file: FileMeta) {
    if (file.fileType === "CUSTOMER_SIGNATURE_DOCUMENT") {
      this.fileService.getFileDataDecrypted(file.id).subscribe((res) => {
        this.handleFileResponse(res, file);
      });
    } else {
      this.fileService.getFileData(file.id).subscribe((res) => {
        this.handleFileResponse(res, file);
      });
    }
  }

  private handleFileResponse(res: Blob, file: FileMeta) {
    const blob = new Blob([res], { type: "application/octet-stream" });
    this.downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.downloadUrl;
    a.download = file.fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(this.downloadUrl);
      document.body.removeChild(a);
    }, 0);
  }

  private delete(file: FileMeta) {
    this.fileService.delete(file.id).subscribe();

    this.files.forEach((f, index) => {
      if (f === file) this.files.splice(index, 1);
    });
    this.hasFilesEvent.emit(this.files.length > 0);
    this.documentEvent.emit(null);
  }

  onDelete(file: FileMeta) {
    const fileName = file.fileName;
    const title = this.translateService.instant("deleteFileConfirmationTitle");
    const text = this.translateService.instant("deleteFileConfirmationText", { fileName });
    this.dialogService.openConfirmationDialog(title, text, () => this.delete(file));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getFileuploadClass() {
    if (this.fileUploadSingleLine) {
      return "col-6";
    }
    return "col-12";
  }
}
