import { EventEmitterService } from './../../../../services/eventEmitterService';
import { UntypedFormGroup } from '@angular/forms';
import { EmployeeService } from './../../../../services/employee.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Employee } from 'src/app/services/employee.service';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'add-person-bottom-sheet',
  templateUrl: './add-person-bottom-sheet.component.html',
  styleUrls: ['./add-person-bottom-sheet.component.scss']
})
export class AddPersonBottomSheetComponent implements OnInit {

  selectedEmployee: Employee;
  trainerForm: UntypedFormGroup;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheet: MatBottomSheet, private employeeService: EmployeeService) { }

  ngOnInit() {
    this.selectedEmployee = this.data.selectedEmployee;
    this.trainerForm = this.employeeService.mapEmployeeToForm(this.selectedEmployee);
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

}
