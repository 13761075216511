import { ReportsPageComponent } from './reports-page/reports-page.component';
import { DocumentModule } from './documents/document.module';
import { ComponentsModule } from './../../components/components.module';
import { UtilsModule } from './../../utils/utils.module';
import { MailLogPageComponent } from './mail-log-page/mail-log-page.component';
import { MeetingFormComponent } from './components/meeting-form/meeting-form.component';
import { MeetingTableDetailsComponent } from './components/meeting-table-details/meeting-table-details.component';
import { MeetingTableComponent } from './components/meeting-table/meeting-table.component';
import { MeetingAdministrationPageComponent } from './meeting-administration-page/meeting-administration-page.component';
import { MetadataTableComponent } from './components/metadata-table/metadata-table.component';
import { MetadataPageComponent } from './metadata-page/metadata-page.component';
import { ConfigurationPageComponent } from './configuration-page/configuration-page.component';

import { NgModule } from '@angular/core';
import { MailLogTableComponent } from './components/mail-log-table/mail-log-table.component';
import { MeetingOverviewGridComponent } from './components/meeting-overview-grid/meeting-overview-grid.component';
import { MailLogTableDetailsComponent } from './components/mail-log-table-details/mail-log-table-details.component';
import { ReportTileComponent } from './components/report-tile/report-tile.component';
import { ReportChartComponent } from './components/report-chart/report-chart.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { ReportsOverviewPageComponent } from './reports-overview-page/reports-overview-page.component';

@NgModule({
  declarations: [
    ConfigurationPageComponent,
    MetadataPageComponent,
    MetadataTableComponent,
    MeetingAdministrationPageComponent,
    MeetingTableComponent,
    MeetingTableDetailsComponent,
    MeetingFormComponent,
    MailLogPageComponent,
    MailLogTableComponent,
    MeetingOverviewGridComponent,
    MailLogTableDetailsComponent,
    ReportTileComponent,
    ReportChartComponent,
    ReportsOverviewPageComponent,
    ReportsPageComponent
  ],
  imports: [
    UtilsModule,
    ComponentsModule,
    DocumentModule,
    AgChartsAngularModule
  ],
  exports: [
    MeetingAdministrationPageComponent,
    ConfigurationPageComponent,
    MetadataPageComponent,
    MailLogPageComponent,
    ReportsOverviewPageComponent,
    ReportsPageComponent
  ]
})
export class AdministrationModule {}
