<svg xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  id="svg-icon"
  class="svg-icon"
  viewBox="0 0 45 45"
>
  <path
  [class.green]="color === 'green'"
  [class.navy]="color === 'navy'"
  [class.blue]="color === 'blue'"
  [class.grey]="color === 'grey'"
  d="M22.5,0C10.07,0,0,10.07,0,22.5s10.07,22.5,22.5,22.5,22.5-10.07,22.5-22.5S34.93,0,22.5,0ZM30.09,13.56c1.7,0,3.08,1.38,3.08,3.08s-1.38,3.08-3.08,3.08-3.08-1.38-3.08-3.08,1.38-3.08,3.08-3.08h0ZM14.91,13.56c1.7,0,3.08,1.38,3.08,3.08s-1.38,3.08-3.08,3.08-3.08-1.38-3.08-3.08,1.38-3.08,3.08-3.08h0ZM33.77,28.3c-.11.17-1.13,1.73-2.99,3.22-1.69,1.36-4.08,2.68-7.18,2.95h-.09c-.06,0-.11,0-.17,0-.06,0-.13,0-.19,0h-.1c-.1,0-.19,0-.29,0h-.61c-.1,0-.19,0-.29,0h-.09c-.06,0-.13,0-.19,0-.05,0-.11,0-.16,0h-.1c-3.02-.26-5.37-1.53-7.05-2.85l-.02-.02s-.03-.02-.04-.03-.02-.02-.04-.03c-1.88-1.5-2.91-3.07-3.02-3.24-.24-.38-.36-.81-.36-1.23-.02-.78.37-1.55,1.07-1.99,1.07-.68,2.49-.35,3.17.72.04.07.53.82,1.43,1.66.2.19.43.38.67.57.83.65,1.71,1.13,2.62,1.45.02,0,.04.01.05.02.09.03.18.06.27.09.04.01.08.02.12.03.06.02.12.03.18.05.07.02.14.03.2.05.04,0,.07.02.11.02.09.02.19.04.28.06.03,0,.06.01.08.02.1.02.2.03.3.05.03,0,.06,0,.09.01.06,0,.12.01.18.02.08,0,.15.01.23.02h.14c.09,0,.17,0,.26.01h.43c.08,0,.17,0,.25-.01h.15c.07,0,.15-.01.22-.02.06,0,.12-.01.18-.02.03,0,.05,0,.08-.01.11-.01.21-.03.31-.05.02,0,.05,0,.07-.01.1-.02.19-.04.29-.06.03,0,.07-.02.1-.02.07-.02.14-.03.21-.05.06-.02.12-.03.18-.05.04-.01.08-.02.11-.03.09-.03.18-.06.28-.09.02,0,.03-.01.05-.02.92-.32,1.79-.8,2.63-1.45.25-.2.49-.4.7-.59l.02-.02h0c.87-.83,1.34-1.55,1.38-1.62.68-1.07,2.09-1.4,3.17-.72.69.43,1.07,1.17,1.07,1.93v.06c0,.42-.12.85-.36,1.24h0v-.02Z"
/>
</svg>
