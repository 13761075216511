<mat-card>
  <mat-card-content>
    <div *ngIf="uploadResponse.status === 'error'">
      {{ uploadResponse.message }}
    </div>
    <div>
      <button type="submit" class="btn btn-secondary" (click)="file.click()">{{'choose_file' | translate}}</button>
      <span class="file-name">{{trimmedFileName}}</span>
      <span class="error-text" *ngIf="error">{{ error }}</span>
      <input type="file" (change)="onFileChange($event)" #file [hidden]="true" />
      <button type="submit" class="btn btn-primary" (click)="onSubmit()" *ngIf="fileControl.value">{{'upload' | translate}}</button>
    </div>
    <p *ngIf="showProgressBar && !uploadComplete">
      <ngb-progressbar type="success" [value]="uploadResponse.message" [striped]="true"></ngb-progressbar>
    </p>
  </mat-card-content>
</mat-card>