import { Injectable } from '@angular/core';
import { Event } from './event.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BookAgainService {
  eventToBookAgain: Event = null;
  eventForm: UntypedFormGroup;

  constructor() {}

  setEventToBookAgain(event: Event) {
    event.id = null;
    if (event.customer && event.customer.addresses) {
      event.customer.addresses.forEach(address => {
        address.id = null;
      });
    }
    event.buid = null;
    event.customerAddress.id = null;
    if (event.eventHealthScreenings) {
      event.eventHealthScreenings.forEach(eihs => {
        eihs.id = null;
        eihs.eventInquiry = null;
      });
    }
    event.eventlocation.id = null;
    this.eventToBookAgain = event;
    return event;
  }

  clearEventToBookAgain() {
    this.eventToBookAgain = null;
  }

  setEventForm(eventForm: UntypedFormGroup) {
    this.eventForm = eventForm;
  }

  clearEventForm() {
    this.eventForm = null;
  }

}
