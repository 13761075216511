import { ValueLabel } from "../components/value-label";
import { ValueLabelBoolean } from "../components/value-label-boolean";
export class SelectOptions {
  public static salutationOptions: ValueLabel[] = [
    { value: "male", label: "MR" },
    { value: "female", label: "MRS" },
  ];

  public static trainerLocations: ValueLabel[] = [
    { value: "Berlin", label: "Berlin" },
    { value: "Hamburg", label: "Hamburg" },
    { value: "Online", label: "Online" },
  ];

  public static trainerStates: ValueLabel[] = [
    { value: "APPLICATION", label: "employeeState_APPLICATION" },
    { value: "TRAINING", label: "employeeState_TRAINING" },
    { value: "VISIT", label: "employeeState_VISIT" },
    { value: "ACTIVE", label: "employeeState_ACTIVE" },
    { value: "INACTIVE", label: "employeeState_INACTIVE" },
  ];

  public static healthScreeningLocations: ValueLabel[] = [
    { value: "INDOOR", label: "indoor" },
    { value: "OUTDOOR", label: "outdoor" },
    // { value: "ONLINE", label: "online" },
  ];

  public static onlinePlatformOptions: ValueLabel[] = [
    { value: "GIB", label: "ONLINE_PLATFORM_GIB" },
    { value: "TNG3", label: "ONLINE_PLATFORM_TNG3" },
  ];

  public static powerOptions: ValueLabelBoolean[] = [
    { value: true, label: "REQUIRED" },
    { value: false, label: "NOT_REQUIRED" },
  ];

  public static videoTypes: ValueLabel[] = [
    {
      value: "HEALTHSCREENING_VIDEO_TRAINING",
      label: "HEALTHSCREENING_VIDEO_TRAINING",
    },
    {
      value: "HEALTHSCREENING_VIDEO_PRESENTATION",
      label: "HEALTHSCREENING_VIDEO_PRESENTATION",
    },
  ];

  public static dateFormats = {
    parse: {
      dateInput: "DD.MM.YYYY",
    },
    display: {
      dateInput: "DD.MM.YYYY",
      monthYearLabel: "MMM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMMM YYYY",
    },
  };

  public static addressTypeOptions: ValueLabel[] = [
    { value: "HOME", label: "HOMEADRESS" },
    { value: "BILLING", label: "BILLINGADRESS" },
  ];

  public static trainerAvailabilityOptions: ValueLabel[] = [
    { value: "AVAILABLE", label: "available" },
    { value: "UNAVAILABLE", label: "not_available" },
  ];

  public static feedbackBooleans: ValueLabelBoolean[] = [
    { value: true, label: "yes" },
    { value: false, label: "no" },
  ];

  public static feedbackOptions: ValueLabel[] = [
    { value: "4", label: "veryGood" },
    { value: "3", label: "good" },
    { value: "2", label: "bad" },
    { value: "1", label: "veryBad" },
  ];

  public static yesNoOptions: ValueLabelBoolean[] = [
    { value: true, label: "yes" },
    { value: false, label: "no" },
  ];

  public static contactTypeOptions: ValueLabel[] = [
    { value: "EMAIL", label: "email" },
    { value: "PHONE", label: "phonenumber" },
  ];

  public static moduleTypes: ValueLabel[] = [
    { value: "HEALTH_MODULE", label: "eventHealthScreenings" },
    { value: "WEBINAR", label: "interactiveWebinars" },
    { value: "ACTIVE_BREAK", label: "activeBreaks" },
  ];
}
