import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "topic", pure: false })
export class TopicPipe implements PipeTransform {
  constructor() {}

  transform(objects: any[], topic: string) {
    if (!topic) {
      return objects;
    } else {
      return objects.filter((object) => object.form.get("topic").value === topic);
    }
  }
}

@Pipe({ name: "topicString", pure: false })
export class TopicStringPipe implements PipeTransform {
  constructor() {}
  transform(objects: any[], topic: string) {
    if (!topic) {
      return objects;
    } else {
      let retval = objects.filter((object) => object.topic === topic);
      return retval;
    }
  }
}
