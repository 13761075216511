<mat-tab-group>
  <mat-tab label="{{ 'healthInsurance' | translate }}">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="row">
                <div class="col-sm-6">
                  <h6>{{ "healthInsurance" | translate }}</h6>
                  <gib-input [placeholder]="'companynameAlias'" [control]="form.get('companynameAlias')"></gib-input>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6"></div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="center">
          <button mat-button (click)="cancel()">
            {{ "CANCEL" | translate }}
          </button>
          <button *ngIf="!_healthInsurance.companynameAlias" type="button" class="btn btn-primary" (click)="addHealthInsuranceClicked()">
            {{ "ADD" | translate }}
          </button>
          <button *ngIf="_healthInsurance.companynameAlias" type="button" class="btn btn-primary" (click)="updateHealthInsuranceClicked()">
            {{ "save" | translate }}
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="_healthInsurance.companynameAlias" label="{{ 'logo' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents [maxFileCount]="1" [userId]="healthInsurance.companynameAlias" [fileType]="'HEALTH_INSURANCE_LOGO'" (hasFilesEvent)="onHasFilesEvent($event)" (documentEvent)="onDocumentEvent()"></gib-documents>
        <button *ngIf="hasLogo" type="button" class="btn btn-primary actionButton" (click)="openDownloadPdfConfirmation()"> {{ 'print_test' | translate }} </button>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="_healthInsurance.companynameAlias" label="{{ 'attachmentPrivacy' | translate }}">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <h6>{{ "DataPrivacyFormTitle" | translate }}</h6>
                </div>
                <div class="col-sm-12 col-md-12">
                  <gib-textarea [placeholder]="'privacyStatementDe'" [control]="form.get('privacyStatementDe')">
                  </gib-textarea>
                </div>
                <div class="col-sm-12 col-md-12">
                  <h6>{{ "privacyStatementDe" | translate }}</h6>
                  <gib-documents [maxFileCount]="1" [userId]="healthInsurance.companynameAlias" [fileType]="'PRIVACY_POLICY_DE'" (documentEvent)="onDocumentEvent()"></gib-documents>
                </div>
                <div class="col-sm-12 col-md-12">
                  <gib-textarea [placeholder]="'privacyStatementEn'" [control]="form.get('privacyStatementEn')">
                  </gib-textarea>
                </div>
                <div class="col-sm-12 col-md-12">
                  <h6>{{ "privacyStatementEn" | translate }}</h6>
                  <gib-documents [maxFileCount]="1" [userId]="healthInsurance.companynameAlias" [fileType]="'PRIVACY_POLICY_EN'" (documentEvent)="onDocumentEvent()"></gib-documents>
                </div>
                <mat-divider></mat-divider>
                <div class="col-sm-12 col-md-12">
                  <h6>{{ "DataPrivacy3rdPartyFormTitle" | translate }}</h6>
                </div>
                <div class="col-sm-12 col-md-12">
                  <gib-textarea [placeholder]="'privacyStatement3rdPartyDe'" [control]="form.get('privacyStatement3rdPartyDe')">
                  </gib-textarea>
                </div>
                <div class="col-sm-12 col-md-12">
                  <gib-textarea [placeholder]="'privacyStatement3rdPartyEn'" [control]="form.get('privacyStatement3rdPartyEn')">
                  </gib-textarea>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12"></div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="center">
          <button mat-button (click)="cancel()">
            {{ "CANCEL" | translate }}
          </button>
          <button *ngIf="!_healthInsurance.companynameAlias" type="button" class="btn btn-primary" (click)="addHealthInsuranceClicked()">
            {{ "ADD" | translate }}
          </button>
          <button *ngIf="_healthInsurance.companynameAlias" type="button" class="btn btn-primary" (click)="updateHealthInsuranceClicked()">
            {{ "save" | translate }}
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
</mat-tab-group>