import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromInactiveEvents from "../reducers/inactive-event.reducer";

export const getInactiveEventsState = createSelector(fromFeature.getGibState, (state: fromFeature.GibState) => state.inactiveEvents);
export const getInactiveEventsEntities = createSelector(getInactiveEventsState, fromInactiveEvents.getInactiveEventsEntities);
export const getInactiveEvents = (period: string) =>
  createSelector(getInactiveEventsEntities, (entities) => {
    if (entities && entities[period]) {
      return Object.keys(entities[period]).map((id) => entities[period][id]);
    }
    return [];
  });
export const getInactiveEventsPeriods = createSelector(getInactiveEventsEntities, (entities) => {
  return Object.keys(entities);
});
export const getInactiveEventsLoaded = createSelector(getInactiveEventsState, fromInactiveEvents.getInactiveEventsLoaded);
export const getInactiveEventsLoading = createSelector(getInactiveEventsState, fromInactiveEvents.getInactiveEventsLoading);
