<h5 *ngIf="files.length > 0">{{ "documents" | translate }}</h5>
<div class="row">
  <div [ngClass]="getFileuploadClass()">
    <ul class="list-group">
      <li *ngFor="let file of files" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        {{ file.title }} - {{ file.fileName }}
        <span>
          <i class="material-icons mr-4" (click)="onDownload(file)" style="cursor: pointer">get_app</i>
          <i class="material-icons" (click)="onDelete(file)" style="cursor: pointer">delete</i>
        </span>
      </li>
    </ul>
  </div>
</div>

<h5>{{ "uploadDocument" | translate }}</h5>
<div class="row" *ngIf="this.maxFileCount > this.files.length">
  <div [ngClass]="getFileuploadClass()">
    <gib-input [placeholder]="'title'" [control]="title"></gib-input>
  </div>
  <div [ngClass]="getFileuploadClass()">
    <gib-fileupload [fileType]="fileType" id="upload_component" [control]="control" [userId]="userId" [healthScreeningId]="healthScreeningId" [type]="fileType" [maxFileSize]="100" [title]="title.value" (fileUploaded)="onFileUploaded()"></gib-fileupload>
  </div>
</div>

<div class="row" *ngIf="this.maxFileCount <= this.files.length">
  <p class="info">{{ "maxfileCountReached" | translate }}</p>
</div>
