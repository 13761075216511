<div class="row" *ngIf="eventParticipant.get('scheduletype').value === 'OPEN' || eventParticipant.get('scheduletype').value === 'CLOSED'">
  <div class="col-sm-1">
    <gib-input [placeholder]="'from'" [control]="eventParticipant.get('timeFrom')"></gib-input>
  </div>
  <div class="col-sm-1">
    <gib-input [placeholder]="'to'" [control]="eventParticipant.get('timeTo')"></gib-input>
  </div>
  <div class="col-sm-2">
    <gib-input [placeholder]="'firstname'" [control]="eventParticipant.get('firstname')"></gib-input>
  </div>
  <div class="col-sm-2">
    <gib-input [placeholder]="'lastname'" [control]="eventParticipant.get('lastname')"></gib-input>
  </div>
  <div [class.col-sm-3]="!scheduleEntryCompanynameRequired()" [class.col-sm-2]="scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'email'" [control]="eventParticipant.get('email')"></gib-input>
  </div>
  <div class="col-sm-2" *ngIf="scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'companyname'" [control]="eventParticipant.get('companyname')"></gib-input>
  </div>
  <div class="col-sm-1" *ngIf="scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'zip'" [control]="eventParticipant.get('zip')"></gib-input>
  </div>
  <div class="col-sm-1" *ngIf="scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'phonenumber'" [control]="eventParticipant.get('phone')"></gib-input>
  </div>
  <div class="col-sm-2" *ngIf="!scheduleEntryCompanynameRequired()">
    <gib-input [placeholder]="'phonenumber'" [control]="eventParticipant.get('phone')"></gib-input>
  </div>
  <div class="col-sm-1" *ngIf="this.userRole === 'gib_director' || this.userRole === 'gib_admin' || this.userRole === 'gib_employee' || this.userRole === 'gib_trainer'">
    <i *ngIf="eventParticipant.get('meetingUrl').value !== '' && eventParticipant.get('meetingUrl').value !== null" placement="left" class="material-icons" [ngbTooltip]="'copyMeetingUrl' | translate" (click)="copyMeeintingLink(eventParticipant)">contacts</i>
    <i *ngIf="showSendReminder(eventParticipant)" placement="left" class="material-icons" [ngbTooltip]="'sendReminderMailToOnlineEventParticipant' | translate" (click)="sendReminderMailToOnlineEventParticipantClicked(eventParticipant)">announcement</i>
    <i *ngIf="eventParticipant.get('acceptedThirdPartyPrivacy').value === true" placement="left" class="material-icons" [ngbTooltip]="'3rdPartyAccepted' | translate">check</i>
  </div>
  <div class="col-sm-1" *ngIf="afgView">
    <i *ngIf="eventParticipant.get('meetingUrl').value !== '' && eventParticipant.get('meetingUrl').value !== null" placement="left" class="material-icons" [ngbTooltip]="'copyMeetingUrl' | translate" (click)="copyMeeintingLink(eventParticipant)">contacts</i>
  </div>
</div>

<div class="row" *ngIf="eventParticipant.get('scheduletype').value === 'UNAVAILABLE' || eventParticipant.get('scheduletype').value === 'BLOCKED'">
  <div class="col-sm-1">
    <gib-input [placeholder]="'from'" [control]="eventParticipant.get('timeFrom')"></gib-input>
  </div>
  <div class="col-sm-1">
    <gib-input [placeholder]="'to'" [control]="eventParticipant.get('timeTo')"></gib-input>
  </div>
  <div class="col-sm-8">
    <mat-label> {{ eventParticipant.get("title").value | translate }}</mat-label>
  </div>
  <div class="col-sm-2"></div>
</div>
<hr />
