import { Router, ActivatedRoute } from "@angular/router";
import { GibDialogService } from "./../../../../components/dialogs/gib-dialog.service";
import { ScheduleService } from "./../../../../services/schedule-vote.service";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, FormBuilder } from "@angular/forms";
import { FormHelper } from "../../../../helper/form.helper";
import { TranslateService } from "@ngx-translate/core";
import { HealthInsuranceService, PrivacyStatementDto } from "src/app/services/health-insurance.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "voting-participant",
  templateUrl: "./voting-participant.component.html",
  styleUrls: ["./voting-participant.component.scss"],
})
export class VotingParticipantComponent implements OnInit {
  @Input() votingParticipant: UntypedFormGroup;
  @Input() companynameAlias: string;
  @Input() sendAppointmentlistToCorporateCustomer: boolean = false;

  userId;
  uuid;

  constructor(private route: ActivatedRoute, private scheduleService: ScheduleService, private formHelper: FormHelper, private dialogService: GibDialogService, private translateService: TranslateService, private healthInsuranceService: HealthInsuranceService, private envService: EnvService) {}

  ngOnInit() {
    this.route.queryParams.subscribe((values) => {
      this.uuid = values["uuid"];
      return this.uuid;
    });
  }

  updateScheduleDataClicked() {
    this.formHelper.isFormValidElseShowErrors(this.votingParticipant, "appointmentCouldNotBeSet", () => this.updateScheduleData());
  }

  updateScheduleData() {
    const lang = localStorage.getItem("gib-locale");
    const title = this.translateService.instant("ScheduleVoteDataPrivacyTitle");
    const text = this.translateService.instant("ScheduleVoteDataPrivacyText");
    this.healthInsuranceService.get3rdPartyPrivacyStatement(this.companynameAlias).subscribe((res) => {
      let privacyStatement: PrivacyStatementDto = res.body;
      let thirdPartyPrivacyText = "";
      let eventPrivacyText = "";
      let link = "";
      if (privacyStatement) {
        if (lang === "en") {
          link = this.envService.backendUrl + "/public/files/privacyPolicy/" + lang + "/" + privacyStatement.enFilename;
          thirdPartyPrivacyText = privacyStatement.en3rd;
          eventPrivacyText = privacyStatement.en + this.translateService.instant("ScheduleVoteDataPrivacyEventPrivacyText", { privacyPolicyUrl: link, title: privacyStatement.enFiletitle });
        } else {
          thirdPartyPrivacyText = privacyStatement.de3rd;
          link = this.envService.backendUrl + "/public/files/privacyPolicy/" + lang + "/" + privacyStatement.deFilename;
          eventPrivacyText = privacyStatement.de + this.translateService.instant("ScheduleVoteDataPrivacyEventPrivacyText", { privacyPolicyUrl: link, title: privacyStatement.deFiletitle });
        }
      }
      this.dialogService.openPrivacyDialog(title, text, eventPrivacyText, thirdPartyPrivacyText, this.votingParticipant, (updValue: UntypedFormGroup) => {
        if (updValue) {
          this.votingParticipant = updValue;
          const scheduleEntry = this.votingParticipant.getRawValue();
          this.scheduleService.updateScheduleEntries(scheduleEntry).subscribe((res) => {
            this.userId = res.body.participantId;
            this.votingParticipant.get("scheduletype").setValue("CLOSED");
            this.openPrivacyPopup();
            this.formHelper.disableControls(this.votingParticipant);
          });
        }
      });
    });
  }

  openPrivacyPopup() {
    const URL = window.location.href;
    const title = this.translateService.instant("openOnlinePrivacyPopupTitle");
    const text = this.translateService.instant("openOnlinePrivacyPopupText");
    this.dialogService.openDialog(title, text, "success");
  }

  deleteScheduleEntry() {
    const scheduleEntry = this.votingParticipant.getRawValue();
    let userid = this.votingParticipant.get("participantId").value;
    if (userid === null || userid === "") {
      userid = this.userId;
    }
    this.scheduleService.deleteScheduleEntry(scheduleEntry.id, userid).subscribe((res) => {
      this.votingParticipant.get("scheduletype").setValue("OPEN");
      this.votingParticipant.get("firstname").setValue("");
      this.votingParticipant.get("lastname").setValue("");
      this.votingParticipant.get("email").setValue("");
      this.votingParticipant.get("companyname").setValue("");
      this.votingParticipant.get("phone").setValue("");
      this.votingParticipant.get("zip").setValue("");
      if (scheduleEntry.scheduleEntryCompanynameRequired) {
        this.formHelper.enableControlsByName(this.votingParticipant, ["firstname", "lastname", "email", "companyname", "phone", "zip"]);
      } else {
        this.formHelper.enableControlsByName(this.votingParticipant, ["firstname", "lastname", "email", "phone"]);
      }
    });
  }
}
