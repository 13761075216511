<div>
  <h4>{{eventHealthScreening.get('name').value}}</h4>
  <div class="row">
    <div class="col-sm-6 label-col">
      <mat-label>{{ 'availableTrainer' | translate}}</mat-label>
    </div>
    <div class="col-sm-6">
      <gib-select [placeholder]="'TRAINER'" [control]="selectedTrainer" [options]="trainer"></gib-select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 label-col">
      <mat-label>{{ 'appliedForThisEvent' | translate}}</mat-label>
    </div>
    <div class="col-sm-6">
      <gib-select [placeholder]="'TRAINER'" [control]="selectedAppliedTrainer" [options]="appliedTrainer"></gib-select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6" style="text-align: right">
      <mat-label><b>{{ 'selectedTrainer' | translate}}:</b></mat-label>
    </div>
    <div class="col-sm-6">
      <b>{{selectedTrainerName ? selectedTrainerName : ''}}</b>
    </div>
  </div>
  <hr>
</div>
