import { FormHelper } from "src/app/helper/form.helper";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Customer, CustomerService } from "../../../services/customer.service";
import { CustomerTableComponent } from "../components/customer-table/customer-table.component";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { Observable } from "rxjs";
import { getAllControllers } from "../../../store/selectors/tk-controller.selectors";

@Component({
  selector: "tk-controller-page",
  templateUrl: "./tk-controller-page.component.html",
  styleUrls: ["./tk-controller-page.component.scss"],
})
export class TkControllerPageComponent implements OnInit {
  @ViewChild("custmrTable", { static: true }) tkControllerTable: CustomerTableComponent;

  tkControllers$: Observable<Customer[]> = this.store.select(fromStore.getAllControllers);
  selectedTkController: Customer;

  constructor(private formHelper: FormHelper, private store: Store<fromStore.GibState>) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadTkControllers());
  }

  fetchCustomers() {
    this.tkControllerTable.resetDisplayedColumns();
    this.selectedTkController = null;
    this.store.dispatch(new fromStore.LoadTkControllers());
  }

  openCustomerCard(custmr?: Customer) {
    if (!custmr) {
      this.selectedTkController = new Customer();
      this.selectedTkController.role = "gib_tkcontrolling";
    } else {
      this.selectedTkController = custmr;
    }
  }
}
