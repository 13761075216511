import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { EventService } from "../../../../services/event.service";
import { FormHelper } from "../../../../helper/form.helper";
import { EventDetailsBottomSheetComponent } from "../event-details-bottom-sheet/event-details-bottom-sheet.component";
import { InternalNoteService, InternalNote } from "src/app/services/internal-note.service";

@Component({
  selector: "grid-event-details-button",
  templateUrl: "./grid-event-details-button.component.html",
  styleUrls: ["./grid-event-details-button.component.sass"],
})
export class GridEventDetailsButtonComponent implements ICellRendererAngularComp {
  eventId: number;
  eihsId: number;
  refreshData: any;
  showButton = false;
  eventCompleted: boolean;
  isEditable = true;

  public params;

  lastEditedIndex;

  constructor(private bottomSheet: MatBottomSheet, private eventService: EventService, private formHelper: FormHelper, private internalNoteService: InternalNoteService) {}

  ngOnInit() {}

  public agInit(params: any) {
    this.params = params;
    this.refreshData = params.refreshData;
    this.eventId = params.data.eventId;
    this.eihsId = params.data.eventInquiryHealthscreeningId;
    this.showButton = params.showButton;
    if (params.data.state === "COMPLETED") {
      this.eventCompleted = true;
    }
    this.isEditable = params.isEditable;
    this.lastEditedIndex = params.node.rowIndex;
  }

  refresh() {
    return true;
  }

  openEventDetails() {
    this.eventService.findById("" + this.eventId).subscribe((res) => {
      const eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(res.body, this.eihsId);
      const eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(eventForm);
      this.internalNoteService.getById(this.eventId, localStorage.getItem("role")).subscribe((res) => {
        let internalNote = res.body;
        if (internalNote === null) {
          internalNote = new InternalNote();
        }
        const internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
        this.formHelper.disableControls(internalNoteForm);
        this.bottomSheet
          .open(EventDetailsBottomSheetComponent, { data: { eventForm, isEditable: this.isEditable, selectedIndex: eihsIndex, internalNoteForm, allowModuleReplace: true } })
          .afterDismissed()
          .subscribe(() => {
            this.refreshData();
            this.params.context.componentParent.lastEditedIndex = this.lastEditedIndex;
          });
      });
    });
  }
}
