import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { GlobalRateDto } from "../../../../services/global-rate.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "tk-uz-global-rates-table",
  styleUrls: ["tk-uz-global-rates-table.component.scss"],
  template: ` <div class="row">
    <div class="col-sm-12 d-flex justify-content-end  mb-2">
      <a class="btn btn-secondary add-btn" (click)="addGlobalRate()">+</a>
    </div>
    <div class="col-sm-12">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "id" | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.id }}</td>
          </ng-container>

          <ng-container matColumnDef="validFrom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "validFrom" | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.validFrom }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
              <i class="material-icons mr-2" style="cursor: pointer;" [ngbTooltip]="'EDIT' | translate" (click)="onEdit(row)">edit</i>
              <i class="material-icons" style="cursor: pointer;" [ngbTooltip]="'DELETE' | translate" (click)="onDelete(row)">delete</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
        </table>
        <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
      </div>
    </div>
  </div>`,
})
export class TkUzGlobalRatesTableComponent {
  @Input() set globalRates(globalRates: GlobalRateDto[]) {
    if (globalRates) {
      this.data = globalRates;
      this.dataSource = new MatTableDataSource(globalRates);
      this.dataSource.paginator = this.paginator;
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
    }
  }
  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<string> = new EventEmitter();
  @Output() delete: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  data: GlobalRateDto[] = [];
  dataSource: MatTableDataSource<GlobalRateDto>;
  sort: any;
  displayedColumns: string[] = ["id", "validFrom", "actions"];

  constructor(private dialogService: GibDialogService, private translateService: TranslateService) {}

  addGlobalRate(): void {
    this.add.emit();
  }

  onEdit(row: GlobalRateDto): void {
    this.edit.emit(row.id);
  }

  onDelete(row: GlobalRateDto): void {
    const title = this.translateService.instant("tkUzGlobalRatesDeleteConfirmationTitle");
    const text = this.translateService.instant("tkUzGlobalRatesDeleteConfirmationText");
    this.dialogService.openConfirmationDialog(title, text, () => this.delete.emit(row.id));
  }
}
