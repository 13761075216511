import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "event-timing",
  templateUrl: "./event-timing.component.html",
  styleUrls: ["./event-timing.component.scss"],
})
export class EventTimingComponent implements OnInit {
  @Input() eventForm: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}
}
