import { FormHelper } from './../../../../helper/form.helper';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Output, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'metadata-table',
  templateUrl: './metadata-table.component.html',
  styleUrls: ['./metadata-table.component.scss']
})
export class MetadataTableComponent implements OnInit {

  tsks: any[];

  displayedColumns: string[] = ['processName', 'processStep', 'approver', 'outcome', 'reason', 'created', 'completed'];


  dataSource: MatTableDataSource < any > ;


  @Input() set tasks(tasks: any[]) {
    this.tsks = tasks;
    if (tasks && tasks.length > 0) {
      for (const task of tasks) {
        if (task.processName) {
          task.processName = this.translateService.instant(task.processName);
        }
        if (task.outcome !== null) {
          task.outcome = this.translateService.instant('outcome_' + task.outcome);
        }
        if (task.processStep && (task.processStep.includes('ut') || task.processStep.includes('adhoc'))) {
          task.processStep = task.processStepName;
        } else {
          task.processStep = this.translateService.instant('step_' + task.processStep);
        }
      }
    }

    this.dataSource = new MatTableDataSource(tasks);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  customFilterPredicate(row, filter): boolean {
    let prozessFilter = false;
    let processStepFilter = false;
    let approverFilter = false;
    let reasonFilter = false;
    if (row.processName) {
      prozessFilter = row.processName.toLowerCase().includes(filter);
    }
    if (row.approver) {
      approverFilter = row.approver.toLowerCase().includes(filter);
    }
    if (row.reason) {
      reasonFilter = row.reason.toLowerCase().includes(filter);
    }
    if (row.processStep) {
      processStepFilter = row.processStep.toLowerCase().includes(filter);
    }
    return processStepFilter || prozessFilter || approverFilter || reasonFilter;
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if ($event.active === 'createdAt') {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    }
    if ($event.direction === 'desc') {
      sortedTasks.reverse();
    }
    this.dataSource.data = sortedTasks;
    this.cdr.detectChanges();
  }


}
