<div *ngIf="this.userRole === 'gib_director' || this.userRole === 'gib_admin' || this.userRole === 'gib_employee'">
  <div class="row" *ngFor="let video of _videos; let i = index">
    <div class="col-sm-10">
      <vg-player>
        <video [vgMedia]="media" #media preload="none" controls>
          <source [attr.src]="videoUrls[i]" type="video/mp4" />
        </video>
      </vg-player>
    </div>
    <div class="col-sm-2">
      <i class="material-icons delete-icon" (click)="openDeleteConfirmation(video)">delete</i>
    </div>
    <div class="col-sm-12">
      <label>{{ video.fileName.length > 45 ? trimFileName(video.fileName, 45) : video.fileName }}</label>
      <hr />
    </div>
  </div>
</div>

<div *ngIf="this.userRole === 'gib_trainer' || this.userRole === 'gib_customer'">
  <div class="row" *ngFor="let video of _videos; let i = index">
    <div class="col-sm-12">
      <vg-player>
        <video [vgMedia]="media" #media preload="none" controls>
          <source [attr.src]="videoUrls[i]" type="video/mp4" />
        </video>
      </vg-player>
    </div>
    <div class="col-sm-12">
      <label>{{ video.fileName.length > 45 ? trimFileName(video.fileName, 45) : video.fileName }}</label>
      <i *ngIf="this.enableVideoDownload" class="material-icons download" (click)="onDownload(video)" style="cursor: pointer">get_app</i>
    </div>
  </div>
</div>
