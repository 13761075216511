import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogConfirmationTextData {
  title: string;
  text: string;
  confirmationPlaceholderText: string;
}

@Component({
  selector: 'gib-text-confirmation-dialog',
  templateUrl: './gib-text-confirmation-dialog.component.html',
  styleUrls: ['./gib-text-confirmation-dialog.component.scss']
})
export class GibTextConfirmationDialogComponent {

  control: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef < GibTextConfirmationDialogComponent > , @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationTextData) {}

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.control.valid) {
      this.dialogRef.close(this.control.value);
    } else {
      this.control.markAsTouched();
    }
  }

}
