import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError, concatMap } from "rxjs/operators";
import * as customerActions from "../actions/customer.actions";
import * as fromServices from "../../services";
import { FormHelper } from "src/app/helper/form.helper";

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerService: fromServices.CustomerService,
    private formHelper: FormHelper
  ) {}

  loadCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(customerActions.LOAD_CUSTOMERS),
      concatMap(() => {
        return this.customerService.findAll().pipe(
          map((response) => {
            return new customerActions.LoadCustomersSuccess({customers: response.body})
          },
            catchError((error) => of(new customerActions.LoadCustomersFail(error)))
          )
        );
      })
    );
  });
}
