import { SelectOptions } from 'src/app/utils/select-options';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'internal-note',
  templateUrl: './internal-note.component.html',
  styleUrls: ['./internal-note.component.scss']
})
export class InternalNoteComponent implements OnInit {

  userRole;
  contactTypeOptions = SelectOptions.contactTypeOptions;
  yesNoOptions = SelectOptions.yesNoOptions;
  
  @Input() internalNoteForm: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.userRole = localStorage.getItem('role');
  }
}
