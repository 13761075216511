import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'online-meeting-information',
  templateUrl: './online-meeting-information.component.html',
  styleUrls: ['./online-meeting-information.component.scss']
})
export class OnlineMeetingInformationComponent implements OnInit {

  @Input() meetingForm;

  constructor() { }

  ngOnInit() {
  }

}
