import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import { AppComponent } from "../../app.component";
import { environment } from "../../../environments/environment";
import { EnvService } from "src/app/services/env.service";

export class MessageCounter {
  documentVerifications: any;
  tasks: any;
  events: any;
  trainers: any;
  customers: any;

  constructor() {
    this.documentVerifications = 0;
    this.tasks = 0;
    this.events = 0;
    this.trainers = 0;
    this.customers = 0;
  }
}

export class WebSocketAPI {
  webSocketEndPoint: string;
  stompClient: any;
  appComponent: AppComponent;
  constructor(appComponent: AppComponent, private envService: EnvService) {
    this.appComponent = appComponent;
  }
  _connect() {
    const token: string = localStorage.getItem("token");
    this.webSocketEndPoint = this.envService.backendUrl + "/gib-websocket?access_token=" + token;
    const ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;

    _this.stompClient.connect(
      {},
      (frame) => {
        _this.stompClient.subscribe("/gib-websocket/document-verification-count", (sdkEvent) => {
          _this.handleDocumentVerificationCount(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/employee-task-count", (sdkEvent) => {
          _this.handleTaskCount(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/employee-task-count-events", (sdkEvent) => {
          _this.handleEventsTaskCount(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/employee-task-count-trainers", (sdkEvent) => {
          _this.handleTrainersTaskCount(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/employee-task-count-customers", (sdkEvent) => {
          _this.handleCustomersTaskCount(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/customer-feedback", (sdkEvent) => {
          _this.handleCustomersFeedbackRefresh(sdkEvent);
        });
        _this.stompClient.subscribe("/gib-websocket/event-sign-off", (sdkEvent) => {
          _this.handleEventSignOffRefresh(sdkEvent);
        });
      },
      this.errorCallBack
    );
  }

  _disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  errorCallBack(error) {
    console.log("errorCallBack -> " + error);
  }

  handleDocumentVerificationCount(message) {
    this.appComponent.handleMessage(message.body, "documentVerifications");
  }

  handleTaskCount(message) {
    this.appComponent.handleMessage(message.body, "tasks");
  }

  handleEventsTaskCount(message) {
    this.appComponent.handleMessage(message.body, "events");
  }

  handleTrainersTaskCount(message) {
    this.appComponent.handleMessage(message.body, "trainers");
  }

  handleCustomersTaskCount(message) {
    this.appComponent.handleMessage(message.body, "customers");
  }

  handleCustomersFeedbackRefresh(message) {
    this.appComponent.handleMessage(message.body, "feedback");
  }
  handleEventSignOffRefresh(message) {
    this.appComponent.handleMessage(message.body, "event-sign-off");
  }
}
