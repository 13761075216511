<div class="row">
  <div class="col-sm-6">
    <gib-input [placeholder]="'moduleName'" [control]="eventHealthscreening.get('name')"></gib-input>
  </div>
  <div class="col-sm-3">
    <gib-datepicker [placeholder]="'vehicleModuleDateFrom'" [control]="eventHealthscreening.get('vehicleModuleDateFrom')"></gib-datepicker>
  </div>
  <div class="col-sm-3">
    <gib-timepicker [placeholder]="'modulePickup'" [control]="eventHealthscreening.get('vehicleModuleTimeFrom')"></gib-timepicker>
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    <gib-input [placeholder]="'vehicleDescription'" [control]="eventHealthscreening.get('vehicle')"></gib-input>
  </div>
  <div class="col-sm-3">
    <gib-input [placeholder]="'peopleCount'" [control]="eventHealthscreening.get('vehiclePassengerCount')"></gib-input>
  </div>
  <div class="col-sm-3">
    <gib-datepicker [placeholder]="'vehicleModuleDateTo'" [control]="eventHealthscreening.get('vehicleModuleDateTo')"></gib-datepicker>
  </div>
  <div class="col-sm-3">
    <gib-timepicker [placeholder]="'moduleReturn'" [control]="eventHealthscreening.get('vehicleModuleTimeTo')"></gib-timepicker>
  </div>
</div>