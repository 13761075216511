import { FormGroup, ValidatorFn } from "@angular/forms";

export function atLeastOneCheckboxCheckedValidator(minRequired = 1, fields: string[]): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    for (const field of fields) {
      const control = formGroup.controls[field];
      if (control.value === true) {
        checked++;
      }
    }
    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: {
          error: true,
          fields: [...fields],
        },
      };
    }
    return null;
  };
}
