<div *ngIf="type === 'customerFeedbackReport'">
  <h1> {{ 'customerFeedbackForm' | translate }} </h1>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="reportChartWrapper">
        <report-chart *ngIf="optionsOverallRating" [options]="optionsOverallRating"></report-chart>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="reportChartWrapper">
        <report-chart *ngIf="trainerContactedYou" [options]="trainerContactedYou"></report-chart>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="reportChartWrapper">
        <report-chart *ngIf="trainerOnTime" [options]="trainerOnTime"></report-chart>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="reportChartWrapper">
        <report-chart *ngIf="bookAgain" [options]="bookAgain"></report-chart>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'overallRatingCommentReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.overallRatingComment">
          - {{text}}
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'whyNotBookAgainReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.whyNotBookAgain">
          - {{text}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'improvementsReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.improvements">
          - {{text}}
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type === 'trainerFeedbackReport'">
  <h1> {{ 'trainerFeedbackForm' | translate }} </h1>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <report-chart *ngIf="optionsOverallRating" [options]="optionsOverallRating"></report-chart>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <report-chart *ngIf="orgaGib" [options]="orgaGib"></report-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <report-chart *ngIf="orgaLocation" [options]="orgaLocation"></report-chart>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <report-chart *ngIf="orgaCustomer" [options]="orgaCustomer"></report-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <report-chart *ngIf="measuringDevice" [options]="measuringDevice"></report-chart>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'overallRatingCommentReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.overallRatingComment">
          - {{text}}
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'orgaCommentReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.orgaComment">
          - {{text}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'measuringDeviceCommentReportTitle'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.measuringDeviceComment">
          - {{text}}
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 stringArrayWrapper">
      <div class="stringArrayElement" *ngIf="optionsOverallRating">
        <gib-h5 [text]="'generalComment'"></gib-h5>
        <div *ngFor="let text of stringArrayMap.generalComment">
          - {{text}}
        </div>
      </div>
    </div>
  </div>
</div>