import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gib-gdpr-checkbox',
  templateUrl: './gib-gdpr-checkbox.component.html',
  styleUrls: ['./gib-gdpr-checkbox.component.scss']
})
export class GibGdprCheckboxComponent implements OnInit {

  text: string;
  
  @Input() control: UntypedFormControl;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.text = this.translateService.instant('privacyCheck');
  }

}