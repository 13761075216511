<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.text"></p>
  <gib-datepicker [placeholder]="'abortDate'" [control]="cancelationDate"></gib-datepicker>
  <gib-input [placeholder]="'abortReason'" [control]="reason"></gib-input>
</div>
<gib-radio-button-group [control]="forceDelete" [valueLabels]="deleteOptions"></gib-radio-button-group>
<div mat-dialog-actions>
  <button mat-button (click)="cancelAction()">{{ "CANCEL" | translate }}</button>
  <button class="btn btn-secondary" (click)="confirmAction()" mat-button cdkFocusInitial>Ok</button>
</div>
