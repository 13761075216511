import { map } from "rxjs/operators";
import { FileService, FileMeta } from "./../../../../services/file.service";
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Employee, EmployeeService } from "src/app/services/employee.service";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { ValueLabel } from "../../../../components/value-label";
import { FormHelper } from "../../../../helper/form.helper";
import { HealthScreeningService } from "../../../../services/health-screening.service";
import { KeycloakConnectorService } from "../../../../services/keycloak-connector.service";
import { SelectOptions } from "../../../../utils/select-options";
import { EventEmitterService } from "src/app/services/eventEmitterService";
import { Subscription } from "rxjs";
import { CustomValidators } from "src/app/utils/custom-validator";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "add-trainer",
  templateUrl: "./add-trainer.component.html",
  styleUrls: ["./add-trainer.component.scss"],
})
export class AddTrainerComponent implements OnInit {
  _employee: Employee;
  availableHealthscreenings = [];
  salutationOptions = SelectOptions.salutationOptions;
  locations = SelectOptions.trainerLocations;
  states = SelectOptions.trainerStates;
  images: FileMeta[] = [];
  // documents: FileMeta[] = [];
  imageUrls: string[] = [];

  imageUploadForm = this.formbuilder.group({
    imageControl: [null, []],
  });
  afgFormControl = new FormControl(false)

  @Input() set employee(emp: Employee) {
    this._employee = emp;
    this._employee.assignedHealthscreenings.sort(this.formHelper.sortByModuleName)
    this.form = this.initFormGroup();
    this.initFormListener();
    // if (emp.id) {
    //   this.loadDocuments();
    // }
  }

  get employee(): Employee {
    return this._employee;
  }

  @Input() roles: ValueLabel[];
  @Output() employeeAdded = new EventEmitter<string>();

  form: UntypedFormGroup;

  constructor(
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private formHelper: FormHelper,
    private keycloakConnector: KeycloakConnectorService,
    private healthScreeningService: HealthScreeningService,
    private formbuilder: UntypedFormBuilder,
    private fileService: FileService,
    private eventEmitterService: EventEmitterService,
    private changeDetector: ChangeDetectorRef,
    private customValidators: CustomValidators,
    private configService: ConfigurationService
  ) {}

  ngOnInit() {
    this.healthScreeningService.findAllOverview().subscribe((res) => {
      this.availableHealthscreenings = res.body.sort(this.formHelper.sortByModuleName);
    });
  }

  initFormGroup(): UntypedFormGroup {
    const employeeForm = this.employeeService.mapEmployeeToForm(this._employee);
    employeeForm.get("salutation").setValidators(Validators.required);
    employeeForm.get("username").setValidators(Validators.required);
    employeeForm.get("firstname").setValidators(Validators.required);
    employeeForm.get("lastname").setValidators(Validators.required);
    employeeForm.get("role").setValidators(Validators.required);
    employeeForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    employeeForm.get("phonenumber").setValidators(Validators.required);
    if (this._employee.role === 'gib_afg') {
      this.afgFormControl.setValue(true);
    }
    if (this._employee.id) {
      this.afgFormControl.disable();
    }
    return employeeForm;
  }

  initFormListener() {
    // GPD-106: automatically set username, if first- and lastname are set
    this.form.get("firstname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("lastname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
    this.form.get("lastname").valueChanges.subscribe((val) => {
      if (val.length >= 1 && this.form.get("firstname").value.length >= 1 && this.form.get("username").value === "") {
        this.presetUsername();
      }
    });
  }

  presetUsername() {
    const newUsername = this.form.get("firstname").value.charAt(0).toLowerCase() + this.form.get("lastname").value.charAt(0).toLowerCase() + "-gib";
    this.form.get("username").setValue(newUsername);
  }

  addEmployeeClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "EMPLOYEE_ADDED_ERROR_TITLE", () => this.addEmployee());
  }

  addEmployee() {
    const employee = this.employeeService.mapFormToEmployee(this.form);
    if (this.afgFormControl?.value === true) {
      employee.role = 'gib_afg';
    }
    employee.assignedHealthscreenings = this._employee.assignedHealthscreenings;
    employee.addedAt = new Date();
    this.keycloakConnector
      .createUser(employee)
      .then((userId) => {
        employee.id = userId;
        this.configService.findAll().subscribe(res => {
          let defaultPassword = res.body.find(item => item.key === 'DEFAULT_PASSWORD').value;
          this.keycloakConnector.resetPassword(employee.id, defaultPassword, true);
          this.employeeService.create(employee).subscribe(
            (res) => {
              this.openSuccessFullAddedDialog(res.body);
              this.employeeAdded.emit(null);
              this.eventEmitterService.emitEvent();
            },
            (error) => {
              this.keycloakConnector.deleteUser(userId).subscribe(() => {});
            }
          );
        });
      })
      .catch((err) => {
        this.openErrorAddedDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      });
  }

  updateEmployeeClicked() {
    this.formHelper.isFormValidElseShowErrors(this.form, "EMPLOYEE_EDIT_ERROR_TITLE", () => this.updateEmployee());
  }

  updateEmployee() {
    const employee = this.employeeService.mapFormToEmployee(this.form);
    employee.id = this._employee.id;
    employee.assignedHealthscreenings = this._employee.assignedHealthscreenings;
    this.keycloakConnector.updateUser(employee).then(
      () => {
        this.employeeService.update(employee).subscribe((res) => {
          this.openSuccessFullEditDialog(res.body);
          this.employeeAdded.emit(null);
          this.eventEmitterService.emitEvent();
        });
      },
      (err) => {
        this.openErrorEditDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      }
    );
  }

  cancel() {
    // do nothing, just emit with null to close the panel
    this.employeeAdded.emit(null);
  }

  private openSuccessFullAddedDialog(emp: Employee): void {
    const empFullName = this.employeeService.getFullName(emp);
    const title = this.translateService.instant("EMPLOYEE_ADDED_SUCCESS_TITLE");
    const text = this.translateService.instant("EMPLOYEE_ADDED_SUCCESS_TEXT", { employeeName: empFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openSuccessFullEditDialog(emp: Employee): void {
    const empFullName = this.employeeService.getFullName(emp);
    const title = this.translateService.instant("EMPLOYEE_EDIT_SUCCESS_TITLE");
    const text = this.translateService.instant("EMPLOYEE_EDIT_SUCCESS_TEXT", { employeeName: empFullName });
    this.dialogService.openDialog(title, text, "success");
  }

  private openErrorAddedDialog(errorMessage: string): void {
    const title = this.translateService.instant("EMPLOYEE_ADDED_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  private openErrorEditDialog(errorMessage: string): void {
    const title = this.translateService.instant("EMPLOYEE_EDIT_ERROR_TITLE");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  onDocumentEvent() {}
}
