<mat-form-field class="example-full-width">
  <input [disabled]="disabled" (focusout)="trim()" trim matInput placeholder="{{ placeholder }} " [formControl]="inputControl" type="{{ type }}" />
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('email')">{{ "email_invalid" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('password')">{{ "password_invalid" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noMobileNumber')">{{ "no_mobile_number" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('pwNotSame')">{{ "pwsNotSame" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noValidNumber')">{{ noValidNumberErrorMessage }}</mat-error>
</mat-form-field>
