import { GibDialogService } from './../../../../components/dialogs/gib-dialog.service';
import { FeedbackService, EventFeedbackTrainer } from '../../../../services/feedback.service';
import { FormHelper } from '../../../../helper/form.helper';
import { SelectOptions } from '../../../../utils/select-options';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventInquiryHealthscreening, EventService } from '../../../../services/event.service';

@Component({
  selector: 'trainer-feedback-form',
  templateUrl: './trainer-feedback-form.component.html',
  styleUrls: ['./trainer-feedback-form.component.scss']
})
export class TrainerFeedbackFormComponent implements OnInit {

  feedbackOptions = SelectOptions.feedbackOptions;
  feedbackBooleans = SelectOptions.feedbackBooleans;

  @Input() eventForm: UntypedFormGroup;
  @Input() buid: string;
  @Input() eihs: EventInquiryHealthscreening;
  @Input() eventFeedbackTrainerForm: UntypedFormGroup;

  @Output() feedbackSubmitted = new EventEmitter();

  // eventFeedbackTrainerForm: FormGroup = this.initFormGroup();
  eihsIndex: string;
  showButton = true;

  constructor(private formHelper: FormHelper, private feedbackService: FeedbackService,
              private translateService: TranslateService, private dialogService: GibDialogService,
              private eventService: EventService) {}

  ngOnInit() {
    this.eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(this.eventService.mapFormToEvent(this.eventForm), this.eihs.id);
    this.formHelper.disableControlsByName(this.eventForm, ['corporateCustomerCompanyName', 'customerContactFirstname',
      'customerContactLastname', 'customerPhone', 'orderNumber', 'eventDate', 'startTime',
      'endTime', 'customerCompanyName'
    ]);
    this.formHelper.disableControlsByName(this.eventForm.get('eventHealthScreenings').get(this.eihsIndex).get('trainer').get('0'), [
      'fullName'
    ]);
  }


  submitTrainerFeedbackClicked() {
    const title = this.translateService.instant('feedbackTitle');
    const text = this.translateService.instant('feedbackText');
    this.formHelper.isFormValidElseShowErrors(this.eventFeedbackTrainerForm, 'feedbackErrorTitle', () => this.dialogService.openConfirmationDialog(title, text, () => this.submitTrainerFeedback()));
  }

  submitTrainerFeedback() {
    const feedback = this.feedbackService.mapFormToTrainerFeedback(this.eventFeedbackTrainerForm);
    feedback.eihs = {
      id: this.eihs.id,
      eventInquiry: { id: this.eihs.eventInquiry.id }
    };
    const feedbackBody = {
      feedback,
      buid: this.buid
    };
    this.feedbackService.submitEventFeedbackTrainer(feedbackBody).subscribe(res => {
      this.formHelper.disableControls(this.eventFeedbackTrainerForm);
      this.feedbackSubmitted.emit(feedback);
      this.showButton = false;
    });
  }

}
