import { EventEmitterService } from "./../../../services/eventEmitterService";
import { TrainerTableComponent } from "./../components/trainer-table/trainer-table.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "../../../components/value-label";
import { Employee, EmployeeService } from "../../../services/employee.service";
import { FormHelper } from "../../../helper/form.helper";
import { AddPersonBottomSheetComponent } from "../components/add-person-bottom-sheet/add-person-bottom-sheet.component";
import { getAllEmployees, GibState } from "src/app/store";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { Role } from "src/app/services/keycloak-connector.service";
import { Observable } from "rxjs";

@Component({
  selector: "trainer-page",
  templateUrl: "./trainer-page.component.html",
  styleUrls: ["./trainer-page.component.scss"],
})
export class TrainerPageComponent implements OnInit {
  @ViewChild("emptable", { static: true }) employeeTable: TrainerTableComponent;

  employees$: Observable<Employee[]> = this.store.select(getAllEmployees);
  selectedEmployee: Employee;
  rolesToFind = ["gib_trainer", "gib_afg"] as Role[]

  constructor(
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private formHelper: FormHelper,
    private bottomSheet: MatBottomSheet,
    private eventEmitterService: EventEmitterService,
    private store: Store<fromStore.GibState>
  ) {}

  ngOnInit() {
    this.store.dispatch(
      new fromStore.LoadEmployeesByRole({roles: this.rolesToFind})
    )
    this.eventEmitterService.listen().subscribe(() => {
      this.fetchTrainer();
    });
  }

  fetchTrainer() {
    this.employeeTable.resetDisplayedColumns();
    this.selectedEmployee = null;
    this.store.dispatch(
      new fromStore.LoadEmployeesByRole({roles: this.rolesToFind})
    )
  }

  openBottomSheet(trainer?: Employee) {
    if (!trainer) {
      this.selectedEmployee = new Employee();
      this.selectedEmployee.role = "gib_trainer";
      const sheetData = { selectedEmployee: this.selectedEmployee };
      this.bottomSheet.open(AddPersonBottomSheetComponent, { data: sheetData });
    } else {
      this.selectedEmployee = trainer;
      this.employeeService.findOne(this.selectedEmployee.id).subscribe((res) => {
        const sheetData = { selectedEmployee: res.body };
        this.bottomSheet.open(AddPersonBottomSheetComponent, { data: sheetData });
      });
    }
  }

}
