import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "src/app/components/value-label";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../helper/form.helper";
import { EmployeeService } from "../../../services/employee.service";
import { FileMeta, FileService } from "../../../services/file.service";
import { HealthScreening, HealthScreeningService } from "../../../services/health-screening.service";
import { SelectOptions } from "../../../utils/select-options";
import { atLeastOneCheckboxCheckedValidator } from "src/app/validators/checkbox.validator";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "create-health-screening-page",
  templateUrl: "./create-health-screening-page.component.html",
  styleUrls: ["./create-health-screening-page.component.scss"],
})
export class CreateHealthScreeningPageComponent implements OnInit {
  healthScreeningObject: HealthScreening = new HealthScreening();

  healthScreeningForm: UntypedFormGroup = this.initFormGroup();

  availableTrainer = [];

  imageUploadForm = this.formbuilder.group({
    imageControl: [null, []],
  });

  videoUploadForm = this.formbuilder.group({
    videoControl: [null, []],
    videoType: [null, []],
  });

  images: FileMeta[];
  imageUrls: string[] = [];

  videoTrainingUrls: string[] = [];
  videoMarketingUrls: string[] = [];

  videosTraining: FileMeta[];
  videosMarketing: FileMeta[];

  videoTypes: ValueLabel[] = SelectOptions.videoTypes;
  companynameAlias: string = "";

  constructor(
    private formHelper: FormHelper,
    private healthScreeningService: HealthScreeningService,
    private fileService: FileService,
    private translateService: TranslateService,
    private formbuilder: UntypedFormBuilder,
    private dialogService: GibDialogService,
    public router: Router,
    private employeeService: EmployeeService,
    private localtion: Location,
    private route: ActivatedRoute
  ) {}

  initFormGroup(): UntypedFormGroup {
    const healthscreeningForm = this.healthScreeningService.createHealthscreeningForm(new HealthScreening());
    healthscreeningForm.get("moduleType").setValidators(Validators.required);
    healthscreeningForm.get("topic").setValidators(Validators.required);
    healthscreeningForm.setValidators(atLeastOneCheckboxCheckedValidator(1, ["online", "onsite"]));
    return healthscreeningForm;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.companynameAlias = params["companynameAlias"];
      this.companynameAlias = this.companynameAlias.toUpperCase();
    });
    this.employeeService.findByRoles(["gib_trainer"]).subscribe((res) => {
      if (res && res.body) {
        this.availableTrainer = res.body.sort(this.formHelper.sortByLastname);
      }
    });
  }

  onBasicSettingsNext(stepper: MatStepper) {
    this.formHelper.isFormValidElseShowErrors(this.healthScreeningForm, "createHealthScreeningErrorTitle", () => {
      stepper.next();
    });
  }

  onSelectionChange(event) {
    const { previouslySelectedIndex, selectedIndex } = event;
    switch (selectedIndex) {
      case 0: {
        this.healthScreeningForm.get("moduleName").clearValidators();
        this.healthScreeningForm.controls["moduleName"].updateValueAndValidity();
        this.healthScreeningForm.get("moduleNameEn").clearValidators();
        this.healthScreeningForm.controls["moduleNameEn"].updateValueAndValidity();
        this.healthScreeningForm.get("shortDescription").clearValidators();
        this.healthScreeningForm.controls["shortDescription"].updateValueAndValidity();
        this.healthScreeningForm.get("recommendedOnsiteInterval").clearValidators();
        this.healthScreeningForm.controls["recommendedOnsiteInterval"].updateValueAndValidity();
        this.healthScreeningForm.get("recommendedOnlineInterval").clearValidators();
        this.healthScreeningForm.controls["recommendedOnlineInterval"].updateValueAndValidity();
        this.healthScreeningForm.get("size").clearValidators();
        this.healthScreeningForm.controls["size"].updateValueAndValidity();
        this.healthScreeningForm.get("power").clearValidators();
        this.healthScreeningForm.controls["power"].updateValueAndValidity();
        this.healthScreeningForm.get("location").clearValidators();
        this.healthScreeningForm.controls["location"].updateValueAndValidity();

        break;
      }
      case 1: {
        this.healthScreeningForm.get("moduleName").setValidators(Validators.required);
        this.healthScreeningForm.controls["moduleName"].updateValueAndValidity();
        this.healthScreeningForm.get("moduleNameEn").setValidators(Validators.required);
        this.healthScreeningForm.controls["moduleNameEn"].updateValueAndValidity();
        this.healthScreeningForm.get("shortDescription").setValidators(Validators.required);
        this.healthScreeningForm.controls["shortDescription"].updateValueAndValidity();

        const isHealthModule = this.healthScreeningForm.get("moduleType").value === "HEALTH_MODULE";
        const isOnline = this.healthScreeningForm.get("online").value;
        const isOnsite = this.healthScreeningForm.get("onsite").value;

        if (isHealthModule) {
          if (isOnsite) {
            this.healthScreeningForm.get("recommendedOnsiteInterval").setValidators(Validators.required);
            this.healthScreeningForm.controls["recommendedOnsiteInterval"].updateValueAndValidity();
          }
          if (isOnline) {
            this.healthScreeningForm.get("recommendedOnlineInterval").setValidators(Validators.required);
            this.healthScreeningForm.controls["recommendedOnlineInterval"].updateValueAndValidity();
          }
        }

        if (isOnsite) {
          this.healthScreeningForm.get("size").setValidators(Validators.required);
          this.healthScreeningForm.controls["size"].updateValueAndValidity();
          this.healthScreeningForm.get("power").setValidators(Validators.required);
          this.healthScreeningForm.controls["power"].updateValueAndValidity();
          this.healthScreeningForm.get("location").setValidators(Validators.required);
          this.healthScreeningForm.controls["location"].updateValueAndValidity();
        } else {
          this.healthScreeningForm.get("size").removeValidators(Validators.required);
          this.healthScreeningForm.get("size").updateValueAndValidity();
          this.healthScreeningForm.get("power").removeValidators(Validators.required);
          this.healthScreeningForm.get("power").updateValueAndValidity();
          this.healthScreeningForm.get("location").removeValidators(Validators.required);
          this.healthScreeningForm.get("location").updateValueAndValidity();
        }

        break;
      }
    }
  }

  loadImages() {
    this.fileService.getFileMetaByHealthscreening(this.healthScreeningObject.id, "HEALTHSCREENING_PICTURE").subscribe((res) => {
      this.images = res;
    });
  }

  setImageUrls(imageUrls: string[]) {
    this.imageUrls = imageUrls;
  }

  loadTrainingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthScreeningObject.id, "HEALTHSCREENING_VIDEO_TRAINING").subscribe((res) => {
      this.videosTraining = res;
    });
  }

  loadMarketingVideos() {
    this.fileService.getFileMetaByHealthscreening(this.healthScreeningObject.id, "HEALTHSCREENING_VIDEO_PRESENTATION").subscribe((res) => {
      this.videosMarketing = res;
    });
  }

  loadVideosByType() {
    if (this.videoUploadForm.get("videoType").value === "HEALTHSCREENING_VIDEO_TRAINING") {
      this.loadTrainingVideos();
    } else if (this.videoUploadForm.get("videoType").value === "HEALTHSCREENING_VIDEO_PRESENTATION") {
      this.loadMarketingVideos();
    }
  }

  setTrainingVideoUrls(videoUrls: string[]) {
    this.videoTrainingUrls = videoUrls;
  }

  setMarketingVideoUrls(videoUrls: string[]) {
    this.videoMarketingUrls = videoUrls;
  }

  createHealthScreeningClicked() {
    this.handleRestrictedVisibility();
    this.formHelper.isFormValidElseShowErrors(this.healthScreeningForm, "createHealthScreeningErrorTitle", () => this.createHealthScreening());
  }

  editHealthScreeningClicked() {
    this.handleRestrictedVisibility();
    this.formHelper.isFormValidElseShowErrors(this.healthScreeningForm, "editHealthScreeningErrorTitle", () => this.editHealthScreening());
  }

  private handleRestrictedVisibility() {
    const visibileForCompanynameAliases = this.healthScreeningForm.get("visibileForCompanynameAliases").value;
    if (visibileForCompanynameAliases && visibileForCompanynameAliases.length > 0) {
      this.healthScreeningForm.get("restrictedVisibility").setValue(true);
    } else {
      this.healthScreeningForm.get("restrictedVisibility").setValue(false);
    }
  }

  createHealthScreening() {
    const healthScreening = this.healthScreeningService.mapFormToHealthScreening(this.healthScreeningForm);
    healthScreening.assignedUsers = this.healthScreeningObject.assignedUsers;
    this.healthScreeningService.create(healthScreening).subscribe((res) => {
      this.healthScreeningObject = res.body;
      this.healthScreeningForm = this.healthScreeningService.createHealthscreeningForm(this.healthScreeningObject);
    });
  }

  editHealthScreening() {
    const healthScreening = this.healthScreeningService.mapFormToHealthScreening(this.healthScreeningForm);
    healthScreening.assignedUsers = this.healthScreeningObject.assignedUsers;
    healthScreening.id = this.healthScreeningObject.id;
    this.healthScreeningService.update(healthScreening).subscribe((res) => {
      this.healthScreeningObject = res.body;
    });
  }

  cancelClicked() {
    const title = this.translateService.instant("HEALTHSCREENING_CANCEL_CREATION_CONFIRMATION_TITLE");
    const text = this.translateService.instant("HEALTHSCREENING_CANCEL_CREATION_CONFIRMATION_TEXT");
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteHealthScreening(this.healthScreeningObject.id));
  }

  deleteHealthScreening(id: number) {
    if (id) {
      this.healthScreeningService.delete(id).subscribe(() => {
        this.localtion.back();
      });
    } else {
      this.localtion.back();
    }
  }

  saveAndBackToOverview() {
    if (this.healthScreeningObject.id) {
      const healthScreening = this.healthScreeningService.mapFormToHealthScreening(this.healthScreeningForm);
      healthScreening.assignedUsers = this.healthScreeningObject.assignedUsers;
      healthScreening.id = this.healthScreeningObject.id;
      this.healthScreeningService.update(healthScreening).subscribe(() => {
        this.localtion.back();
      });
    } else {
      this.localtion.back();
    }
  }

  onClose(): void {
    this.router.navigate(["health-screening-overview-page", this.companynameAlias]);
  }

  get isOnline(): boolean {
    return this.healthScreeningForm.get("online").value;
  }

  get isOnsite(): boolean {
    return this.healthScreeningForm.get("onsite").value;
  }
}
