import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatBottomSheet } from "@angular/material/bottom-sheet";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../../helper/form.helper";
import { CustomerService } from "../../../../services/customer.service";
import { Chat, EventService, EventInquiryHealthscreening } from "../../../../services/event.service";
import { KeycloakConnectorService } from "../../../../services/keycloak-connector.service";
import { ProcessService, TaskOutcome } from "../../../../services/process-service";
import { InternalNoteService } from "src/app/services/internal-note.service";

@Component({
  selector: "task-bottom-sheet",
  templateUrl: "./task-bottom-sheet.component.html",
  styleUrls: ["./task-bottom-sheet.component.scss"],
  animations: [trigger("visibility", [state("visible", style({ opacity: 1, height: "*" })), state("hidden", style({ opacity: 0, height: "0px" })), transition("visible <=> hidden", animate(500))])],
})
export class TaskBottomSheetComponent implements OnInit {
  editMode: boolean;
  showPrintButtons: boolean = true;

  eventForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;
  trainerForEventForm: UntypedFormGroup;
  internalNoteForm: UntypedFormGroup;
  chatElements: Chat[];
  showButtons = true;
  result: string;
  resultColor = "#000";
  task: any;
  eihs: EventInquiryHealthscreening;
  feedbackForm: UntypedFormGroup;
  buttonProps: any[] = [];
  userRole = "";
  buid;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private formHelper: FormHelper,
    private eventService: EventService,
    private translateService: TranslateService,
    private dialogService: GibDialogService,
    private customerService: CustomerService,
    private processService: ProcessService,
    private cdr: ChangeDetectorRef,
    private keycloakConnector: KeycloakConnectorService,
    private bottomSheetRef: MatBottomSheetRef<TaskBottomSheetComponent>,
    private bottomSheet: MatBottomSheet,
    private internalNoteService: InternalNoteService
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.eventForm = this.data.eventForm;
    this.customerForm = this.data.customerForm;
    this.trainerForEventForm = this.data.trainerForEventForm;
    this.feedbackForm = this.data.feedbackForm;
    this.internalNoteForm = this.data.internalNoteForm;
    this.task = this.data.task;
    this.eihs = this.data.eihs;
    if (this.task) {
      this.loadChat();
      this.setButtonProperties();
    }
    if (this.eventForm) {
      this.buid = this.eventForm.get("buid").value;
    }
  }

  setButtonProperties() {
    switch (this.task.task.taskDefinitionKey) {
      case "ut_check_availability":
        const button1: any = {};
        button1.type = "success";
        button1.action = this.submitTaskClicked.bind(this);
        button1.icon = "check";
        button1.text = "available";
        this.buttonProps.push(button1);
        const button2: any = {};
        button2.type = "danger";
        button2.action = this.rejectTaskClicked.bind(this);
        button2.icon = "close";
        button2.text = "not_available";
        this.buttonProps.push(button2);
        break;
      case "ut_process_query":
        const queryButton: any = {};
        queryButton.type = "secondary";
        queryButton.action = this.queryTaskClicked.bind(this);
        queryButton.icon = "chat";
        queryButton.text = "responseQuery";
        this.buttonProps.push(queryButton);
        break;
      case "ut_create_customer":
        const ccButton: any = {};
        ccButton.type = "secondary";
        ccButton.action = this.createCustomerClicked.bind(this);
        ccButton.icon = "person_add";
        ccButton.text = "createCustomer";
        this.buttonProps.push(ccButton);
        break;
      case "ut_create_companyname_alias":
        const ccaButton: any = {};
        ccaButton.type = "secondary";
        ccaButton.action = this.saveCustomerClicked.bind(this);
        ccaButton.icon = "check";
        ccaButton.text = "save";
        this.buttonProps.push(ccaButton);
        break;
      case "ut_edit_course_template":
        const ecButton: any = {};
        ecButton.type = "primary";
        ecButton.action = this.editCoursClicked.bind(this);
        ecButton.icon = "done_all";
        ecButton.text = "editCourseEdited";
        this.buttonProps.push(ecButton);
        break;
      case "ut_edit_course_tempalte":
        const tempalteButton: any = {};
        tempalteButton.type = "primary";
        tempalteButton.action = this.editCoursClicked.bind(this);
        tempalteButton.icon = "done_all";
        tempalteButton.text = "editCourseEdited";
        this.buttonProps.push(tempalteButton);
        break;
      case "ut_select_trainer":
        const scButton: any = {};
        scButton.type = "primary";
        scButton.action = this.trainerSelectionClicked.bind(this);
        scButton.icon = "supervisor_account";
        scButton.text = "completeTrainerSelection";
        this.buttonProps.push(scButton);
        break;
      case "ut_trainer_call_customer":
        const tcButton: any = {};
        tcButton.type = "primary";
        tcButton.action = this.customerCalledClicked.bind(this);
        tcButton.icon = "phone";
        tcButton.text = "calledCustomer";
        this.buttonProps.push(tcButton);
        break;
      case "ut_inform_about_failure_on_call":
        const fcButton: any = {};
        fcButton.type = "primary";
        fcButton.action = this.customerInformedClicked.bind(this);
        fcButton.icon = "perm_phone_msg";
        fcButton.text = "informAboutFailure";
        this.buttonProps.push(fcButton);
        break;
      case "ut_gib_call_customer":
        const gccButton: any = {};
        gccButton.type = "primary";
        gccButton.action = this.customerCalledClicked.bind(this);
        gccButton.icon = "phone";
        gccButton.text = "calledCustomer";
        this.buttonProps.push(gccButton);
        break;
      case "ut_call_customer":
        const cccButton: any = {};
        cccButton.type = "primary";
        cccButton.action = this.customerCalledClicked.bind(this);
        cccButton.icon = "phone";
        cccButton.text = "calledCustomer";
        this.buttonProps.push(cccButton);
        break;
      default:
        break;
    }
  }

  startEditMode() {
    if (this.eventForm) {
      this.formHelper.enableControls(this.eventForm);
      this.formHelper.enableControls(this.internalNoteForm);
      // enable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and enable the controls into the component itself
      if (this.task.task.taskDefinitionKey === "ut_check_availability") {
        this.eventForm.get("eventHealthScreenings").enable();
      }
      this.formHelper.disableControlsByName(this.eventForm, ["bookingCopyRecepientMail", "bookingCopyRecepientMailTemp"]);
      for (const ehs of this.eventForm.get("eventHealthScreenings")["controls"]) {
        if (ehs.get("scheduleEntries").value) {
          for (const scheduleEntry of ehs.get("scheduleEntries")["controls"]) {
            this.formHelper.disableControlsByName(scheduleEntry, ["timeFrom", "timeTo"]);
          }
        }
        if (ehs.get("trainer").value) {
          for (const trainer of ehs.get("trainer")["controls"]) {
            this.formHelper.disableControls(trainer);
          }
        }
        if (ehs.get("scheduleEntries").value && ehs.get("scheduleEntries").value.length > 0) {
          this.formHelper.disableControlsByName(ehs, ["onlineModule"]);
          this.formHelper.disableControlsByName(ehs, ["hybrid"]);
        }
      }
      if (this.feedbackForm) {
        this.formHelper.enableControls(this.feedbackForm);
      }
      if (this.eventForm.get("customer").value && this.eventForm.get("customer").get("id").value) {
        this.formHelper.disableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
        this.formHelper.disableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
      }
    } else if (this.trainerForEventForm) {
      this.formHelper.enableControls(this.trainerForEventForm);
    } else if (this.customerForm) {
      this.formHelper.enableControls(this.customerForm);
    }
    this.editMode = true;
    this.showPrintButtons = false;
    this.bottomSheetRef.disableClose = true;
  }

  updateTask() {
    let eventToUpdate = null;
    if (this.eventForm) {
      eventToUpdate = this.eventService.mapFormToEvent(this.eventForm);
    } else if (this.trainerForEventForm) {
      eventToUpdate = this.eventService.mapFormToEvent(this.trainerForEventForm);
    }
    const internalNote = this.internalNoteService.mapFormToInternalNote(this.internalNoteForm);

    this.eventService.update(eventToUpdate).subscribe((res) => {
      this.editMode = false;
      this.showPrintButtons = true;
      this.bottomSheetRef.disableClose = false;
      if (this.eventForm) {
        this.formHelper.disableControls(this.eventForm);
        // disable separately again to throw the valuechange event here,
        // so the module selection component can handle the listener and disable the controls into the component itself
        this.eventForm.get("eventHealthScreenings").disable();
      } else if (this.trainerForEventForm) {
        this.formHelper.disableControls(this.trainerForEventForm);
      } else if (this.customerForm) {
        this.formHelper.disableControls(this.customerForm);
      }
      internalNote.id = res.body.id;
      this.internalNoteService.update(internalNote, this.userRole).subscribe((res) => {
        this.formHelper.disableControls(this.internalNoteForm);
        this.cdr.detectChanges();
      });
    });
  }

  cancel() {
    this.eventService.findById(this.eventForm.get("id").value).subscribe((res) => {
      this.editMode = false;
      this.showPrintButtons = true;
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(this.eventForm);
      // disable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and disable the controls into the component itself
      this.eventForm.get("eventHealthScreenings").disable();
      this.cdr.detectChanges();

      this.internalNoteService.getById(this.eventForm.get("id").value, this.userRole).subscribe((res) => {
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
        this.formHelper.disableControls(this.internalNoteForm);
      });
    });

    // this.eventService.findById(this.eventForm.get("id").value).subscribe((res) => {
    //   this.editMode = false;
    //   this.showPrintButtons = true;
    //   this.eventForm = this.eventService.mapEventToForm(res.body);
    //   this.formHelper.disableControls(this.eventForm);
    //   // disable separately again to throw the valuechange event here,
    //   // so the module selection component can handle the listener and disable the controls into the component itself
    //   this.eventForm.get("eventHealthScreenings").disable();
    //   this.cdr.detectChanges();

    //   this.internalNoteService.getById(this.eventForm.get("id").value, this.userRole).subscribe((res) => {
    //     this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
    //     this.formHelper.disableControls(this.internalNoteForm);
    //   });
    // });
  }

  loadChat() {
    this.eventService.getChatHistory(this.task.buid, "Antwort_Buchungsformular").subscribe((res) => {
      this.chatElements = res.body;
      this.cdr.detectChanges();
    });
  }

  trainerSelectionClicked() {
    const title = this.translateService.instant("completeTrainerSelection");
    const text = this.translateService.instant("completeTrainerSelectionText");
    this.dialogService.openConfirmationDialog(title, text, () => this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "trainerSelectionCompleted"));
  }

  onDebug() {
    return this.eventService.isEveryModulescheduled(this.eventService.mapFormToEvent(this.eventForm)); // || this.eventForm.get("skipSchedules").value;
  }

  editCoursClicked() {
    if (this.eventService.isEveryModulescheduled(this.eventService.mapFormToEvent(this.eventForm))) {
      const title = this.translateService.instant("editCourseEdited");
      const text = this.translateService.instant("editCourseEditedText");
      this.dialogService.openConfirmationDialog(title, text, () => this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "editCourseWereEdited"));
    } else {
      const errorMessage = this.translateService.instant("EveryModuleNeedshedule");
      this.dialogService.openErrorDialog("taskNotCompleteTitle", [errorMessage]);
    }
  }

  submitTaskClicked() {
    const title = this.translateService.instant("eventAvailableTitle");
    const text = this.translateService.instant("eventAvailableText");
    this.dialogService.openConfirmationDialog(title, text, () => this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "eventMarkedAsAvailable"));
  }

  customerCalledClicked() {
    const title = this.translateService.instant("customerCalledTitle");
    const text = this.translateService.instant("customerCalledText");
    this.dialogService.openConfirmationDialog(title, text, () => this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "customerCalled"));
  }

  customerInformedClicked() {
    this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "customerInformed");
  }

  queryTaskClicked() {
    const title = this.translateService.instant("queryResponseTitle");
    const text = this.translateService.instant("queryResponseText");
    const confirmationPlaceholderText = "queryResponse";
    this.dialogService.openConfirmationTextDialog(title, text, confirmationPlaceholderText).subscribe((res) => {
      if (res) {
        const params = new Map();
        params.set("query", res);
        params.set("AUTHOR", localStorage.officialName);
        this.completeTask("QUERY", this.task.task.id, res, "#000", "eventQueriedByEmployee", params);
      }
    });
  }

  rejectTaskClicked() {
    const title = this.translateService.instant("eventNotAvailableTitle");
    const text = this.translateService.instant("eventNotAvailableText");
    this.dialogService.openConfirmationDialog(title, text, () => this.completeTask("REJECTED", this.task.task.id, null, "#000", "eventMarkedAsNotAvailable"));
  }

  submitTrainerFeedbackTask() {
    this.completeTask("APPROVED", this.task.task.id, null, "var(--success)", "feedbackSubmitted");
  }

  completeTask(outcome: TaskOutcome, taskId: string, query: string, resultColor: string, resultText: string, params?: Map<string, string>) {
    this.processService.completeTask(taskId, outcome, query, params).subscribe((res) => {
      this.showButtons = false;
      this.result = resultText;
      this.resultColor = resultColor;
      if (this.trainerForEventForm) {
        this.formHelper.disableControls(this.trainerForEventForm);
      }
      if (outcome === "QUERY") {
        this.loadChat();
      }
      this.cdr.detectChanges();
      this.data.taskCompleteFn();
    });
  }

  createCustomerClicked() {
    this.formHelper.isFormValidElseShowErrors(this.customerForm, "CUSTOMER_ADDED_ERROR_TITLE", () => this.createCustomer("customerCreated", "var(--success)"));
  }

  saveCustomerClicked() {
    this.formHelper.isFormValidElseShowErrors(this.customerForm, "CUSTOMER_ADDED_ERROR_TITLE", () => this.saveCustomer("customerSaved", "var(--success)"));
  }

  saveCustomer(resultText: string, resultColor: string) {
    const customer = this.customerService.mapFormToCustomer(this.customerForm);
    this.customerService.update(customer).subscribe((res) => {
      const params = new Map();
      params.set("customerId", customer.id);
      this.completeTask("APPROVED", this.task.task.id, null, resultColor, resultText, params);
      this.formHelper.disableControls(this.customerForm);
    });
  }

  createCustomer(resultText: string, resultColor: string) {
    const customer = this.customerService.mapFormToCustomer(this.customerForm);
    this.keycloakConnector
      .createUser(customer)
      .then((userId) => {
        customer.id = userId;
        this.customerService.startCustomerRegistration(customer).subscribe(
          (res) => {
            const params = new Map();
            params.set("customerId", userId);
            this.completeTask("APPROVED", this.task.task.id, null, resultColor, resultText, params);
            this.formHelper.disableControls(this.customerForm);
          },
          (error) => {
            this.keycloakConnector.deleteUser(userId).subscribe(() => {});
          }
        );
      })
      .catch((err) => {
        this.openCreateCustomerErrorDialog(this.keycloakConnector.handleErrorMessage(err.error.errorMessage));
      });
  }

  private openCreateCustomerErrorDialog(errorMessage: string): void {
    const title = this.translateService.instant("customerCreateErrorTitle");
    this.dialogService.openErrorDialog(title, [errorMessage]);
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }
}
