import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromActiveEvents from "../reducers/active-event.reducer";
import { EventOverviewDTO } from "src/app/services";

export const getActiveEventsState = createSelector(fromFeature.getGibState, (state: fromFeature.GibState) => state.activeEvents);
export const getActiveEventsEntities = createSelector(getActiveEventsState, fromActiveEvents.getActiveEventsEntities);
export const getActiveEventsOverview = (period: string) =>
  createSelector(getActiveEventsEntities, (entities) => {
    if (entities && entities[period]) {
      return Object.keys(entities[period]).map((id) => entities[period][id]);
    }
  });
export const getActiveEventsTwoWeeks = (fromDate: Date, toDate: Date) =>
  createSelector(getActiveEventsEntities, (entities) => {
    const fromPeriod = getPeriod(fromDate);
    const toPeriod = getPeriod(toDate);

    let period: string = "";
    let events: EventOverviewDTO[] = [];

    if (fromPeriod === toPeriod) {
      period = fromPeriod;
      if (entities && entities[period]) {
        events = Object.keys(entities[period])
          .map((id) => entities[period][id])
          .filter((event) => new Date(event.eventDate).setHours(0, 0, 0, 0) >= fromDate.setHours(0, 0, 0, 0) && new Date(event.eventDate).setHours(0, 0, 0, 0) <= toDate.setHours(0, 0, 0, 0));
      }
    } else {
      period = fromPeriod;
      if (entities && entities[period]) {
        events = Object.keys(entities[period])
          .map((id) => entities[period][id])
          .filter((event) => new Date(event.eventDate).setHours(0, 0, 0, 0) >= fromDate.setHours(0, 0, 0, 0));
      }
      period = toPeriod;
      if (entities && entities[period]) {
        events = events.concat(
          Object.keys(entities[period])
            .map((id) => entities[period][id])
            .filter((event) => new Date(event.eventDate).setHours(0, 0, 0, 0) <= toDate.setHours(0, 0, 0, 0))
        );
      }
    }
    return events;
  });
export const getActiveEvents = (period: string) =>
  createSelector(getActiveEventsEntities, (entities) => {
    if (entities && entities[period]) {
      return Object.keys(entities[period])
        .map((id) => entities[period][id])
        .filter((event) => !event.reduxId.startsWith("-"));
    }
  });
export const getActiveEventsPeriods = createSelector(getActiveEventsEntities, (entities) => {
  return Object.keys(entities);
});
export const getActiveEventsLoaded = createSelector(getActiveEventsState, fromActiveEvents.getActiveEventsLoaded);
export const getActiveEventsLoading = createSelector(getActiveEventsState, fromActiveEvents.getActiveEventsLoading);

function getPeriod(date: Date): string {
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return month + "/" + date.getFullYear();
}
